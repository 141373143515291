import React from "react";
import { Component, Fragment } from "react";
import {
	Progress,
	Card,
	CardBody,
  CardTitle,
	Row,
	Col,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown,
	Tooltip
} from 'reactstrap';

import img1 from '../../../assets/images/background/active-bg.png';
import Moment from 'moment';
import { authenticationService } from '../../../jwt/_services';

class TopComponentsByBatch extends React.Component {

	noResults = true;
	intervalID = 0;

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			topComponents: [],
			colors: [{color:'warning'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'}, {color:'cyan'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'},{color:'warning'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'}, {color:'cyan'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'}],
      batches: [{name:'AZENA'}, {name:'320'}, {name:'500'}, {name:'504'}, {name:'510'}, {name:'520'}, {name:'530'}, {name:'Jaguar'}, {name:'Koala'}, {name:'Lion'}, {name:'Monkey'}],
			noDevices: false,
			noDataForDevices: false,
			currentDataSetting: 1,
			startDate: Moment().subtract(3, 'year').format('YYYY-MM-DD'),
			endDate: Moment().format('YYYY-MM-DD'),
			currentDate: Moment().format('YYYY-MM-DD'),
			isOpen: false,
			currentItem: "320",
			tooltipOpen: false,
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			ratioRange: 0,
      bomData: []
	  };

		this.toggleDropDown = this.toggleDropDown.bind(this);
		//this.handleSubmit = this.handleSubmit.bind(this);
		//this.handleChange = this.handleChange.bind(this);

		this.toggleTooltip = this.toggleTooltip.bind(this);
  }

	async componentDidMount() {
    this.getBOMData();
		this.getData();
		//this.intervalID = setInterval(this.getData, 5000);
	}

	toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	selectDropDownItem(id, title) {

    console.log("selectDropDownItem id: " + id);
    console.log("selectDropDownItem title: " + title);

		this.setState({ topComponents: [] });

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		//this.getData();
    setTimeout(this.getData, 1000);
  }

	componentWillUnmount() {
		//console.log("top-procedures componentWillUnmount");
		clearInterval(this.intervalID);
	}

  getBOMData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-bill-of-materials";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all BOM data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ bomData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getData = async () => {

    var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-top-components-by-batch?Quantity=20&Batch="+this.state.currentItem;

    console.log("url: " + url);

		try {

	    const response = await fetch(url, {
	      method: 'GET',
	      headers: {
	        Authorization: this.state.currentUser.jwtToken
	      },
	    });

	    const body = await response.json();
	    console.log("topprocedures body response: " + JSON.stringify(body));

	    if (body.status == 'Success') {
	      if (body.response.length>0) {
	        var largest = 100;
	        for (var i=0; i < body.response.length; i++) {
	          if (body.response[i].total > largest) {
	            largest = body.response[i].total;
	          }
	        }
	        console.log("topprocedures body response LARGEST: " + largest);
	        this.setState({ topComponents: body.response }, () => console.log(this.state.topComponents));
	        this.setState({
	          noDataForDevices: false,
	          ratioRange: 2*largest
	        });
	      } else {
	        this.setState({
	          noDataForDevices: true
	        });
	      }
	    } else {
	      this.setState({
	        noDataForDevices: true
	      });
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	}

	getColor(presetID) {

		var color = "success";

		if (presetID>=1 && presetID<=5) {
			color = "success";
		} else if (presetID>=11 && presetID<=19) {
			color = "info";
		} else if (presetID>=21 && presetID<=23) {
			color = "yellow";
		}

		return color;

	}

  getName(bomID) {

		var name = "N/A";

    console.log("bomID: " + bomID);

    const result = this.state.bomData.filter(bom => bom.id==bomID);

    name = result[0].Description;

		return name;

	}

	render() {

		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-1 [Classic]                                                  */
			/*--------------------------------------------------------------------------------*/
			<Card className="card-hover">
				<CardBody style={{ height: 650 }}>
					<Row>
						<Col xs="8" md="8" lg="8" className="text-left">
							<CardTitle>Top 10 Components By Batch
							<i style={{color: '#ffffff'}} className="mdi mdi-information-outline ml-1" id="TopProceduresTooltip"/>
							<Tooltip placement="top" isOpen={this.state.tooltipOpen} target="TopProceduresTooltip" toggle={this.toggleTooltip}>
								Shows the most common replaced components used by device model.
							</Tooltip>
							</CardTitle>
						</Col>
						<Col xs="4" md="4" lg="4" className="text-right">
							<ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggleDropDown} direction='down'>
								<DropdownToggle color="dark" caret>
									{this.state.currentItem}
								</DropdownToggle>
								<DropdownMenu right>
                  {this.state.batches.map((batch, i) => (
                    <Fragment>
                      <DropdownItem onClick={() => this.selectDropDownItem(i, batch.name)}>{batch.name}</DropdownItem>
                    </Fragment>
                  ))}
								</DropdownMenu>
							</ButtonDropdown>
						</Col>
					</Row>
					{this.state.noDevices ? (
						<div className="mt-5 text-center">
							<h5>User has no devices</h5>
						</div>
					) : (
            <Fragment>
            <div className="ml-auto mt-1" style={{height: '555px', overflow: "scroll"}}>
                {this.state.noDataForDevices ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: '100%'}}>
                    <h5>No data for Batch</h5>
                  </div>
                ) : (
                  <div className="pt-1">
                    {this.state.topComponents.map((component, i) => (
                      <Fragment>
                      <Row key={i}>
                        <Col xs="6" md="6" lg="6" className="text-left">
                          <span>{component.total}</span>
                        </Col>
                        <Col xs="6" md="6" lg="6" className="text-right">
                          {this.state.bomData.length>0 &&
                            <span>{this.getName(component.bomID)}</span>
                          }
                        </Col>
                      </Row>
                      <Progress multi className="mt-1 mb-3">
                        <Progress bar color={this.state.colors[i].color} value={(component.total/this.state.ratioRange)*100} />
                      </Progress>
                      </Fragment>
                    ))}
                  </div>
                )}
            </div>
            </Fragment>
					)}
				</CardBody>
			</Card>

		);
	}
}

export default TopComponentsByBatch;
