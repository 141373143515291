import React from 'react';
import { connect } from 'react-redux';
import { Fragment } from "react";
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from './layout-components/header/header';
import Sidebar from './layout-components/sidebar/sidebar';
import Footer from './layout-components/footer/footer';
import Customizer from './layout-components/customizer/customizer';

import { authenticationService } from '../jwt/_services';

//Moving router
import Dashboard from '../views/dashboard/dashboard';
import ActiveRepairs from '../views/active-repairs/active-repairs';
import CompletedRepairs from '../views/completed-repairs/completed-repairs';
import RepairLog from '../views/repair-log/repair-log';
import RepairOptionManagement from '../views/repair-option-management/repair-option-management';
import LiveInventoryList from '../views/live-inventory/live-inventory';
import InventoryManagementList from '../views/inventory-management/inventory-management';
import Users from '../views/users/users';
import ComponentStatistics from '../views/component-statistics/component-statistics';
import PendingCustomerApproval from '../views/pending-customer-approval/pending-customer-approval';
import RepairInstructions from '../views/repair-instructions/repair-instructions';
import AssemblyInstructions from '../views/assembly-instructions/assembly-instructions';
import RepairVideos from '../views/repair-videos/repair-videos';
import GeminiUpdate from '../views/gemini-update/gemini-update';
//import GeminiRemoteSupport from '../views/gemini-remote-support/gemini-remote-support';
import EvoRemoteSupport from '../views/evo-remote-support/evo-remote-support';
import GeminiDevices from '../views/gemini-devices/gemini-devices';

import QuotingOptionsView from '../views/quoting-options-view/quoting-options-view';
import RepairOptionsView from '../views/repair-options-view/repair-options-view';
import RepairReasonsView from '../views/repair-reasons-view/repair-reasons-view';
import TAMNamesView from '../views/tam-names-view/tam-names-view';
import CustomerNotesView from '../views/customer-notes-view/customer-notes-view';
import DevicesBatchUploadView from '../views/devices-batch-upload-view/devices-batch-upload-view';
import ProductBatchView from '../views/product-batch-view/product-batch-view';
import ReportsView from '../views/reports-view/reports-view';
import Pending from '../views/pending/pending';
import DealerListView from '../views/dealer-list-view/dealer-list-view';
import BatchShipmentDateUploadView from '../views/batch-shipment-date/batch-shipment-date-view';
import Calibration from '../views/calibration/calibration';

const mapStateToProps = state => ({
	...state
});

var testCount = 0;

class Fulllayout extends React.Component {

	intervalID = 0;

	constructor(props) {
		super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log("currentUser fulllayout: "+JSON.stringify(currentUser));

		//console.log("currentUser role: "+JSON.stringify(currentUser.payload["custom:role"]));

		this.updateDimensions = this.updateDimensions.bind(this);
		this.state = {
			isOpen: false,
			currentUser,
			addRoutes: 1,
			width: window.innerWidth,
			ThemeRoutes: []
		};

		this.props.history.listen((location, action) => {
			if (window.innerWidth < 767 &&
				document.getElementById('main-wrapper').className.indexOf("show-sidebar") !== -1) {
				document.getElementById('main-wrapper').classList.toggle("show-sidebar");
			}
		});
	}
	/*--------------------------------------------------------------------------------*/
	/*Life Cycle Hook, Applies when loading or resizing App                           */
	/*--------------------------------------------------------------------------------*/
	componentDidMount() {
		window.addEventListener("load", this.updateDimensions);
		window.addEventListener("resize", this.updateDimensions);

		this.generateThemeRoutes();

	}


	generateThemeRoutes() {

		const currentUser = authenticationService.currentUserValue;
		console.log("currentUser router: "+JSON.stringify(currentUser));

		var ThemeRoutes = [
			{
				collapse: false,
				open: false,
				name: "Pending",
				path: "/pending",
				state: "pending",
				icon: "mdi mdi-view-dashboard",
				component: Pending,
				child: [

				]
			},
			{ path: '/', pathTo: '/pending', name: 'Pending', redirect: true }

		];

		if ((currentUser.payload['custom:role'] && currentUser.payload['custom:role']>1)) {
			ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "Dashboard",
					path: "/dashboard",
					state: "dashboard",
					icon: "mdi mdi-view-dashboard",
					component: Dashboard,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Component Statistics",
					path: "/component-statistics",
					state: "component-statistics",
					icon: "mdi mdi-chart-bar",
					component: ComponentStatistics,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/repair-reasons",
							name: 'Repair Reasons',
							state: "repair-reasons",
							icon: 'mdi mdi-wrench',
							component: RepairReasonsView
						},
						{
							path: "/repair-options",
							name: 'Repair Options',
							state: "repair-options",
							icon: 'mdi mdi-chart-pie',
							component: RepairOptionsView
						},
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
						{
							path: "/inventory-management",
							name: 'Inventory Management',
							state: "inventory-management",
							icon: 'mdi mdi-sort-numeric',
							component: InventoryManagementList
						},
						{
							path: "/quoting-options",
							name: 'Quoting Options',
							state: "quoting-options",
							icon: 'mdi mdi-cash-usd',
							component: QuotingOptionsView
						},
						{
							path: "/customer-notes",
							name: 'Customer Notes',
							state: "customer-notes",
							icon: 'mdi mdi-note-text',
							component: CustomerNotesView
						},
						{
							path: "/product-batch-management",
							name: 'Product Batch Management',
							state: "product-batch-management",
							icon: 'mdi mdi-note-text',
							component: ProductBatchView
						},
						{
							path: "/reports",
							name: 'Reports',
							state: "reports",
							icon: 'mdi mdi-chart-pie',
							component: ReportsView
						},
						{
							path: "/dealers",
							name: 'Dealers',
							state: "dealers",
							icon: 'mdi mdi-sitemap',
							component: DealerListView
						},
					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/calibration",
							name: 'Calibration',
							state: "calibration",
							icon: 'mdi mdi-chart-bar',
							component: Calibration
						},
						{
							path: "/repair-instructions",
							name: 'Repair Instructions',
							state: "repair-instructions",
							icon: 'mdi mdi-wrench',
							component: RepairInstructions
						},
						{
							path: "/assembly-instructions",
							name: 'Assembly Instructions',
							state: "assembly-instructions",
							icon: 'mdi mdi-wrench',
							component: AssemblyInstructions
						},
						{
							path: "/repair-videos",
							name: 'Repair Videos',
							state: "repair-videos",
							icon: 'mdi mdi-wrench',
							component: RepairVideos
						},
						{
							path: "/gemini-update",
							name: 'Gemini Update',
							state: "gemini-update",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-serials",
							name: 'Gemini Serials',
							state: "gemini-serials",
							icon: 'mdi mdi-wrench',
							component: GeminiDevices
						},
						{
							path: "/gemini-remote-support",
							name: 'Gemini Remote Support',
							state: "gemini-remote-support",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-batch-upload",
							name: 'Gemini SN Batch Upload',
							state: "gemini-batch-upload",
							icon: 'mdi mdi-wrench',
							component: DevicesBatchUploadView
						},
						{
							path: "/batch-shipment-date-upload",
							name: "G2 Shipment Date Upload",
							state: "batch-shipment-date-upload",
							icon: "mdi mdi-library-books",
							component: BatchShipmentDateUploadView,
						},
					]
				},
				{
					collapse: false,
					open: false,
					path: "/users",
					name: "Users",
					state: "Users",
					icon: "mdi mdi-account-multiple",
					component: Users,
					child: [

					]
				},
				{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

			];

		}

		if ((currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1)) {
			ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Component Statistics",
					path: "/component-statistics",
					state: "component-statistics",
					icon: "mdi mdi-chart-bar",
					component: ComponentStatistics,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
					]
				},
				{
					collapse: false,
					open: false,
					name: "Live Inventory",
					path: "/live-inventory",
					state: "live-inventory",
					icon: "mdi mdi-counter",
					component: LiveInventoryList,
					child: [

					]
				},
				{ path: '/', pathTo: '/active-repairs', name: 'Active Repairs', redirect: true }

			];
		}

		if (currentUser.payload['custom:market'] && currentUser.payload['custom:market']=="us-azena" && currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1) {
			ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "Dashboard",
					path: "/dashboard",
					state: "dashboard",
					icon: "mdi mdi-view-dashboard",
					component: Dashboard,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Component Statistics",
					path: "/component-statistics",
					state: "component-statistics",
					icon: "mdi mdi-chart-bar",
					component: ComponentStatistics,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/repair-reasons",
							name: 'Repair Reasons',
							state: "repair-reasons",
							icon: 'mdi mdi-wrench',
							component: RepairReasonsView
						},
						{
							path: "/repair-options",
							name: 'Repair Options',
							state: "repair-options",
							icon: 'mdi mdi-chart-pie',
							component: RepairOptionsView
						},
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
						{
							path: "/inventory-management",
							name: 'Inventory Management',
							state: "inventory-management",
							icon: 'mdi mdi-sort-numeric',
							component: InventoryManagementList
						},
						{
							path: "/quoting-options",
							name: 'Quoting Options',
							state: "quoting-options",
							icon: 'mdi mdi-cash-usd',
							component: QuotingOptionsView
						},
						{
							path: "/customer-notes",
							name: 'Customer Notes',
							state: "customer-notes",
							icon: 'mdi mdi-note-text',
							component: CustomerNotesView
						},
						{
							path: "/dealers",
							name: 'Dealers',
							state: "dealers",
							icon: 'mdi mdi-sitemap',
							component: DealerListView
						},
					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/calibration",
							name: 'Calibration',
							state: "calibration",
							icon: 'mdi mdi-chart-bar',
							component: Calibration
						},
						{
							path: "/repair-instructions",
							name: 'Repair Instructions',
							state: "repair-instructions",
							icon: 'mdi mdi-wrench',
							component: RepairInstructions
						},
						{
							path: "/assembly-instructions",
							name: 'Assembly Instructions',
							state: "assembly-instructions",
							icon: 'mdi mdi-wrench',
							component: AssemblyInstructions
						},
						{
							path: "/repair-videos",
							name: 'Repair Videos',
							state: "repair-videos",
							icon: 'mdi mdi-wrench',
							component: RepairVideos
						},
						{
							path: "/gemini-update",
							name: 'Gemini Update',
							state: "gemini-update",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-serials",
							name: 'Gemini Serials',
							state: "gemini-serials",
							icon: 'mdi mdi-wrench',
							component: GeminiDevices
						},
						{
							path: "/gemini-remote-support",
							name: 'Gemini Remote Support',
							state: "gemini-remote-support",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-batch-upload",
							name: 'Gemini SN Batch Upload',
							state: "gemini-batch-upload",
							icon: 'mdi mdi-wrench',
							component: DevicesBatchUploadView
						},
						{
							path: "/batch-shipment-date-upload",
							name: "G2 Shipment Date Upload",
							state: "batch-shipment-date-upload",
							icon: "mdi mdi-library-books",
							component: BatchShipmentDateUploadView,
						},
					]
				},
				{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

			];
		}

		if (currentUser.payload['custom:market'] && currentUser.payload['custom:market']=="us" && currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1) {
			ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Pending Customer Approval",
					path: "/pending-customer-approval",
					state: "pending-customer-approval",
					icon: "mdi mdi-timer",
					component: PendingCustomerApproval,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/gemini-update",
							name: 'Gemini Update',
							state: "gemini-update",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-remote-support",
							name: 'Gemini Remote Support',
							state: "gemini-remote-support",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
					]
				},
				{ path: '/', pathTo: '/active-repairs', name: 'Active Repairs', redirect: true }

			];
		}

		if (currentUser.payload['custom:market'] && currentUser.payload['custom:market']=="europe" && currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1) {
			var ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "Dashboard",
					path: "/dashboard",
					state: "dashboard",
					icon: "mdi mdi-view-dashboard",
					component: Dashboard,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
						{
							path: "/quoting-options",
							name: 'Quoting Options',
							state: "quoting-options",
							icon: 'mdi mdi-cash-usd',
							component: QuotingOptionsView
						},
						{
							path: "/customer-notes",
							name: 'Customer Notes',
							state: "customer-notes",
							icon: 'mdi mdi-note-text',
							component: CustomerNotesView
						},
						{
							path: "/dealers",
							name: 'Dealers',
							state: "dealers",
							icon: 'mdi mdi-sitemap',
							component: DealerListView
						},
					]
				},
				{
					collapse: false,
					open: false,
					name: "Live Inventory",
					path: "/live-inventory",
					state: "live-inventory",
					icon: "mdi mdi-counter",
					component: LiveInventoryList,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/repair-instructions",
							name: 'Repair Instructions',
							state: "repair-instructions",
							icon: 'mdi mdi-wrench',
							component: RepairInstructions
						},
						{
							path: "/assembly-instructions",
							name: 'Assembly Instructions',
							state: "assembly-instructions",
							icon: 'mdi mdi-wrench',
							component: AssemblyInstructions
						},
						{
							path: "/repair-videos",
							name: 'Repair Videos',
							state: "repair-videos",
							icon: 'mdi mdi-wrench',
							component: RepairVideos
						},
						{
							path: "/gemini-update",
							name: 'Gemini Update',
							state: "gemini-update",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-serials",
							name: 'Gemini Serials',
							state: "gemini-serials",
							icon: 'mdi mdi-wrench',
							component: GeminiDevices
						},
						{
							path: "/gemini-remote-support",
							name: 'Gemini Remote Support',
							state: "gemini-remote-support",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-batch-upload",
							name: 'Gemini SN Batch Upload',
							state: "gemini-batch-upload",
							icon: 'mdi mdi-wrench',
							component: DevicesBatchUploadView
						},
						{
							path: "/batch-shipment-date-upload",
							name: "G2 Shipment Date Upload",
							state: "batch-shipment-date-upload",
							icon: "mdi mdi-library-books",
							component: BatchShipmentDateUploadView,
						},
					]
				},
				{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

			];
		}

		if (currentUser.payload['custom:market'] && currentUser.payload['custom:market']=="aus" && currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1) {
			var ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "Dashboard",
					path: "/dashboard",
					state: "dashboard",
					icon: "mdi mdi-view-dashboard",
					component: Dashboard,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
						{
							path: "/quoting-options",
							name: 'Quoting Options',
							state: "quoting-options",
							icon: 'mdi mdi-cash-usd',
							component: QuotingOptionsView
						},
						{
							path: "/customer-notes",
							name: 'Customer Notes',
							state: "customer-notes",
							icon: 'mdi mdi-note-text',
							component: CustomerNotesView
						},
						{
							path: "/dealers",
							name: 'Dealers',
							state: "dealers",
							icon: 'mdi mdi-sitemap',
							component: DealerListView
						},
					]
				},
				{
					collapse: false,
					open: false,
					name: "Live Inventory",
					path: "/live-inventory",
					state: "live-inventory",
					icon: "mdi mdi-counter",
					component: LiveInventoryList,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/repair-instructions",
							name: 'Repair Instructions',
							state: "repair-instructions",
							icon: 'mdi mdi-wrench',
							component: RepairInstructions
						},
						{
							path: "/assembly-instructions",
							name: 'Assembly Instructions',
							state: "assembly-instructions",
							icon: 'mdi mdi-wrench',
							component: AssemblyInstructions
						},
						{
							path: "/repair-videos",
							name: 'Repair Videos',
							state: "repair-videos",
							icon: 'mdi mdi-wrench',
							component: RepairVideos
						},
						{
							path: "/gemini-update",
							name: 'Gemini Update',
							state: "gemini-update",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-serials",
							name: 'Gemini Serials',
							state: "gemini-serials",
							icon: 'mdi mdi-wrench',
							component: GeminiDevices
						},
						{
							path: "/gemini-remote-support",
							name: 'Gemini Remote Support',
							state: "gemini-remote-support",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
					]
				},
				{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

			];
		}

		if (currentUser.payload['custom:market'] && currentUser.payload['custom:market']=="taiwan" && currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1) {
			var ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "Dashboard",
					path: "/dashboard",
					state: "dashboard",
					icon: "mdi mdi-view-dashboard",
					component: Dashboard,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
						{
							path: "/quoting-options",
							name: 'Quoting Options',
							state: "quoting-options",
							icon: 'mdi mdi-cash-usd',
							component: QuotingOptionsView
						},
						{
							path: "/customer-notes",
							name: 'Customer Notes',
							state: "customer-notes",
							icon: 'mdi mdi-note-text',
							component: CustomerNotesView
						},
						{
							path: "/dealers",
							name: 'Dealers',
							state: "dealers",
							icon: 'mdi mdi-sitemap',
							component: DealerListView
						},
					]
				},
				{
					collapse: false,
					open: false,
					name: "Live Inventory",
					path: "/live-inventory",
					state: "live-inventory",
					icon: "mdi mdi-counter",
					component: LiveInventoryList,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/repair-instructions",
							name: 'Repair Instructions',
							state: "repair-instructions",
							icon: 'mdi mdi-wrench',
							component: RepairInstructions
						},
						{
							path: "/assembly-instructions",
							name: 'Assembly Instructions',
							state: "assembly-instructions",
							icon: 'mdi mdi-wrench',
							component: AssemblyInstructions
						},
						{
							path: "/repair-videos",
							name: 'Repair Videos',
							state: "repair-videos",
							icon: 'mdi mdi-wrench',
							component: RepairVideos
						},
						{
							path: "/gemini-update",
							name: 'Gemini Update',
							state: "gemini-update",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-serials",
							name: 'Gemini Serials',
							state: "gemini-serials",
							icon: 'mdi mdi-wrench',
							component: GeminiDevices
						},
						{
							path: "/gemini-remote-support",
							name: 'Gemini Remote Support',
							state: "gemini-remote-support",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
					]
				},
				{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

			];
		}

		if (currentUser.payload['custom:market'] && currentUser.payload['custom:market']=="colombia" && currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1) {
			var ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "Dashboard",
					path: "/dashboard",
					state: "dashboard",
					icon: "mdi mdi-view-dashboard",
					component: Dashboard,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
						{
							path: "/quoting-options",
							name: 'Quoting Options',
							state: "quoting-options",
							icon: 'mdi mdi-cash-usd',
							component: QuotingOptionsView
						},
						{
							path: "/customer-notes",
							name: 'Customer Notes',
							state: "customer-notes",
							icon: 'mdi mdi-note-text',
							component: CustomerNotesView
						},
						{
							path: "/dealers",
							name: 'Dealers',
							state: "dealers",
							icon: 'mdi mdi-sitemap',
							component: DealerListView
						},
					]
				},
				{
					collapse: false,
					open: false,
					name: "Live Inventory",
					path: "/live-inventory",
					state: "live-inventory",
					icon: "mdi mdi-counter",
					component: LiveInventoryList,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/repair-instructions",
							name: 'Repair Instructions',
							state: "repair-instructions",
							icon: 'mdi mdi-wrench',
							component: RepairInstructions
						},
						{
							path: "/assembly-instructions",
							name: 'Assembly Instructions',
							state: "assembly-instructions",
							icon: 'mdi mdi-wrench',
							component: AssemblyInstructions
						},
						{
							path: "/repair-videos",
							name: 'Repair Videos',
							state: "repair-videos",
							icon: 'mdi mdi-wrench',
							component: RepairVideos
						},
						{
							path: "/gemini-update",
							name: 'Gemini Update',
							state: "gemini-update",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-serials",
							name: 'Gemini Serials',
							state: "gemini-serials",
							icon: 'mdi mdi-wrench',
							component: GeminiDevices
						},
						{
							path: "/gemini-remote-support",
							name: 'Gemini Remote Support',
							state: "gemini-remote-support",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
					]
				},
				{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

			];
		}

		if (currentUser.payload['custom:market'] && currentUser.payload['custom:market']=="brazil" && currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1) {
			var ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "Dashboard",
					path: "/dashboard",
					state: "dashboard",
					icon: "mdi mdi-view-dashboard",
					component: Dashboard,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
						{
							path: "/quoting-options",
							name: 'Quoting Options',
							state: "quoting-options",
							icon: 'mdi mdi-cash-usd',
							component: QuotingOptionsView
						},
						{
							path: "/customer-notes",
							name: 'Customer Notes',
							state: "customer-notes",
							icon: 'mdi mdi-note-text',
							component: CustomerNotesView
						},
						{
							path: "/dealers",
							name: 'Dealers',
							state: "dealers",
							icon: 'mdi mdi-sitemap',
							component: DealerListView
						},
					]
				},
				{
					collapse: false,
					open: false,
					name: "Live Inventory",
					path: "/live-inventory",
					state: "live-inventory",
					icon: "mdi mdi-counter",
					component: LiveInventoryList,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/repair-instructions",
							name: 'Repair Instructions',
							state: "repair-instructions",
							icon: 'mdi mdi-wrench',
							component: RepairInstructions
						},
						{
							path: "/assembly-instructions",
							name: 'Assembly Instructions',
							state: "assembly-instructions",
							icon: 'mdi mdi-wrench',
							component: AssemblyInstructions
						},
						{
							path: "/repair-videos",
							name: 'Repair Videos',
							state: "repair-videos",
							icon: 'mdi mdi-wrench',
							component: RepairVideos
						},
						{
							path: "/gemini-update",
							name: 'Gemini Update',
							state: "gemini-update",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-serials",
							name: 'Gemini Serials',
							state: "gemini-serials",
							icon: 'mdi mdi-wrench',
							component: GeminiDevices
						},
						{
							path: "/gemini-remote-support",
							name: 'Gemini Remote Support',
							state: "gemini-remote-support",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
					]
				},
				{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

			];
		}

		this.setState({ ThemeRoutes });
	}


	/*--------------------------------------------------------------------------------*/
	/*Function that handles sidebar, changes when resizing App                        */
	/*--------------------------------------------------------------------------------*/
	updateDimensions() {
		let element = document.getElementById('main-wrapper');
		this.setState({
			width: window.innerWidth
		});
		switch (this.props.settings.activeSidebarType) {
			case 'full':
			case 'iconbar':
				if (this.state.width < 1170) {
					element.setAttribute("data-sidebartype", "mini-sidebar");
					element.classList.add("mini-sidebar");
				} else {
					element.setAttribute("data-sidebartype", this.props.settings.activeSidebarType);
					element.classList.remove("mini-sidebar");
				}
				break;

			case 'overlay':
				if (this.state.width < 767) {
					element.setAttribute("data-sidebartype", "mini-sidebar");
				} else {
					element.setAttribute("data-sidebartype", this.props.settings.activeSidebarType);
				}
				break;

			default:
		}
	}
	/*--------------------------------------------------------------------------------*/
	/*Life Cycle Hook                                                                 */
	/*--------------------------------------------------------------------------------*/
	componentWillUnmount() {
		window.removeEventListener("load", this.updateDimensions);
		window.removeEventListener("resize", this.updateDimensions);
	}
	render() {
		console.log("currentUser role fulllayout: "+JSON.stringify(this.state.currentUser.role));
		/*--------------------------------------------------------------------------------*/
		/* Theme Setting && Layout Options wiil be Change From Here                       */
		/*--------------------------------------------------------------------------------*/
		return (
			<div
				id="main-wrapper"
				dir={this.props.settings.activeDir}
				data-theme={this.props.settings.activeTheme}
				data-layout={this.props.settings.activeThemeLayout}
				data-sidebartype={this.props.settings.activeSidebarType}
				data-sidebar-position={this.props.settings.activeSidebarPos}
				data-header-position={this.props.settings.activeHeaderPos}
				data-boxed-layout={this.props.settings.activeLayout}
			>
				{/*--------------------------------------------------------------------------------*/}
				{/* Header                                                                         */}
				{/*--------------------------------------------------------------------------------*/}
				<Header />
				{/*--------------------------------------------------------------------------------*/}
				{/* Sidebar                                                                        */}
				{/*--------------------------------------------------------------------------------*/}
				<Sidebar {...this.props} routes={this.state.ThemeRoutes} />
				{/*--------------------------------------------------------------------------------*/}
				{/* Page Main-Content                                                              */}
				{/*--------------------------------------------------------------------------------*/}
				<div className="page-wrapper d-block">
					<div className="page-content container-fluid">
					<Switch>
						<Route path="/repair-log/:id" key="1000" component={RepairLog}/>

						{
							// {this.state.currentUser && this.state.currentUser.payload['custom:role'] &&
							// 	<Route path="/vitals/:id" component={Vitals} key="1000" />
							// }
							// {this.state.currentUser && this.state.currentUser.payload['custom:role'] &&
							// 	<Route path="/customize/:id" component={VitalsCustomize} key="1001" />
							// }
							// {this.state.currentUser && this.state.currentUser.payload['custom:role'] &&
							// 	<Route path="/remotesupport/:id" component={RemoteSupport} key="1002" />
							// }
							// <Route path="/account" component={Account} key="1003" />
						}

						{this.state.ThemeRoutes.map((prop, key) => {
							if (prop.navlabel) {
								return null;
							}
							else if (prop.collapse) {
								return prop.child.map((prop2, key2) => {
									if (prop2.collapse) {
										return prop2.subchild.map((prop3, key3) => {

											// if (prop3.path.includes("vitals")) {
											// 	console.log("is vitals");
											// 	return (
											// 		<Route path="/vitals/:id" key={key3} component={props => (<Vitals {...props} serial={prop3.serial} />)} />
											// 	);
											// } else if (prop3.path.includes("customize")) {
											// 	console.log("is customize");
											// 	return (
											// 		<Route path="/customize/:id" key={key3} component={props => (<VitalsCustomize {...props} serial={prop3.serial} />)} />
											// 	);
											// } else {
											// 	return (
											// 		<Route path={prop3.path} component={prop3.component} key={key3} />
											// 	);
											// }

											return (
												<Route path={prop3.path} component={prop3.component} key={key3} />
											);

										});
									}
									return (
										<Route path={prop2.path} component={prop2.component} key={key2} />
									);
								});
							}
							else if (prop.redirect) {
								return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
							}
							else {
								return (
									<Route path={prop.path} component={prop.component} key={key} />
								);
							}
						})}
					</Switch>
					</div>
					<Footer />
				</div>
				{/*--------------------------------------------------------------------------------*/}
				{/* Customizer from which you can set all the Layout Settings                      */}
				{/*--------------------------------------------------------------------------------*/}

			</div>
		);
	}
}
export default connect(mapStateToProps)(Fulllayout);
