import React from "react";
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown,
	Tooltip
} from 'reactstrap';

import { authenticationService } from '../../../jwt/_services';
import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import Moment from 'moment';
var moment = require('moment-timezone');

class AverageAzenaRepairTime extends React.Component {

	noResults = true;
	intervalID = 0;

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			power: '',
      averageTime: '',
			noDevices: false,
			noData: false,
			currentDataSetting: 1,
			startDate: Moment().format('YYYY-MM-DD'),
			endDate: Moment().format('YYYY-MM-DD'),
			currentDate: Moment().format('YYYY-MM-DD'),
			isOpen: false,
			currentItem: "Today",
			tooltipOpen: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.toggleTooltip = this.toggleTooltip.bind(this);
  }

	async componentDidMount() {
		//this.selectDropDownItem(2, "Week");
    this.selectDropDownItem(3, 'Month')

	 	//this.getData();
		//this.intervalID = setInterval(()=>{ this.getData(); }, 5000);
	}

	toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	handleChange(event) {
		if (event.target.getAttribute('name') == 'startDate') {
			this.setState({startDate: event.target.value});
			this.setState({
	      currentItem: "Custom",
				currentDataSetting: 4
	    });
		} else if (event.target.getAttribute('name') == 'endDate') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({endDate: event.target.value});
			this.setState({
				currentItem: "Custom",
				currentDataSetting: 4
	    });
		}
  }

	handleSubmit(event) {

		this.getData();
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		if (id==1) {
			this.setState({ startDate: Moment().format('YYYY-MM-DD') });
		} else if (id==2) {
			this.setState({ startDate: Moment().subtract(1, 'week').format('YYYY-MM-DD') });
		} else if (id==3) {
			this.setState({ startDate: Moment().subtract(1, 'month').format('YYYY-MM-DD') });
		} else if (id==4) {
			this.setState({ startDate: Moment().subtract(1, 'month').format('YYYY-MM-DD') });
		}

		this.setState({ endDate: Moment().format('YYYY-MM-DD') });
		//this.getData();
    setTimeout(this.getData, 1000);
  }

	componentWillUnmount() {
		//console.log("UserPowerSettings componentWillUnmount");
		clearInterval(this.intervalID);
	}

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected });
		this.getData(rSelected);
  }

	stepBack () {
		if (this.state.currentDataSetting==1) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'day').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'day').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==2) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'week').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'week').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==3) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'month').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'month').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		}
		//this.getData();
    setTimeout(this.getData, 1000);
	}

	stepForward () {
		if (this.state.currentDataSetting==1) {
			let revisedStartDate = Moment(this.state.startDate).add(1, 'day').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).add(1, 'day').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==2) {
			let revisedStartDate = Moment(this.state.startDate).add(1, 'week').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).add(1, 'week').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		}
		else if (this.state.currentDataSetting==3) {
		 let revisedStartDate = Moment(this.state.startDate).add(1, 'month').format('YYYY-MM-DD');
		 let revisedEndDate = Moment(this.state.endDate).add(1, 'month').format('YYYY-MM-DD');
		 console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
		 console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
		 this.setState({ startDate: revisedStartDate });
		 this.setState({ endDate: revisedEndDate });
	 }
		//this.getData();
    setTimeout(this.getData, 1000);
	}

	getData = async () => {

		var timezone = Moment.tz.guess();

		console.log("Start Date: " + JSON.stringify(this.state.startDate));
		console.log("End Date: " + JSON.stringify(this.state.endDate));

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-azena-repair-time-average-date-range?StartDate="+this.state.startDate+"&EndDate="+this.state.endDate+"&Timezone="+timezone;
		console.log("used-power-settings date range url: " + url);

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("AverageRepairTime date range body response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				if (body.response.length>0) {
					//let powerAvg = parseFloat(body.response[0].date_average, 10)/10;
					//let roundedPower = powerAvg.toFixed(1)
					//this.setState({ power: roundedPower }, () => console.log(this.state.power));
	        this.setState({ averageTime: Math.round(body.response[0].date_average) });
					this.state.noData = false;
				} else {
					this.state.noData = true;
				}
				this.forceUpdate();
			} else {
				this.state.noData = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

		// } else {
		// 	var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/total-procedures?TimeInterval="+this.state.currentDataSetting;
		//
		// 	const response = await fetch(url, {
		// 		method: 'GET',
		// 		headers: {
		// 			Authorization: this.state.currentUser.jwtToken
		// 		},
		// 	});
		//
		// 	const body = await response.json();
		// 	console.log("totalprocedures body response: " + JSON.stringify(body));
		//
		// 	if (body.status == 'Success') {
		// 		if (body.results.length>0) {
		// 			this.setState({ totalProcedures: body.results[0].total }, () => console.log(this.state.totalProcedures));
		// 		} else {
		// 			this.state.noData = true;
		// 		}
		// 		this.forceUpdate();
		// 	} else {
		// 		this.state.noData = true;
		// 	}
		// }

	}

	render() {
		return (
      <Card className="card-hover">
				<CardBody style={{ height: 250 }}>
				<Row>
					<Col xs="9" md="9" lg="9" className="text-left">
						<CardTitle>Average Azena Repair Time
						<i style={{color: '#ffffff'}} className="mdi mdi-information-outline ml-1" id="AARTooltip"/>
						<Tooltip placement="top" isOpen={this.state.tooltipOpen} target="AARTooltip" toggle={this.toggleTooltip}>
							Shows the average repair time for the desired time period.
						</Tooltip>
						</CardTitle>
					</Col>
					<Col xs="3" md="3" lg="3" className="text-right">
						<ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
							<DropdownToggle color="dark" caret>
								{this.state.currentItem}
							</DropdownToggle>
							<DropdownMenu right>
								<DropdownItem onClick={() => this.selectDropDownItem(1, 'Today')}>Today</DropdownItem>
								<DropdownItem onClick={() => this.selectDropDownItem(2, 'Week')}>Week</DropdownItem>
								<DropdownItem onClick={() => this.selectDropDownItem(3, 'Month')}>Month</DropdownItem>
								<DropdownItem onClick={() => this.selectDropDownItem(4, 'Custom')}>Custom</DropdownItem>
							</DropdownMenu>
						</ButtonDropdown>
					</Col>
				</Row>
					{
					// <Row>
					// 	<Col xs="12" md="12" lg="12">
					// 		<div className="mt-1 mb-2">
					// 			<ButtonGroup style={{ width: '100%' }}>
					// 				<Button
					// 					style={{width: '100%'}}
					// 					color="dark"
					// 					onClick={() => this.onRadioBtnClick(1)}
					// 					active={this.state.rSelected === 1}
					// 				>
					// 					Today
					// 				</Button>
					// 				<Button
					// 					color="dark"
					// 					style={{width: '100%'}}
					// 					onClick={() => this.onRadioBtnClick(2)}
					// 					active={this.state.rSelected === 2}
					// 				>
					// 					This Week
					// 				</Button>
					// 				<Button
					// 					color="dark"
					// 					style={{width: '100%'}}
					// 					onClick={() => this.onRadioBtnClick(3)}
					// 					active={this.state.rSelected === 3}
					// 				>
					// 					This Month
					// 				</Button>
					// 			</ButtonGroup>
					// 		</div>
					// 	</Col>
					// </Row>
					}
					<div className="pt-3 pb-4">
						{this.state.currentDataSetting != 4 ? (
							<Row className="align-items-center">
								<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
									<Button size="sm" color="link" onClick={() => this.stepBack()} title="Vitals">
										<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-left mdi-24px"/>
									</Button>
								</div>
								<div style={{ width: '80%', margin: '0 auto', height: '100%' }}>
									<div className="mt-5 mb-3 text-center">
										{this.state.noDevices || this.state.noData &&
											<Fragment>
											{!this.state.noData &&
												<h2 style={{color:'#e9c736'}}>No results Found</h2>
											}
											{this.state.noData &&
												<h2 style={{color:'#e9c736'}}>No results Found</h2>
											}
											</Fragment>
										}
										{!this.state.noDevices && !this.state.noData &&
											<h2 style={{color:'#e9c736'}}>{this.state.averageTime} {parseInt(this.state.averageTime)==1 ? "Day" : "Days"}</h2>
										}
									</div>
								</div>
								<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
									<Button size="sm" color="link" disabled={this.state.currentDate==this.state.endDate} onClick={() => this.stepForward()} title="Vitals">
										<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-right mdi-24px"/>
									</Button>
								</div>
							</Row>
						) : (
							<Fragment>
								<div className="mt-5 mb-3 text-center">
									{this.state.noDevices || this.state.noData &&
										<Fragment>
										{!this.state.noData &&
											<h2 style={{color:'#e9c736'}}>No results Found</h2>
										}
										{this.state.noData &&
											<h2 style={{color:'#e9c736'}}>No results Found</h2>
										}
										</Fragment>
									}
									{!this.state.noDevices && !this.state.noData &&
										<h2 style={{color:'#e9c736'}}>{this.state.averageTime} Days</h2>
									}
								</div>
							</Fragment>
						)}
					</div>
					<Row className="mt-2 ml-1">
						{this.state.currentDataSetting == 1 ? (
							<div className="float-left" style={{ width: '40px' }}>
								<h6 style={{ lineHeight: '1.8' }}>Day:</h6>
							</div>
						) : (
							<div className="float-left" style={{ width: '40px' }}>
								<h6 style={{ lineHeight: '1.8' }}>From:</h6>
							</div>
						)}
						<div className="float-left" style={{ width: '140px' }}>
							<Input name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
						</div>
						{this.state.currentDataSetting != 1 &&
							<Fragment>
							<div className="float-left ml-2" style={{ width: '23px' }}>
								<h6 style={{ lineHeight: '1.8' }}>To:</h6>
							</div>
							<div className="float-left" style={{ width: '140px' }}>
								<Input name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
							</div>
							</Fragment>
						}
						<div className="float-left ml-2" style={{ width: '50px' }}>
							<Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="Vitals" disabled={this.state.currentDataSetting != 4}>
								GO
							</Button>
						</div>
					</Row>
        </CardBody>
      </Card>
		);
	}
}

export default AverageAzenaRepairTime;
