
import React, { Component, Fragment, useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardTitle,
	Row,
	Col,
	Button,
	Fade
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { authenticationService } from '../../jwt/_services';

import '../../assets/calculator/css/calculator.css';

import {
	TopPendingRepairs
} from '../../components/dashboard/';

class PendingCustomerApproval extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;

    this.state = {
			currentUser,
			dashboardTitle: "Dashboard - Page One",
			dashboardButtonTitle: "Dashboard - Page Two",
			dashboardCurrentPage: 1
	  };

		this.toggleDashBoardPage = this.toggleDashBoardPage.bind(this);
		//this.testCall = this.testCall.bind(this);

  }

	componentDidMount() {

		//this.getData();
	}

	componentWillUnmount() {

	}

	toggleDashBoardPage() {

		if (this.state.dashboardCurrentPage==1) {
			this.setState({ dashboardCurrentPage: 2, dashboardTitle: "Dashboard - Page Two", dashboardButtonTitle: "Dashboard - Page One"});
		} else {
			this.setState({ dashboardCurrentPage: 1, dashboardTitle: "Dashboard - Page One", dashboardButtonTitle: "Dashboard - Page Two"});
		}

	}

	getData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/all-devices";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all devices: " + JSON.stringify(body));

		if (body.status == 'Success') {
			//this.setState({ devices: body.results });
		} else {
      //ADD ERROR MESSAGE
    }

    //this.setState({ devicesLoaded: true });
	};


	render() {
		return (
			<div>
        <Row>
          <Col md="12">
            <TopPendingRepairs />
          </Col>
        </Row>
			</div>
		);
	}
}

export default PendingCustomerApproval;
