import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	ButtonDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class ProductBatchManagement extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info token: ' + JSON.stringify(currentUser.token));

    this.state = {
			repairCategories: [],
      repairOptions: [],
			repairBOM: [],
			bomData: [],
			productsData: [],
      batchesData: [],
			quoteData: [],
			tamData: [],
			productItemsForBOM: [],
			productItemsForQuoteItem: [],
			productItemsForCategory: [],
			obj: {},
			modal: false,
      modal2: false,
			modal3: false,
			modal4: false,
			canEditCost: false,
			isOpen: false,
			currentItem: "ALL",
			currentDataSetting: 0,
			isOpen2: false,
			currentItem2: "ALL",
			currentDataSetting2: 0,
			isOpen3: false,
			currentItem3: "ALL",
			currentDataSetting3: 0,
      newProductStep: 0,
      addNewBatch: false,
      editingID: 999,
      editingBatch: {},
      currentBatches: [],
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

    this.launchCreateProduct = this.launchCreateProduct.bind(this);
		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.toggle3 = this.toggle3.bind(this);

		this.toggleDropDown3 = this.toggleDropDown3.bind(this);
    this.toggleNewBatchClicked = this.toggleNewBatchClicked.bind(this);
    this.setEditing = this.setEditing.bind(this);

  }

	async componentDidMount() {

    this.getProductsData();
    this.getBatchData();
    //this.getOptionsData();
		//this.getBOMData();
		//this.getQuoteData();
		//this.getTAMData();

		console.log('repair options currentUser email: ' + JSON.stringify(this.state.currentUser.payload.email));

		if (this.state.currentUser.payload.email == "jluo@azenamedical.com" || this.state.currentUser.payload.email == "hbrown@azenamedical.com" || this.state.currentUser.payload.email == "ryan.a.stickel@gmail.com") {
			this.setState({ canEditCost: true });
		}
		//canEditCost
	}

  launchCreateProduct() {

    this.setState({
      currentBatches: [],
	  });

    this.toggle();
  }

	toggle() {
	  this.setState({
      newProductStep: 0,
	    modal: !this.state.modal,
	  });
	}

  toggle2() {

	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle3() {
	  this.setState({
	    modal3: !this.state.modal3
	  });
	}

	toggleDropDown3() {
    this.setState({
      isOpen3: !this.state.isOpen3
    });
  }

  toggleNewBatchClicked() {
    this.setState({
      addNewBatch: !this.state.addNewBatch
    });
  }

  setEditing(row, batch) {
    this.setState({
      editingID: row,
      editingBatch: batch
    });
  }


	selectDropDownItem3(id, title) {

    this.setState({
      currentItem3: title,
			currentDataSetting3: id
    });

		setTimeout(this.getCategoryData, 300);

  }

	componentWillUnmount() {

	}

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	getCategoryData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-repair-categories?ProductID="+this.state.currentDataSetting3;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all repairCategories: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ repairCategories: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getProductItemsForCategory = async (obj) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-product-items-for-category?CategoryID="+obj.categoryID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all ProductItemForCategory data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productItemsForCategory: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	addProductItemForCategory = async (categoryID, productID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-product-item-for-category?CategoryID="+categoryID+"&ProductID="+productID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("add ProductItemForCategory response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteAllProductItemsForCategory = async (categoryID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-product-items-for-category?CategoryID="+categoryID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("ProductItemForCategory delete response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getProductsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-products";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Products data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productsData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  getBatchData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-batches";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Batches data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ batchesData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	createProductSubmit = () => {
		//event.preventDefault();

    //ProductName
    var productName = document.getElementById("ProductName").value;

		this.setState({
			newProductStep: 1
		})

    this.createProduct(productName);
	}

  createProduct = async (productName) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-product?ProductName="+productName;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create category response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				var productID = body.response.insertId;

	      let productTemp = JSON.parse(JSON.stringify(this.state.productsData));

	      var productID = body.response.insertId;

	      var newProduct = {
	        ProductID: productID,
	        Name: productName
	      }

	      var newObj = {
	        ProductID: productID,
	        Name: productName,
	        Batches: []
	      }

	      productTemp.unshift(newProduct);
	      this.setState({ productsData: productTemp, newProductStep: 1, obj: newObj });

			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  createBatchSubmit = () => {
		//event.preventDefault();

    //ProductName
    var batchName = document.getElementById("BatchName").value;

		// this.setState({
		// 	addNewBatch: false
		// })

    this.createBatch(batchName);
	}

  createBatch = async (batchName) => {

    var productID = this.state.obj.ProductID;

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-batch?BatchName="+batchName+"&ProductID="+productID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create category response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				var batchID = body.response.insertId;

	      let currentBatchesTemp = JSON.parse(JSON.stringify(this.state.currentBatches));

	      var newBatch = {
	        id: batchID,
	        Name: batchName,
	        ProductID: productID
	      }

	      currentBatchesTemp.unshift(newBatch);

	      console.log("currentBatchesTemp: " + JSON.stringify(currentBatchesTemp));

	      this.setState({ currentBatches: currentBatchesTemp, addNewBatch: false });

	      this.getProductsData();
	      this.getBatchData();

			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  saveBatchName = async (batchName, batchID, batchInputID, orderID) => {

    console.log("saveBatchName batchName: " + batchName);
    console.log("saveBatchName batchID: " + batchID);
    console.log("saveBatchName batchInputID: " + batchInputID);

    var newBatchName = document.getElementById(batchInputID).value;

    console.log("saveBatchName newBatchName: " + newBatchName);

    if (this.state.editingBatch.Name != newBatchName) {
      console.log("save newBatchName: " + newBatchName);
    } else {
      console.log("newBatchName unchanged");
    }

    this.setState({ editingID: 999 });

    var productID = this.state.obj.id;

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-batch?BatchName="+newBatchName+"&BatchID="+batchID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create category response: " + JSON.stringify(body));

			if (body.status == 'Success') {

	      this.getBatchData();

	      // let newObj = JSON.parse(JSON.stringify(this.state.obj));
	      //
	      // console.log("newObj batchName: " + newObj);
	      //
	      // newObj.Batches[orderID].Name = newBatchName;
	      //
	      // this.setState({ obj: newObj });

			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleCategorySubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let categoryID = event.target.categoryID.value;
    console.log(`categoryID is:`, categoryID);
    let name = event.target.name.value;
    console.log(`name is:`, name);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.repairCategories));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			name: name
    }
		this.setState({
			repairCategories: newObj,
			modal: !this.state.modal
		})
    console.log("saved repairCategories is:", this.state.repairCategories[id].name);

    this.updateCategory(categoryID, name, products);
	}

  updateCategory = async (categoryID, name, products) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-repair-category?CategoryID="+categoryID+"&Name="+name;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Update category response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				await this.deleteAllProductItemsForCategory(categoryID);

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForCategory(categoryID, product);
				}

				// products.map((product, i) => {
				// 	this.addProductItemForBOM(bomID, product);
				// });

				this.getCategoryData();

			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteCategory = async () => {

		console.log("Delete Category: " + this.state.obj.name);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-repair-category?CategoryID="+this.state.obj.categoryID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete category response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle2();
				this.getCategoryData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));

		if (id == 'datecreated') {
			var dateString = Moment(row[id]).format('MMM Do YYYY');
			return (
					dateString !== undefined ?
							String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}

  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          borderBottom: "1px solid",
          borderBottomColor: "#282B31"
        }
      };
    }
    return {};
  };


  render() {

		const data = this.state.productsData.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

      const batches = this.state.batchesData.filter(batch => batch.ProductID==prop.id);

      console.log('found batches: ' + batches);

			return {
				id: key,
        ProductID: prop.id,
				Name: prop.Name,
        Batches: batches,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);

                const batches = this.state.batchesData.filter(batch => batch.ProductID==obj.ProductID);

                console.log('button obj: ' + obj);
								this.setState({
									obj: obj,
									modal2: !this.state.modal2,
                  currentBatches: batches
								});
								//this.showVerification(obj);

								//this.getProductItemsForCategory(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal2: !this.state.modal3,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		return (
      <Fragment>
			<Row>
				<Col xs="12" md="12" lg="12">
					<Card className="card-hover">
						<CardBody>
							<Row className="mb-3">
								<Col md="5">
									<h3 style={{color:'#737782'}}>Product and Batch Management</h3>
								</Col>
								<Col md="7" className="text-right">
									<div>
										<Button className="float-right ml-3" color="yellow" onClick={this.launchCreateProduct}>Create Product</Button>
									</div>
								</Col>
							</Row>
							<ReactTable
								style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
								columns={[
									{
										Header: "Product",
										accessor: "Name",
										minWidth: 40,
										style: {'display': 'flex', 'flexDirection': 'column'},
										Cell: row => (
												<div style={{ textAlign: "center" }}>{row.value}</div>
											)
									},
									{
										Header: "Batches",
										accessor: "Batches",
										minWidth: 13,
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
                        <div style={{ textAlign: "center" }}>
                        {row.value && row.value.length>0 ? (
                          <Fragment>
                          {row.value.map((batch, i) => (
                            <Fragment>
                            <Col xs="12">
      												<span>{batch.Name}</span>
      											</Col>
                            </Fragment>
                            ))
                          }

                          </Fragment>
                        ) : (
                          <Fragment>
                            <span>No Batches</span>
                          </Fragment>
                        )}
                        </div>
											)
									},
									{
										Header: "Actions",
										accessor: "actions",
										sortable: false,
										minWidth: 15,
										filterable: false
									}
								]}
								defaultPageSize={14}
								sortable={true}
								showPaginationBottom={true}
								className="-striped -highlight"
								data={data}
								filterable
								filterAll={true}
                getTrProps={this.getTrProps}
								defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
								getTrGroupProps={(state, rowInfo, column, instance) => {
												if (rowInfo !== undefined) {
														return {
																onClick: (e, handleOriginal) => {
																	console.log('It was in this row:', rowInfo)
																	console.log('with ID:', rowInfo.original.categoryID);

																	//this.goToRepairLog(rowInfo.original.repairId);
																},
																style: {
																		cursor: 'pointer'
																}
														}
												}}
										}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Create Product</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                <div style={{ width: "100%", float: "left"}}>
                  <Label for="ProductName">Product Name</Label>
                </div>
                <div className="pb-3" style={{ width: "50%", float: "left"}}>
                  <Input type="text" name="ProductName" id="ProductName" disabled={this.state.newProductStep==1}/>
                </div>
                {this.state.newProductStep==0 &&
                  <div style={{ width: "50%", float: "left"}}>
                    <Button color="primary" className="ml-1" onClick={this.createProductSubmit}>Save</Button>
                    <Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
                  </div>
                }
              </Col>
            </Row>
            {this.state.newProductStep==1 &&
              <Fragment>
                {this.state.addNewBatch ? (
                  <Fragment>
                    <Row>
                      <Col md="12">
                        <div style={{ width: "100%", float: "left"}}>
                          <Label for="ProductName">Batch Name</Label>
                        </div>
                        <div className="pb-3" style={{ width: "50%", float: "left"}}>
                          <Input type="text" name="BatchName" id="BatchName"/>
                        </div>
                        <div style={{ width: "50%", float: "left"}}>
                          <Button color="primary" className="ml-1" onClick={this.createBatchSubmit}>Save</Button>
                          <Button color="secondary" className="ml-1" onClick={this.toggleNewBatchClicked}>Cancel</Button>
                        </div>
                      </Col>
                    </Row>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Row>
                      <Col md="12">
                        <Label for="ProductName">Batch</Label><Button color="brightgreen" size="sm" className="ml-3" onClick={this.toggleNewBatchClicked}>ADD</Button>
                      </Col>
                    </Row>
                  </Fragment>
                )}
              </Fragment>
            }
            {this.state.currentBatches && this.state.currentBatches.length>0 &&
              <Fragment>
              <Row className="pt-3">
                <Col md="12">
                  <Label for="ProductName">Batch List</Label>
                </Col>
              </Row>
              {this.state.currentBatches.map((batch, i) => (
                <Fragment>
                  <Row>
                    <Col md="12">
                      <div className="pb-3" style={{ width: "50%", float: "left"}}>
                        <Input type="text" key={"BatchName"+batch.id} name={"BatchName"+batch.id} id={"BatchName"+batch.id} defaultValue={batch.Name} disabled={this.state.editingID!=i}/>
                      </div>
                      <div style={{ width: "50%", float: "left"}}>
                        {this.state.editingID==i ? (
                          <Button color="brightgreen" className="ml-1" onClick={()=>{this.saveBatchName(batch.Name, batch.id, "BatchName"+batch.id, i);}}>Save</Button>
                        ) : (
                          <Button color="primary" className="ml-1" onClick={()=>{this.setEditing(i, batch);}}>Edit</Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Fragment>
                ))
              }
              </Fragment>
            }
          </ModalBody>
          <ModalFooter>
          {this.state.newProductStep==1 &&
            <Button color="secondary" style={{float: "right"}} className="" onClick={this.toggle}>Close</Button>
          }
          </ModalFooter>
        </Modal>
				<Modal isOpen={this.state.modal2} toggle={this.toggle2}>
					<ModalHeader toggle={this.toggle2}>Edit Product Batches</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                <div style={{ width: "100%", float: "left"}}>
                  <Label for="ProductName">Product Name</Label>
                </div>
                <div className="pb-3" style={{ width: "50%", float: "left"}}>
                  <Input type="text" name="ProductName" id="ProductName" defaultValue={this.state.obj.Name} disabled={true}/>
                </div>
              </Col>
            </Row>
            {this.state.addNewBatch ? (
              <Fragment>
                <Row>
                  <Col md="12">
                    <div style={{ width: "100%", float: "left"}}>
                      <Label for="ProductName">Batch Name</Label>
                    </div>
                    <div className="pb-3" style={{ width: "50%", float: "left"}}>
                      <Input type="text" name="BatchName" id="BatchName"/>
                    </div>
                    <div style={{ width: "50%", float: "left"}}>
                      <Button color="primary" className="ml-1" onClick={this.createBatchSubmit}>Save</Button>
                      <Button color="secondary" className="ml-1" onClick={this.toggleNewBatchClicked}>Cancel</Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            ) : (
              <Fragment>
                <Row>
                  <Col md="12">
                    <Label for="ProductName">Batch</Label><Button color="brightgreen" size="sm" className="ml-3" onClick={this.toggleNewBatchClicked}>ADD</Button>
                  </Col>
                </Row>
              </Fragment>
            )}
            {this.state.currentBatches && this.state.currentBatches.length>0 &&
              <Fragment>
              <Row className="pt-3">
                <Col md="12">
                  <Label for="ProductName">Batch List</Label>
                </Col>
              </Row>
              {this.state.currentBatches.map((batch, i) => (
                <Fragment>
                  <Row>
                    <Col md="12">
                      <div className="pb-3" style={{ width: "50%", float: "left"}}>
                        <Input type="text" key={"BatchName"+batch.id} name={"BatchName"+batch.id} id={"BatchName"+batch.id} defaultValue={batch.Name} disabled={this.state.editingID!=i}/>
                      </div>
                      <div style={{ width: "50%", float: "left"}}>
                        {this.state.editingID==i ? (
                          <Button color="brightgreen" className="ml-1" onClick={()=>{this.saveBatchName(batch.Name, batch.id, "BatchName"+batch.id, i);}}>Save</Button>
                        ) : (
                          <Button color="primary" className="ml-1" onClick={()=>{this.setEditing(i, batch);}}>Edit</Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Fragment>
                ))
              }
              </Fragment>
            }
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" style={{float: "right"}} className="" onClick={this.toggle2}>Close</Button>
          </ModalFooter>
				</Modal>
				<Modal isOpen={this.state.modal3} toggle={this.toggle3}>
  				<ModalHeader toggle={this.toggle3}>Delete Product Reason</ModalHeader>
  				<ModalBody>
						<span>Are you sure you want to delete this Category?</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteCategory}>Yes</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle3}>Cancel</Button>
					</ModalFooter>
			  </Modal>
			</div>
      </Fragment>
		);
	}
}

export default ProductBatchManagement;
