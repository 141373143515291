import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';
var moment = require('moment-timezone');

class CompletedRepairsList extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			repairLogs: [],
			marketsData: [],
			pages: -1,
			loading: false,
			pageSize: 10,
			currentPage: 1,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			searchTerm: "",
			startDate: "",
			endDate: "",
			productSelect: "ALL",
			batchSelect: "ALL",
			marketSelect: "all",
			productsData: [],
			batchesData: [],
			totalResults: 0,
			productList: ["All", "Gemini", "EVO"],
			batchList: ["All", "320", "500", "504", "510", "520", "530", "AZENA", "Giraffe", "Hippo", "Iguana", "Jaguar", "Koala", "Lion", "Mercury", "Monkey"],
			currentDate: Moment().format('YYYY-MM-DD'),
			currentDataSetting: 0
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
		this.onRadioBtnClick2 = this.onRadioBtnClick2.bind(this);

		this.onChange = this.onChange.bind(this);
		this.onChangeProduct = this.onChangeProduct.bind(this);
		this.onChangeBatch = this.onChangeBatch.bind(this);
		this.handleChange = this.handleChange.bind(this);

  }

	async componentDidMount() {

		await this.getMarketsData();

		this.getProductsData();
		this.getBatchData();

    this.getData(this.state.currentPage, this.state.pageSize);
		//setTimeout(() => this.getData(this.state.currentPage, this.state.pageSize), 1000);

		//Test session error handling
		// var currentUserHack = JSON.parse(JSON.stringify(this.state.currentUser));
		// currentUserHack.jwtToken = "blahblah";
		// this.setState({ currentUser: currentUserHack });
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);
	}

	async onRadioBtnClick(marketSelect) {

		await this.setState({ marketSelect });

		this.setState({ marketSelect: marketSelect });

		setTimeout(this.onChange, 500);

	}

	async onRadioBtnClick2(productSelect) {

		this.setState({ productSelect: productSelect });
		this.setState({ batchSelect: "ALL" });

		setTimeout(this.onChange, 500);

	}

	getProductsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-products";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Products data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productsData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getBatchData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-batches";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Batches data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ batchesData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleChange(event) {
		if (event.target.getAttribute('name') == 'startDate') {
			this.setState({startDate: event.target.value});
			this.setState({
				currentDataSetting: 1
	    });
		} else if (event.target.getAttribute('name') == 'endDate') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({endDate: event.target.value});
			this.setState({
				currentDataSetting: 1
	    });
		}

		//this.searchData("", 1, this.state.pageSize);
		setTimeout(() => this.searchData("", 1, this.state.pageSize), 1000);
  }

	onChange() {
		var searchTerm = document.getElementById("SearchTerm").value;

		clearInterval(this.intervalID);

		this.setState({ currentPage: 1 });

		// if (searchTerm && searchTerm != "") {
		// 	console.log("searchTerm: " + searchTerm);
		// 	this.searchData(searchTerm, 1, this.state.pageSize);
		// 	//this.intervalID = setInterval(()=>{ this.searchData(searchTerm, 1, this.state.pageSize); }, 5000);
		// }

		this.searchData(searchTerm, 1, this.state.pageSize);
	}

	onChangeProduct = (event) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);
		console.log("checked value: " + event.target.checked);

		let id = event.target.id;
		let value = event.target.value;

		//console.log("repairLog: " + JSON.stringify(repairLog));

		this.setState({ productSelect: value });
		this.setState({ batchSelect: "ALL" });

		setTimeout(this.onChange, 500);

	}

	onChangeBatch = (event) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);
		console.log("checked value: " + event.target.checked);

		let id = event.target.id;
		let value = event.target.value;

		//console.log("repairLog: " + JSON.stringify(repairLog));

		this.setState({ batchSelect: value });

		setTimeout(this.onChange, 500);

	}

	onChangeMarket = (event) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);
		console.log("checked value: " + event.target.checked);

		let id = event.target.id;
		let value = event.target.value;

		console.log("onChangeMarket value: " + JSON.stringify(value));

		this.setState({ marketSelect: value });

		setTimeout(this.onChange, 500);

	}

	clearSearch() {

		clearInterval(this.intervalID);

		document.getElementById("SearchTerm").value = "";

		this.setState({ currentPage: 1, currentDataSetting:0 });
		this.setState({ startDate: "" });
		this.setState({ endDate: "" });
		this.setState({ productSelect: "ALL" });
		this.setState({ batchSelect: "ALL" });
		this.setState({ marketSelect: "all" });
		setTimeout(() => this.getData(1, this.state.pageSize), 500);
	}

	goToRepairLog(id){
		//window.location.href="/repair-log/"+id;

		window.open(
		  "/repair-log/"+id,
		  '_blank'
		);
	}

	getData = async (currentPage, pageSize) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-completed-repair-logs?CurrentPage="+currentPage+"&PageSize="+pageSize;

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']!="us" && this.state.currentUser.payload['custom:market']!="us-azena") {
			url = url+"&Market="+this.state.currentUser.payload['custom:market'];
		} else {
			if (this.state.marketSelect!="all") {
				url = url+"&Market="+this.state.marketSelect;
			}
		}

		console.log("url: " + url);

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all repairLogs: " + JSON.stringify(body));

			this.setState({loading: false});

			if (body.status == 'Success') {
				this.setState({ repairLogs: body.response, pages: body.pages, totalResults: body.totalrecords });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	searchData = async (searchTerm, currentPage, pageSize) => {

		var timezone = Moment.tz.guess();

		if (this.state.currentDataSetting==0) {

			var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"search-completed-repair-logs?SearchTerm="+encodeURIComponent(searchTerm)+"&CurrentPage="+currentPage+"&PageSize="+pageSize;

			if (this.state.productSelect!="ALL") {
				//url = url+"&Product="+this.state.productList[this.state.productSelect];
				url = url+"&Product="+this.state.productSelect;
			}

			if (this.state.batchSelect!="ALL") {
				//url = url+"&Batch="+this.state.batchList[this.state.batchSelect];
				url = url+"&Batch="+this.state.batchSelect;
			}

			if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']!="us" && this.state.currentUser.payload['custom:market']!="us-azena") {
				url = url+"&Market="+this.state.currentUser.payload['custom:market'];
			} else {
				if (this.state.marketSelect!="all") {
					url = url+"&Market="+this.state.marketSelect;
				}
			}

			console.log("url: " + url);

			try {

				const response = await fetch(url, {
					method: 'GET',
					headers: {
						Authorization: this.state.currentUser.jwtToken
					},
				});

				const body = await response.json();
				console.log("all repairLogs: " + JSON.stringify(body));

				this.setState({loading: false});

				if (body.status == 'Success') {
					this.setState({ repairLogs: body.response, pages: body.pages, totalResults: body.totalrecords });
				} else {
		      //ADD ERROR MESSAGE
		    }

			}
			catch (err) {
				console.log(err);
				authenticationService.refresh();
			}

		} else {
			var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"search-completed-repair-logs-date-range?SearchTerm="+encodeURIComponent(searchTerm)+"&CurrentPage="+currentPage+"&PageSize="+pageSize+"&StartDate="+this.state.startDate+"&EndDate="+this.state.endDate+"&Timezone="+timezone;

			if (this.state.productSelect!="ALL") {
				//url = url+"&Product="+this.state.productList[this.state.productSelect];
				url = url+"&Product="+this.state.productSelect;
			}

			if (this.state.batchSelect!="ALL") {
				//url = url+"&Batch="+this.state.batchList[this.state.batchSelect];
				url = url+"&Batch="+this.state.batchSelect;
			}

			if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']!="us" && this.state.currentUser.payload['custom:market']!="us-azena") {
				url = url+"&Market="+this.state.currentUser.payload['custom:market'];
			} else {
				if (this.state.marketSelect!="all") {
					url = url+"&Market="+this.state.marketSelect;
				}
			}

			console.log("url: " + url);

			try {

				const response = await fetch(url, {
					method: 'GET',
					headers: {
						Authorization: this.state.currentUser.jwtToken
					},
				});

				const body = await response.json();
				console.log("all repairLogs: " + JSON.stringify(body));

				this.setState({loading: false});

				if (body.status == 'Success') {
					this.setState({ repairLogs: body.response, pages: body.pages, totalResults: body.totalrecords });
				} else {
		      //ADD ERROR MESSAGE
		    }

			}
			catch (err) {
				console.log(err);
				authenticationService.refresh();
			}

		}

	};

	getMarketsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-markets";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all markets data: " + JSON.stringify(body));

			if (body.status == 'Success') {

				let userMarket = this.state.currentUser.payload['custom:market'];
				var marketsData = body.response;

				if (userMarket=='aus' || userMarket=='europe' || userMarket=='brazil' || userMarket=='taiwan' || userMarket=='colombia') {
					marketsData = marketsData.filter(market => market.Abbreviation.includes(userMarket))
				}

				this.setState({ marketsData });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  render() {

		var isForeignUser = false;

		if (this.state.currentUser.payload['custom:market'] && (this.state.currentUser.payload['custom:market']=="europe" || this.state.currentUser.payload['custom:market']=="aus" || this.state.currentUser.payload['custom:market']=="brazil" || this.state.currentUser.payload['custom:market']=="taiwan" || this.state.currentUser.payload['custom:market']=="colombia")) {
			isForeignUser = true;
		}

		var batchesList = [];

		if (this.state.productSelect!="ALL") {

			let currentProduct = this.state.productsData.find(product => product.Name==this.state.productSelect);

			if (currentProduct) {
				batchesList = this.state.batchesData.filter(batch => batch.ProductID==currentProduct.id);
			}

		} else {

			batchesList = this.state.batchesData;

		}

		const data = this.state.repairLogs.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedrepair, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				repairId: prop.id,
				productName: prop.ProductName,
				serialnum: prop.serialnum,
				batchnum: prop.batchnum,
				datecreated: Moment(prop.datecreated).format('MMM Do YYYY'),
				market: prop.market,
				Status: prop.Status,
				UPIClosed: prop.UPIClosed,
				CustomerName: prop.CustomerName,
				TrackingNum: prop.TrackingNum
			};
		});

		return (
      <Card className="card-hover">
				<CardBody>
          <Row className="mb-3">
            <Col md="3">
              <h1 style={{color:'#737782'}}>Completed Repairs</h1>
            </Col>
						<Col md="4" className="">
							<Row className="">
								{!isForeignUser &&
								<Col xs="12" md="12" lg="12" className="">
									<div className="mb-2">
										<ButtonGroup style={{ width: '100%' }}>
											<Button
												style={{width: '100%'}}
												size="sm"
												color="btngray"
												onClick={() => this.onRadioBtnClick("all")}
												active={this.state.marketSelect === "all"}
											>
												ALL Markets
											</Button>
											{this.state.marketsData.map((market, i) => (
												<Button
													className="ml-1"
													color="btngray"
													size="sm"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(market.Abbreviation)}
													active={this.state.marketSelect === market.Abbreviation}
												>
													{market.Name}
												</Button>
											))}
										</ButtonGroup>
									</div>
								</Col>
								}
							</Row>
							<Row className="">
								<Col xs="7" md="7" lg="7" className="">
									<div className="">
										<ButtonGroup style={{ width: '100%' }}>
											<Button
												style={{width: '100%'}}
												color="btngray"
												size="sm"
												onClick={() => this.onRadioBtnClick2("ALL")}
												active={this.state.productSelect === "ALL"}
											>
												ALL
											</Button>
											{this.state.productsData.map((product, i) => (
												<Button
													className="ml-1"
													style={{width: '100%'}}
													color="btngray"
													size="sm"
													onClick={() => this.onRadioBtnClick2(product.Name)}
													active={this.state.productSelect === product.Name}
												>
													{product.Name}
												</Button>
											))}
										</ButtonGroup>
									</div>
								</Col>
								<Col xs="5" md="5" lg="5" className="">
									<div className="float-right ml-2" style={{ width: '120px' }}>
										<Input style={{height: '30px', padding: '3px'}} type="select" name="batchSelect" id="batchSelect" value={this.state.batchSelect} onChange={this.onChangeBatch}>
											<option value="ALL">ALL</option>
											{batchesList.map((batch, i) => (
												<option value={batch.Name}>{batch.Name}</option>
											))}
										</Input>
									</div>
									<div className="float-right text-right" style={{ width: '60px' }}>
										<h6 style={{ lineHeight: '1.9' }}>Batch:</h6>
									</div>
								</Col>
							</Row>
						</Col>
						<Col md="2" className="">
							<Row className="ml-2">
								<Col xs="12" md="12" lg="12" className="">
									<div className="float-left text-right" style={{ width: '50px' }}>
										<h6 style={{ lineHeight: '1.9' }}>From:</h6>
									</div>
									<div className="float-left ml-2" style={{ width: '140px' }}>
										<Input style={{width:'100%', height: '30px'}} name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
									</div>
								</Col>
							</Row>
							<Row className="ml-2">
								<Col xs="12" md="12" lg="12" className="">
									<div className="float-left text-right" style={{ width: '50px' }}>
										<h6 style={{ lineHeight: '1.9' }}>To:</h6>
									</div>
									<div className="float-left ml-2" style={{ width: '140px' }}>
										<Input style={{width:'100%', height: '30px'}} name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
									</div>
								</Col>
							</Row>
						</Col>
						<Col md="3" className="text-left">
							<Row>
								<Col xs="12" md="12" lg="12" className="">
									<div className="float-left text-left" style={{ width: '50%' }}>
										<Label style={{fontSize:'18px'}} for="">SMART SEARCH:</Label>
									</div>
									{
										// <div className="float-right text-right" style={{ width: '50%' }}>
										// 	<Label style={{fontSize:'18px'}} for="">Records: {this.state.totalResults}</Label>
										// </div>
									}
								</Col>
							</Row>
							<Row>
								<Col xs="9" md="9" lg="9" className="">
									<Input style={{width:'100%', height: '30px'}} type="text" name="SearchTerm" id="SearchTerm" onChange={this.onChange}/>
								</Col>
								<Col xs="2" md="2" lg="2" className="text-left">
									<Button className="" size="sm" color="yellow" title="Clear" onClick={() => this.clearSearch()}>
										Clear
									</Button>
								</Col>
							</Row>
            </Col>
          </Row>
					<ReactTable
					  style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
						columns={[
							{
								Header: "ID",
								accessor: "repairId",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								minWidth: 15,
								Cell: row => (
					          <div style={{ textAlign: "center" }}>{row.value}</div>
					        )
							},
							{
								Header: "Serial Number",
								accessor: "serialnum",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								minWidth: 25,
								Cell: row => (
					          <div style={{ textAlign: "center" }}>{row.value}</div>
					        )
							},
							{
								Header: "Product Name",
								accessor: "productName",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								minWidth: 25,
								Cell: row => (
					          <div style={{ textAlign: "center" }}>{row.value}</div>
					        )
							},
							{
								Header: "Batch Number",
								accessor: "batchnum",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								minWidth: 25,
								Cell: row => (
					          <div style={{ textAlign: "center" }}>{row.value}</div>
					        )
							},
							{
								Header: "Creation Date",
								accessor: "datecreated",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								minWidth: 25,
								Cell: row => (
					          <div style={{ textAlign: "center" }}>{row.value}</div>
					        )
							},
							{
								Header: "Market",
								accessor: "market",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								minWidth: 15,
								Cell: row => (
					          <div style={{ textAlign: "center" }}>{row.value}</div>
					        )
							},
							{
								Header: "UPI Closed?",
								accessor: "UPIClosed",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								minWidth: 35,
								Cell: row => (

									<div style={{ textAlign: "center" }}>
										{row.value==1 ? (
											<i style={{color: '#21c603'}} className="mdi mdi-checkbox-marked-outline mdi-24px"/>
										) : (
											<i style={{color: '#f62d51'}} className="mdi mdi-close-outline mdi-24px"/>
										)}
									</div>

					        )
							},
							{
								Header: "Customer Name",
								accessor: "CustomerName",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								minWidth: 50,
								Cell: row => (
					          <div style={{ textAlign: "center" }}>{row.value}</div>
					        )
							},
							{
								Header: "Tracking #",
								accessor: "TrackingNum",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								minWidth: 40,
								Cell: row => (
					          <div style={{ textAlign: "center" }}>{row.value}</div>
					        )
							}
						]}
						defaultPageSize={this.state.pageSize}
						showPaginationBottom={true}
						className="-striped -highlight"
						data={data}
						pages={this.state.pages}
						sortable={false}
						loading={this.state.loading}
						onFetchData={(state, instance) => {
					    // show the loading overlay
					    this.setState({loading: true});
					    // fetch your data
							console.log("page: "+state.page+1);
							console.log("pageSize: "+state.pageSize);
							console.log("sorted: "+state.sorted);
							console.log("filtered: "+state.filtered);

							var searchTerm = document.getElementById("SearchTerm").value;

							if (searchTerm && searchTerm != "") {
								console.log("searchTerm: " + searchTerm);
								this.searchData(searchTerm, state.page+1, state.pageSize);
							} else {

								if (this.state.productSelect!=0 || this.state.batchSelect!=0 || this.state.currentDataSetting!=0) {
									this.searchData(searchTerm, state.page+1, state.pageSize);
								} else {
									this.getData(state.page+1, state.pageSize);
								}

							}

					  }}
						manual
						getTrGroupProps={(state, rowInfo, column, instance) => {
                    if (rowInfo !== undefined) {
                        return {
                            onClick: (e, handleOriginal) => {
                              console.log('It was in this row:', rowInfo);
															console.log('with ID:', rowInfo.original.repairId);

															this.goToRepairLog(rowInfo.original.repairId);
                            },
                            style: {
                                cursor: 'pointer'
                            }
                        }
                    }}
                }
					/>
					<Row className="mt-2 text-center">
						<Col md="12">
							<Label style={{fontSize:'15px'}} for=""><b>Records Found: {this.state.totalResults}</b></Label>
						</Col>
					</Row>
        </CardBody>
      </Card>
		);
	}
}

export default CompletedRepairsList;
