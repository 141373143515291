import React from "react";
import { Component, Fragment } from "react";
import ReactTable from 'react-table-v6'

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class UsersList extends React.Component {

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			totalProcedures: 0,
			modal: false,
      modal2: false,
			modal3: false,
			modal4: false,
			modal5: false,
      dropdownOpen: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
      users: [],
      roles: [],
      obj: {},
			code: 123456,
			verificationError: false,
			statusSubject: "Failed",
			statusMessage: "Failed to send message.",
	  };

		this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
		this.toggle3 = this.toggle3.bind(this);
		this.toggle4 = this.toggle4.bind(this);
		this.toggle5 = this.toggle5.bind(this);

		this.changeUserRole = this.changeUserRole.bind(this);
		this.changeUserMarket = this.changeUserMarket.bind(this);
  }

	async componentDidMount() {

    this.getData();
	}

  getData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/get-users";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});
			const body = await response.json();
			console.log("all users: " + JSON.stringify(body.response.Users));

			let users = body.response.Users;

			const filteredUsers = users.filter(user => {

					var includeUser = false;

					var role = user.Attributes.filter(attribute => attribute.Name == "custom:role").map(name => name.Value)
					var market = user.Attributes.filter(attribute => attribute.Name == "custom:market").map(name => name.Value)

					if (role) {
						if (role>0) {
							includeUser = true;
							console.log("user role: " + role);
						}
					}

					if (market && market!="") {
						includeUser = true;
						console.log("user market: " + market);
					}

					return includeUser;
			});

			console.log("filteredUsers users: " + JSON.stringify(filteredUsers));

			if (body.status == 'Success') {
				this.setState({ users: filteredUsers });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

  toggle2() {

	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	showVerification(obj) {
		this.sendCode(obj);

	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle3() {
	  this.setState({
	    modal3: !this.state.modal3
	  });
	}

	toggle4() {
	  this.setState({
	    modal4: !this.state.modal4
	  });
	}

	toggle5() {
	  this.setState({
	    modal5: !this.state.modal5
	  });
	}

	changeUserMarket = (event, id) => {

		console.log("changeUserMarket input id: " + id);
		console.log("changeUserMarket input value: " + event.target.value);

		let value = event.target.value;
		var market = "us";

		if (value=='US-UPI') {
			market = "us";
		} else if (value=='Europe') {
			market = "europe";
		} else if (value=='Australia') {
			market = "aus";
		} else if (value=='US-Azena') {
			market = "us-azena";
		} else if (value=='Brazil') {
			market = "brazil";
		} else if (value=='Taiwan') {
			market = "taiwan";
		} else if (value=='Colombia') {
			market = "colombia";
		}

		console.log(`user obj:`, this.state.users[id]);

		let newObj = JSON.parse(JSON.stringify(this.state.users));
    //console.log(`user copy is:`, newObj);

		let oldObj = this.state.users[id];

		var allowphonecontact = oldObj.Attributes.filter(attribute => attribute.Name == "custom:allowphonecontact").map(name => name.Value);
		var firstname = oldObj.Attributes.filter(attribute => attribute.Name == "given_name").map(name => name.Value);
		var lastname = oldObj.Attributes.filter(attribute => attribute.Name == "family_name").map(name => name.Value);
		var email = oldObj.Attributes.filter(attribute => attribute.Name == "email").map(name => name.Value);
		var phone = oldObj.Attributes.filter(attribute => attribute.Name == "custom:phone").map(name => name.Value);
		var role = oldObj.Attributes.filter(attribute => attribute.Name == "custom:role").map(name => name.Value);
		var sub = oldObj.Attributes.filter(attribute => attribute.Name == "sub").map(name => name.Value);

		var originRole = oldObj.Attributes.filter(attribute => attribute.Name == "custom:role").map(name => name.Value);

		if (role!=originRole) {
			newObj[id] = {
				Attributes: [ /* required */
					    {
					      Name: 'given_name', /* required */
					      Value: firstname
					    },
							{
					      Name: 'family_name', /* required */
					      Value: lastname
					    },
							{
					      Name: 'email', /* required */
					      Value: email
					    },
							{
					      Name: 'custom:role', /* required */
					      Value: role
					    },
							{
					      Name: 'custom:market', /* required */
					      Value: market
					    },
							{
					      Name: 'custom:phone', /* required */
					      Value: phone
					    },
							{
					      Name: 'custom:allowphonecontact', /* required */
					      Value: allowphonecontact
					    },
							{
					      Name: 'sub', /* required */
					      Value: sub
					    },
					  ],
	    }

			this.setState({
				users: newObj
			})

			this.changeMarketForUser(sub, market);
		}

	}

	changeMarketForUser = async (userSubID, market) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"change-user-market?UserID="+userSubID+"&MarketChange="+market;
		console.log("url: " + url);

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});
			const body = await response.json();
			console.log("market change response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({ userToDeleteDevices: body.response });
				//this.toggle3();
				this.getData();
			} else {
	      //ADD ERROR MESSAGE
				//this.setState({ userToDeleteDevices: [] });
				//this.toggle3();
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	changeUserRole = (event, id) => {

		console.log("changeUserRole input id: " + id);
		console.log("changeUserRole input value: " + event.target.value);

		let value = event.target.value;
		var role = 0;

		if (value=='General User') {
			role = 0;
		} else if (value=='Tech Support') {
			role = 1;
		} else if (value=='Administrator') {
			role = 2;
		}

		console.log(`user obj:`, this.state.users[id]);

		let newObj = JSON.parse(JSON.stringify(this.state.users));
    //console.log(`user copy is:`, newObj);

		let oldObj = this.state.users[id];

		var allowphonecontact = oldObj.Attributes.filter(attribute => attribute.Name == "custom:allowphonecontact").map(name => name.Value);
		var firstname = oldObj.Attributes.filter(attribute => attribute.Name == "given_name").map(name => name.Value);
		var lastname = oldObj.Attributes.filter(attribute => attribute.Name == "family_name").map(name => name.Value);
		var email = oldObj.Attributes.filter(attribute => attribute.Name == "email").map(name => name.Value);
		var phone = oldObj.Attributes.filter(attribute => attribute.Name == "custom:phone").map(name => name.Value);
		var market = oldObj.Attributes.filter(attribute => attribute.Name == "custom:market").map(name => name.Value);
		var sub = oldObj.Attributes.filter(attribute => attribute.Name == "sub").map(name => name.Value);

		var originRole = oldObj.Attributes.filter(attribute => attribute.Name == "custom:role").map(name => name.Value);

		if (role!=originRole) {
			newObj[id] = {
				Attributes: [ /* required */
					    {
					      Name: 'given_name', /* required */
					      Value: firstname
					    },
							{
					      Name: 'family_name', /* required */
					      Value: lastname
					    },
							{
					      Name: 'email', /* required */
					      Value: email
					    },
							{
					      Name: 'custom:role', /* required */
					      Value: role
					    },
							{
					      Name: 'custom:market', /* required */
					      Value: market
					    },
							{
					      Name: 'custom:phone', /* required */
					      Value: phone
					    },
							{
					      Name: 'custom:allowphonecontact', /* required */
					      Value: allowphonecontact
					    },
							{
					      Name: 'sub', /* required */
					      Value: sub
					    },
					  ],
	    }

			this.setState({
				users: newObj
			})

			this.changeRoleForUser(sub, role);
		}

	}

	changeRoleForUser = async (userSubID, role) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/change-user-role?UserID="+userSubID+"&RoleChange="+role;
		console.log("url: " + url);

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});
			const body = await response.json();
			console.log("role change response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({ userToDeleteDevices: body.response });
				//this.toggle3();
				this.getData();
			} else {
	      //ADD ERROR MESSAGE
				//this.setState({ userToDeleteDevices: [] });
				//this.toggle3();
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	async sendCode(obj) {

		console.log("Send Code to User: " + obj.email);

		var code = Math.floor(100000 + Math.random() * 900000);
		console.log("random code: "+code);

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/verify-change-for-user?UserEmail="+obj.email+"&ConfirmationCode="+code;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("send code status: " + JSON.stringify(body.status));

			if (body.status == 'Success') {
				this.setState({code: code});
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	}

  handleVerifySubmit = (event) => {
		event.preventDefault();

	  let code = event.target.code.value;
    console.log(`code is:`, code);

		if (code == this.state.code) {
			this.setState({
				verificationError: false
			});

			this.setState({
		    modal2: !this.state.modal2
		  });
			this.toggle();
		} else {
			this.setState({
		    verificationError: true
		  });
		}
	}

  addNewUser = async (firstname, lastname, phone, email) => {

    // authenticationService.register(firstname, lastname, email, password, role)
    //     .then(
    //         user => {
		//
    //             let status = user.status;
    //             console.log('status: ' + status);
		//
    //             if (status === 'Success') {
    //                 this.toggle2();
    //                 this.getData();
    //             } else if (status === 'Error') {
    //                 console.log('error info: ' + user.response);
    //             } else {
    //               console.log('status info: ' + status);
    //             }
		//
    //         },
    //         error => {
    //             console.log('error info: ' + error);
    //         }
    //     );

	};

	sendMessage = (event) => {
		event.preventDefault();

		console.log("Send message");

	  let firstname = event.target.firstname.value;
    console.log(`firstname is:`, firstname);
    let lastname = event.target.lastname.value;
    console.log(`lastname is:`, lastname);
		let email = event.target.email.value;
    console.log(`email is:`, email);
		let phone = event.target.phone.value;
		console.log(`phone is:`, phone);
		let allowphonecontact = event.target.allowphonecontact.value;
		console.log(`allowphonecontact is:`, allowphonecontact);
		let messagetype = event.target.messagetype.value;
		console.log(`messagetype is:`, messagetype);
		let subject = event.target.subject.value;
		console.log(`subject is:`, subject);
		let message = event.target.message.value;
		console.log(`role is:`, message);

		let formattedPhoneNumber = this.enforcePhoneNumberPattern(phone);
		console.log(`Formatted Phone Number is:`, formattedPhoneNumber);

		if (messagetype == "Email") {
			this.sendEmailMessage(email, subject, message);
		} else {
			this.sendTextMessage(formattedPhoneNumber, subject, message);
		}
	}

	enforcePhoneNumberPattern(string) {
	  let newString = string.match(/[0-9]{0,14}/g);

	  if (newString === null) {
	    return '';
	  }

	  // Join parts returned from RegEx match
	  newString = newString.join('');

	  // Start number with "+"
	  newString = '+' + newString;

	  // Limit length to 15 characters
	  newString = newString.substring(0, 15);

	  return newString;
	}

	sendTextMessage = async (recipient, subject, message) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/send-text-message-to-user?TextRecipient="+recipient+"&TextSubject="+subject+"&TextMessage="+encodeURIComponent(message);

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Send Text response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle4();
				this.setState({
					statusSubject: "SUCCESS!",
					statusMessage: "Text Message sent Successfully!"
				})

			} else {

				this.toggle4();
				this.setState({
					statusSubject: "FAILED!",
					statusMessage: "Failed to send Text Message to "+recipient+"."
				})

			}

			this.toggle5();

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	rfc3986EncodeURIComponent (str) {
	   return encodeURIComponent(str).replace(/[!()*]/g, escape);

		 //return encodeURIComponent(str).replace(/'/g, "%27");
	}

	fixedEncodeURIComponent(str) {
	  return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
	    return '%' + c.charCodeAt(0).toString(16);
	  });
	}

	sendEmailMessage = async (recipient, subject, message) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/send-email-to-user?EmailRecipient="+recipient+"&EmailSubject="+this.fixedEncodeURIComponent(subject)+"&EmailMessage="+this.fixedEncodeURIComponent(message);
		//var url = "EmailRecipient="+recipient+"&EmailSubject="+this.fixedEncodeURIComponent(subject)+"&EmailMessage="+this.fixedEncodeURIComponent(message);

		console.log("Send Email url: " + url);

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Send Text response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle4();
				this.setState({
					statusSubject: "SUCCESS!",
					statusMessage: "Email sent Successfully!"
				})

			} else {

				this.toggle4();
				this.setState({
					statusSubject: "FAILED!",
					statusMessage: "Failed to send Email to "+recipient+"."
				})

			}

			this.toggle5();

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  handleSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let firstname = event.target.firstname.value;
    //console.log(`firstname is:`, firstname);
    let lastname = event.target.lastname.value;
    //console.log(`lastname is:`, lastname);
		let email = event.target.email.value;
    //console.log(`email is:`, email);
		let phone = event.target.phone.value;
		//console.log(`phone is:`, phone);
		let allowphonecontactChange = event.target.allowphonecontactChange.value;

		if (allowphonecontactChange == "YES") {
			allowphonecontactChange = "1";
		} else {
			allowphonecontactChange = "0";
		}
		//console.log(`phone is:`, phone);
		let role = event.target.role.value;

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.users));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			Attributes: [ /* required */
				    {
				      Name: 'given_name', /* required */
				      Value: firstname
				    },
						{
				      Name: 'family_name', /* required */
				      Value: lastname
				    },
						{
				      Name: 'email', /* required */
				      Value: email
				    },
						{
				      Name: 'custom:role', /* required */
				      Value: role
				    },
						{
				      Name: 'custom:phone', /* required */
				      Value: phone
				    },
						{
				      Name: 'custom:allowphonecontact', /* required */
				      Value: allowphonecontactChange
				    },
				  ],
    }
		this.setState({
			users: newObj,
			modal: !this.state.modal
		})
    console.log("saved user is:", this.state.users[id].Attributes);

    this.updateUser(firstname, lastname, phone, email, allowphonecontactChange);
	}

  updateUser = async (firstname, lastname, phone, email, allowphonecontact) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/edit-user?UserEmail="+email+"&UserPhone="+phone+"&UserFirstName="+firstname+"&UserLastName="+lastname+"&AllowPhoneContact="+allowphonecontact;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Device Registered User response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				this.state.noDataForDevices = false;
			} else {
				this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteUser = async () => {

		this.toggle3();

		console.log("Delete User: " + this.state.obj.email);

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/delete-user?UserEmail="+this.state.obj.email;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete Registered User response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				const arrayCopy = this.state.users.filter((row) => row.Username !== this.state.obj.userID);
	    	this.setState({users: arrayCopy});
				this.state.noDataForDevices = false;
			} else {
				this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  handleChange = (selectedOption) => {
    console.log(`Option selected:`, selectedOption.target.value);
    console.log(`obj is:`, this.state.obj);
    this.state.obj.role = selectedOption.target.value;
    console.log(`new obj is:`, this.state.obj);
  }

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		if (id == 'marketString') {
			var marketString = row[id].market;
			return (
					marketString !== undefined ?
							String(marketString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (id == 'roleString') {
			var roleString = row[id].role;
			return (
					roleString !== undefined ?
							String(roleString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		return (
				row[id] !== undefined ?
						String(String(row[id]).toLowerCase()).includes(filter.value.toLowerCase())
				:
						true
		);
	}

	defaultSortMethod = (a, b, desc) => {

		console.log("defaultSortMethod a: " + JSON.stringify(a));
		console.log("defaultSortMethod b: " + JSON.stringify(b));
		console.log("defaultSortMethod desc: " + JSON.stringify(desc));

		if (a.role) {

			// force null and undefined to the bottom
		  a = a === null || a === undefined ? -Infinity : a;
		  b = b === null || b === undefined ? -Infinity : b;
		  // force any string values to lowercase
		  a = typeof a.role === "string" ? a.role.toLowerCase() : a.role;
		  b = typeof b.role === "string" ? b.role.toLowerCase() : b.role;
		  // Return either 1 or -1 to indicate a sort priority
		  if (a > b) {
		    return 1;
		  }
		  if (a < b) {
		    return -1;
		  }
		  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
		  return 0;

		}

		if (a.market) {

			// force null and undefined to the bottom
		  a = a === null || a === undefined ? -Infinity : a;
		  b = b === null || b === undefined ? -Infinity : b;
		  // force any string values to lowercase
		  a = typeof a.market === "string" ? a.market.toLowerCase() : a.market;
		  b = typeof b.market === "string" ? b.market.toLowerCase() : b.market;
		  // Return either 1 or -1 to indicate a sort priority
		  if (a > b) {
		    return 1;
		  }
		  if (a < b) {
		    return -1;
		  }
		  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
		  return 0;

		}

	  // force null and undefined to the bottom
	  a = a === null || a === undefined ? -Infinity : a;
	  b = b === null || b === undefined ? -Infinity : b;
	  // force any string values to lowercase
	  a = typeof a === "string" ? a.toLowerCase() : a;
	  b = typeof b === "string" ? b.toLowerCase() : b;
	  // Return either 1 or -1 to indicate a sort priority
	  if (a > b) {
	    return 1;
	  }
	  if (a < b) {
	    return -1;
	  }
	  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
	  return 0;
	};

	render() {

    const data = this.state.users.map((user, key) => {

			var role = user.Attributes.filter(attribute => attribute.Name == "custom:role").map(name => name.Value)

			var roleString = "UPI";
			if (role) {
				if (role == "1") {
					roleString = "Tech Support"
				} else if (role == "2") {
					roleString = "Administrator"
				}
			}

			var market = user.Attributes.filter(attribute => attribute.Name == "custom:market").map(name => name.Value)

			var marketString = "United States";
			if (market) {
				if (market == "us") {
					marketString = "US-UPI"
				} else if (market == "europe") {
					marketString = "Europe"
				} else if (market == "aus") {
					marketString = "Australia"
				} else if (market == "us-azena") {
					marketString = "US-Azena"
				} else if (market == "brazil") {
					marketString = "Brazil"
				} else if (market == "taiwan") {
					marketString = "Taiwan"
				} else if (market == "colombia") {
					marketString = "Colombia"
				}
			}

			// var region = user.Attributes.filter(attribute => attribute.Name == "custom:region").map(name => name.Value)
			//
			// var marketString = "United States";
			// if (market) {
			// 	if (market == "us") {
			// 		marketString = "United States"
			// 	} else if (market == "europe") {
			// 		marketString = "Europe"
			// 	} else if (market == "aus") {
			// 		marketString = "Australia"
			// 	}
			// }

			var allowphonecontact = user.Attributes.filter(attribute => attribute.Name == "custom:allowphonecontact").map(name => name.Value)
			var allowphonecontactString = "No";
			if (allowphonecontact) {
				if (allowphonecontact == "0") {
					allowphonecontactString = "No"
				} else if (allowphonecontact == "1") {
					allowphonecontactString = "Yes"
				}
			}

			return {
				id: key,
        userID: user.Username,
				firstname: user.Attributes.filter(attribute => attribute.Name == "given_name").map(name => name.Value),
				lastname: user.Attributes.filter(attribute => attribute.Name == "family_name").map(name => name.Value),
				phone: user.Attributes.filter(attribute => attribute.Name == "custom:phone").map(name => name.Value),
				email: user.Attributes.filter(attribute => attribute.Name == "email").map(name => name.Value),
				roleString: {role: roleString, id: key},
				role: role,
				marketString: {market: marketString, id: key},
				allowphonecontactString: allowphonecontactString,
				allowphonecontact: allowphonecontact,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									obj: obj
								});
								this.showVerification(obj);
							}}
							color="link"
							size="sm"
							round="true"
							icon="true"
							title="Verification Code"
						>
							<i className="mdi mdi-verified mdi-24px" />
						</Button>
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
								//this.showVerification(obj);
							}}
							color="link"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
							title="Send Message"
						>
							<i className="mdi mdi-email mdi-24px" />
						</Button>
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal3: !this.state.modal3,
									obj: obj
								});
							}}
							color="link"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
							title="Delete Account"
						>
							<i style={{ color: "#e1212c"}} className="fa fa-trash-alt fa-lg" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let newdata = data2;
								newdata.find((o, i) => {
									if (o.id === key) {
										newdata.splice(i, 1);
										console.log(newdata);
										return true;
									}
									return false;
								});
								this.setState({ jsonData: newdata });
							}}
							className="ml-1"
							color="danger"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-times" />
						</Button> */}
					</div>
				)
			};
		});


		return (
      <Card className="card-hover">
				<CardBody>
          <Row className="mb-3">
            <Col md="3">
              <h1 style={{color:'#737782'}}>Gemini Users</h1>
            </Col>
            <Col md="2">
            </Col>
          </Row>
          <ReactTable
            style={{backgroundColor:'#000', padding:'10px'}}
						columns={[
							{
								Header: "First Name",
								accessor: "firstname",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
							},
							{
								Header: "Last Name",
								accessor: "lastname",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
							},
							{
								Header: "Market",
								accessor: "marketString",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
										<Fragment>
											{
												//<div style={{ textAlign: "center" }}>{row.value}</div>
												// <Input style={{height: '30px', padding: '3px'}} type="select" name="RepairIDsOne" id="RepairIDsOne" value={this.state.selectedModalRepair} onChange={this.onChangeRepairSelection}>
												// 	<option value="0">Select Repair</option>
												// </Input>
											}
											<Input style={{height: '30px', padding: '3px'}} type="select" value={row.value.market} onChange={(e)=>this.changeUserMarket(e, row.value.id)}>
												<option value="US-UPI">US-UPI</option>
												<option value="US-Azena">US-Azena</option>
												<option value="Europe">Europe</option>
												<option value="Australia">Australia</option>
												<option value="Brazil">Brazil</option>
												<option value="Taiwan">Taiwan</option>
												<option value="Colombia">Colombia</option>
											</Input>
										</Fragment>
                  )
							},
							{
								Header: "Email",
								accessor: "email",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
							},
							{
								Header: "Phone Number",
								accessor: "phone",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
							},

							{
								Header: "Send SMS",
								accessor: "allowphonecontactString",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
							},
							{
								Header: "Role",
								accessor: "roleString",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
										<Fragment>
											{
												//<div style={{ textAlign: "center" }}>{row.value}</div>
												// <Input style={{height: '30px', padding: '3px'}} type="select" name="RepairIDsOne" id="RepairIDsOne" value={this.state.selectedModalRepair} onChange={this.onChangeRepairSelection}>
												// 	<option value="0">Select Repair</option>
												// </Input>
											}
											<Input style={{height: '30px', padding: '3px'}} type="select" value={row.value.role} onChange={(e)=>this.changeUserRole(e, row.value.id)}>
												<option value="General User">General User</option>
												<option value="Tech Support">Tech Support</option>
												<option value="Administrator">Administrator</option>
											</Input>
										</Fragment>
                  )
							},
							{
								Header: "Actions",
								accessor: "actions",
								sortable: false,
								filterable: false
							}
						]}
						defaultPageSize={10}
            sortable={true}
						showPaginationBottom={true}
						className="-striped -highlight"
						data={data}
						filterable
						filterAll={true}
						defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
						defaultSortMethod={(a,b,desc) => this.defaultSortMethod(a,b,desc) }
					/>
        </CardBody>
				<div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
  				<ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
  				<ModalBody>
  					<Form onSubmit={this.handleSubmit}>
  						<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
              <Input type="hidden" name="userID" id="userID" defaultValue={this.state.obj.userID}/>
							<Input type="hidden" name="email" id="email" defaultValue={this.state.obj.email}/>
							<Input type="hidden" name="role" id="role" defaultValue={this.state.obj.role}/>
							<Input type="hidden" name="allowphonecontact" id="allowphonecontact" defaultValue={this.state.obj.allowphonecontact}/>
  						<FormGroup>
  							<Label for="firstname">First Name</Label>
  							<Input type="text" name="firstname" id="firstname" defaultValue={this.state.obj.firstname}/>
  						</FormGroup>
  						<FormGroup>
  							<Label for="lastname">Last Name</Label>
  							<Input type="text" name="lastname" id="lastname" defaultValue={this.state.obj.lastname}/>
  						</FormGroup>
							<FormGroup>
  							<Label for="lastname">Phone Number</Label>
  							<Input type="text" name="phone" id="phone" defaultValue={this.state.obj.phone}/>
  						</FormGroup>
							<FormGroup>
				        <Label for="messagetype">Send SMS:</Label>
				        <Input type="select" name="allowphonecontactChange" id="allowphonecontactChange" defaultValue={this.state.obj.allowphonecontact==1 ? "YES" : "NO"}>
				          <option>YES</option>
									<option>NO</option>
				        </Input>
				      </FormGroup>
  						<FormGroup>
  							<Button color="primary" type="submit">Save</Button>
  							<Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
  						</FormGroup>
  					</Form>
  				</ModalBody>
			  </Modal>
        <Modal isOpen={this.state.modal2} toggle={this.toggle2}>
  				<ModalHeader toggle={this.toggle2}>Enter the Code</ModalHeader>
  				<ModalBody>
						<Row>
							<Col xs="12" md="12" lg="12" className="text-left">
								<h4>We sent a verification code to: {this.state.obj.email}</h4>
							</Col>
						</Row>
  					<Form onSubmit={this.handleVerifySubmit}>
  						<FormGroup>
  							<Label for="code">Verification Code</Label>
  							<Input type="text" name="code" id="code"/>
  						</FormGroup>
							{this.state.verificationError &&
														<div className={'alert alert-danger'}>Verification code does not match.</div>
												}
  						<FormGroup>
  							<Button color="primary" type="submit">VERIFY CODE</Button>
								<Button color="secondary" className="ml-1" onClick={()=>{ this.sendCode(this.state.obj)} }>RESEND EMAIL</Button>
  							<Button color="secondary" className="ml-1" onClick={this.toggle2}>CANCEL</Button>
  						</FormGroup>
  					</Form>
  				</ModalBody>
			  </Modal>
				<Modal isOpen={this.state.modal3} toggle={this.toggle3}>
  				<ModalHeader toggle={this.toggle3}>Delete User</ModalHeader>
  				<ModalBody>
						<span>Are you sure you want to delete this user?</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteUser}>Save</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle3}>Cancel</Button>
					</ModalFooter>
			  </Modal>
				<Modal isOpen={this.state.modal4} toggle={this.toggle4}>
  				<ModalHeader toggle={this.toggle4}>Send a Message</ModalHeader>
  				<ModalBody>
						<Row>
							<Col xs="12" md="12" lg="12" className="text-left pb-3">
								<h4>Send message to: {this.state.obj.firstname} {this.state.obj.lastname}</h4>
							</Col>
						</Row>
  					<Form onSubmit={this.sendMessage}>
							<Input type="hidden" name="email" id="email" defaultValue={this.state.obj.email}/>
							<Input type="hidden" name="phone" id="phone" defaultValue={this.state.obj.phone}/>
							<Input type="hidden" name="firstname" id="firstname" defaultValue={this.state.obj.firstname}/>
							<Input type="hidden" name="lastname" id="lastname" defaultValue={this.state.obj.lastname}/>
							<Input type="hidden" name="allowphonecontact" id="allowphonecontact" defaultValue={this.state.obj.allowphonecontact}/>
							<FormGroup>
				        <Label for="messagetype">Select Message Type:</Label>
				        <Input type="select" name="messagetype" id="messagetype">
				          <option>Email</option>
									{this.state.obj.allowphonecontact == "1" &&
										<Fragment>
										<option>Text Message</option>
										</Fragment>
									}
				        </Input>
				      </FormGroup>
  						<FormGroup>
  							<Label for="subject">Subject:</Label>
  							<Input type="text" name="subject" id="subject" placeholder="Enter your subject..."/>
  						</FormGroup>
  						<FormGroup>
  							<Label for="message">Message:</Label>
  							<Input type="textarea" name="message" id="message" placeholder="Enter your message..."/>
  						</FormGroup>
  						<FormGroup>
  							<Button color="primary" type="submit">Send</Button>
  							<Button color="secondary" className="ml-1" onClick={this.toggle4}>Cancel</Button>
  						</FormGroup>
  					</Form>
  				</ModalBody>
			  </Modal>
				<Modal isOpen={this.state.modal5} toggle={this.toggle5}>
  				<ModalHeader toggle={this.toggle5}>{this.state.statusSubject}</ModalHeader>
  				<ModalBody>
						<span>{this.state.statusMessage}</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" className="ml-1" onClick={this.toggle5}>OK</Button>
					</ModalFooter>
			  </Modal>
				</div>
      </Card>
		);
	}
}

export default UsersList;
