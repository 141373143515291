import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import ReactPlayer from 'react-player';
import { ToastContainer, toast } from 'react-toastify';
import Loader from 'react-loader-spinner'

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
  ButtonDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
	Fade
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';
import videoPlaceholder from '../../../assets/images/video.png';
import pdfPlaceholder from '../../../assets/images/pdf-icon-2.png';

class AssemblyInstructionsList extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
      productsData: [],
			marketsData: [],
      modal: false,
      modal2: false,
			modal3: false,
      modal4: false,
      instructionFilesData: [],
      productItemsForInstruction: [],
      marketItemsForInstruction: [],
      obj: {},
      isOpen: false,
			currentItem: "ALL Markets",
			currentDataSetting: 100,
			isOpen2: false,
			currentItem2: "ALL Products",
			currentDataSetting2: 0,
			marketSelected: 100,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
      submitStatus: '',
			lightBoxIndex: 0,
			lightBoxDataIndex: 1,
      objUrl: "",
			isUploadingFile: false,
			selectedFileOne: null,
			loadedOne: false,
			filePreviewOne: null,
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);

    this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.toggle3 = this.toggle3.bind(this);
    this.toggle4 = this.toggle4.bind(this);

    this.toggleDropDown = this.toggleDropDown.bind(this);
		this.toggleDropDown2 = this.toggleDropDown2.bind(this);

		this.onChangeHandlerOne = this.onChangeHandlerOne.bind(this);
		this.onClickHandlerOne = this.onClickHandlerOne.bind(this);

  }


	async componentDidMount() {

    if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="europe") {
			this.setState({ currentItem: "Europe", marketSelected: 1, isInternationalTech: true });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="aus") {
			this.setState({ currentItem: "Australia", marketSelected: 2, isInternationalTech: true });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="brazil") {
			this.setState({ currentItem: "Brazil", marketSelected: 3, isInternationalTech: true });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="taiwan") {
			this.setState({ currentItem: "Taiwan", marketSelected: 4, isInternationalTech: true });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="colombia") {
			this.setState({ currentItem: "Colombia", marketSelected: 5, isInternationalTech: true });
		}

    //this.getCategoryData();
    //this.getOptionsData();
		await this.getMarketsData();
		this.getProductsData();

    this.getInstructionFilesData();
	}

	async onRadioBtnClick(marketSelected) {
		await this.setState({ marketSelected, currentDataSetting: marketSelected });
		setTimeout(this.getInstructionFilesData, 300);
	}

	componentWillUnmount() {
		//console.log("Laser-Usage componentWillUnmount");
		clearInterval(this.intervalID);
	}

  toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

  toggle2() {

	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle3() {
	  this.setState({
	    modal3: !this.state.modal3
	  });
	}

  toggle4() {
    console.log("toggle4");
	  this.setState({
	    modal4: !this.state.modal4
	  });
	}

  toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	toggleDropDown2() {
    this.setState({
      isOpen2: !this.state.isOpen2
    });
  }

  selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		setTimeout(this.getInstructionFilesData, 300);

  }

	selectDropDownItem2(id, title) {

    this.setState({
      currentItem2: title,
			currentDataSetting2: id
    });

		setTimeout(this.getInstructionFilesData, 300);

  }

  getMarketsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-markets";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all markets data: " + JSON.stringify(body));

			if (body.status == 'Success') {

				let userMarket = this.state.currentUser.payload['custom:market'];
				var marketsData = body.response;

				if (userMarket=='aus' || userMarket=='europe' || userMarket=='brazil' || userMarket=='taiwan' || userMarket=='colombia') {
					marketsData = marketsData.filter(market => market.Abbreviation.includes(userMarket))
				}

				this.setState({ marketsData });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getProductsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-products";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Products data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productsData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  getInstructionFilesData = async () => {

    var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-repair-instruction-files?Category=2&ProductID="+this.state.currentDataSetting2;

		if (this.state.marketSelected != 100) {
			url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-repair-instruction-files?Category=2&ProductID="+this.state.currentDataSetting2+"&MarketID="+this.state.marketSelected;
		}

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all instructionFilesData data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ instructionFilesData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  getProductItemsForInstruction = async (obj) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-product-items-for-category?CategoryID="+obj.instructionID+"&ManageOptionType=1";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all productItemsForInstruction data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productItemsForInstruction: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  getMarketItemsForInstruction = async (obj) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-market-items-for-manage-option?ManageOptionType=3&OptionID="+obj.instructionID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all marketItemsForInstruction data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ marketItemsForInstruction: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  createRepairInstructionSubmit = (event) => {
		event.preventDefault();

    let partNumber = event.target.PartNumber.value;
    console.log(`partNumber is:`, partNumber);

		let title = event.target.Title.value;
    console.log(`title is:`, title);

		// let description = event.target.Description.value;
    // console.log(`description is:`, description);

    let url = "";
    console.log(`url is:`, url);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

		let markets = Array.from(event.target.Markets.selectedOptions, option => option.value);
    console.log(`markets is:`, markets);

		var instructionObject = {partNumber, title, url, products, markets};

		if (this.state.loadedOne) {
			this.getPresignedBucketURL(instructionObject, 0);
		} else {
			let fileURL = url;

			this.setState({
				modal: !this.state.modal
			})
			//console.log("saved instructionFilesData is:", this.state.instructionFilesData[instructionObject.id].PartNumber);

			this.createRepairInstruction(instructionObject, fileURL);
		}

    //this.createRepairInstruction(instructionObject, fileURL);
	}

	createRepairInstruction = async (instructionObject, fileURL) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-repair-instruction-file?Category=2&PartNumber="+instructionObject.partNumber+"&Title="+instructionObject.title+"&URL="+fileURL;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create RepairInstruction response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				var instructionID = body.response.insertId;

				for (var i=0; i<instructionObject.products.length; i++) {
					var product = instructionObject.products[i];
					await this.addProductItemForInstruction(instructionID, product);
				}

				for (var i=0; i<instructionObject.markets.length; i++) {
					var market = instructionObject.markets[i];
					await this.addMarketItemForInstruction(instructionID, market);
				}

				this.getInstructionFilesData();
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  submitEditRepairInstruction = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let instructionID = event.target.instructionID.value;
    console.log(`instructionID is:`, instructionID);

    let partNumber = event.target.PartNumber.value;
    console.log(`partNumber is:`, partNumber);

		let title = event.target.Title.value;
    console.log(`title is:`, title);

		// let description = event.target.Description.value;
    // console.log(`description is:`, description);

    let url = event.target.File.value;
    console.log(`url is:`, url);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

		let markets = Array.from(event.target.Markets.selectedOptions, option => option.value);
    console.log(`markets is:`, markets);

		var instructionObject = {id, instructionID, partNumber, title, url, products, markets};

		if (this.state.loadedOne) {
			this.getPresignedBucketURL(instructionObject, 1);
		} else {
			let fileURL = url;

			let newObj = JSON.parse(JSON.stringify(this.state.instructionFilesData));

			newObj[id] = {
				PartNumber: partNumber,
				Title: title,
				url: fileURL
			}
			this.setState({
				instructionFilesData: newObj,
				modal2: !this.state.modal2
			})
			//console.log("saved instructionFilesData is:", this.state.instructionFilesData[instructionObject.id].PartNumber);

			this.updateRepairInstruction(instructionObject, fileURL);
		}

    //this.updateRepairInstruction(instructionID, partNumber, title, description, url, products, markets);
	}

	updateRepairInstruction = async (instructionObject, fileURL) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-repair-instruction-file?RepairInstructionID="+instructionObject.instructionID+"&PartNumber="+instructionObject.partNumber+"&Title="+instructionObject.title+"&URL="+fileURL;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Update instruction item response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				console.log("instructionObject: "+JSON.stringify(instructionObject));
				console.log("instructionObject.product: "+JSON.stringify(instructionObject.product));

				await this.deleteAllProductItemsForInstruction(instructionObject.instructionID);
				await this.deleteAllMarketItemsForInstruction(instructionObject.instructionID);

				for (var i=0; i<instructionObject.products.length; i++) {
					var product = instructionObject.products[i];
					await this.addProductItemForInstruction(instructionObject.instructionID, product);
				}

				for (var i=0; i<instructionObject.markets.length; i++) {
					var market = instructionObject.markets[i];
					await this.addMarketItemForInstruction(instructionObject.instructionID, market);
				}

				// products.map((product, i) => {
				// 	this.addProductItemForBOM(bomID, product);
				// });

				this.getInstructionFilesData();

			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  addProductItemForInstruction = async (instructionID, productID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-product-item-for-category?ManageOptionType=1&CategoryID="+instructionID+"&ProductID="+productID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("add ProductItemForInstruction response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  deleteAllProductItemsForInstruction = async (instructionID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-product-items-for-category?ManageOptionType=1&CategoryID="+instructionID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("AllProductItemsForInstruction delete response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  addMarketItemForInstruction = async (instructionID, marketID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-market-item-for-manage-option?ManageOptionType=3&OptionID="+instructionID+"&MarketID="+marketID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("add MarketItemForInstruction response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  deleteAllMarketItemsForInstruction = async (instructionID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-market-items-for-manage-option?ManageOptionType=3&OptionID="+instructionID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("AllMarketItemsForInstruction delete response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  deleteInstruction = async () => {

		console.log("Delete Instruction: " + this.state.obj.PartNumber);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-repair-instruction-file?RepairInstructionID="+this.state.obj.instructionID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete Instruction response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle3();
				this.getInstructionFilesData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  // lightBoxHandler(dataIndex, imageIndex) {
	// 	this.toggle4();
	// 	this.setState({ lightBoxDataIndex: dataIndex, lightBoxIndex: imageIndex });
	// }

  lightBoxHandler(objUrl, lightBoxDataIndex) {
    console.log("lightBoxHandler objUrl: " + JSON.stringify(objUrl));
		//this.toggle4();
		this.setState({ objUrl, lightBoxDataIndex });
    setTimeout(this.toggle4, 300);
	}

  is_video_url_extension (url) {

		var ext = url.split(/[#?]/)[0].split('.').pop().trim();

		var isVideo = false;

		if (ext == "mp4") {
			isVideo = true;
		} else if (ext == "m4a") {
			isVideo = true;
		} else if (ext == "m4p") {
			isVideo = true;
		} else if (ext == "m4b") {
			isVideo = true;
		} else if (ext == "m4r") {
			isVideo = true;
		} else if (ext == "m4v") {
			isVideo = true;
		} else if (ext == "mov") {
			isVideo = true;
		} else if (ext == "m4v") {
			isVideo = true;
		}

	  return isVideo;
	}

	is_pdf_url_extension (url) {

		var ext = url.split(/[#?]/)[0].split('.').pop().trim();

		var isPDF = false;

		if (ext == "pdf") {
			isPDF = true;
		}

	  return isPDF;
	}

	onChangeHandlerOne = (event) => {

		var contentType = event.target.files[0].type;

		const contentTypeArray = contentType.split("/");

		let extension = contentTypeArray[1];

		if (this.is_video_url_extension(extension)) {
			this.setState({
	      selectedFileOne: event.target.files[0],
				filePreviewOne: videoPlaceholder,
	      loadedOne: true,
	    })
		} else {
			this.setState({
	      selectedFileOne: event.target.files[0],
				filePreviewOne: URL.createObjectURL(event.target.files[0]),
	      loadedOne: true,
	    })
		}

		console.log("file type: "+event.target.files[0].type);
	}

	onClickHandlerOne = () => {

		//this.uploadToS3(0);
		//this.getPresignedBucketURL(0);
	}

	getPresignedBucketURL = async (instructionObject, type) => {

		this.setState({ isUploadingFile: true });

		var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		console.log('random hash: ' + hash);

		var contentType = null;
		var fileData = null;
		var fileSize = null;

		console.log("file type: "+this.state.selectedFileOne.type);
		console.log("file size: "+this.state.selectedFileOne.size);
		fileSize = this.state.selectedFileOne.size;
		contentType = this.state.selectedFileOne.type;
		fileData = this.state.selectedFileOne;

		const contentTypeArray = contentType.split("/");

		//OLD WAY
		var fileName = this.state.mediaHash+"-"+hash+"."+contentTypeArray[1];

		if (contentTypeArray[1]=="quicktime") {
			fileName = this.state.mediaHash+"-"+hash+".mov";
		}
		console.log('fileName: ' + fileName);

		// if (section==3) {
		// 	fileName = this.state.mediaHash+"-"+hash+"-PurchaseOrder.pdf";
		// }
		//
		// if (section==4) {
		// 	fileName = this.state.mediaHash+"-"+hash+"-LoanerReturnLabel.pdf";
		// }

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-presigned-bucket-url?FileName="+fileName+"&ContentType="+contentType;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all getPresignedBucketURL: " + JSON.stringify(body));

			if (body.status == 'Success') {
				let url = body.response;

				this.uploadToS3PresignedURL(url, fileName, fileData, contentType, instructionObject, type);

			} else {
	      //ADD ERROR MESSAGE
				this.setState({ isUploadingFile: false });

				let msg = "Problem Uploading Selected File, Please Try Again.";

				toast.error(msg, {
					position: "top-center",
					autoClose: 4000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					closeButton: false
				});
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	uploadToS3PresignedURL = async (url, fileName, fileData, contentType, instructionObject, type) => {

		console.log("uploadToS3PresignedURL url: " + url);
		console.log("uploadToS3PresignedURL fileName: " + fileName);

		try {
			const options = {
				method: 'PUT',
				body: fileData,
				headers: {
		      'Content-Type': contentType
		    },
			};

			const response = await fetch(url, options);

			//const body = await response.json();
			console.log("uploadToS3PresignedURL upload response: " + JSON.stringify(response));

			if (response.status == '200') {
				console.log("uploadToS3PresignedURL Upload Success!");

				this.setState({ isUploadingFile: false });

				let msg = "Upload Successful!";

				toast.success(msg, {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					closeButton: false
				});

				let fileURL = "https://azena-repair-files.s3.us-west-2.amazonaws.com/"+fileName;

				document.getElementById("fileSectionOne").value = null;
				this.setState({ filePreviewOne: null, loadedOne: false });

				if (type==0) {

					this.setState({
						modal: !this.state.modal
					})

					this.createRepairInstruction(instructionObject, fileURL);
				} else {
					let newObj = JSON.parse(JSON.stringify(this.state.instructionFilesData));

			    newObj[instructionObject.id] = {
			      PartNumber: instructionObject.partNumber,
			      Title: instructionObject.title,
			      url: fileURL
			    }
			    this.setState({
			      instructionFilesData: newObj,
			      modal2: !this.state.modal2
			    })
			    console.log("saved instructionFilesData is:", this.state.instructionFilesData[instructionObject.id].PartNumber);

					this.updateRepairInstruction(instructionObject, fileURL);
				}

			} else {
				//ADD ERROR MESSAGE
				this.setState({ isUploadingFile: false });

				let msg = "Problem Uploading Selected File, Please Try Again.";

				toast.error(msg, {
					position: "top-center",
					autoClose: 4000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					closeButton: false
				});
			}
		}
		catch (err) {
			console.log("uploadToS3 error: "+err);
			this.setState({ isUploadingFile: false });

			let msg = "Problem Uploading Selected File, Please Try Again.";

			toast.error(msg, {
				position: "top-center",
				autoClose: 4000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
				closeButton: false
			});
		}
	}

  render() {

    var isForeignUser = false;

		if (this.state.currentUser.payload['custom:market'] && (this.state.currentUser.payload['custom:market']=="europe" || this.state.currentUser.payload['custom:market']=="aus" || this.state.currentUser.payload['custom:market']=="brazil" || this.state.currentUser.payload['custom:market']=="taiwan" || this.state.currentUser.payload['custom:market']=="colombia")) {
			isForeignUser = true;
		}

		const data = this.state.instructionFilesData.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			if (isForeignUser) {

				return {
					id: key,
					instructionID: prop.id,
					PartNumber: prop.PartNumber,
	        Title: prop.Title,
	        url: prop.url,
					urlObj: { url:prop.url, key:key},
	        ProductName: prop.productName,
	        MarketName: prop.marketName,
	        files: (
						// we've added some custom button actions
						<div style={{ marginTop: "25px" }} className="text-center">
							{prop.url ? (
								<Card style={{ backgroundColor: "#313642" }}>
		                <CardBody>

		                  <div style={{ height: '50px', width: '100%'}}>
		                    {this.is_video_url_extension(prop.url) ? (
		                      <ReactPlayer style={{ cursor:"pointer" }} width="100%" height="100%" url={prop.url} onClick={() => { this.lightBoxHandler(prop.url, 3); }}/>
		                    ) : (
		                      <Fragment>
		                      {this.is_pdf_url_extension(prop.url) ? (
		                        <Fragment>
		                        <img key={key} style={{ height: 'inherit', maxWidth: '100%', cursor:"pointer" }} className="mr-2" src={pdfPlaceholder} onClick={() => { this.lightBoxHandler(prop.url, 2); }}/>
		                        </Fragment>
		                      ) : (
		                        <Fragment>
		                        <img key={key} style={{ height: 'inherit', maxWidth: '100%', cursor:"pointer" }} className="mr-2" src={prop.url} onClick={() => { this.lightBoxHandler(prop.url, 1); }}/>
		                        </Fragment>
		                      )}
		                      </Fragment>
		                    )}
		                  </div>

		                </CardBody>
		            </Card>
							) : (
								<Card style={{ backgroundColor: "#313642" }}>
		                <CardBody>

		                  <div style={{ height: '50px', width: '100%', marginTop: "20px"}}>
		                    <span>Waiting for Upload...</span>
		                  </div>

		                </CardBody>
		            </Card>
							)}
						</div>
					),
				};
			} else {
				return {
					id: key,
					instructionID: prop.id,
					PartNumber: prop.PartNumber,
					Title: prop.Title,
					url: prop.url,
					urlObj: { url:prop.url, key:key},
					ProductName: prop.productName,
					MarketName: prop.marketName,
					files: (
						// we've added some custom button actions
						<div style={{ marginTop: "25px" }} className="text-center">
							{prop.url ? (
								<Card style={{ backgroundColor: "#313642" }}>
										<CardBody>

											<div style={{ height: '50px', width: '100%'}}>
												{this.is_video_url_extension(prop.url) ? (
													<ReactPlayer style={{ cursor:"pointer" }} width="100%" height="100%" url={prop.url} onClick={() => { this.lightBoxHandler(prop.url, 3); }}/>
												) : (
													<Fragment>
													{this.is_pdf_url_extension(prop.url) ? (
														<Fragment>
														<img key={key} style={{ height: 'inherit', maxWidth: '100%', cursor:"pointer" }} className="mr-2" src={pdfPlaceholder} onClick={() => { this.lightBoxHandler(prop.url, 2); }}/>
														</Fragment>
													) : (
														<Fragment>
														<img key={key} style={{ height: 'inherit', maxWidth: '100%', cursor:"pointer" }} className="mr-2" src={prop.url} onClick={() => { this.lightBoxHandler(prop.url, 1); }}/>
														</Fragment>
													)}
													</Fragment>
												)}
											</div>

										</CardBody>
								</Card>
							) : (
								<Card style={{ backgroundColor: "#313642" }}>
										<CardBody>

											<div style={{ height: '50px', width: '100%', marginTop: "20px"}}>
												<span>Waiting for Upload...</span>
											</div>

										</CardBody>
								</Card>
							)}
						</div>
					),
					actions: (
						// we've added some custom button actions
						<div style={{ marginTop: "60px" }} className="d-flex justify-content-center align-items-center">
							{/* use this button to add a edit kind of action */}
							<Button
								onClick={() => {
									let obj = data.find(o => o.id === key);
									console.log('button obj: ' + obj);
									this.setState({
										modal2: !this.state.modal2,
										obj: obj,
									});
									//this.showVerification(obj);
									this.getProductItemsForInstruction(obj);
									this.getMarketItemsForInstruction(obj);
								}}
								color="yellow"
								size="sm"
								round="true"
								icon="true"
							>
								<i className="fa fa-edit" />
							</Button>
							<Button
								onClick={() => {
									let obj = data.find(o => o.id === key);
									console.log('button obj: ' + obj);
									this.setState({
										modal3: !this.state.modal3,
										obj: obj
									});
								}}
								color="danger"
								size="sm"
								round="true"
								icon="true"
								className="ml-2"
							>
								<i className="fa fa-trash-alt" />
							</Button>
							{/* use this button to remove the data row */}
							{/* <Button
								onClick={() => {
									let obj = data2.find(o => o.id === key);
									console.log('button obj: ' + obj);
									this.setState({
										modal4: !this.state.modal4,
										obj: obj
									});
								}}
								color="danger"
								size="sm"
								round="true"
								icon="true"
								className="ml-2"
							>
								<i className="fa fa-trash-alt" />
							</Button> */}
						</div>
					)
				};
			}
		});

		var columns = [
			 {
				 Header: "Part Number",
				 accessor: "PartNumber",
				 minWidth: 15,
				 style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				 Cell: row => (
						 <div style={{ textAlign: "center" }}>{row.value}</div>
					 )
			 },
			 {
				 Header: "Title",
				 accessor: "Title",
				 minWidth: 30,
				 style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				 Cell: row => (
						 <div style={{ textAlign: "center" }}>{row.value}</div>
					 )
			 },
			 {
				 Header: "Product",
				 accessor: "ProductName",
				 minWidth: 15,
				 style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				 Cell: row => (
						 <div style={{ textAlign: "center" }}>{row.value}</div>
					 )
			 },
			 {
				 Header: "Market",
				 accessor: "MarketName",
				 minWidth: 15,
				 style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				 Cell: row => (
						 <div style={{ textAlign: "center" }}>{row.value}</div>
					 )
			 },
			 {
				 Header: "File",
				 accessor: "files",
				 sortable: false,
				 minWidth: 15,
				 filterable: false
			 },
			 {
				 Header: "Actions",
				 accessor: "actions",
				 sortable: false,
				 minWidth: 15,
				 filterable: false
			 }
		 ];

		if (isForeignUser) {
		 columns = [
				{
					Header: "Part Number",
					accessor: "PartNumber",
					minWidth: 15,
					style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
					Cell: row => (
							<div style={{ textAlign: "center" }}>{row.value}</div>
						)
				},
				{
					Header: "Title",
					accessor: "Title",
					minWidth: 30,
					style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
					Cell: row => (
							<div style={{ textAlign: "center" }}>{row.value}</div>
						)
				},
				{
					Header: "Product",
					accessor: "ProductName",
					minWidth: 15,
					style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
					Cell: row => (
							<div style={{ textAlign: "center" }}>{row.value}</div>
						)
				},
				{
					Header: "Market",
					accessor: "MarketName",
					minWidth: 15,
					style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
					Cell: row => (
							<div style={{ textAlign: "center" }}>{row.value}</div>
						)
				},
				{
					Header: "File",
					accessor: "files",
					sortable: false,
					minWidth: 15,
					filterable: false
				}
			];
		}

		return (
			<Fragment>
				<Row>
					<Col md="12">
						<Card className="card-hover">
							<CardBody>
                <Row className="mb-3">
									<Col md="2">
										{this.state.marketSelected==100 &&
											<Fade in="true">
											<h3 style={{color:'#737782'}}>ALL Assembly Instructions</h3>
											</Fade>
										}
										{this.state.marketSelected==0 &&
											<Fade in="true">
											<h3 style={{color:'#737782'}}>US Instructions</h3>
											</Fade>
										}
										{this.state.marketSelected==1 &&
											<Fade in="true">
											<h3 style={{color:'#737782'}}>Europe Instructions</h3>
											</Fade>
										}
										{this.state.marketSelected==2 &&
											<Fade in="true">
											<h3 style={{color:'#737782'}}>Australia Instructions</h3>
											</Fade>
										}
										{this.state.marketSelected==3 &&
											<Fade in="true">
											<h3 style={{color:'#737782'}}>Brazil Instructions</h3>
											</Fade>
										}
										{this.state.marketSelected==4 &&
											<Fade in="true">
											<h3 style={{color:'#737782'}}>Taiwan Instructions</h3>
											</Fade>
										}
										{this.state.marketSelected==5 &&
											<Fade in="true">
											<h3 style={{color:'#737782'}}>Colombia Instructions</h3>
											</Fade>
										}
									</Col>
									<Col md="6">
									{!isForeignUser &&
										<div className="mt-1 mb-1 pb-4">
											<ButtonGroup style={{ width: '100%' }}>
												<Button
													style={{width: '100%'}}
													color="btngray"
													onClick={() => this.onRadioBtnClick(100)}
													active={this.state.marketSelected === 100}
												>
													ALL Instructions
												</Button>
												<Button
													className="ml-1"
													style={{width: '100%'}}
													color="btngray"
													onClick={() => this.onRadioBtnClick(0)}
													active={this.state.marketSelected === 0}
												>
													US
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(1)}
													active={this.state.marketSelected === 1}
												>
													Europe
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(2)}
													active={this.state.marketSelected === 2}
												>
													Australia
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(3)}
													active={this.state.marketSelected === 3}
												>
													Brazil
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(4)}
													active={this.state.marketSelected === 4}
												>
													Taiwan
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(5)}
													active={this.state.marketSelected === 5}
												>
													Colombia
												</Button>
											</ButtonGroup>
										</div>
									}
		              </Col>
                  <Col md="4" className="text-right">
                    <div>
                      {
                        // <ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
                        //   <DropdownToggle color="dark" caret>
                        //     {this.state.currentItem}
                        //   </DropdownToggle>
                        //   <DropdownMenu right>
                        //     <DropdownItem onClick={() => this.selectDropDownItem(100, 'ALL Markets')}>ALL Markets</DropdownItem>
                        //     {this.state.marketsData.map((market, i) => (
                        //       <DropdownItem onClick={() => this.selectDropDownItem(market.id, market.Name)}>{market.Name}</DropdownItem>
                        //     ))}
                        //   </DropdownMenu>
                        // </ButtonDropdown>
                      }
                      <ButtonDropdown className="ml-3" isOpen={this.state.isOpen2} toggle={this.toggleDropDown2}>
                        <DropdownToggle color="dark" caret>
                          {this.state.currentItem2}
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem onClick={() => this.selectDropDownItem2(0, 'ALL Products')}>ALL Products</DropdownItem>
                          {this.state.productsData.map((product, i) => (
                            <DropdownItem onClick={() => this.selectDropDownItem2(product.id, product.Name)}>{product.Name}</DropdownItem>
                          ))}
                        </DropdownMenu>
                      </ButtonDropdown>
											{!isForeignUser &&
	                      <Button className="float-right ml-3" color="yellow" onClick={this.toggle}>Add Assembly Instruction</Button>
											}
                    </div>
                  </Col>
                </Row>
								<ReactTable
									style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
									columns={columns}
									defaultPageSize={15}
									sortable={true}
									showPaginationBottom={true}
									className="-striped -highlight"
									data={data}
									filterable
									getTrGroupProps={(state, rowInfo, column, instance) => {
													if (rowInfo !== undefined) {
															return {
																	onClick: (e, handleOriginal) => {
																		//console.log('It was in this row:', rowInfo)
																		//console.log('with ID:', rowInfo.original.categoryID);

																		//this.goToRepairLog(rowInfo.original.repairId);
																	},
																	style: {
																			cursor: 'pointer'
																	}
															}
													}}
											}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
        <div>
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>Create Assembly Instruction</ModalHeader>
            <ModalBody>
              <Form onSubmit={this.createRepairInstructionSubmit}>
                <FormGroup>
                  <Label for="PartNumber">Part Number</Label>
                  <Input type="text" name="PartNumber" id="PartNumber"/>
                </FormGroup>
                <FormGroup>
                  <Label for="Title">Title</Label>
                  <Input type="text" name="Title" id="Title"/>
                </FormGroup>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label for="Products">Select Products</Label>
                      <Input id="Products" multiple name="Products" type="select">
                        {this.state.productsData.map((product, i) => {

                          return (
                            <option value={product.id}>{product.Name}</option>
                          );

                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label for="Markets">Select Markets</Label>
                      <Input id="Markets" multiple name="Markets" type="select" disabled={isForeignUser}>
                        {this.state.marketsData.map((market, i) => {

                          if (this.state.currentUser.payload['custom:market'] && (this.state.currentUser.payload['custom:market']=="europe" || this.state.currentUser.payload['custom:market']=="aus" || this.state.currentUser.payload['custom:market']=="brazil" || this.state.currentUser.payload['custom:market']=="taiwan" || this.state.currentUser.payload['custom:market']=="colombia")) {
                            return (
                              <option value={market.id} selected>{market.Name}</option>
                            );
                          } else {
                            return (
                              <option value={market.id}>{market.Name}</option>
                            );
                          }

                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
								{!this.state.isUploadingFile ? (
									<Row className="pt-4 pb-4">
										<Col xs="6" md="6" lg="6">
											<Input className="" type="file" name="fileSectionOne" id="fileSectionOne" onChange={this.onChangeHandlerOne} onClick={e => (e.target.value = null)}/>
										</Col>
										<Col xs="6" md="6" lg="6">
											{this.state.loadedOne &&
												<img className="mr-2" src={this.state.filePreviewOne} width="100"/>
											}
										</Col>
									</Row>
								) : (
									<Row className="pt-4 pb-4 pl-4">
											<Fade in="true">
												<Row className="justify-content-center">
													<Loader type="ThreeDots" color="#ffc108" height={30} width={200}/>
												</Row>
											</Fade>
									</Row>
								)}
                <FormGroup>
                  <Button color="primary" type="submit">Save</Button>
                  <Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
                </FormGroup>
              </Form>
            </ModalBody>
          </Modal>
          <Modal isOpen={this.state.modal2} toggle={this.toggle2}>
  					<ModalHeader toggle={this.toggle2}>Edit Assembly Instruction</ModalHeader>
  					<ModalBody>
  						<Form onSubmit={this.submitEditRepairInstruction}>
  							<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
  							<Input type="hidden" name="instructionID" id="instructionID" defaultValue={this.state.obj.instructionID}/>
								<Input type="hidden" name="File" id="File" defaultValue={this.state.obj.url}/>
  							<FormGroup>
  								<Label for="PartNumber">Part Number</Label>
  								<Input type="text" name="PartNumber" id="PartNumber" defaultValue={this.state.obj.PartNumber}/>
  							</FormGroup>
  							<FormGroup>
  								<Label for="Title">Title</Label>
  								<Input type="text" name="Title" id="Title" defaultValue={this.state.obj.Title}/>
  							</FormGroup>
  							<Row>
  								<Col md="6">
  									<FormGroup>
  								    <Label for="Products">Select Products</Label>
  								    <Input id="Products" multiple name="Products" type="select">
  										{this.state.productsData.map((product, i) => {

  											var productItem = this.state.productItemsForInstruction.find(p => p.ProductID === product.id);

  											console.log('product: ', product.id);
  											console.log('productItem: ', productItem);

  											if (productItem) {
  												return (
  													<option value={product.id} selected>{product.Name}</option>
  												);
  											} else {
  												return (
  													<option value={product.id}>{product.Name}</option>
  												);
  											}

  										})}
  								    </Input>
  								  </FormGroup>
  								</Col>
  								<Col md="6">
  									<FormGroup>
  										<Label for="Markets">Select Markets</Label>
  										<Input id="Markets" multiple name="Markets" type="select" disabled={isForeignUser}>
  										{this.state.marketsData.map((market, i) => {

  											var marketItem = this.state.marketItemsForInstruction.find(m => m.MarketID === market.id);

  											console.log('market: ', market.id);
  											console.log('marketItem: ', marketItem);

  											if (marketItem) {
  												return (
  													<option value={market.id} selected>{market.Name}</option>
  												);
  											} else {
  												return (
  													<option value={market.id}>{market.Name}</option>
  												);
  											}

  										})}
  										</Input>
  									</FormGroup>
  								</Col>
  							</Row>
								{!this.state.isUploadingFile ? (
									<Row className="pt-4 pb-4">
										<Col xs="6" md="6" lg="6">
											<Input className="" type="file" name="fileSectionOne" id="fileSectionOne" onChange={this.onChangeHandlerOne} onClick={e => (e.target.value = null)}/>
										</Col>
										<Col xs="6" md="6" lg="6">
											{this.state.loadedOne &&
												<img className="mr-2" src={this.state.filePreviewOne} width="100"/>
											}
										</Col>
									</Row>
								) : (
									<Row className="pt-4 pb-4 pl-4">
											<Fade in="true">
												<Row className="justify-content-center">
													<Loader type="ThreeDots" color="#ffc108" height={30} width={200}/>
												</Row>
											</Fade>
									</Row>
								)}
  							{this.state.submitStatus &&
  								<div className={'alert alert-danger'}>{this.state.submitStatus}</div>
  							}
  							<FormGroup>
  								<Button color="primary" type="submit">Save</Button>
  								<Button color="secondary" className="ml-1" onClick={this.toggle2}>Cancel</Button>
  							</FormGroup>
  						</Form>
  					</ModalBody>
  				</Modal>
          <Modal isOpen={this.state.modal3} toggle={this.toggle3}>
    				<ModalHeader toggle={this.toggle3}>Delete Instruction</ModalHeader>
    				<ModalBody>
  						<span>Are you sure you want to delete this Instruction?</span>
    				</ModalBody>
  					<ModalFooter>
  						<Button color="primary" onClick={this.deleteInstruction}>Yes</Button>
  						<Button color="secondary" className="ml-1" onClick={this.toggle3}>Cancel</Button>
  					</ModalFooter>
  			  </Modal>
          <Modal isOpen={this.state.modal4} toggle={this.toggle4} centered={true} contentClassName="custom-modal-style">
    				<ModalHeader toggle={this.toggle4}>Preview Media</ModalHeader>
    				<ModalBody>

						{this.state.objUrl && this.state.lightBoxDataIndex==1 &&
							<div style={{ height: '100%', width: '100%'}}>
								<img style={{ maxWidth: '100%', cursor:"pointer" }} className="mr-2" src={this.state.objUrl}/>
							</div>
						}

						{this.state.objUrl && this.state.lightBoxDataIndex==2 &&
							<div style={{ height: '100%', width: '100%'}}>
								<iframe src={this.state.objUrl} width="100%" height="700px">
								</iframe>
							</div>
						}

            {this.state.objUrl && this.state.lightBoxDataIndex==3 &&
							<div style={{ height: '100%', width: '100%'}}>
								<ReactPlayer url={this.state.objUrl} controls={true} />
							</div>
						}

    				</ModalBody>
  					<ModalFooter>
  						<Button color="primary" className="ml-1" onClick={this.toggle4}>DONE</Button>
  					</ModalFooter>
  			  </Modal>
        </div>
			</Fragment>
		);
	}
}

export default AssemblyInstructionsList;
