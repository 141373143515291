import React from 'react';
import {
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	CustomInput,
	FormGroup,
	Row,
	Col,
	UncontrolledTooltip,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import { Auth } from "aws-amplify";
import azenaevologo from '../../assets/images/logo2_x3.png';
import azenaevologotext from '../../assets/images/logo2_x3_text.png';
import azenaevosupportlogo from '../../assets/images/support_logo.png';
import azenarepairlogo from '../../assets/images/GEMINI_LOGO_REPAIR.png';
import img1 from '../../assets/images/logo2_x3.png';
import img2 from '../../assets/images/background/login-register.jpg';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { authenticationService } from '../../jwt/_services';
const sidebarBackground = {
	//backgroundImage: "url(" + img2 + ")",
	backgroundColor: "#313642",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "bottom center"
};

const formBackground = {
	//backgroundImage: "url(" + img2 + ")",
	backgroundColor: "#000"
};

class Login extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: false,
			modal2: false,
			code: '',
			email: '',
			password: '',
			confirmPassword: '',
			resetSuccessful: false,
			resetConfirmSuccessful: false,
			resetStatus: ''
	  };

		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.resetPasswordCall = this.resetPasswordCall.bind(this);
		this.resetPasswordConfirmCall = this.resetPasswordConfirmCall.bind(this)

	}

	async componentDidMount() {

	}

	handleClick() {
		var elem = document.getElementById('loginform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		var elem = document.getElementById('changeform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('recoverform').style.display = "block";
	}

	backToLogin() {
		var elem = document.getElementById('recoverform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		var elem = document.getElementById('changeform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('loginform').style.display = "block";
		//this.toggle();
	}

	showChangeForm() {
		var elem = document.getElementById('loginform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		var elem = document.getElementById('recoverform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('changeform').style.display = "block";
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });

		console.log('this.state.resetSuccessful: ' + this.state.resetSuccessful);
		if (this.state.resetSuccessful) {
			this.showChangeForm();
		}
	}

	toggle2() {
	  this.setState({
	    modal2: !this.state.modal2
	  });

		if (this.state.resetConfirmSuccessful) {
			this.backToLogin();
		}
	}

	handleChange(event) {
		if (event.target.getAttribute('name') == 'email') {
			this.setState({email: event.target.value});
		} else if (event.target.getAttribute('name') == 'code') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({code: event.target.value});
		} else if (event.target.getAttribute('name') == 'password') {
			//console.log('this.state.password: ' + event.target.value);
			this.setState({password: event.target.value});
		} else if (event.target.getAttribute('name') == 'confirmPassword') {
			//console.log('this.state.confirmPassword: ' + event.target.value);
			this.setState({confirmPassword: event.target.value});
		}
  }

	handleSubmit(event) {
    //alert('A email was submitted: ' + this.state.email);
		this.resetPasswordCall();
    event.preventDefault();
		this.toggle();
  }

	async resetPasswordCall(event) {
    event.preventDefault();

    try {
      await Auth.forgotPassword(this.state.email);
			this.state.resetSuccessful = true;
			console.log('sent confirmation email Successfully.');
			this.toggle();
    } catch (error) {
			console.log('error: ' + error.message);
			this.state.resetSuccessful = false;
    }
  }

	async resetPasswordConfirmCall(event) {
		event.preventDefault();

		if (this.state.password == this.state.confirmPassword) {

	    try {
	      await Auth.forgotPasswordSubmit(
	        this.state.email,
	        this.state.code,
	        this.state.password
	      );
				console.log('Changed password Successfully.');
	      this.state.resetConfirmSuccessful = true;
				this.setState({ resetStatus: '' });
				this.toggle2();
	    } catch (error) {
				console.log('error: ' + error.message);
	      this.state.resetConfirmSuccessful = false;
				this.setState({ resetStatus: error.message });
	    }

		}
  }

	render() {
		return <div className="">
			{/*--------------------------------------------------------------------------------*/}
			{/*Login Cards*/}
			{/*--------------------------------------------------------------------------------*/}
			<div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={sidebarBackground}>
				<div className="auth-box on-sidebar" style={formBackground}>
					<div id="loginform">
						<div className="logo mt-5">
							<span className="db mb-5"><img style={{ width: "100%"}} src={azenarepairlogo} alt="logo" /></span>
							<h5 className="mt-2 mb-3"></h5>
							<h5 className="font-medium mb-3 text-light">Sign In</h5>
						</div>
						<Row>
							<Col xs="12">
								<Formik
				                    initialValues={{
				                        email: '',
				                        password: ''
				                    }}
				                    validationSchema={Yup.object().shape({
				                        email: Yup.string().required('Email is required'),
				                        password: Yup.string().required('Password is required')
				                    })}
				                    onSubmit={({ email, password }, { setStatus, setSubmitting }) => {
				                        setStatus();

																authenticationService.login(email, password)
																		.then(
																				user => {
																					console.log('user info: ' + JSON.stringify(user));
																					if (user.payload) {
																						let email = user.payload.email;
																						console.log('email: ' + email);

																						const { from } = this.props.location.state || { from: { pathname: "/" } };
																						this.props.history.push(from);
																						//window.location.reload(true);

																					} else {

																						console.log('error info: ' + user.message);
																						setSubmitting(false);
																						setStatus(user.message);

																					}

																				},
																				error => {
																					setSubmitting(false);
																					setStatus(error.message);
																				}
																		);

				                    }}
				                    render={({ errors, status, touched, isSubmitting }) => (
								<Form className="mt-3" id="loginform">
									<InputGroup className="mb-3">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ti-user"></i>
											</InputGroupText>
										</InputGroupAddon>

										<Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                		<ErrorMessage name="email" component="div" className="invalid-feedback" />
									</InputGroup>
									<InputGroup className="mb-3">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ti-pencil"></i>
											</InputGroupText>
										</InputGroupAddon>
										<Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                		<ErrorMessage name="password" component="div" className="invalid-feedback" />

									</InputGroup>
									<div className="d-flex no-block align-items-center mb-3 text-light">
										<CustomInput type="checkbox" id="exampleCustomCheckbox" label="Remember Me" />
										<div className="ml-auto">
											<a style={{ cursor:'pointer' }} id="to-recover" onClick={this.handleClick} className="forgot text-light float-right"><i className="fa fa-lock mr-1"></i> Forgot pwd?</a>
										</div>
									</div>
									<Row className="mb-3">
										<Col xs="12">
											<button type="submit" className="btn btn-block btn-primary" disabled={isSubmitting}>Login</button>
										</Col>
									</Row>
									{status &&
                                <div className={'alert alert-danger'}>{status}</div>
                            }
									{
										// <div className="text-center text-light mb-3">
										// 	Don't have an account? <a href="/authentication/register" className="text-info ml-1"><b>Sign Up</b></a>
										// </div>
									}
								</Form>
								)}
                />
							</Col>
						</Row>
					</div>
					<div id="recoverform" style={{display:'none'}}>
						<div className="logo">
							<span className="db"><img style={{ width: "100%"}} src={azenarepairlogo} alt="logo" /></span>
							<h5 className="mb-3"></h5>
							<h5 className="font-medium mb-3 text-light">Reset Password</h5>
							<span className="text-light">Enter your Email and instructions will be sent to you!</span>
						</div>
						<Row className="mt-3">
							<Col xs="12">
								<Form onSubmit={this.resetPasswordCall}>
									<FormGroup>
										<Input type="text" value={this.state.email} onChange={this.handleChange}  name="email" bsSize="lg" id="email" placeholder="Email" required />
									</FormGroup>
									<Row className="mt-3 mb-3">
										<Col xs="12">
											<Button color="danger" size="lg" type="submit" block>Reset</Button>
										</Col>
									</Row>
									<div className="text-center text-light">
											<a style={{ cursor:'pointer' }} id="to-login" onClick={this.backToLogin} className="text-light ml-1"><b>CANCEL</b></a>
									</div>
								</Form>
							</Col>
						</Row>
						<div>
						<Modal color="dark" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
			        <ModalHeader toggle={this.toggle}>Reset Password</ModalHeader>
			        <ModalBody>
								An email has been sent to {this.state.email} containing a confirmation code.
			        </ModalBody>
			        <ModalFooter>
			          <Button color="secondary" onClick={this.toggle}>OK</Button>
			        </ModalFooter>
			      </Modal>
						</div>
					</div>

					<div id="changeform" style={{display:'none'}}>
						<div className="logo">
							<span className="db"><img style={{ width: "100%"}} src={azenarepairlogo} alt="logo" /></span>
							<h5 className="mb-3"></h5>
							<h5 className="font-medium mb-3 text-light">Confirm Change Password</h5>
							<span className="text-light">Enter the code sent to your Email and your new password!</span>
						</div>
						<Row className="mt-3">
							<Col xs="12">
								<Form onSubmit={this.resetPasswordConfirmCall}>
									<FormGroup>
										<Input type="text" value={this.state.code} onChange={this.handleChange}  name="code" bsSize="lg" id="code" placeholder="Confirmation Code" required />
									</FormGroup>
									<FormGroup>
										<Input type="password" value={this.state.password} onChange={this.handleChange}  name="password" bsSize="lg" id="password" placeholder="New Password" required />
									</FormGroup>
									<FormGroup>
										<Input type="password" value={this.state.confirmPassword} onChange={this.handleChange}  name="confirmPassword" bsSize="lg" id="confirmPassword" placeholder="Confirm New Password" required />
									</FormGroup>
									<Row className="mt-3 mb-3">
										<Col xs="12">
											<Button color="danger" size="lg" type="submit" block>Confirm</Button>
										</Col>
									</Row>
									{this.state.resetStatus &&
																<div className={'alert alert-danger'}>{this.state.resetStatus}</div>
														}
									<div className="text-center text-light">
											<a style={{ cursor:'pointer' }} id="to-login" onClick={this.backToLogin} className="text-light ml-1"><b>CANCEL</b></a>
									</div>
									<div className="text-center text-light pt-5">
											<h5 className="text-light">Password to be minimum of 8 characters, 1 upper case letter, 1 lowercase letter, 1 number, and 1 special character "$ # @"</h5>
									</div>
								</Form>
							</Col>
						</Row>
						<div>
						<Modal color="dark" isOpen={this.state.modal2} toggle={this.toggle2} className={this.props.className}>
			        <ModalHeader toggle={this.toggle2}>Reset Password</ModalHeader>
			        <ModalBody>
								Password has been Successfully Reset. Please Login.
			        </ModalBody>
			        <ModalFooter>
			          <Button color="secondary" onClick={this.toggle2}>OK</Button>
			        </ModalFooter>
			      </Modal>
						</div>
					</div>

				</div>
			</div>
		</div>;
	}
}

export default Login;
