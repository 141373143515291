import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	ButtonDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class RepairOptionList extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info token: ' + JSON.stringify(currentUser.token));

    this.state = {
			repairCategories: [],
      repairOptions: [],
			repairBOM: [],
			bomData: [],
			productsData: [],
			quoteData: [],
			tamData: [],
			productItemsForBOM: [],
			productItemsForQuoteItem: [],
			productItemsForCategory: [],
			obj: {},
			modal: false,
      modal2: false,
			modal3: false,
			modal4: false,
			modal5: false,
			modal6: false,
			modal7: false,
			modal8: false,
			modal9: false,
			modal10: false,
			modal11: false,
			modal12: false,
			modal13: false,
			modal14: false,
			modal15: false,
			modal16: false,
			canEditCost: false,
			isOpen: false,
			currentItem: "ALL",
			currentDataSetting: 0,
			isOpen2: false,
			currentItem2: "ALL",
			currentDataSetting2: 0,
			isOpen3: false,
			currentItem3: "ALL",
			currentDataSetting3: 0,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.toggle3 = this.toggle3.bind(this);
		this.toggle4 = this.toggle4.bind(this);
		this.toggle5 = this.toggle5.bind(this);
		this.toggle6 = this.toggle6.bind(this);
		this.toggle7 = this.toggle7.bind(this);
		this.toggle8 = this.toggle8.bind(this);
		this.toggle9 = this.toggle9.bind(this);
		this.toggle10 = this.toggle10.bind(this);
		this.toggle11 = this.toggle11.bind(this);
		this.toggle12 = this.toggle12.bind(this);
		this.toggle13 = this.toggle13.bind(this);
		this.toggle14 = this.toggle14.bind(this);
		this.toggle15 = this.toggle15.bind(this);
		this.toggle16 = this.toggle16.bind(this);

		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.toggleDropDown2 = this.toggleDropDown2.bind(this);
		this.toggleDropDown3 = this.toggleDropDown3.bind(this);

  }

	async componentDidMount() {

    this.getCategoryData();
    this.getOptionsData();
		this.getProductsData();
		this.getBOMData();
		this.getQuoteData();
		this.getTAMData();

		console.log('repair options currentUser email: ' + JSON.stringify(this.state.currentUser.payload.email));

		if (this.state.currentUser.payload.email == "jluo@azenamedical.com" || this.state.currentUser.payload.email == "hbrown@azenamedical.com" || this.state.currentUser.payload.email == "ryan.a.stickel@gmail.com") {
			this.setState({ canEditCost: true });
		}
		//canEditCost
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

  toggle2() {

	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle3() {
	  this.setState({
	    modal3: !this.state.modal3
	  });
	}

	toggle4() {
	  this.setState({
	    modal4: !this.state.modal4
	  });
	}

	toggle5() {
	  this.setState({
	    modal5: !this.state.modal5
	  });
	}

	toggle6() {
	  this.setState({
	    modal6: !this.state.modal6
	  });
	}

	toggle7() {
	  this.setState({
	    modal7: !this.state.modal7
	  });
	}

	toggle8() {
	  this.setState({
	    modal8: !this.state.modal8
	  });
	}

	toggle9() {
	  this.setState({
	    modal9: !this.state.modal9
	  });
	}

	toggle10() {
	  this.setState({
	    modal10: !this.state.modal10
	  });
	}

	toggle11() {
	  this.setState({
	    modal11: !this.state.modal11
	  });
	}

	toggle12() {
	  this.setState({
	    modal12: !this.state.modal12
	  });
	}

	toggle13() {
	  this.setState({
	    modal13: !this.state.modal13
	  });
	}

	toggle14() {
	  this.setState({
	    modal14: !this.state.modal14
	  });
	}

	toggle15() {
	  this.setState({
	    modal15: !this.state.modal15
	  });
	}

	toggle16() {
	  this.setState({
	    modal16: !this.state.modal16
	  });
	}

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	toggleDropDown2() {
    this.setState({
      isOpen2: !this.state.isOpen2
    });
  }

	toggleDropDown3() {
    this.setState({
      isOpen3: !this.state.isOpen3
    });
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		setTimeout(this.getBOMData, 300);

  }

	selectDropDownItem2(id, title) {

    this.setState({
      currentItem2: title,
			currentDataSetting2: id
    });

		setTimeout(this.getQuoteData, 300);

  }

	selectDropDownItem3(id, title) {

    this.setState({
      currentItem3: title,
			currentDataSetting3: id
    });

		setTimeout(this.getCategoryData, 300);

  }

	componentWillUnmount() {

	}

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	getCategoryData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-repair-categories?ProductID="+this.state.currentDataSetting3;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all repairCategories: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ repairCategories: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  getOptionsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-repair-options";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all repairOptions: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ repairOptions: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getBOMData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-bill-of-materials?ProductID="+this.state.currentDataSetting;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all BOM data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ bomData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getProductItemsForBOM = async (obj) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-product-items-for-bom?BomID="+obj.bomID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all productItemsForBOM data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productItemsForBOM: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteAllProductItemsForBOM = async (bomID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-product-items-for-bom?BomID="+bomID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all BOM data: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	addProductItemForBOM = async (bomID, productID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-product-item-for-bom?BomID="+bomID+"&ProductID="+productID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all BOM data: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getProductItemsForQuoteItem = async (obj) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-product-items-for-quote-item?QuoteID="+obj.qItemID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all ProductItemsForQuoteItem data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productItemsForQuoteItem: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteAllProductItemsForQuoteItem = async (quoteID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-product-items-for-quote-item?QuoteID="+quoteID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("ProductItemsForQuoteItem delete response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	addProductItemForQuoteItem = async (quoteID, productID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-product-item-for-quote-item?QuoteID="+quoteID+"&ProductID="+productID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("add ProductItemForQuoteItem response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getProductItemsForCategory = async (obj) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-product-items-for-category?CategoryID="+obj.categoryID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all ProductItemForCategory data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productItemsForCategory: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	addProductItemForCategory = async (categoryID, productID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-product-item-for-category?CategoryID="+categoryID+"&ProductID="+productID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("add ProductItemForCategory response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteAllProductItemsForCategory = async (categoryID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-product-items-for-category?CategoryID="+categoryID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("ProductItemForCategory delete response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getProductsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-products";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Products data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productsData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getQuoteData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-quote-items?ProductID="+this.state.currentDataSetting2;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Quote data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ quoteData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getTAMData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-tam-names";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all TAM data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ tamData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	createCategorySubmit = (event) => {
		event.preventDefault();

    let name = event.target.name.value;
    console.log(`name is:`, name);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

		this.setState({
			modal5: !this.state.modal5
		})

    this.createCategory(name, products);
	}

  createCategory = async (name, products) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-repair-category?Name="+name;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create category response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				var categoryID = body.response.insertId;

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForCategory(categoryID, product);
				}

				this.getCategoryData();
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	createBOMSubmit = (event) => {
		event.preventDefault();

    let partNumber = event.target.PartNumber.value;
    console.log(`partNumber is:`, partNumber);

		let cost = event.target.Cost.value;
    console.log(`cost is:`, cost);

		let quantity = event.target.Quantity.value;
    console.log(`quantity is:`, quantity);

		let description = event.target.Description.value;
    console.log(`description is:`, description);

		//let products = event.target.Products.value;
		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

		this.setState({
			modal11: !this.state.modal11
		})

    this.createBOM(partNumber, cost, quantity, description, products);
	}

  createBOM = async (partNumber, cost, quantity, description, products) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-bill-of-materials?PartNumber="+partNumber+"&Cost="+cost+"&Description="+description+"&Quantity="+quantity;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create BOM response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				var bomID = body.response.insertId;

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForBOM(bomID, product);
				}

				// products.map((product, i) => {
				// 	this.addProductItemForBOM(bomID, product);
				// });

				this.getBOMData();
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	createQuoteItemSubmit = (event) => {
		event.preventDefault();

    let sku = event.target.sku.value;
    console.log(`sku is:`, sku);

		let cost = event.target.Cost.value;
    console.log(`cost is:`, cost);

		let quantity = event.target.Quantity.value;
    console.log(`quantity is:`, quantity);

		let description = event.target.Description.value;
    console.log(`description is:`, description);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

		this.setState({
			modal13: !this.state.modal13
		})

    this.createQuoteItem(sku, cost, quantity, description, products);
	}

	createQuoteItem = async (sku, cost, quantity, description, products) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-quote-item?SKU="+sku+"&Cost="+cost+"&Description="+description+"&Quantity="+quantity;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create Quote response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				var quoteID = body.response.insertId;

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForQuoteItem(quoteID, product);
				}

				this.getQuoteData();
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	createTAMSubmit = (event) => {
		event.preventDefault();

    let name = event.target.name.value;
    console.log(`name is:`, name);

		let phone = event.target.phone.value;
    console.log(`phone is:`, phone);

		this.setState({
			modal12: !this.state.modal12
		})

    this.createTAM(name, phone);
	}

  createTAM = async (name, phone) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-tam-names?Name="+name+"&Phone="+phone;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create TAM response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				this.getTAMData();
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleCategorySubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let categoryID = event.target.categoryID.value;
    console.log(`categoryID is:`, categoryID);
    let name = event.target.name.value;
    console.log(`name is:`, name);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.repairCategories));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			name: name
    }
		this.setState({
			repairCategories: newObj,
			modal: !this.state.modal
		})
    console.log("saved repairCategories is:", this.state.repairCategories[id].name);

    this.updateCategory(categoryID, name, products);
	}

  updateCategory = async (categoryID, name, products) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-repair-category?CategoryID="+categoryID+"&Name="+name;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Update category response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				await this.deleteAllProductItemsForCategory(categoryID);

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForCategory(categoryID, product);
				}

				// products.map((product, i) => {
				// 	this.addProductItemForBOM(bomID, product);
				// });

				this.getCategoryData();

			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteCategory = async () => {

		console.log("Delete Category: " + this.state.obj.name);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-repair-category?CategoryID="+this.state.obj.categoryID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete category response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle2();
				this.getCategoryData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteOption = async () => {

		console.log("Delete Category: " + this.state.obj.name);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-repair-option?OptionID="+this.state.obj.optionID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete option response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle4();
				this.getOptionsData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteBOM = async () => {

		console.log("Delete BOM: " + this.state.obj.PartNumber);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-bill-of-materials?BomID="+this.state.obj.bomID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete BOM response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle8();
				await this.deleteAllProductItemsForBOM(this.state.obj.bomID);
				this.getBOMData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteQuoteItem = async () => {

		console.log("Delete Quote: " + this.state.obj.SKU);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-quote-item?QItemID="+this.state.obj.qItemID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete Quote response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle15();
				this.getQuoteData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteTAM = async () => {

		console.log("Delete TAM: " + this.state.obj.name);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-tam-names?TamID="+this.state.obj.tamID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete TAM response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle10();
				this.getTAMData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	createOptionSubmit = (event) => {
		event.preventDefault();

    let name = event.target.name.value;
    console.log(`name is:`, name);

		this.setState({
			modal6: !this.state.modal6
		})

    this.createOption(name);
	}

  createOption = async (name) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-repair-option?Name="+name;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create option response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;
				this.getOptionsData();
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleOptionSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let optionID = event.target.optionID.value;
    console.log(`optionID is:`, optionID);
    let name = event.target.name.value;
    console.log(`name is:`, name);

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.repairOptions));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			name: name
    }
		this.setState({
			repairOptions: newObj,
			modal3: !this.state.modal3
		})
    console.log("saved repairOptions is:", this.state.repairOptions[id].name);

    this.updateOption(optionID, name);
	}

  updateOption = async (optionID, name) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-repair-option?OptionID="+optionID+"&Name="+name;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Update option response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleBOMSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let bomID = event.target.bomID.value;
    console.log(`bomID is:`, bomID);
    let partNumber = event.target.PartNumber.value;
    console.log(`partNumber is:`, partNumber);
		let cost = event.target.Cost.value;
    console.log(`cost is:`, cost);
		let quantity = event.target.Quantity.value;
    console.log(`quantity is:`, quantity);
		let description = event.target.Description.value;
    console.log(`description is:`, description);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.bomData));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			PartNumber: partNumber,
			Cost: cost,
			Quantity: quantity,
			Description: description
    }
		this.setState({
			bomData: newObj,
			modal7: !this.state.modal7
		})
    console.log("saved bomData is:", this.state.bomData[id].PartNumber);

    this.updateBOM(bomID, partNumber, cost, quantity, description, products);
	}

  updateBOM = async (bomID, partNumber, cost, quantity, description, products) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-bill-of-materials?BomID="+bomID+"&PartNumber="+partNumber+"&Cost="+cost+"&Description="+description+"&Quantity="+quantity;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Update bomData response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				await this.deleteAllProductItemsForBOM(bomID);

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForBOM(bomID, product);
				}

				// products.map((product, i) => {
				// 	this.addProductItemForBOM(bomID, product);
				// });

				this.getBOMData();

			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleQuoteItemSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let qItemID = event.target.qItemID.value;
    console.log(`qItemID is:`, qItemID);
    let sku = event.target.sku.value;
    console.log(`sku is:`, sku);
		let cost = event.target.Cost.value;
    console.log(`cost is:`, cost);
		let quantity = event.target.Quantity.value;
    console.log(`quantity is:`, quantity);
		let description = event.target.Description.value;
    console.log(`description is:`, description);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.quoteData));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			SKU: sku,
			Cost: cost,
			Quantity: quantity,
			Description: description
    }
		this.setState({
			quoteData: newObj,
			modal14: !this.state.modal14
		})
    console.log("saved quoteData is:", this.state.quoteData[id].SKU);

    this.updateQuoteItem(qItemID, sku, cost, quantity, description, products);
	}

	updateQuoteItem = async (qItemID, sku, cost, quantity, description, products) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-quote-item?QItemID="+qItemID+"&SKU="+sku+"&Cost="+cost+"&Description="+description+"&Quantity="+quantity;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Update quote item response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				await this.deleteAllProductItemsForQuoteItem(qItemID);

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForQuoteItem(qItemID, product);
				}

				// products.map((product, i) => {
				// 	this.addProductItemForBOM(bomID, product);
				// });

				this.getQuoteData();

			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleTAMSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let tamID = event.target.tamID.value;
    console.log(`tamID is:`, tamID);
    let name = event.target.name.value;
    console.log(`name is:`, name);
		let phone = event.target.phone.value;
    console.log(`phone is:`, phone);

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.tamData));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			name: name,
			phone: phone
    }
		this.setState({
			tamData: newObj,
			modal9: !this.state.modal9
		})
    console.log("saved tamData is:", this.state.tamData[id].name);

    this.updateTAM(tamID, name, phone);
	}

  updateTAM = async (tamID, name, phone) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-tam-names?TamID="+tamID+"&Name="+name+"&Phone="+phone;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Update tamData response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};


  render() {

		const data = this.state.repairCategories.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				categoryID: prop.id,
				ProductName: prop.productName,
        name: prop.name,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									obj: obj,
									modal: !this.state.modal,
								});
								//this.showVerification(obj);

								this.getProductItemsForCategory(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal2: !this.state.modal2,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal2: !this.state.modal2,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

    const data2 = this.state.repairOptions.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				optionID: prop.id,
        name: prop.name,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal3: !this.state.modal3,
									obj: obj
								});
								//this.showVerification(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		const data3 = this.state.bomData.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				bomID: prop.id,
				PartNumber: prop.PartNumber,
				ProductName: prop.productName,
        Cost: prop.Cost,
				Quantity: prop.Quantity,
				Description: prop.Description,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data3.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal7: !this.state.modal7,
									obj: obj
								});

								this.getProductItemsForBOM(obj)
								//this.showVerification(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data3.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal8: !this.state.modal8,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		const data4 = this.state.tamData.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				tamID: prop.id,
        name: prop.name,
				phone: prop.phone,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data4.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal9: !this.state.modal9,
									obj: obj
								});
								//this.showVerification(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data4.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal10: !this.state.modal10,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		const data5 = this.state.quoteData.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				qItemID: prop.id,
				sku: prop.SKU,
        Cost: prop.Cost,
				Quantity: prop.Quantity,
				ProductName: prop.productName,
				Description: prop.Description,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data5.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal14: !this.state.modal14,
									obj: obj
								});
								//this.showVerification(obj);
								this.getProductItemsForQuoteItem(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data5.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal15: !this.state.modal15,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		return (
      <Fragment>
			{!this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0 ? (
				<Fragment>
					<Row>
		        <Col xs="4" md="4" lg="4">
		          <Card className="card-hover">
		            <CardBody>
		              <Row className="mb-3">
		                <Col md="5">
		                  <h3 style={{color:'#737782'}}>Repair Reason</h3>
		                </Col>
										<Col md="7" className="text-right">
											<div>
												<ButtonDropdown isOpen={this.state.isOpen3} toggle={this.toggleDropDown3}>
													<DropdownToggle color="dark" caret>
														{this.state.currentItem3}
													</DropdownToggle>
													<DropdownMenu right>
														<DropdownItem onClick={() => this.selectDropDownItem3(0, 'ALL')}>ALL</DropdownItem>
														{this.state.productsData.map((product, i) => (
															<DropdownItem onClick={() => this.selectDropDownItem3(product.id, product.Name)}>{product.Name}</DropdownItem>
														))}
													</DropdownMenu>
												</ButtonDropdown>
												<Button className="float-right ml-3" color="yellow" onClick={this.toggle5}>Create Reason</Button>
											</div>
		                </Col>
		              </Row>
		              <ReactTable
		                style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
		                columns={[
		                  {
		                    Header: "Name",
		                    accessor: "name",
												minWidth: 40,
		                    style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
		                    Cell: row => (
		                        <div style={{ textAlign: "center" }}>{row.value}</div>
		                      )
		                  },
											{
												Header: "Product",
												accessor: "ProductName",
												minWidth: 13,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Actions",
												accessor: "actions",
												sortable: false,
												minWidth: 15,
												filterable: false
											}
		                ]}
		                defaultPageSize={6}
		                sortable={true}
		                showPaginationBottom={true}
		                className="-striped -highlight"
		                data={data}
		                filterable
		                getTrGroupProps={(state, rowInfo, column, instance) => {
		                        if (rowInfo !== undefined) {
		                            return {
		                                onClick: (e, handleOriginal) => {
		                                  console.log('It was in this row:', rowInfo)
		                                  console.log('with ID:', rowInfo.original.categoryID);

		                                  //this.goToRepairLog(rowInfo.original.repairId);
		                                },
		                                style: {
		                                    cursor: 'pointer'
		                                }
		                            }
		                        }}
		                    }
		              />
		            </CardBody>
		          </Card>
		        </Col>
						<Col xs="4" md="4" lg="4">
		          <Card className="card-hover">
		            <CardBody>
		              <Row className="mb-3">
		                <Col md="6">
		                  <h3 style={{color:'#737782'}}>Repair Options</h3>
		                </Col>
										<Col md="6">
		                  <Button className="float-right" color="yellow" onClick={this.toggle6}>Create Repair Option</Button>
		                </Col>
		              </Row>
		              <ReactTable
		                style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
		                columns={[
		                  {
		                    Header: "Name",
		                    accessor: "name",
		                    style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
		                    Cell: row => (
		                        <div style={{ textAlign: "center" }}>{row.value}</div>
		                      )
		                  },
											{
												Header: "Actions",
												accessor: "actions",
												minWidth: 20,
												sortable: false,
												filterable: false
											}
		                ]}
		                defaultPageSize={6}
		                sortable={true}
		                showPaginationBottom={true}
		                className="-striped -highlight"
		                data={data2}
		                filterable
		                getTrGroupProps={(state, rowInfo, column, instance) => {
		                        if (rowInfo !== undefined) {
		                            return {
		                                onClick: (e, handleOriginal) => {
		                                  console.log('It was in this row:', rowInfo)
		                                  console.log('with ID:', rowInfo.original.optionID);

		                                  //this.goToRepairLog(rowInfo.original.repairId);
		                                },
		                                style: {
		                                    cursor: 'pointer'
		                                }
		                            }
		                        }}
		                    }
		              />
		            </CardBody>
		          </Card>
		        </Col>
						<Col xs="4" md="4" lg="4">
							<Card className="card-hover">
								<CardBody>
									<Row className="mb-3">
										<Col md="6">
											<h3 style={{color:'#737782'}}>TAM Names</h3>
										</Col>
										<Col md="6">
											<Button className="float-right" color="yellow" onClick={this.toggle12}>Create TAM Name</Button>
										</Col>
									</Row>
									<ReactTable
										style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
										columns={[
											{
												Header: "Name",
												accessor: "name",
												minWidth: 40,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Phone Number",
												accessor: "phone",
												minWidth: 40,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Actions",
												accessor: "actions",
												minWidth: 20,
												sortable: false,
												filterable: false
											}
										]}
										defaultPageSize={6}
										sortable={true}
										showPaginationBottom={true}
										className="-striped -highlight"
										data={data4}
										filterable
										getTrGroupProps={(state, rowInfo, column, instance) => {
														if (rowInfo !== undefined) {
																return {
																		onClick: (e, handleOriginal) => {
																			console.log('It was in this row:', rowInfo)
																			console.log('with ID:', rowInfo.original.optionID);

																			//this.goToRepairLog(rowInfo.original.repairId);
																		},
																		style: {
																				cursor: 'pointer'
																		}
																}
														}}
												}
									/>
								</CardBody>
							</Card>
						</Col>
		      </Row>
					<Row>
		        <Col xs="6" md="6" lg="6">
		          <Card className="card-hover">
		            <CardBody>
		              <Row className="mb-3">
		                <Col md="6">
		                  <h3 style={{color:'#737782'}}>Azena Inventory</h3>
		                </Col>
										<Col md="6" className="text-right">
											<div>
												<ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
													<DropdownToggle color="dark" caret>
														{this.state.currentItem}
													</DropdownToggle>
													<DropdownMenu right>
														<DropdownItem onClick={() => this.selectDropDownItem(0, 'ALL')}>ALL</DropdownItem>
														{this.state.productsData.map((product, i) => (
															<DropdownItem onClick={() => this.selectDropDownItem(product.id, product.Name)}>{product.Name}</DropdownItem>
														))}
													</DropdownMenu>
												</ButtonDropdown>
		                  	<Button className="float-right ml-3" color="yellow" onClick={this.toggle11}>Create New Part</Button>
											</div>
		                </Col>
		              </Row>
		              <ReactTable
		                style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
		                columns={[
		                  {
		                    Header: "Part Number",
		                    accessor: "PartNumber",
												minWidth: 15,
		                    style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
		                    Cell: row => (
		                        <div style={{ textAlign: "center" }}>{row.value}</div>
		                      )
		                  },
											{
		                    Header: "Cost",
		                    accessor: "Cost",
												minWidth: 8,
		                    style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
		                    Cell: row => (
		                        <div style={{ textAlign: "center" }}>{row.value}</div>
		                      )
		                  },
											{
												Header: "Quantity",
												accessor: "Quantity",
												minWidth: 8,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
		                    Header: "Description",
		                    accessor: "Description",
												minWidth: 40,
		                    style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
		                    Cell: row => (
		                        <div style={{ textAlign: "center" }}>{row.value}</div>
		                      )
		                  },
											{
												Header: "Product",
												accessor: "ProductName",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Actions",
												accessor: "actions",
												sortable: false,
												minWidth: 15,
												filterable: false
											}
		                ]}
		                defaultPageSize={6}
		                sortable={true}
		                showPaginationBottom={true}
		                className="-striped -highlight"
		                data={data3}
		                filterable
		                getTrGroupProps={(state, rowInfo, column, instance) => {
		                        if (rowInfo !== undefined) {
		                            return {
		                                onClick: (e, handleOriginal) => {
		                                  console.log('It was in this row:', rowInfo)
		                                  console.log('with ID:', rowInfo.original.categoryID);

		                                  //this.goToRepairLog(rowInfo.original.repairId);
		                                },
		                                style: {
		                                    cursor: 'pointer'
		                                }
		                            }
		                        }}
		                    }
		              />
		            </CardBody>
		          </Card>
		        </Col>
						<Col xs="6" md="6" lg="6">
							<Card className="card-hover">
								<CardBody>
									<Row className="mb-3">
										<Col md="6">
											<h3 style={{color:'#737782'}}>Quoting Options</h3>
										</Col>
										<Col md="6" className="text-right">
											<div>
												<ButtonDropdown isOpen={this.state.isOpen2} toggle={this.toggleDropDown2}>
													<DropdownToggle color="dark" caret>
														{this.state.currentItem2}
													</DropdownToggle>
													<DropdownMenu right>
														<DropdownItem onClick={() => this.selectDropDownItem2(0, 'ALL')}>ALL</DropdownItem>
														{this.state.productsData.map((product, i) => (
															<DropdownItem onClick={() => this.selectDropDownItem2(product.id, product.Name)}>{product.Name}</DropdownItem>
														))}
													</DropdownMenu>
												</ButtonDropdown>
												<Button className="float-right ml-3" color="yellow" onClick={this.toggle13}>Create New Quoting Item</Button>
											</div>
										</Col>
									</Row>
									<ReactTable
										style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
										columns={[
											{
												Header: "SKU",
												accessor: "sku",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Cost",
												accessor: "Cost",
												minWidth: 10,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Quantity",
												accessor: "Quantity",
												minWidth: 8,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Description",
												accessor: "Description",
												minWidth: 40,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Product",
												accessor: "ProductName",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Actions",
												accessor: "actions",
												sortable: false,
												minWidth: 15,
												filterable: false
											}
										]}
										defaultPageSize={6}
										sortable={true}
										showPaginationBottom={true}
										className="-striped -highlight"
										data={data5}
										filterable
										getTrGroupProps={(state, rowInfo, column, instance) => {
														if (rowInfo !== undefined) {
																return {
																		onClick: (e, handleOriginal) => {
																			console.log('It was in this row:', rowInfo)
																			console.log('with ID:', rowInfo.original.categoryID);

																			//this.goToRepairLog(rowInfo.original.repairId);
																		},
																		style: {
																				cursor: 'pointer'
																		}
																}
														}}
												}
									/>
								</CardBody>
							</Card>
		        </Col>
		      </Row>
				</Fragment>
			) : (
				<Fragment>
				<Row>
					<Col xs="12" md="12" lg="12">
	          <Card className="card-hover">
	            <CardBody>
	              <Row className="mb-3">
	                <Col md="6">
	                  <h1 style={{color:'#737782'}}>TAM Names</h1>
	                </Col>
									<Col md="6">
	                  <Button className="float-right" color="yellow" onClick={this.toggle12}>Create TAM Name</Button>
	                </Col>
	              </Row>
	              <ReactTable
	                style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
	                columns={[
	                  {
	                    Header: "Name",
	                    accessor: "name",
											minWidth: 40,
	                    style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
	                    Cell: row => (
	                        <div style={{ textAlign: "center" }}>{row.value}</div>
	                      )
	                  },
										{
	                    Header: "Phone Number",
	                    accessor: "phone",
											minWidth: 40,
	                    style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
	                    Cell: row => (
	                        <div style={{ textAlign: "center" }}>{row.value}</div>
	                      )
	                  },
										{
											Header: "Actions",
											accessor: "actions",
											minWidth: 20,
											sortable: false,
											filterable: false
										}
	                ]}
	                defaultPageSize={6}
	                sortable={true}
	                showPaginationBottom={true}
	                className="-striped -highlight"
	                data={data4}
	                filterable
	                getTrGroupProps={(state, rowInfo, column, instance) => {
	                        if (rowInfo !== undefined) {
	                            return {
	                                onClick: (e, handleOriginal) => {
	                                  console.log('It was in this row:', rowInfo)
	                                  console.log('with ID:', rowInfo.original.optionID);

	                                  //this.goToRepairLog(rowInfo.original.repairId);
	                                },
	                                style: {
	                                    cursor: 'pointer'
	                                }
	                            }
	                        }}
	                    }
	              />
	            </CardBody>
	          </Card>
	        </Col>
	      </Row>
				</Fragment>
			)}
			<div>
				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Edit Repair Reason</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.handleCategorySubmit}>
							<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
							<Input type="hidden" name="categoryID" id="categoryID" defaultValue={this.state.obj.categoryID}/>
							<FormGroup>
								<Label for="firstname">Name</Label>
								<Input type="text" name="name" id="name" defaultValue={this.state.obj.name}/>
							</FormGroup>
							<FormGroup>
								<Label for="Products">Select Products</Label>
								<Input id="Products" multiple name="Products" type="select">
								{this.state.productsData.map((product, i) => {

									var productItem = this.state.productItemsForCategory.find(p => p.ProductID === product.id);

									console.log('product: ', product.id);
									console.log('productItem: ', productItem);

									if (productItem) {
										return (
											<option value={product.id} selected>{product.Name}</option>
										);
									} else {
										return (
											<option value={product.id}>{product.Name}</option>
										);
									}

								})}
								</Input>
							</FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal2} toggle={this.toggle2}>
  				<ModalHeader toggle={this.toggle2}>Delete Repair Reason</ModalHeader>
  				<ModalBody>
						<span>Are you sure you want to delete this Category?</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteCategory}>Yes</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle2}>Cancel</Button>
					</ModalFooter>
			  </Modal>
				<Modal isOpen={this.state.modal3} toggle={this.toggle3}>
					<ModalHeader toggle={this.toggle3}>Edit Repair Option</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.handleOptionSubmit}>
							<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
							<Input type="hidden" name="optionID" id="optionID" defaultValue={this.state.obj.optionID}/>
							<FormGroup>
								<Label for="firstname">Name</Label>
								<Input type="text" name="name" id="name" defaultValue={this.state.obj.name}/>
							</FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle3}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal4} toggle={this.toggle4}>
  				<ModalHeader toggle={this.toggle4}>Delete Repair Option</ModalHeader>
  				<ModalBody>
						<span>Are you sure you want to delete this Option?</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteOption}>Yes</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle4}>Cancel</Button>
					</ModalFooter>
			  </Modal>
				<Modal isOpen={this.state.modal5} toggle={this.toggle5}>
					<ModalHeader toggle={this.toggle5}>Create Repair Reason</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.createCategorySubmit}>
							<FormGroup>
								<Label for="firstname">Name</Label>
								<Input type="text" name="name" id="name" defaultValue={this.state.obj.name}/>
							</FormGroup>
							<FormGroup>
								<Label for="Products">Select Products</Label>
								<Input id="Products" multiple name="Products" type="select">
									{this.state.productsData.map((product, i) => (
										<option value={product.id}>{product.Name}</option>
									))}
								</Input>
							</FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle5}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal6} toggle={this.toggle6}>
					<ModalHeader toggle={this.toggle6}>Create Repair Option</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.createOptionSubmit}>
							<FormGroup>
								<Label for="firstname">Name</Label>
								<Input type="text" name="name" id="name" defaultValue={this.state.obj.name}/>
							</FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle6}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal7} toggle={this.toggle7}>
					<ModalHeader toggle={this.toggle7}>Edit Part</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.handleBOMSubmit}>
							<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
							<Input type="hidden" name="bomID" id="bomID" defaultValue={this.state.obj.bomID}/>
							<FormGroup>
								<Label for="PartNumber">Part Number</Label>
								<Input type="text" name="PartNumber" id="PartNumber" defaultValue={this.state.obj.PartNumber}/>
							</FormGroup>
							<FormGroup>
								<Label for="Cost">Cost</Label>
								<Input type="text" name="Cost" id="Cost" defaultValue={this.state.obj.Cost}/>
							</FormGroup>
							<FormGroup>
								<Label for="Cost">Quantity</Label>
								<Input type="text" name="Quantity" id="Quantity" defaultValue={this.state.obj.Quantity}/>
							</FormGroup>
							<FormGroup>
								<Label for="Description">Description</Label>
								<Input type="text" name="Description" id="Description" defaultValue={this.state.obj.Description}/>
							</FormGroup>
							<FormGroup>
						    <Label for="Products">Select Products</Label>
						    <Input id="Products" multiple name="selectMulti" type="select">
									{
										// {this.state.productsData.map((product, i) => (
										// 	<option value={product.id}>{product.Name}</option>
										// ))}
									}

									{this.state.productsData.map((product, i) => {

										var productItem = this.state.productItemsForBOM.find(p => p.ProductID === product.id);

										console.log('product: ', product.id);
										console.log('productItem: ', productItem);

										if (productItem) {
											return (
												<option value={product.id} selected>{product.Name}</option>
											);
										} else {
											return (
												<option value={product.id}>{product.Name}</option>
											);
										}

									})}
						    </Input>
						  </FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle7}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal8} toggle={this.toggle8}>
  				<ModalHeader toggle={this.toggle8}>Delete Part</ModalHeader>
  				<ModalBody>
						<span>Are you sure you want to delete this Part?</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteBOM}>Yes</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle8}>Cancel</Button>
					</ModalFooter>
			  </Modal>
				<Modal isOpen={this.state.modal9} toggle={this.toggle9}>
					<ModalHeader toggle={this.toggle9}>Edit TAM Names</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.handleTAMSubmit}>
							<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
							<Input type="hidden" name="tamID" id="tamID" defaultValue={this.state.obj.tamID}/>
							<FormGroup>
								<Label for="name">Name</Label>
								<Input type="text" name="name" id="name" defaultValue={this.state.obj.name}/>
							</FormGroup>
							<FormGroup>
								<Label for="phone">Phone Number</Label>
								<Input type="text" name="phone" id="phone" defaultValue={this.state.obj.phone}/>
							</FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle9}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal10} toggle={this.toggle10}>
  				<ModalHeader toggle={this.toggle10}>Delete TAM Name</ModalHeader>
  				<ModalBody>
						<span>Are you sure you want to delete this TAM name?</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteTAM}>Yes</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle10}>Cancel</Button>
					</ModalFooter>
			  </Modal>
				<Modal isOpen={this.state.modal11} toggle={this.toggle11}>
					<ModalHeader toggle={this.toggle11}>Create Part</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.createBOMSubmit}>
							<FormGroup>
								<Label for="PartNumber">Part Number</Label>
								<Input type="text" name="PartNumber" id="PartNumber"/>
							</FormGroup>
							<FormGroup>
								<Label for="Cost">Cost</Label>
								<Input type="text" name="Cost" id="Cost"/>
							</FormGroup>
							<FormGroup>
								<Label for="Quantity">Quantity</Label>
								<Input type="text" name="Quantity" id="Quantity"/>
							</FormGroup>
							<FormGroup>
								<Label for="Description">Description</Label>
								<Input type="text" name="Description" id="Description"/>
							</FormGroup>
							<FormGroup>
						    <Label for="Products">Select Products</Label>
						    <Input id="Products" multiple name="Products" type="select">
									{this.state.productsData.map((product, i) => (
										<option value={product.id}>{product.Name}</option>
									))}
						    </Input>
						  </FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle11}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal12} toggle={this.toggle12}>
					<ModalHeader toggle={this.toggle12}>Create Tam Name</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.createTAMSubmit}>
							<FormGroup>
								<Label for="name">Name</Label>
								<Input type="text" name="name" id="name"/>
							</FormGroup>
							<FormGroup>
								<Label for="phone">Phone Number</Label>
								<Input type="text" name="phone" id="phone"/>
							</FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle12}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal13} toggle={this.toggle13}>
					<ModalHeader toggle={this.toggle13}>Create New Quoting Item</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.createQuoteItemSubmit}>
							<FormGroup>
								<Label for="PartNumber">SKU</Label>
								<Input type="text" name="PartNumber" id="sku"/>
							</FormGroup>
							<FormGroup>
								<Label for="Cost">Cost</Label>
								<Input type="text" name="Cost" id="Cost"/>
							</FormGroup>
							<FormGroup>
								<Label for="Quantity">Quantity</Label>
								<Input type="text" name="Quantity" id="Quantity"/>
							</FormGroup>
							<FormGroup>
								<Label for="Description">Description</Label>
								<Input type="text" name="Description" id="Description"/>
							</FormGroup>
							<FormGroup>
						    <Label for="Products">Select Products</Label>
						    <Input id="Products" multiple name="Products" type="select">
									{this.state.productsData.map((product, i) => (
										<option value={product.id}>{product.Name}</option>
									))}
						    </Input>
						  </FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle13}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal14} toggle={this.toggle14}>
					<ModalHeader toggle={this.toggle14}>Edit Quote Item</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.handleQuoteItemSubmit}>
							<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
							<Input type="hidden" name="qItemID" id="qItemID" defaultValue={this.state.obj.qItemID}/>
							<FormGroup>
								<Label for="sku">SKU</Label>
								<Input type="text" name="sku" id="sku" defaultValue={this.state.obj.sku}/>
							</FormGroup>
							<FormGroup>
								<Label for="Cost">Cost</Label>
								<Input type="text" name="Cost" id="Cost" defaultValue={this.state.obj.Cost} disabled={!this.state.canEditCost}/>
							</FormGroup>
							<FormGroup>
								<Label for="Cost">Quantity</Label>
								<Input type="text" name="Quantity" id="Quantity" defaultValue={this.state.obj.Quantity}/>
							</FormGroup>
							<FormGroup>
								<Label for="Description">Description</Label>
								<Input type="text" name="Description" id="Description" defaultValue={this.state.obj.Description}/>
							</FormGroup>
							<FormGroup>
						    <Label for="Products">Select Products</Label>
						    <Input id="Products" multiple name="Products" type="select">
								{this.state.productsData.map((product, i) => {

									var productItem = this.state.productItemsForQuoteItem.find(p => p.ProductID === product.id);

									console.log('product: ', product.id);
									console.log('productItem: ', productItem);

									if (productItem) {
										return (
											<option value={product.id} selected>{product.Name}</option>
										);
									} else {
										return (
											<option value={product.id}>{product.Name}</option>
										);
									}

								})}
						    </Input>
						  </FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle14}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal15} toggle={this.toggle15}>
  				<ModalHeader toggle={this.toggle15}>Delete Quote Item</ModalHeader>
  				<ModalBody>
						<span>Are you sure you want to delete this Item?</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteQuoteItem}>Yes</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle15}>Cancel</Button>
					</ModalFooter>
			  </Modal>
			</div>
      </Fragment>
		);
	}
}

export default RepairOptionList;
