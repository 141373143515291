import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';
import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';

let lineOptions2 = {
	maintainAspectRatio: false,
	legend: {
		display: false,
		labels: { fontFamily: "Nunito Sans" }
	},
	scales: {
		yAxes: [{
			gridLines: {
				display: false
			},
			ticks: {
				fontFamily: "Nunito Sans",
				fontColor: 'rgb(178, 185, 191)'
			}
		}],
		xAxes: [{
			gridLines: {
				display: false
			},
			ticks: {
        display: false,
				fontFamily: "Nunito Sans",
				fontColor: 'rgb(178, 185, 191)'
			}
		}]
	}
}

class TotalRepairsByTechnician extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			repairTotals: [],
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		//this.toggle4 = this.toggle4.bind(this);

  }

	async componentDidMount() {

    this.getData();
	}

	componentWillUnmount() {

	}

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	getData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-total-repairs-by-technician";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("top pending repairLogs: " + JSON.stringify(body));

			this.setState({loading: false});

			if (body.status == 'Success') {

	      var chartData = {
						labels: body.response.map(k => k.TechnicianRepair),
						datasets: [{
							label: 'Total Repairs',
							backgroundColor: '#e9c736',
							data: body.response.map(k => k.total)
						}]
					}

	      this.setState({ repairTotals: chartData });

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  render() {

		return (
			<Fragment>
        <Card className="card-hover">
          <CardBody style={{ height: 330 }}>
            <Row className="mb-3">
              <Col md="12">
                <h3 style={{color:'#737782'}}>Total Repairs By Technician</h3>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="12">
                <Bar height={250} data={this.state.repairTotals} options={lineOptions2} />
              </Col>
            </Row>
          </CardBody>
        </Card>
			</Fragment>
		);
	}
}

export default TotalRepairsByTechnician;
