import React from 'react';
import Moment from 'moment';


class Footer extends React.Component {
  render() {
    return (
      <footer className="footer text-center">
        {process.env.REACT_APP_NAME} v{process.env.REACT_APP_VERSION} | Copyright © {Moment().format('YYYY')} <a href="https://www.azenamedical.com/">Azena Medical, LLC.</a>  All rights reserved.
      </footer>
    );
  }
}
export default Footer;
