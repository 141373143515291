import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	ButtonDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
	Fade
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class TAMNames extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info token: ' + JSON.stringify(currentUser.token));

    this.state = {
			repairCategories: [],
      repairOptions: [],
			repairBOM: [],
			bomData: [],
			productsData: [],
			quoteData: [],
			marketsData: [],
			tamData: [],
			productItemsForBOM: [],
			productItemsForQuoteItem: [],
			productItemsForCategory: [],
			marketItemsForTamItem: [],
			marketSelected: 100,
			obj: {},
			modal: false,
      modal2: false,
			modal3: false,
			modal4: false,
			modal5: false,
			modal6: false,
			modal7: false,
			modal8: false,
			modal9: false,
			modal10: false,
			modal11: false,
			modal12: false,
			modal13: false,
			modal14: false,
			modal15: false,
			modal16: false,
			canEditCost: false,
			isOpen: false,
			currentItem: "ALL Markets",
			currentDataSetting: 100,
			isOpen2: false,
			currentItem2: "ALL",
			currentDataSetting2: 0,
			isOpen3: false,
			currentItem3: "ALL",
			currentDataSetting3: 0,
			phoneNumber: "",
			status: "",
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);

		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.toggle3 = this.toggle3.bind(this);
		this.toggle4 = this.toggle4.bind(this);
		this.toggle5 = this.toggle5.bind(this);
		this.toggle6 = this.toggle6.bind(this);
		this.toggle7 = this.toggle7.bind(this);
		this.toggle8 = this.toggle8.bind(this);
		this.toggle9 = this.toggle9.bind(this);
		this.toggle10 = this.toggle10.bind(this);
		this.toggle11 = this.toggle11.bind(this);
		this.toggle12 = this.toggle12.bind(this);
		this.toggle13 = this.toggle13.bind(this);
		this.toggle14 = this.toggle14.bind(this);
		this.toggle15 = this.toggle15.bind(this);
		this.toggle16 = this.toggle16.bind(this);

		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.toggleDropDown2 = this.toggleDropDown2.bind(this);
		this.toggleDropDown3 = this.toggleDropDown3.bind(this);

  }

	async componentDidMount() {

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="europe") {
			this.setState({ currentItem: "Europe", marketSelected: 1 });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="aus") {
			this.setState({ currentItem: "Australia", marketSelected: 2 });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="brazil") {
			this.setState({ currentItem: "Brazil", marketSelected: 3 });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="taiwan") {
			this.setState({ currentItem: "Taiwan", marketSelected: 4 });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="colombia") {
			this.setState({ currentItem: "Colombia", marketSelected: 5 });
		}

    await this.getMarketsData();
		this.getProductsData();
		//this.getBOMData();
		//this.getQuoteData();
		this.getTAMData();

		console.log('repair options currentUser email: ' + JSON.stringify(this.state.currentUser.payload.email));

		if (this.state.currentUser.payload.email == "jluo@azenamedical.com" || this.state.currentUser.payload.email == "hbrown@azenamedical.com" || this.state.currentUser.payload.email == "ryan.a.stickel@gmail.com") {
			this.setState({ canEditCost: true });
		}
		//canEditCost
	}

	async onRadioBtnClick(marketSelected) {
		await this.setState({ marketSelected, currentDataSetting: marketSelected });
		//this.getBOMData(marketSelected);

		// this.setState({
    //   currentItem: title,
		// 	currentDataSetting: id
    // });

		setTimeout(this.getTAMData, 300);
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

  toggle2() {

	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle3() {
	  this.setState({
	    modal3: !this.state.modal3
	  });
	}

	toggle4() {
	  this.setState({
	    modal4: !this.state.modal4
	  });
	}

	toggle5() {
	  this.setState({
	    modal5: !this.state.modal5
	  });
	}

	toggle6() {
	  this.setState({
	    modal6: !this.state.modal6
	  });
	}

	toggle7() {
	  this.setState({
	    modal7: !this.state.modal7
	  });
	}

	toggle8() {
	  this.setState({
	    modal8: !this.state.modal8
	  });
	}

	toggle9() {
	  this.setState({
			status: "",
	    modal9: !this.state.modal9
	  });
	}

	toggle10() {
	  this.setState({
	    modal10: !this.state.modal10
	  });
	}

	toggle11() {
	  this.setState({
	    modal11: !this.state.modal11
	  });
	}

	toggle12() {
	  this.setState({
			status: "",
			phoneNumber: "",
	    modal12: !this.state.modal12
	  });
	}

	toggle13() {
	  this.setState({
	    modal13: !this.state.modal13
	  });
	}

	toggle14() {
	  this.setState({
	    modal14: !this.state.modal14
	  });
	}

	toggle15() {
	  this.setState({
	    modal15: !this.state.modal15
	  });
	}

	toggle16() {
	  this.setState({
	    modal16: !this.state.modal16
	  });
	}

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	toggleDropDown2() {
    this.setState({
      isOpen2: !this.state.isOpen2
    });
  }

	toggleDropDown3() {
    this.setState({
      isOpen3: !this.state.isOpen3
    });
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		setTimeout(this.getTAMData, 300);

  }

	selectDropDownItem2(id, title) {

    this.setState({
      currentItem2: title,
			currentDataSetting2: id
    });

		setTimeout(this.getQuoteData, 300);

  }

	selectDropDownItem3(id, title) {

    this.setState({
      currentItem3: title,
			currentDataSetting3: id
    });

		setTimeout(this.getCategoryData, 300);

  }

	componentWillUnmount() {

	}

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	getMarketsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-markets";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all markets data: " + JSON.stringify(body));

			if (body.status == 'Success') {

				let userMarket = this.state.currentUser.payload['custom:market'];
				var marketsData = body.response;

				if (userMarket=='aus' || userMarket=='europe' || userMarket=='brazil' || userMarket=='taiwan' || userMarket=='colombia') {
					marketsData = marketsData.filter(market => market.Abbreviation.includes(userMarket))
				}

				this.setState({ marketsData });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getCategoryData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-repair-categories?ProductID="+this.state.currentDataSetting3;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all repairCategories: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ repairCategories: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  getOptionsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-repair-options";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all repairOptions: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ repairOptions: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getBOMData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-bill-of-materials?ProductID="+this.state.currentDataSetting;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all BOM data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ bomData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getProductItemsForBOM = async (obj) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-product-items-for-bom?BomID="+obj.bomID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all productItemsForBOM data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productItemsForBOM: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteAllProductItemsForBOM = async (bomID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-product-items-for-bom?BomID="+bomID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all BOM data: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	addProductItemForBOM = async (bomID, productID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-product-item-for-bom?BomID="+bomID+"&ProductID="+productID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all BOM data: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getProductItemsForQuoteItem = async (obj) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-product-items-for-quote-item?QuoteID="+obj.qItemID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all ProductItemsForQuoteItem data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productItemsForQuoteItem: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteAllProductItemsForQuoteItem = async (quoteID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-product-items-for-quote-item?QuoteID="+quoteID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("ProductItemsForQuoteItem delete response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	addProductItemForQuoteItem = async (quoteID, productID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-product-item-for-quote-item?QuoteID="+quoteID+"&ProductID="+productID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("add ProductItemForQuoteItem response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getProductItemsForCategory = async (obj) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-product-items-for-category?CategoryID="+obj.categoryID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all ProductItemForCategory data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productItemsForCategory: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	addProductItemForCategory = async (categoryID, productID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-product-item-for-category?CategoryID="+categoryID+"&ProductID="+productID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("add ProductItemForCategory response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteAllProductItemsForCategory = async (categoryID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-product-items-for-category?CategoryID="+categoryID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("ProductItemForCategory delete response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getProductsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-products";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Products data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productsData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getQuoteData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-quote-items?ProductID="+this.state.currentDataSetting2;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Quote data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ quoteData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getTAMData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-tam-names?MarketID="+this.state.marketSelected;

		console.log("getTAMData url: " + url);

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all TAM data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ tamData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	createCategorySubmit = (event) => {
		event.preventDefault();

    let name = event.target.name.value;
    console.log(`name is:`, name);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

		this.setState({
			modal5: !this.state.modal5
		})

    this.createCategory(name, products);
	}

  createCategory = async (name, products) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-repair-category?Name="+name;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create category response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				var categoryID = body.response.insertId;

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForCategory(categoryID, product);
				}

				this.getCategoryData();
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	createBOMSubmit = (event) => {
		event.preventDefault();

    let partNumber = event.target.PartNumber.value;
    console.log(`partNumber is:`, partNumber);

		let cost = event.target.Cost.value;
    console.log(`cost is:`, cost);

		let quantity = event.target.Quantity.value;
    console.log(`quantity is:`, quantity);

		let description = event.target.Description.value;
    console.log(`description is:`, description);

		//let products = event.target.Products.value;
		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

		this.setState({
			modal11: !this.state.modal11
		})

    this.createBOM(partNumber, cost, quantity, description, products);
	}

  createBOM = async (partNumber, cost, quantity, description, products) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-bill-of-materials?PartNumber="+partNumber+"&Cost="+cost+"&Description="+description+"&Quantity="+quantity;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create BOM response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				var bomID = body.response.insertId;

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForBOM(bomID, product);
				}

				// products.map((product, i) => {
				// 	this.addProductItemForBOM(bomID, product);
				// });

				this.getBOMData();
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	createQuoteItemSubmit = (event) => {
		event.preventDefault();

    let sku = event.target.sku.value;
    console.log(`sku is:`, sku);

		let cost = event.target.Cost.value;
    console.log(`cost is:`, cost);

		let quantity = event.target.Quantity.value;
    console.log(`quantity is:`, quantity);

		let description = event.target.Description.value;
    console.log(`description is:`, description);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

		this.setState({
			modal13: !this.state.modal13
		})

    this.createQuoteItem(sku, cost, quantity, description, products);
	}

	createQuoteItem = async (sku, cost, quantity, description, products) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-quote-item?SKU="+sku+"&Cost="+cost+"&Description="+description+"&Quantity="+quantity;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create Quote response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				var quoteID = body.response.insertId;

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForQuoteItem(quoteID, product);
				}

				this.getQuoteData();
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	createTAMSubmit = (event) => {
		event.preventDefault();

    let name = event.target.name.value;
    console.log(`name is:`, name);

		let phone = this.state.phoneNumber;
		//let phone = event.target.phone.value;
    console.log(`phone is:`, phone);

		let markets = Array.from(event.target.Markets.selectedOptions, option => option.value);
    console.log(`markets is:`, markets);

		if (!phone) {
			this.setState({ status: "Phone Number is Required." })
		} else {
			this.setState({
				modal12: !this.state.modal12
			})

			phone = phone.replace("+", "%2b");

			this.createTAM(name, phone, markets);
		}

	}

  createTAM = async (name, phone, markets) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-tam-names?Name="+name+"&Phone="+phone;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create TAM response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				var tamID = body.response.insertId;

				for (var i=0; i<markets.length; i++) {
					var market = markets[i];
					await this.addMarketItemForTamItem(tamID, market);
				}

				this.getTAMData();
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleCategorySubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let categoryID = event.target.categoryID.value;
    console.log(`categoryID is:`, categoryID);
    let name = event.target.name.value;
    console.log(`name is:`, name);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.repairCategories));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			name: name
    }
		this.setState({
			repairCategories: newObj,
			modal: !this.state.modal
		})
    console.log("saved repairCategories is:", this.state.repairCategories[id].name);

    this.updateCategory(categoryID, name, products);
	}

  updateCategory = async (categoryID, name, products) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-repair-category?CategoryID="+categoryID+"&Name="+name;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Update category response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				await this.deleteAllProductItemsForCategory(categoryID);

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForCategory(categoryID, product);
				}

				// products.map((product, i) => {
				// 	this.addProductItemForBOM(bomID, product);
				// });

				this.getCategoryData();

			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteCategory = async () => {

		console.log("Delete Category: " + this.state.obj.name);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-repair-category?CategoryID="+this.state.obj.categoryID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete category response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle2();
				this.getCategoryData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteOption = async () => {

		console.log("Delete Category: " + this.state.obj.name);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-repair-option?OptionID="+this.state.obj.optionID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete option response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle4();
				this.getOptionsData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteBOM = async () => {

		console.log("Delete BOM: " + this.state.obj.PartNumber);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-bill-of-materials?BomID="+this.state.obj.bomID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete BOM response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle8();
				await this.deleteAllProductItemsForBOM(this.state.obj.bomID);
				this.getBOMData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteQuoteItem = async () => {

		console.log("Delete Quote: " + this.state.obj.SKU);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-quote-item?QItemID="+this.state.obj.qItemID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete Quote response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle15();
				this.getQuoteData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteTAM = async () => {

		console.log("Delete TAM: " + this.state.obj.name);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-tam-names?TamID="+this.state.obj.tamID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete TAM response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle10();
				this.getTAMData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	createOptionSubmit = (event) => {
		event.preventDefault();

    let name = event.target.name.value;
    console.log(`name is:`, name);

		this.setState({
			modal6: !this.state.modal6
		})

    this.createOption(name);
	}

  createOption = async (name) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-repair-option?Name="+name;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create option response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;
				this.getOptionsData();
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleOptionSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let optionID = event.target.optionID.value;
    console.log(`optionID is:`, optionID);
    let name = event.target.name.value;
    console.log(`name is:`, name);

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.repairOptions));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			name: name
    }
		this.setState({
			repairOptions: newObj,
			modal3: !this.state.modal3
		})
    console.log("saved repairOptions is:", this.state.repairOptions[id].name);

    this.updateOption(optionID, name);
	}

  updateOption = async (optionID, name) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-repair-option?OptionID="+optionID+"&Name="+name;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Update option response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleBOMSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let bomID = event.target.bomID.value;
    console.log(`bomID is:`, bomID);
    let partNumber = event.target.PartNumber.value;
    console.log(`partNumber is:`, partNumber);
		let cost = event.target.Cost.value;
    console.log(`cost is:`, cost);
		let quantity = event.target.Quantity.value;
    console.log(`quantity is:`, quantity);
		let description = event.target.Description.value;
    console.log(`description is:`, description);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.bomData));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			PartNumber: partNumber,
			Cost: cost,
			Quantity: quantity,
			Description: description
    }
		this.setState({
			bomData: newObj,
			modal7: !this.state.modal7
		})
    console.log("saved bomData is:", this.state.bomData[id].PartNumber);

    this.updateBOM(bomID, partNumber, cost, quantity, description, products);
	}

  updateBOM = async (bomID, partNumber, cost, quantity, description, products) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-bill-of-materials?BomID="+bomID+"&PartNumber="+partNumber+"&Cost="+cost+"&Description="+description+"&Quantity="+quantity;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Update bomData response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				await this.deleteAllProductItemsForBOM(bomID);

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForBOM(bomID, product);
				}

				// products.map((product, i) => {
				// 	this.addProductItemForBOM(bomID, product);
				// });

				this.getBOMData();

			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleQuoteItemSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let qItemID = event.target.qItemID.value;
    console.log(`qItemID is:`, qItemID);
    let sku = event.target.sku.value;
    console.log(`sku is:`, sku);
		let cost = event.target.Cost.value;
    console.log(`cost is:`, cost);
		let quantity = event.target.Quantity.value;
    console.log(`quantity is:`, quantity);
		let description = event.target.Description.value;
    console.log(`description is:`, description);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.quoteData));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			SKU: sku,
			Cost: cost,
			Quantity: quantity,
			Description: description
    }
		this.setState({
			quoteData: newObj,
			modal14: !this.state.modal14
		})
    console.log("saved quoteData is:", this.state.quoteData[id].SKU);

    this.updateQuoteItem(qItemID, sku, cost, quantity, description, products);
	}

	updateQuoteItem = async (qItemID, sku, cost, quantity, description, products) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-quote-item?QItemID="+qItemID+"&SKU="+sku+"&Cost="+cost+"&Description="+description+"&Quantity="+quantity;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Update quote item response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				await this.deleteAllProductItemsForQuoteItem(qItemID);

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForQuoteItem(qItemID, product);
				}

				// products.map((product, i) => {
				// 	this.addProductItemForBOM(bomID, product);
				// });

				this.getQuoteData();

			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleTAMSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let tamID = event.target.tamID.value;
    console.log(`tamID is:`, tamID);
    let name = event.target.name.value;
    console.log(`name is:`, name);
		let phone = this.state.phoneNumber;
		//let phone = event.target.phone.value;
    console.log(`phone is:`, phone);

		let markets = Array.from(event.target.Markets.selectedOptions, option => option.value);
    console.log(`markets is:`, markets);

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.tamData));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			name: name,
			phone: phone
    }

		if (!phone) {
			this.setState({ status: "Phone Number is Required." })
		} else {
			this.setState({
				tamData: newObj,
				modal9: !this.state.modal9
			})

			console.log("saved tamData is:", this.state.tamData[id].name);

			phone = phone.replace("+", "%2b");

			this.updateTAM(tamID, name, phone, markets);
		}
	}

  updateTAM = async (tamID, name, phone, markets) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-tam-names?TamID="+tamID+"&Name="+name+"&Phone="+phone;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Update tamData response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				await this.deleteAllMarketItemsForTamItem(tamID);

				for (var i=0; i<markets.length; i++) {
					var market = markets[i];
					await this.addMarketItemForTamItem(tamID, market);
				}

				this.getTAMData();

			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getMarketItemsForTamItem = async (obj) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-market-items-for-manage-option?ManageOptionType=0&OptionID="+obj.tamID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all MarketItemsForTamItem data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ marketItemsForTamItem: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	addMarketItemForTamItem = async (tamID, marketID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-market-item-for-manage-option?ManageOptionType=0&OptionID="+tamID+"&MarketID="+marketID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("add MarketItemForTamItem response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteAllMarketItemsForTamItem = async (tamID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-market-items-for-manage-option?ManageOptionType=0&OptionID="+tamID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("MarketItemsForTamItem delete response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	onChangePhone = (value) => {

		// console.log("costOrPartNumberChange input id: " + event.target.id);
		console.log("onChangePhone input value: " + value);

		this.setState({
			status: "",
			phoneNumber: value,
		});

	}

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));

		if (id == 'datecreated') {
			var dateString = Moment(row[id]).format('MMM Do YYYY');
			return (
					dateString !== undefined ?
							String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}


  render() {

		var isForeignUser = false;

		if (this.state.currentUser.payload['custom:market'] && (this.state.currentUser.payload['custom:market']=="europe" || this.state.currentUser.payload['custom:market']=="aus" || this.state.currentUser.payload['custom:market']=="brazil" || this.state.currentUser.payload['custom:market']=="taiwan" || this.state.currentUser.payload['custom:market']=="colombia")) {
			isForeignUser = true;
		}

		var defaultCountryCode = "US";

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="aus") {
			defaultCountryCode = "AU";
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="europe") {
			defaultCountryCode = "GB";
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="brazil") {
			defaultCountryCode = "BR";
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="taiwan") {
			defaultCountryCode = "TW";
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="colombia") {
			defaultCountryCode = "CO";
		}

		const data = this.state.repairCategories.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				categoryID: prop.id,
				ProductName: prop.productName,
        name: prop.name,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									obj: obj,
									modal: !this.state.modal,
								});
								//this.showVerification(obj);

								this.getProductItemsForCategory(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal2: !this.state.modal2,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal2: !this.state.modal2,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

    const data2 = this.state.repairOptions.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				optionID: prop.id,
        name: prop.name,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal3: !this.state.modal3,
									obj: obj
								});
								//this.showVerification(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		const data3 = this.state.bomData.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				bomID: prop.id,
				PartNumber: prop.PartNumber,
				ProductName: prop.productName,
        Cost: prop.Cost,
				Quantity: prop.Quantity,
				Description: prop.Description,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data3.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal7: !this.state.modal7,
									obj: obj
								});

								this.getProductItemsForBOM(obj)
								//this.showVerification(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data3.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal8: !this.state.modal8,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		const data4 = this.state.tamData.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				MarketName: prop.marketName,
				tamID: prop.id,
        name: prop.name,
				phone: prop.phone,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data4.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal9: !this.state.modal9,
									phoneNumber: obj.phone,
									obj: obj
								});
								//this.showVerification(obj);
								this.getMarketItemsForTamItem(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data4.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal10: !this.state.modal10,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		return (
      <Fragment>
					<Row>
						<Col xs="12" md="12" lg="12">
							<Card className="card-hover">
								<CardBody>
									<Row className="mb-3">
										<Col md="2">
											{this.state.marketSelected==100 &&
												<Fade in="true">
												<h3 style={{color:'#737782'}}>ALL TAM Names</h3>
												</Fade>
											}
											{this.state.marketSelected==0 &&
												<Fade in="true">
												<h3 style={{color:'#737782'}}>US TAM Names</h3>
												</Fade>
											}
											{this.state.marketSelected==1 &&
												<Fade in="true">
												<h3 style={{color:'#737782'}}>Europe TAM Names</h3>
												</Fade>
											}
											{this.state.marketSelected==2 &&
												<Fade in="true">
												<h3 style={{color:'#737782'}}>Australia TAM Names</h3>
												</Fade>
											}
											{this.state.marketSelected==3 &&
												<Fade in="true">
												<h3 style={{color:'#737782'}}>Brazil TAM Names</h3>
												</Fade>
											}
											{this.state.marketSelected==4 &&
												<Fade in="true">
												<h3 style={{color:'#737782'}}>Taiwan TAM Names</h3>
												</Fade>
											}
											{this.state.marketSelected==5 &&
												<Fade in="true">
												<h3 style={{color:'#737782'}}>Colombia TAM Names</h3>
												</Fade>
											}
										</Col>
										<Col md="8">
										{!isForeignUser &&
											<div className="mt-1 mb-1 pb-4">
												<ButtonGroup style={{ width: '100%' }}>
													<Button
														style={{width: '100%'}}
														color="btngray"
														onClick={() => this.onRadioBtnClick(100)}
														active={this.state.marketSelected === 100}
													>
														ALL TAM Names
													</Button>
													<Button
														className="ml-1"
														style={{width: '100%'}}
														color="btngray"
														onClick={() => this.onRadioBtnClick(0)}
														active={this.state.marketSelected === 0}
													>
														US TAM Names
													</Button>
													<Button
														className="ml-1"
														color="btngray"
														style={{width: '100%'}}
														onClick={() => this.onRadioBtnClick(1)}
														active={this.state.marketSelected === 1}
													>
														Europe TAM Names
													</Button>
													<Button
														className="ml-1"
														color="btngray"
														style={{width: '100%'}}
														onClick={() => this.onRadioBtnClick(2)}
														active={this.state.marketSelected === 2}
													>
														Australia TAM Names
													</Button>
													<Button
														className="ml-1"
														color="btngray"
														style={{width: '100%'}}
														onClick={() => this.onRadioBtnClick(3)}
														active={this.state.marketSelected === 3}
													>
														Brazil TAM Names
													</Button>
													<Button
														className="ml-1"
														color="btngray"
														style={{width: '100%'}}
														onClick={() => this.onRadioBtnClick(4)}
														active={this.state.marketSelected === 4}
													>
														Taiwan TAM Names
													</Button>
													<Button
														className="ml-1"
														color="btngray"
														style={{width: '100%'}}
														onClick={() => this.onRadioBtnClick(5)}
														active={this.state.marketSelected === 5}
													>
														Colombia TAM Names
													</Button>
												</ButtonGroup>
											</div>
										}
										</Col>
										<Col md="2" className="text-right">
											{
												// <ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
												// 	<DropdownToggle color="dark" caret>
												// 		{this.state.currentItem}
												// 	</DropdownToggle>
												// 	<DropdownMenu right>
												// 		<DropdownItem onClick={() => this.selectDropDownItem(100, 'ALL Markets')}>ALL Markets</DropdownItem>
												// 		{this.state.marketsData.map((market, i) => (
												// 			<DropdownItem onClick={() => this.selectDropDownItem(market.id, market.Name)}>{market.Name}</DropdownItem>
												// 		))}
												// 	</DropdownMenu>
												// </ButtonDropdown>
											}
											<Button className="float-right ml-3" color="yellow" onClick={this.toggle12}>Create TAM Name</Button>
										</Col>
									</Row>
									<ReactTable
										style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
										columns={[
											{
												Header: "Location / Market",
												accessor: "MarketName",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Name",
												accessor: "name",
												minWidth: 40,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Phone Number",
												accessor: "phone",
												minWidth: 40,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{formatPhoneNumberIntl(row.value) ? formatPhoneNumberIntl(row.value) : row.value}</div>
													)
											},
											{
												Header: "Actions",
												accessor: "actions",
												minWidth: 20,
												sortable: false,
												filterable: false
											}
										]}
										defaultPageSize={14}
										sortable={true}
										showPaginationBottom={true}
										className="-striped -highlight"
										data={data4}
										filterable
										filterAll={true}
										defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
										getTrGroupProps={(state, rowInfo, column, instance) => {
														if (rowInfo !== undefined) {
																return {
																		onClick: (e, handleOriginal) => {
																			console.log('It was in this row:', rowInfo)
																			console.log('with ID:', rowInfo.original.optionID);

																			//this.goToRepairLog(rowInfo.original.repairId);
																		},
																		style: {
																				cursor: 'pointer'
																		}
																}
														}}
												}
									/>
								</CardBody>
							</Card>
						</Col>
		      </Row>
			<div>
				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Edit Repair Reason</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.handleCategorySubmit}>
							<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
							<Input type="hidden" name="categoryID" id="categoryID" defaultValue={this.state.obj.categoryID}/>
							<FormGroup>
								<Label for="firstname">Name</Label>
								<Input type="text" name="name" id="name" defaultValue={this.state.obj.name}/>
							</FormGroup>
							<FormGroup>
								<Label for="Products">Select Products</Label>
								<Input id="Products" multiple name="Products" type="select">
								{this.state.productsData.map((product, i) => {

									var productItem = this.state.productItemsForCategory.find(p => p.ProductID === product.id);

									console.log('product: ', product.id);
									console.log('productItem: ', productItem);

									if (productItem) {
										return (
											<option value={product.id} selected>{product.Name}</option>
										);
									} else {
										return (
											<option value={product.id}>{product.Name}</option>
										);
									}

								})}
								</Input>
							</FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal2} toggle={this.toggle2}>
  				<ModalHeader toggle={this.toggle2}>Delete Repair Reason</ModalHeader>
  				<ModalBody>
						<span>Are you sure you want to delete this Category?</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteCategory}>Yes</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle2}>Cancel</Button>
					</ModalFooter>
			  </Modal>
				<Modal isOpen={this.state.modal3} toggle={this.toggle3}>
					<ModalHeader toggle={this.toggle3}>Edit Repair Option</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.handleOptionSubmit}>
							<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
							<Input type="hidden" name="optionID" id="optionID" defaultValue={this.state.obj.optionID}/>
							<FormGroup>
								<Label for="firstname">Name</Label>
								<Input type="text" name="name" id="name" defaultValue={this.state.obj.name}/>
							</FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle3}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal4} toggle={this.toggle4}>
  				<ModalHeader toggle={this.toggle4}>Delete Repair Option</ModalHeader>
  				<ModalBody>
						<span>Are you sure you want to delete this Option?</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteOption}>Yes</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle4}>Cancel</Button>
					</ModalFooter>
			  </Modal>
				<Modal isOpen={this.state.modal5} toggle={this.toggle5}>
					<ModalHeader toggle={this.toggle5}>Create Repair Reason</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.createCategorySubmit}>
							<FormGroup>
								<Label for="firstname">Name</Label>
								<Input type="text" name="name" id="name" defaultValue={this.state.obj.name}/>
							</FormGroup>
							<FormGroup>
								<Label for="Products">Select Products</Label>
								<Input id="Products" multiple name="Products" type="select">
									{this.state.productsData.map((product, i) => (
										<option value={product.id}>{product.Name}</option>
									))}
								</Input>
							</FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle5}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal6} toggle={this.toggle6}>
					<ModalHeader toggle={this.toggle6}>Create Repair Option</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.createOptionSubmit}>
							<FormGroup>
								<Label for="firstname">Name</Label>
								<Input type="text" name="name" id="name" defaultValue={this.state.obj.name}/>
							</FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle6}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal7} toggle={this.toggle7}>
					<ModalHeader toggle={this.toggle7}>Edit Part</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.handleBOMSubmit}>
							<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
							<Input type="hidden" name="bomID" id="bomID" defaultValue={this.state.obj.bomID}/>
							<FormGroup>
								<Label for="PartNumber">Part Number</Label>
								<Input type="text" name="PartNumber" id="PartNumber" defaultValue={this.state.obj.PartNumber}/>
							</FormGroup>
							<FormGroup>
								<Label for="Cost">Cost</Label>
								<Input type="text" name="Cost" id="Cost" defaultValue={this.state.obj.Cost}/>
							</FormGroup>
							<FormGroup>
								<Label for="Cost">Quantity</Label>
								<Input type="text" name="Quantity" id="Quantity" defaultValue={this.state.obj.Quantity}/>
							</FormGroup>
							<FormGroup>
								<Label for="Description">Description</Label>
								<Input type="text" name="Description" id="Description" defaultValue={this.state.obj.Description}/>
							</FormGroup>
							<FormGroup>
						    <Label for="Products">Select Products</Label>
						    <Input id="Products" multiple name="selectMulti" type="select">
									{
										// {this.state.productsData.map((product, i) => (
										// 	<option value={product.id}>{product.Name}</option>
										// ))}
									}

									{this.state.productsData.map((product, i) => {

										var productItem = this.state.productItemsForBOM.find(p => p.ProductID === product.id);

										console.log('product: ', product.id);
										console.log('productItem: ', productItem);

										if (productItem) {
											return (
												<option value={product.id} selected>{product.Name}</option>
											);
										} else {
											return (
												<option value={product.id}>{product.Name}</option>
											);
										}

									})}
						    </Input>
						  </FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle7}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal8} toggle={this.toggle8}>
  				<ModalHeader toggle={this.toggle8}>Delete Part</ModalHeader>
  				<ModalBody>
						<span>Are you sure you want to delete this Part?</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteBOM}>Yes</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle8}>Cancel</Button>
					</ModalFooter>
			  </Modal>
				<Modal isOpen={this.state.modal9} toggle={this.toggle9}>
					<ModalHeader toggle={this.toggle9}>Edit TAM Names</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.handleTAMSubmit}>
							<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
							<Input type="hidden" name="tamID" id="tamID" defaultValue={this.state.obj.tamID}/>
							<FormGroup>
								<Label for="name">Name</Label>
								<Input type="text" name="name" id="name" defaultValue={this.state.obj.name}/>
							</FormGroup>
							<FormGroup>
								<Label for="phone">Phone Number</Label>
								<PhoneInput style={{ height: "25px" }} withCountryCallingCode international countryCallingCodeEditable={false} defaultCountry={defaultCountryCode} name="phone" id="phone" value={this.state.phoneNumber} onChange={this.onChangePhone}/>
								{
									//<Input type="text" name="phone" id="phone" defaultValue={this.state.obj.phone}/>
								}
							</FormGroup>
							<Row>
								<Col md="12">
									<FormGroup>
										<Label for="Markets">Select Markets</Label>
										<Input id="Markets" multiple name="Markets" type="select" disabled={isForeignUser}>
										{this.state.marketsData.map((market, i) => {

											var marketItem = this.state.marketItemsForTamItem.find(m => m.MarketID === market.id);

											console.log('market: ', market.id);
											console.log('marketItem: ', marketItem);

											if (marketItem) {
												return (
													<option value={market.id} selected>{market.Name}</option>
												);
											} else {
												return (
													<option value={market.id}>{market.Name}</option>
												);
											}

										})}
										</Input>
									</FormGroup>
								</Col>
							</Row>
							{this.state.status &&
								<div className={'alert alert-danger'}>{this.state.status}</div>
							}
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle9}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal10} toggle={this.toggle10}>
  				<ModalHeader toggle={this.toggle10}>Delete TAM Name</ModalHeader>
  				<ModalBody>
						<span>Are you sure you want to delete this TAM name?</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteTAM}>Yes</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle10}>Cancel</Button>
					</ModalFooter>
			  </Modal>
				<Modal isOpen={this.state.modal11} toggle={this.toggle11}>
					<ModalHeader toggle={this.toggle11}>Create Part</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.createBOMSubmit}>
							<FormGroup>
								<Label for="PartNumber">Part Number</Label>
								<Input type="text" name="PartNumber" id="PartNumber"/>
							</FormGroup>
							<FormGroup>
								<Label for="Cost">Cost</Label>
								<Input type="text" name="Cost" id="Cost"/>
							</FormGroup>
							<FormGroup>
								<Label for="Quantity">Quantity</Label>
								<Input type="text" name="Quantity" id="Quantity"/>
							</FormGroup>
							<FormGroup>
								<Label for="Description">Description</Label>
								<Input type="text" name="Description" id="Description"/>
							</FormGroup>
							<FormGroup>
						    <Label for="Products">Select Products</Label>
						    <Input id="Products" multiple name="Products" type="select">
									{this.state.productsData.map((product, i) => (
										<option value={product.id}>{product.Name}</option>
									))}
						    </Input>
						  </FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle11}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal12} toggle={this.toggle12}>
					<ModalHeader toggle={this.toggle12}>Create Tam Name</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.createTAMSubmit}>
							<FormGroup>
								<Label for="name">Name</Label>
								<Input type="text" name="name" id="name"/>
							</FormGroup>
							<FormGroup>
								<Label for="phone">Phone Number</Label>
								<PhoneInput style={{ height: "25px" }} withCountryCallingCode international countryCallingCodeEditable={false} defaultCountry={defaultCountryCode} name="phone" id="phone" value={this.state.phoneNumber} onChange={this.onChangePhone}/>
								{
									//<Input type="text" name="phone" id="phone"/>
								}
							</FormGroup>
							<Row>
								<Col md="12">
									<FormGroup>
								    <Label for="Markets">Select Markets</Label>
								    <Input id="Markets" multiple name="Markets" type="select" disabled={isForeignUser}>
											{this.state.marketsData.map((market, i) => {

												if (this.state.currentUser.payload['custom:market'] && (this.state.currentUser.payload['custom:market']=="europe" || this.state.currentUser.payload['custom:market']=="aus" || this.state.currentUser.payload['custom:market']=="brazil" || this.state.currentUser.payload['custom:market']=="taiwan" || this.state.currentUser.payload['custom:market']=="colombia")) {
													return (
														<option value={market.id} selected>{market.Name}</option>
													);
												} else {
													return (
														<option value={market.id}>{market.Name}</option>
													);
												}

											})}
								    </Input>
								  </FormGroup>
								</Col>
							</Row>
							{this.state.status &&
								<div className={'alert alert-danger'}>{this.state.status}</div>
							}
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle12}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal13} toggle={this.toggle13}>
					<ModalHeader toggle={this.toggle13}>Create New Quoting Item</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.createQuoteItemSubmit}>
							<FormGroup>
								<Label for="PartNumber">SKU</Label>
								<Input type="text" name="PartNumber" id="sku"/>
							</FormGroup>
							<FormGroup>
								<Label for="Cost">Cost</Label>
								<Input type="text" name="Cost" id="Cost"/>
							</FormGroup>
							<FormGroup>
								<Label for="Quantity">Quantity</Label>
								<Input type="text" name="Quantity" id="Quantity"/>
							</FormGroup>
							<FormGroup>
								<Label for="Description">Description</Label>
								<Input type="text" name="Description" id="Description"/>
							</FormGroup>
							<FormGroup>
						    <Label for="Products">Select Products</Label>
						    <Input id="Products" multiple name="Products" type="select">
									{this.state.productsData.map((product, i) => (
										<option value={product.id}>{product.Name}</option>
									))}
						    </Input>
						  </FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle13}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal14} toggle={this.toggle14}>
					<ModalHeader toggle={this.toggle14}>Edit Quote Item</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.handleQuoteItemSubmit}>
							<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
							<Input type="hidden" name="qItemID" id="qItemID" defaultValue={this.state.obj.qItemID}/>
							<FormGroup>
								<Label for="sku">SKU</Label>
								<Input type="text" name="sku" id="sku" defaultValue={this.state.obj.sku}/>
							</FormGroup>
							<FormGroup>
								<Label for="Cost">Cost</Label>
								<Input type="text" name="Cost" id="Cost" defaultValue={this.state.obj.Cost} disabled={!this.state.canEditCost}/>
							</FormGroup>
							<FormGroup>
								<Label for="Cost">Quantity</Label>
								<Input type="text" name="Quantity" id="Quantity" defaultValue={this.state.obj.Quantity}/>
							</FormGroup>
							<FormGroup>
								<Label for="Description">Description</Label>
								<Input type="text" name="Description" id="Description" defaultValue={this.state.obj.Description}/>
							</FormGroup>
							<FormGroup>
						    <Label for="Products">Select Products</Label>
						    <Input id="Products" multiple name="Products" type="select">
								{this.state.productsData.map((product, i) => {

									var productItem = this.state.productItemsForQuoteItem.find(p => p.ProductID === product.id);

									console.log('product: ', product.id);
									console.log('productItem: ', productItem);

									if (productItem) {
										return (
											<option value={product.id} selected>{product.Name}</option>
										);
									} else {
										return (
											<option value={product.id}>{product.Name}</option>
										);
									}

								})}
						    </Input>
						  </FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle14}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal15} toggle={this.toggle15}>
  				<ModalHeader toggle={this.toggle15}>Delete Quote Item</ModalHeader>
  				<ModalBody>
						<span>Are you sure you want to delete this Item?</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteQuoteItem}>Yes</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle15}>Cancel</Button>
					</ModalFooter>
			  </Modal>
			</div>
      </Fragment>
		);
	}
}

export default TAMNames;
