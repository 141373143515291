import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	ButtonDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
	Fade
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class CustomerNotes extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			repairCustomerNotes: [],
			productsData: [],
			marketsData: [],
			productItemsForNote: [],
			marketItemsForNoteItem: [],
			obj: {},
			modal: false,
      modal2: false,
			modal5: false,
			isOpen: false,
			currentItem: "ALL Products",
			currentDataSetting: 0,
			isOpen2: false,
			currentItem2: "ALL Markets",
			currentDataSetting2: 100,
			marketSelected: 100,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
		this.onRadioBtnClick2 = this.onRadioBtnClick2.bind(this);

		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.toggle5 = this.toggle5.bind(this);

		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.toggleDropDown2 = this.toggleDropDown2.bind(this);

  }

	async componentDidMount() {

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="europe") {
			this.setState({ currentItem2: "Europe", marketSelected: 1 });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="aus") {
			this.setState({ currentItem2: "Australia", marketSelected: 2 });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="brazil") {
			this.setState({ currentItem2: "Brazil", marketSelected: 3 });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="taiwan") {
			this.setState({ currentItem2: "Taiwan", marketSelected: 4 });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="colombia") {
			this.setState({ currentItem2: "Colombia", marketSelected: 5 });
		}

    await this.getMarketsData();

		this.getProductsData();
    this.getCustomerNotesData();
	}

	async onRadioBtnClick(marketSelected) {
		await this.setState({ marketSelected, currentDataSetting2: marketSelected });

		setTimeout(this.getCustomerNotesData, 300);
	}

	async onRadioBtnClick2(currentDataSetting) {

		await this.setState({ currentDataSetting });

		setTimeout(this.getCustomerNotesData, 300);

	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

  toggle2() {

	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle5() {
	  this.setState({
	    modal5: !this.state.modal5
	  });
	}

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	toggleDropDown2() {
    this.setState({
      isOpen2: !this.state.isOpen2
    });
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		setTimeout(this.getCustomerNotesData, 300);

  }

	selectDropDownItem2(id, title) {

    this.setState({
      currentItem2: title,
			currentDataSetting2: id
    });

		setTimeout(this.getCustomerNotesData, 300);

  }

	componentWillUnmount() {

	}

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	getMarketsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-markets";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all markets data: " + JSON.stringify(body));

			if (body.status == 'Success') {

				let userMarket = this.state.currentUser.payload['custom:market'];
				var marketsData = body.response;

				if (userMarket=='aus' || userMarket=='europe' || userMarket=='brazil' || userMarket=='taiwan' || userMarket=='colombia') {
					marketsData = marketsData.filter(market => market.Abbreviation.includes(userMarket))
				}

				this.setState({ marketsData });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getProductsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-products";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Products data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productsData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getCustomerNotesData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-customer-notes?ProductID="+this.state.currentDataSetting;

		if (this.state.marketSelected != 100) {
			url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-customer-notes?ProductID="+this.state.currentDataSetting+"&MarketID="+this.state.marketSelected;
		}

		console.log("getCustomerNotesData url: " + url);

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all repairCategories: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ repairCustomerNotes: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	createCustomerNoteSubmit = (event) => {
		event.preventDefault();

    let description = event.target.description.value;
    console.log(`description is:`, description);

    let message = event.target.message.value;
    console.log(`message is:`, message);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

		let markets = Array.from(event.target.Markets.selectedOptions, option => option.value);
    console.log(`markets is:`, markets);

		this.setState({
			modal5: !this.state.modal5
		})

    this.createCustomerNote(description, message, products, markets);
	}

  createCustomerNote = async (description, message, products, markets) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-customer-notes?Description="+description+"&Message="+message;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create category response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				var noteID = body.response.insertId;

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForNote(noteID, product);
				}

				for (var i=0; i<markets.length; i++) {
					var market = markets[i];
					await this.addMarketItemForNoteItem(noteID, market);
				}

	      this.getCustomerNotesData();
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleCustomerNoteSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let noteID = event.target.noteID.value;
    console.log(`noteID is:`, noteID);
    let description = event.target.description.value;
    console.log(`description is:`, description);
    let message = event.target.message.value;
    console.log(`message is:`, message);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

		let markets = Array.from(event.target.Markets.selectedOptions, option => option.value);
    console.log(`markets is:`, markets);

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.repairCustomerNotes));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			Description: description,
      Message: message
    }
		this.setState({
			repairCustomerNotes: newObj,
			modal: !this.state.modal
		})
    console.log("saved repairCategories is:", this.state.repairCustomerNotes[id].Description);

    this.updateCustomerNote(noteID, description, message, products, markets);
	}

  updateCustomerNote = async (noteID, description, message, products, markets) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-customer-notes?NoteID="+noteID+"&Description="+description+"&Message="+message;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Update category response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				await this.deleteAllProductItemsForNote(noteID);
				await this.deleteAllMarketItemsForNoteItem(noteID)

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForNote(noteID, product);
				}

				for (var i=0; i<markets.length; i++) {
					var market = markets[i];
					await this.addMarketItemForNoteItem(noteID, market);
				}

				// products.map((product, i) => {
				// 	this.addProductItemForBOM(bomID, product);
				// });

				this.getCustomerNotesData();

			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getProductItemsForNote = async (obj) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-product-items-for-note?NoteID="+obj.noteID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all ProductItemForNote data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productItemsForNote: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	addProductItemForNote = async (noteID, productID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-product-item-for-note?NoteID="+noteID+"&ProductID="+productID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("add ProductItemForNote response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteAllProductItemsForNote = async (noteID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-product-items-for-note?NoteID="+noteID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("ProductItemForNote delete response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getMarketItemsForNoteItem = async (obj) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-market-items-for-manage-option?ManageOptionType=1&OptionID="+obj.noteID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all MarketItemsForNoteItem data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ marketItemsForNoteItem: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	addMarketItemForNoteItem = async (noteID, marketID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-market-item-for-manage-option?ManageOptionType=1&OptionID="+noteID+"&MarketID="+marketID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("add MarketItemForNoteItem response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteAllMarketItemsForNoteItem = async (noteID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-market-items-for-manage-option?ManageOptionType=1&OptionID="+noteID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("MarketItemsForNoteItem delete response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteCustomerNote = async () => {

		console.log("Delete Note: " + this.state.obj.description);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-customer-notes?NoteID="+this.state.obj.noteID;

    console.log("Delete note url: " + url);

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete note response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle2();
	      this.getCustomerNotesData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));

		if (id == 'datecreated') {
			var dateString = Moment(row[id]).format('MMM Do YYYY');
			return (
					dateString !== undefined ?
							String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}

  render() {

		var isForeignUser = false;

		if (this.state.currentUser.payload['custom:market'] && (this.state.currentUser.payload['custom:market']=="europe" || this.state.currentUser.payload['custom:market']=="aus" || this.state.currentUser.payload['custom:market']=="brazil" || this.state.currentUser.payload['custom:market']=="taiwan" || this.state.currentUser.payload['custom:market']=="colombia")) {
			isForeignUser = true;
		}

		const data = this.state.repairCustomerNotes.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				noteID: prop.id,
				MarketName: prop.marketName,
        description: prop.Description,
				ProductName: prop.productName,
        message: prop.Message,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									obj: obj,
									modal: !this.state.modal,
								});
								//this.showVerification(obj);

								this.getProductItemsForNote(obj);
								this.getMarketItemsForNoteItem(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal2: !this.state.modal2,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal2: !this.state.modal2,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		return (
      <Fragment>
        <Card className="card-hover">
          <CardBody>
            <Row className="mb-2">
							<Col md="2">
								{this.state.marketSelected==100 &&
									<Fade in="true">
									<h3 style={{color:'#737782'}}>ALL Customer Notes</h3>
									</Fade>
								}
								{this.state.marketSelected==0 &&
									<Fade in="true">
									<h3 style={{color:'#737782'}}>US Notes</h3>
									</Fade>
								}
								{this.state.marketSelected==1 &&
									<Fade in="true">
									<h3 style={{color:'#737782'}}>Europe Notes</h3>
									</Fade>
								}
								{this.state.marketSelected==2 &&
									<Fade in="true">
									<h3 style={{color:'#737782'}}>Australia Notes</h3>
									</Fade>
								}
								{this.state.marketSelected==3 &&
									<Fade in="true">
									<h3 style={{color:'#737782'}}>Brazil Notes</h3>
									</Fade>
								}
								{this.state.marketSelected==4 &&
									<Fade in="true">
									<h3 style={{color:'#737782'}}>Taiwan Notes</h3>
									</Fade>
								}
								{this.state.marketSelected==5 &&
									<Fade in="true">
									<h3 style={{color:'#737782'}}>Colombia Notes</h3>
									</Fade>
								}
							</Col>
							<Col md="8">
							{!isForeignUser &&
								<div className="mt-1 mb-1">
									<ButtonGroup style={{ width: '100%' }}>
										<Button
											style={{width: '100%'}}
											color="btngray"
											onClick={() => this.onRadioBtnClick(100)}
											active={this.state.marketSelected === 100}
										>
											ALL Customer Notes
										</Button>
										<Button
											className="ml-1"
											style={{width: '100%'}}
											color="btngray"
											onClick={() => this.onRadioBtnClick(0)}
											active={this.state.marketSelected === 0}
										>
											US Notes
										</Button>
										<Button
											className="ml-1"
											color="btngray"
											style={{width: '100%'}}
											onClick={() => this.onRadioBtnClick(1)}
											active={this.state.marketSelected === 1}
										>
											Europe Notes
										</Button>
										<Button
											className="ml-1"
											color="btngray"
											style={{width: '100%'}}
											onClick={() => this.onRadioBtnClick(2)}
											active={this.state.marketSelected === 2}
										>
											Australia Notes
										</Button>
										<Button
											className="ml-1"
											color="btngray"
											style={{width: '100%'}}
											onClick={() => this.onRadioBtnClick(3)}
											active={this.state.marketSelected === 3}
										>
											Brazil Notes
										</Button>
										<Button
											className="ml-1"
											color="btngray"
											style={{width: '100%'}}
											onClick={() => this.onRadioBtnClick(4)}
											active={this.state.marketSelected === 4}
										>
											Taiwan Notes
										</Button>
										<Button
											className="ml-1"
											color="btngray"
											style={{width: '100%'}}
											onClick={() => this.onRadioBtnClick(5)}
											active={this.state.marketSelected === 5}
										>
											Colombia Notes
										</Button>
									</ButtonGroup>
								</div>
							}
							{isForeignUser &&
								<div className="">
									<ButtonGroup style={{ width: '100%' }}>
										<Button
											style={{width: '100%'}}
											color="btngray"
											onClick={() => this.onRadioBtnClick2(0)}
											active={this.state.currentDataSetting === 0}
										>
											ALL Products
										</Button>
										{this.state.productsData.map((product, i) => (
											<Button
												className="ml-1"
												style={{width: '100%'}}
												color="btngray"
												onClick={() => this.onRadioBtnClick2(product.id)}
												active={this.state.currentDataSetting === product.id}
											>
												{product.Name}
											</Button>
										))}
									</ButtonGroup>
								</div>
							}
							</Col>
							<Col md="2" className="text-right">
								<div>
									{
										// <ButtonDropdown isOpen={this.state.isOpen2} toggle={this.toggleDropDown2}>
										// 	<DropdownToggle color="dark" caret>
										// 		{this.state.currentItem2}
										// 	</DropdownToggle>
										// 	<DropdownMenu right>
										// 		<DropdownItem onClick={() => this.selectDropDownItem2(100, 'ALL Markets')}>ALL Markets</DropdownItem>
										// 		{this.state.marketsData.map((market, i) => (
										// 			<DropdownItem onClick={() => this.selectDropDownItem2(market.id, market.Name)}>{market.Name}</DropdownItem>
										// 		))}
										// 	</DropdownMenu>
										// </ButtonDropdown>
									}
									{
										// <ButtonDropdown className="ml-3" isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
										// 	<DropdownToggle color="dark" caret>
										// 		{this.state.currentItem}
										// 	</DropdownToggle>
										// 	<DropdownMenu right>
										// 		<DropdownItem onClick={() => this.selectDropDownItem(0, 'ALL Products')}>ALL Products</DropdownItem>
										// 		{this.state.productsData.map((product, i) => (
										// 			<DropdownItem onClick={() => this.selectDropDownItem(product.id, product.Name)}>{product.Name}</DropdownItem>
										// 		))}
										// 	</DropdownMenu>
										// </ButtonDropdown>
									}

									<Button className="float-right ml-3" color="yellow" onClick={this.toggle5}>Create Customer Note</Button>
								</div>
							</Col>
            </Row>
						{!isForeignUser &&
							<Row className="mb-3">
								<Col md="4">
								</Col>
								<Col md="4">
									<div className="">
										<ButtonGroup style={{ width: '100%' }}>
											<Button
												style={{width: '100%'}}
												color="btngray"
												onClick={() => this.onRadioBtnClick2(0)}
												active={this.state.currentDataSetting === 0}
											>
												ALL Products
											</Button>
											{this.state.productsData.map((product, i) => (
												<Button
													className="ml-1"
													style={{width: '100%'}}
													color="btngray"
													onClick={() => this.onRadioBtnClick2(product.id)}
													active={this.state.currentDataSetting === product.id}
												>
													{product.Name}
												</Button>
											))}
										</ButtonGroup>
									</div>
								</Col>
								<Col md="4">
								</Col>
							</Row>
						}
            <ReactTable
              style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
              columns={[
								{
									Header: "Location / Market",
									accessor: "MarketName",
									minWidth: 25,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
                  Header: "Description",
                  accessor: "description",
									minWidth: 40,
                  style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                  Cell: row => (
                      <div style={{ textAlign: "center" }}>{row.value}</div>
                    )
                },
                {
                  Header: "Message",
                  accessor: "message",
                  style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                  Cell: row => (
                      <div style={{ textAlign: "center" }}>{row.value}</div>
                    )
                },
								{
									Header: "Product",
									accessor: "ProductName",
									minWidth: 25,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  minWidth: 20,
                  filterable: false
                }
              ]}
              defaultPageSize={15}
              sortable={true}
              showPaginationBottom={true}
              className="-striped -highlight"
              data={data}
              filterable
							filterAll={true}
							defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
              getTrGroupProps={(state, rowInfo, column, instance) => {
                      if (rowInfo !== undefined) {
                          return {
                              onClick: (e, handleOriginal) => {
                                console.log('It was in this row:', rowInfo)
                                console.log('with ID:', rowInfo.original.categoryID);

                                //this.goToRepairLog(rowInfo.original.repairId);
                              },
                              style: {
                                  cursor: 'pointer'
                              }
                          }
                      }}
                  }
            />
          </CardBody>
        </Card>
  			<div>
  				<Modal isOpen={this.state.modal} toggle={this.toggle}>
  					<ModalHeader toggle={this.toggle}>Edit Customer Note</ModalHeader>
  					<ModalBody>
  						<Form onSubmit={this.handleCustomerNoteSubmit}>
  							<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
  							<Input type="hidden" name="noteID" id="noteID" defaultValue={this.state.obj.noteID}/>
  							<FormGroup>
  								<Label for="description">Description</Label>
  								<Input type="text" name="description" id="description" defaultValue={this.state.obj.description}/>
  							</FormGroup>
                <FormGroup>
  								<Label for="message">Message</Label>
  								<Input type="text" name="message" id="message" defaultValue={this.state.obj.message}/>
  							</FormGroup>
								<Row>
									<Col md="6">
										<FormGroup>
									    <Label for="Products">Select Products</Label>
									    <Input id="Products" multiple name="Products" type="select">
											{this.state.productsData.map((product, i) => {

												var productItem = this.state.productItemsForNote.find(p => p.ProductID === product.id);

												console.log('product: ', product.id);
												console.log('productItem: ', productItem);

												if (productItem) {
													return (
														<option value={product.id} selected>{product.Name}</option>
													);
												} else {
													return (
														<option value={product.id}>{product.Name}</option>
													);
												}

											})}
									    </Input>
									  </FormGroup>
									</Col>
									<Col md="6">
										<FormGroup>
											<Label for="Markets">Select Markets</Label>
											<Input id="Markets" multiple name="Markets" type="select" disabled={isForeignUser}>
											{this.state.marketsData.map((market, i) => {

												var marketItem = this.state.marketItemsForNoteItem.find(m => m.MarketID === market.id);

												console.log('market: ', market.id);
												console.log('marketItem: ', marketItem);

												if (marketItem) {
													return (
														<option value={market.id} selected>{market.Name}</option>
													);
												} else {
													return (
														<option value={market.id}>{market.Name}</option>
													);
												}

											})}
											</Input>
										</FormGroup>
									</Col>
								</Row>
  							<FormGroup>
  								<Button color="primary" type="submit">Save</Button>
  								<Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
  							</FormGroup>
  						</Form>
  					</ModalBody>
  				</Modal>
  				<Modal isOpen={this.state.modal2} toggle={this.toggle2}>
    				<ModalHeader toggle={this.toggle2}>Delete Customer Note</ModalHeader>
    				<ModalBody>
  						<span>Are you sure you want to delete this Customer Note?</span>
    				</ModalBody>
  					<ModalFooter>
  						<Button color="primary" onClick={this.deleteCustomerNote}>Yes</Button>
  						<Button color="secondary" className="ml-1" onClick={this.toggle2}>Cancel</Button>
  					</ModalFooter>
  			  </Modal>
  				<Modal isOpen={this.state.modal5} toggle={this.toggle5}>
  					<ModalHeader toggle={this.toggle5}>Create Customer Note</ModalHeader>
  					<ModalBody>
  						<Form onSubmit={this.createCustomerNoteSubmit}>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input type="text" name="description" id="description"/>
                </FormGroup>
                <FormGroup>
                  <Label for="message">Message</Label>
                  <Input type="text" name="message" id="message"/>
                </FormGroup>
								<Row>
									<Col md="6">
										<FormGroup>
									    <Label for="Products">Select Products</Label>
									    <Input id="Products" multiple name="Products" type="select">
												{this.state.productsData.map((product, i) => (
													<option value={product.id}>{product.Name}</option>
												))}
									    </Input>
									  </FormGroup>
									</Col>
									<Col md="6">
										<FormGroup>
											<Label for="Markets">Select Markets</Label>
											<Input id="Markets" multiple name="Markets" type="select" disabled={isForeignUser}>
												{this.state.marketsData.map((market, i) => {

													if (this.state.currentUser.payload['custom:market'] && (this.state.currentUser.payload['custom:market']=="europe" || this.state.currentUser.payload['custom:market']=="aus" || this.state.currentUser.payload['custom:market']=="brazil" || this.state.currentUser.payload['custom:market']=="taiwan")) {
														return (
															<option value={market.id} selected>{market.Name}</option>
														);
													} else {
														return (
															<option value={market.id}>{market.Name}</option>
														);
													}

												})}
											</Input>
										</FormGroup>
									</Col>
								</Row>
  							<FormGroup>
  								<Button color="primary" type="submit">Save</Button>
  								<Button color="secondary" className="ml-1" onClick={this.toggle5}>Cancel</Button>
  							</FormGroup>
  						</Form>
  					</ModalBody>
  				</Modal>
  			</div>
      </Fragment>
		);
	}
}

export default CustomerNotes;
