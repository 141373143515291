import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	ButtonDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class RepairReasons extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info token: ' + JSON.stringify(currentUser.token));

    this.state = {
			repairCategories: [],
      repairOptions: [],
			repairBOM: [],
			bomData: [],
			productsData: [],
			quoteData: [],
			tamData: [],
			productItemsForBOM: [],
			productItemsForQuoteItem: [],
			productItemsForCategory: [],
			obj: {},
			modal: false,
      modal2: false,
			modal3: false,
			modal4: false,
			modal5: false,
			modal6: false,
			modal7: false,
			modal8: false,
			modal9: false,
			modal10: false,
			modal11: false,
			modal12: false,
			modal13: false,
			modal14: false,
			modal15: false,
			modal16: false,
			canEditCost: false,
			isOpen: false,
			currentItem: "ALL",
			currentDataSetting: 0,
			isOpen2: false,
			currentItem2: "ALL",
			currentDataSetting2: 0,
			isOpen3: false,
			currentItem3: "ALL",
			currentDataSetting3: 0,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.onRadioBtnClick2 = this.onRadioBtnClick2.bind(this);

		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.toggle5 = this.toggle5.bind(this);

		this.toggleDropDown3 = this.toggleDropDown3.bind(this);

  }

	async componentDidMount() {

    this.getCategoryData();
    //this.getOptionsData();
		this.getProductsData();
		//this.getBOMData();
		//this.getQuoteData();
		//this.getTAMData();

		console.log('repair options currentUser email: ' + JSON.stringify(this.state.currentUser.payload.email));

		if (this.state.currentUser.payload.email == "jluo@azenamedical.com" || this.state.currentUser.payload.email == "hbrown@azenamedical.com" || this.state.currentUser.payload.email == "ryan.a.stickel@gmail.com") {
			this.setState({ canEditCost: true });
		}
		//canEditCost
	}

	async onRadioBtnClick2(currentDataSetting3) {

		await this.setState({ currentDataSetting3 });

		setTimeout(this.getCategoryData, 300);

	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

  toggle2() {

	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle5() {
	  this.setState({
	    modal5: !this.state.modal5
	  });
	}

	toggleDropDown3() {
    this.setState({
      isOpen3: !this.state.isOpen3
    });
  }


	selectDropDownItem3(id, title) {

    this.setState({
      currentItem3: title,
			currentDataSetting3: id
    });

		setTimeout(this.getCategoryData, 300);

  }

	componentWillUnmount() {

	}

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	getCategoryData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-repair-categories?ProductID="+this.state.currentDataSetting3;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all repairCategories: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ repairCategories: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getProductItemsForCategory = async (obj) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-product-items-for-category?CategoryID="+obj.categoryID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all ProductItemForCategory data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productItemsForCategory: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	addProductItemForCategory = async (categoryID, productID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-product-item-for-category?CategoryID="+categoryID+"&ProductID="+productID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("add ProductItemForCategory response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteAllProductItemsForCategory = async (categoryID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-product-items-for-category?CategoryID="+categoryID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("ProductItemForCategory delete response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getProductsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-products";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Products data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productsData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	createCategorySubmit = (event) => {
		event.preventDefault();

    let name = event.target.name.value;
    console.log(`name is:`, name);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

		this.setState({
			modal5: !this.state.modal5
		})

    this.createCategory(name, products);
	}

  createCategory = async (name, products) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-repair-category?Name="+name;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create category response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				var categoryID = body.response.insertId;

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForCategory(categoryID, product);
				}

				this.getCategoryData();
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleCategorySubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let categoryID = event.target.categoryID.value;
    console.log(`categoryID is:`, categoryID);
    let name = event.target.name.value;
    console.log(`name is:`, name);

		let products = Array.from(event.target.Products.selectedOptions, option => option.value);
    console.log(`products is:`, products);

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.repairCategories));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			name: name
    }
		this.setState({
			repairCategories: newObj,
			modal: !this.state.modal
		})
    console.log("saved repairCategories is:", this.state.repairCategories[id].name);

    this.updateCategory(categoryID, name, products);
	}

  updateCategory = async (categoryID, name, products) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-repair-category?CategoryID="+categoryID+"&Name="+name;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Update category response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				await this.deleteAllProductItemsForCategory(categoryID);

				for (var i=0; i<products.length; i++) {
					var product = products[i];
					await this.addProductItemForCategory(categoryID, product);
				}

				// products.map((product, i) => {
				// 	this.addProductItemForBOM(bomID, product);
				// });

				this.getCategoryData();

			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteCategory = async () => {

		console.log("Delete Category: " + this.state.obj.name);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-repair-category?CategoryID="+this.state.obj.categoryID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete category response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle2();
				this.getCategoryData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));

		if (id == 'datecreated') {
			var dateString = Moment(row[id]).format('MMM Do YYYY');
			return (
					dateString !== undefined ?
							String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}


  render() {

		const data = this.state.repairCategories.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				categoryID: prop.id,
				ProductName: prop.productName,
        name: prop.name,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									obj: obj,
									modal: !this.state.modal,
								});
								//this.showVerification(obj);

								this.getProductItemsForCategory(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal2: !this.state.modal2,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal2: !this.state.modal2,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		return (
      <Fragment>
			<Row>
				<Col xs="12" md="12" lg="12">
					<Card className="card-hover">
						<CardBody>
							<Row className="mb-3">
								<Col md="4">
									<h3 style={{color:'#737782'}}>Repair Reason</h3>
								</Col>
								<Col md="4">
									<div className="">
										<ButtonGroup style={{ width: '100%' }}>
											<Button
												style={{width: '100%'}}
												color="btngray"
												onClick={() => this.onRadioBtnClick2(0)}
												active={this.state.currentDataSetting3 === 0}
											>
												ALL
											</Button>
											{this.state.productsData.map((product, i) => (
												<Button
													className="ml-1"
													style={{width: '100%'}}
													color="btngray"
													onClick={() => this.onRadioBtnClick2(product.id)}
													active={this.state.currentDataSetting3 === product.id}
												>
													{product.Name}
												</Button>
											))}
										</ButtonGroup>
									</div>
								</Col>
								<Col md="4" className="text-right">
									<div>
										{
											// <ButtonDropdown isOpen={this.state.isOpen3} toggle={this.toggleDropDown3}>
											// 	<DropdownToggle color="dark" caret>
											// 		{this.state.currentItem3}
											// 	</DropdownToggle>
											// 	<DropdownMenu right>
											// 		<DropdownItem onClick={() => this.selectDropDownItem3(0, 'ALL')}>ALL</DropdownItem>
											// 		{this.state.productsData.map((product, i) => (
											// 			<DropdownItem onClick={() => this.selectDropDownItem3(product.id, product.Name)}>{product.Name}</DropdownItem>
											// 		))}
											// 	</DropdownMenu>
											// </ButtonDropdown>
										}
										<Button className="float-right ml-3" color="yellow" onClick={this.toggle5}>Create Reason</Button>
									</div>
								</Col>
							</Row>
							<ReactTable
								style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
								columns={[
									{
										Header: "Name",
										accessor: "name",
										minWidth: 40,
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
												<div style={{ textAlign: "center" }}>{row.value}</div>
											)
									},
									{
										Header: "Product",
										accessor: "ProductName",
										minWidth: 13,
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
												<div style={{ textAlign: "center" }}>{row.value}</div>
											)
									},
									{
										Header: "Actions",
										accessor: "actions",
										sortable: false,
										minWidth: 15,
										filterable: false
									}
								]}
								defaultPageSize={14}
								sortable={true}
								showPaginationBottom={true}
								className="-striped -highlight"
								data={data}
								filterable
								filterAll={true}
								defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
								getTrGroupProps={(state, rowInfo, column, instance) => {
												if (rowInfo !== undefined) {
														return {
																onClick: (e, handleOriginal) => {
																	console.log('It was in this row:', rowInfo)
																	console.log('with ID:', rowInfo.original.categoryID);

																	//this.goToRepairLog(rowInfo.original.repairId);
																},
																style: {
																		cursor: 'pointer'
																}
														}
												}}
										}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<div>
				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Edit Repair Reason</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.handleCategorySubmit}>
							<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
							<Input type="hidden" name="categoryID" id="categoryID" defaultValue={this.state.obj.categoryID}/>
							<FormGroup>
								<Label for="firstname">Name</Label>
								<Input type="text" name="name" id="name" defaultValue={this.state.obj.name}/>
							</FormGroup>
							<FormGroup>
								<Label for="Products">Select Products</Label>
								<Input id="Products" multiple name="Products" type="select">
								{this.state.productsData.map((product, i) => {

									var productItem = this.state.productItemsForCategory.find(p => p.ProductID === product.id);

									console.log('product: ', product.id);
									console.log('productItem: ', productItem);

									if (productItem) {
										return (
											<option value={product.id} selected>{product.Name}</option>
										);
									} else {
										return (
											<option value={product.id}>{product.Name}</option>
										);
									}

								})}
								</Input>
							</FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modal2} toggle={this.toggle2}>
  				<ModalHeader toggle={this.toggle2}>Delete Repair Reason</ModalHeader>
  				<ModalBody>
						<span>Are you sure you want to delete this Category?</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteCategory}>Yes</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle2}>Cancel</Button>
					</ModalFooter>
			  </Modal>
				<Modal isOpen={this.state.modal5} toggle={this.toggle5}>
					<ModalHeader toggle={this.toggle5}>Create Repair Reason</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.createCategorySubmit}>
							<FormGroup>
								<Label for="firstname">Name</Label>
								<Input type="text" name="name" id="name" defaultValue={this.state.obj.name}/>
							</FormGroup>
							<FormGroup>
								<Label for="Products">Select Products</Label>
								<Input id="Products" multiple name="Products" type="select">
									{this.state.productsData.map((product, i) => (
										<option value={product.id}>{product.Name}</option>
									))}
								</Input>
							</FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Save</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle5}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
			</div>
      </Fragment>
		);
	}
}

export default RepairReasons;
