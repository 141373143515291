import React from "react";
import { Component, Fragment } from "react";
import {
	Progress,
	Card,
	CardBody,
  CardTitle,
	Row,
	Col,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown,
	Tooltip
} from 'reactstrap';

import { authenticationService } from '../../../jwt/_services';
import img1 from '../../../assets/images/background/active-bg.png';
import Moment from 'moment';

class TopRepairReasons extends React.Component {

	noResults = true;
	intervalID = 0;

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			topComponents: [],
			topRepairReasons: [],
			batchesData: [],
			batchSelect: "ALL",
			colors: [{color:'warning'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'}, {color:'cyan'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'},{color:'warning'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'}, {color:'cyan'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'}, {color:'warning'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'}, {color:'cyan'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'},{color:'warning'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'}, {color:'cyan'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'}],
			noDevices: false,
			noDataForDevices: false,
			currentDataSetting: 0,
			startDate: Moment().subtract(3, 'year').format('YYYY-MM-DD'),
			endDate: Moment().format('YYYY-MM-DD'),
			currentDate: Moment().format('YYYY-MM-DD'),
			isOpen: false,
			currentItem: "Gemini",
			tooltipOpen: false,
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			ratioRange: 0,
      bomData: []
	  };

		this.toggleDropDown = this.toggleDropDown.bind(this);
		//this.handleSubmit = this.handleSubmit.bind(this);
		//this.handleChange = this.handleChange.bind(this);

		this.toggleTooltip = this.toggleTooltip.bind(this);
  }

	async componentDidMount() {
		this.getBatchData();
    //this.getBOMData();
		//this.getData();
		//this.intervalID = setInterval(this.getData, 5000);

		this.getRepairReasonsData();
	}

	toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	selectDropDownItem(id, title) {

		this.setState({ topProcedures: [] });

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		if (id==1) {
			this.setState({ startDate: Moment().subtract(1, 'week').format('YYYY-MM-DD') });
		} else if (id==2) {
			this.setState({ startDate: Moment().subtract(1, 'month').format('YYYY-MM-DD') });
		}

		this.setState({ endDate: Moment().format('YYYY-MM-DD') });
		this.getData();
  }

	componentWillUnmount() {
		//console.log("top-procedures componentWillUnmount");
		clearInterval(this.intervalID);
	}

	onChangeBatch = (event) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);

		let id = event.target.id;
		let value = event.target.value;

		//console.log("repairLog: " + JSON.stringify(repairLog));

		this.setState({ batchSelect: value });

		setTimeout(() => this.getRepairReasonsData(), 500);

	}

	getBatchData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-batches";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Batches data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ batchesData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  getBOMData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-bill-of-materials";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all BOM data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ bomData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getRepairReasonsData = async () => {

		var productName = this.props.productName;

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-top-repair-reasons?Quantity=30&ProductName="+productName;

		if (this.state.batchSelect!="ALL") {
			url = url+"&BatchName="+this.state.batchSelect;
		}

		console.log("getRepairReasonsData url: " + JSON.stringify(url));

		try {

	    const response = await fetch(url, {
	      method: 'GET',
	      headers: {
	        Authorization: this.state.currentUser.jwtToken
	      },
	    });

	    const body = await response.json();
	    console.log("getRepairReasonsData body response: " + JSON.stringify(body));

	    if (body.status == 'Success') {
	      if (body.response.length>0) {
	        var largest = 100;
	        for (var i=0; i < body.response.length; i++) {
	          if (body.response[i].total > largest) {
	            largest = body.response[i].total;
	          }
	        }
	        console.log("getRepairReasonsData body response LARGEST: " + largest);
	        this.setState({ topRepairReasons: body.response }, () => console.log(this.state.topRepairReasons));
	        this.setState({
	          noDataForDevices: false,
	          ratioRange: 2*largest
	        });
	      } else {
	        this.setState({
	          noDataForDevices: true
	        });
	      }
	    } else {
	      this.setState({
	        noDataForDevices: true
	      });
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	}

	getData = async () => {

		try {

	    const response = await fetch('https://cqzybp52y3.execute-api.us-west-2.amazonaws.com'+process.env.REACT_APP_ENV+'get-top-components?Quantity=20', {
	      method: 'GET',
	      headers: {
	        Authorization: this.state.currentUser.jwtToken
	      },
	    });

	    const body = await response.json();
	    console.log("topprocedures body response: " + JSON.stringify(body));

	    if (body.status == 'Success') {
	      if (body.response.length>0) {
	        var largest = 100;
	        for (var i=0; i < body.response.length; i++) {
	          if (body.response[i].total > largest) {
	            largest = body.response[i].total;
	          }
	        }
	        console.log("topprocedures body response LARGEST: " + largest);
	        this.setState({ topComponents: body.response }, () => console.log(this.state.topComponents));
	        this.setState({
	          noDataForDevices: false,
	          ratioRange: 2*largest
	        });
	      } else {
	        this.setState({
	          noDataForDevices: true
	        });
	      }
	    } else {
	      this.setState({
	        noDataForDevices: true
	      });
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	}

	getColor(presetID) {

		var color = "success";

		if (presetID>=1 && presetID<=5) {
			color = "success";
		} else if (presetID>=11 && presetID<=19) {
			color = "info";
		} else if (presetID>=21 && presetID<=23) {
			color = "yellow";
		}

		return color;

	}

  getName(bomID) {

		var name = "N/A";

    //console.log("bomID: " + bomID);

    const result = this.state.bomData.filter(bom => bom.id==bomID);

    name = result[0].Description;

		return name;

	}

	render() {

		var batchesList = [];

		var currentProduct = 1;

		if (this.props.productName=="EVO") {
			currentProduct = 2;
		}

		if (currentProduct) {
			batchesList = this.state.batchesData.filter(batch => batch.ProductID==currentProduct);
		}

		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-1 [Classic]                                                  */
			/*--------------------------------------------------------------------------------*/
			<Card className="card-hover">
				<CardBody style={{ height: 650 }}>
					<Row>
						<Col xs="6" md="6" lg="6" className="text-left">
							<CardTitle>Top Repair Reasons ({this.props.productName})
							<i style={{color: '#ffffff'}} className="mdi mdi-information-outline ml-1" id="TopProceduresTooltip"/>
							<Tooltip placement="top" isOpen={this.state.tooltipOpen} target="TopProceduresTooltip" toggle={this.toggleTooltip}>
								Shows the most common repair reasons.
							</Tooltip>
							</CardTitle>
						</Col>
						<Col xs="6" md="6" lg="6" className="text-right">
							<Row className="ml-2">
								<Col xs="12" md="12" lg="12" className="">
									<div className="float-right ml-2" style={{ width: '140px' }}>
										<Input style={{height: '30px', padding: '3px'}} type="select" name="batchSelect" id="batchSelect" value={this.state.batchSelect} onChange={this.onChangeBatch}>
											<option value="ALL">ALL</option>
											{batchesList.map((batch, i) => (
												<option value={batch.Name}>{batch.Name}</option>
											))}
										</Input>
									</div>
									<div className="float-right text-right" style={{ width: '60px' }}>
										<h6 style={{ lineHeight: '1.9' }}>Batch:</h6>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col xs="6" md="6" lg="6" className="text-left">
							<span>Total</span>
						</Col>
						<Col xs="6" md="6" lg="6" className="text-right">
							<span>Name</span>
						</Col>
					</Row>
					<div className="ml-auto mt-1" style={{height: '555px', overflow: "scroll"}}>
							{this.state.noDataForDevices ? (
								<div className="d-flex justify-content-center align-items-center" style={{ height: '100%'}}>
									<h5>No data for Product and Batch</h5>
								</div>
							) : (
								<div className="pt-1">
									{this.state.topRepairReasons.map((reason, i) => (
										<Fragment>
										<Row key={i}>
											<Col xs="6" md="6" lg="6" className="text-left">
												<span>{reason.total}</span>
											</Col>
											<Col xs="6" md="6" lg="6" className="text-right">
												<span>{reason.name}</span>
											</Col>
										</Row>
										<Progress multi className="mt-1 mb-3">
											<Progress bar color={this.state.colors[i].color} value={(reason.total/this.state.ratioRange)*100} />
										</Progress>
										</Fragment>
									))}
								</div>
							)}
					</div>
				</CardBody>
			</Card>

		);
	}
}

export default TopRepairReasons;
