import React from 'react';
import { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
	Nav,
	NavItem,
	NavLink,
	Button,
	Navbar,
	NavbarBrand,
	Collapse,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledCarousel,
	Progress,
	ListGroup,
	ListGroupItem,
	Row,
	Col,
	Form,
	FormGroup,
	Input,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import * as data from './data';
import { authenticationService } from '../../../jwt/_services';

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logodarkicon from '../../../assets/images/logo-icon.png';
import logolighticon from '../../../assets/images/logo-light-icon.png';
import logodarktext from '../../../assets/images/logo-text.png';
import logolighttext from '../../../assets/images/logo-light-text.png';
import profilephoto from '../../../assets/images/users/1.jpg';
import azenalogo from '../../../assets/images/logo_only@3x.png';
import azenaglogo from '../../../assets/images/logo2@2x.png';
import azenalogotext from '../../../assets/images/text_only@3x.png';
import azenaevologo from '../../../assets/images/logo2_x3.png';
import azenaevologotext from '../../../assets/images/logo2_x3_text.png';
import azenaevosupportlogo from '../../../assets/images/support_logo.png';
import repairlogo from '../../../assets/images/GEMINI_LOGO_REPAIR.png';

// import austFlag from '../../../assets/images/flags/Aust_Flag.png';
// import europeFlag from '../../../assets/images/flags/Europe_Flag.png';
// import usFlag from '../../../assets/images/flags/US_Flag.png';

import austFlag from '../../../assets/images/flags/aus_flag_2.png';
import europeFlag from '../../../assets/images/flags/eu_flag_2.png';
import brazilFlag from '../../../assets/images/flags/brazil_flag_2.png';
import usFlag from '../../../assets/images/flags/us_flag_2.png';
import taiwanFlag from '../../../assets/images/flags/taiwan-flag-2.png';
import colombiaFlag from '../../../assets/images/flags/colombia-circle-01.png';

const mapStateToProps = state => ({
	...state
});

class Header extends React.Component {

	intervalID = 0;

	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.showMobilemenu = this.showMobilemenu.bind(this);
		this.sidebarHandler = this.sidebarHandler.bind(this);
		this.toggleResetModal = this.toggleResetModal.bind(this);

		this.state = {
			isOpen: false,
			currentUserEmail: JSON.parse(localStorage.getItem('currentUserEmail')),
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			devices: JSON.parse(localStorage.getItem('deviceList')),
			firstName: JSON.parse(localStorage.getItem('firstName')),
			lastName: JSON.parse(localStorage.getItem('lastName')),
			modal: false,
		};
	}

	componentDidMount() {

		this.intervalID = setInterval(this.refreshUserData.bind(this), 5000);

		console.log("HEADER user Market: "+JSON.stringify(this.state.currentUser.payload["custom:market"]));

	}

	componentWillUnmount() {
		clearInterval(this.intervalID);
	}

	refreshUserData() {

		var currentUserEmail = JSON.parse(localStorage.getItem('currentUserEmail'));
		var currentUser = JSON.parse(localStorage.getItem('currentUser'));
		var devices = JSON.parse(localStorage.getItem('deviceList'));
		var firstName = JSON.parse(localStorage.getItem('firstName'));
		var lastName = JSON.parse(localStorage.getItem('lastName'));

		this.setState({ currentUserEmail, currentUser, devices, firstName, lastName })
	}

	/*--------------------------------------------------------------------------------*/
	/*To open NAVBAR in MOBILE VIEW                                                   */
	/*--------------------------------------------------------------------------------*/
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	/*--------------------------------------------------------------------------------*/
	/*To open SIDEBAR-MENU in MOBILE VIEW                                             */
	/*--------------------------------------------------------------------------------*/
	showMobilemenu() {
		document.getElementById('main-wrapper').classList.toggle('show-sidebar');
	}
	logout() {
		authenticationService.logout();
	}
	toggleResetModal() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	resetPasswordCall = () => {
		const { currentUser } = this.state;
		authenticationService.forgotPassword(this.state.currentUserEmail)
		.then(
				response => {

						let status = response.status;
						console.log('status: ' + status);

						if (status === 'Success') {
								console.log('reset password response info: ' + response);
								this.toggleResetModal();
						} else if (status === 'Error') {
								console.log('error info: ' + response.response);
						} else {
							console.log('status info: ' + status);
						}
				},
				error => {
						console.log('status info: ' + error);
				}
		);

	}
	sidebarHandler = () => {
		let element = document.getElementById('main-wrapper');
		switch (this.props.settings.activeSidebarType) {
			case 'full':
			case 'iconbar':
				element.classList.toggle('mini-sidebar');
				if (element.classList.contains('mini-sidebar')) {
					element.setAttribute('data-sidebartype', 'mini-sidebar');
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.props.settings.activeSidebarType
					);
				}
				break;

			case 'overlay':
			case 'mini-sidebar':
				element.classList.toggle('full');
				if (element.classList.contains('full')) {
					element.setAttribute('data-sidebartype', 'full');
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.props.settings.activeSidebarType
					);
				}
				break;
			default:
		}
	};

	render() {
		var customizePath = "/customize/00000"

		if (this.state.devices) {
			var customizePath = "/customize/"+this.state.devices[0].SerialNumber;
		}
		var accountpath = "/account"
		return (
			<header className="topbar navbarbg" data-navbarbg={this.props.settings.activeNavbarBg}>
				<Navbar className={"top-navbar " + (this.props.settings.activeNavbarBg === "skin6" ? 'navbar-light' : 'navbar-dark')} expand="md">
					<div className="navbar-header" id="logobg" data-logobg={this.props.settings.activeLogoBg}>
						{/*--------------------------------------------------------------------------------*/}
						{/* Mobile View Toggler  [visible only after 768px screen]                         */}
						{/*--------------------------------------------------------------------------------*/}
						<span className="nav-toggler d-block d-md-none" onClick={this.showMobilemenu}>
							<i className="ti-menu ti-close" />
						</span>
						{/*--------------------------------------------------------------------------------*/}
						{/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
						{/*--------------------------------------------------------------------------------*/}
						<NavbarBrand href="/">
							<b className="logo-icon">
								<img src={logodarkicon} alt="homepage" className="dark-logo" />
								<img
									src={repairlogo}
									alt="homepage"
									height="0"
									className="light-logo"
								/>
							</b>
							<span className="logo-text">
								<img src={logodarktext} alt="homepage" className="dark-logo" />
								<img
									src={repairlogo}
									height="42"
									width="180"
									className="light-logo ml-4"
									alt="homepage"
								/>
							</span>
						</NavbarBrand>
						{/*--------------------------------------------------------------------------------*/}
						{/* Mobile View Toggler  [visible only after 768px screen]                         */}
						{/*--------------------------------------------------------------------------------*/}
						<span className="topbartoggler d-block d-md-none" onClick={this.toggle}>
							<i className="ti-more" />
						</span>
					</div>
					<Collapse className="navbarbg" isOpen={this.state.isOpen} navbar data-navbarbg={this.props.settings.activeNavbarBg} >
						<Nav className="float-left" navbar>
							<NavItem>
								<NavLink href="#" className="d-none d-md-block" onClick={this.sidebarHandler}>
									<i className="ti-menu" />
								</NavLink>
							</NavItem>
						</Nav>
						<Nav className="ml-auto float-right" navbar>
							{/*--------------------------------------------------------------------------------*/}
							{/* Start Notifications Dropdown                                                   */}
							{/*--------------------------------------------------------------------------------*/}
							{
								// <UncontrolledDropdown nav inNavbar>
								// 	<DropdownToggle nav caret>
								// 		<i className="mdi mdi-bell font-24" />
								// 	</DropdownToggle>
								// 	<DropdownMenu right className="mailbox">
								// 		<span className="with-arrow">
								// 			<span className="bg-primary" />
								// 		</span>
								// 		<div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2">
								// 			<div className="">
								// 				<h4 className="mb-0">4 New</h4>
								// 				<p className="mb-0">Notifications</p>
								// 			</div>
								// 		</div>
								// 		<div className="message-center notifications">
								// 			{/*<!-- Message -->*/}
								// 			{data.notifications.map((notification, index) => {
								// 				return (
								// 					<span href="" className="message-item" key={index}>
								// 						<span className={"btn btn-circle btn-" + notification.iconbg}>
								// 							<i className={notification.iconclass} />
								// 						</span>
								// 						<div className="mail-contnet">
								// 							<h5 className="message-title">{notification.title}</h5>
								// 							<span className="mail-desc">
								// 								{notification.desc}
								// 							</span>
								// 							<span className="time">{notification.time}</span>
								// 						</div>
								// 					</span>
								// 				);
								// 			})}
								// 		</div>
								// 		<a className="nav-link text-center mb-1 text-dark" href=";">
								// 			<strong>Check all notifications</strong>{' '}
								// 			<i className="fa fa-angle-right" />
								// 		</a>
								// 	</DropdownMenu>
								// </UncontrolledDropdown>
							}
							{/*--------------------------------------------------------------------------------*/}
							{/* End Notifications Dropdown                                                     */}
							{/*--------------------------------------------------------------------------------*/}

							{/*--------------------------------------------------------------------------------*/}
							{/* Start Profile Dropdown                                                         */}
							{/*--------------------------------------------------------------------------------*/}
							<UncontrolledDropdown nav inNavbar>
								<DropdownToggle nav caret className="pro-pic">
									<div className="d-flex no-block align-items-center text-black mb-2">
										<div className="mr-2" style={{height: '65px'}}>
											{this.state.currentUser.payload["custom:role"] ? (
												<Fragment>
												{this.state.currentUser.payload["custom:role"] < 2 &&
													<Fragment>
													{this.state.currentUser.payload["custom:market"] == "us" &&
														<p className="mb-0" style={{height: '20px', lineHeight: '50px'}}>UPI Support:</p>
													}
													{this.state.currentUser.payload["custom:market"] == "europe" &&
														<p className="mb-0" style={{height: '20px', lineHeight: '50px'}}>Europe Support:</p>
													}
													{this.state.currentUser.payload["custom:market"] == "aus" &&
														<p className="mb-0" style={{height: '20px', lineHeight: '50px'}}>AUS Support:</p>
													}
													{this.state.currentUser.payload["custom:market"] == "brazil" &&
														<p className="mb-0" style={{height: '20px', lineHeight: '50px'}}>Brazil Support:</p>
													}
													{this.state.currentUser.payload["custom:market"] == "taiwan" &&
														<p className="mb-0" style={{height: '20px', lineHeight: '50px'}}>Taiwan Support:</p>
													}
													{this.state.currentUser.payload["custom:market"] == "colombia" &&
														<p className="mb-0" style={{height: '20px', lineHeight: '50px'}}>Colombia Support:</p>
													}
													{this.state.currentUser.payload["custom:market"] == "us-upi" &&
														<p className="mb-0" style={{height: '20px', lineHeight: '50px'}}>UPI Support:</p>
													}
													{this.state.currentUser.payload["custom:market"] == "us-azena" &&
														<p className="mb-0" style={{height: '20px', lineHeight: '50px'}}>Azena Support:</p>
													}
													</Fragment>
												}
												{this.state.currentUser.payload["custom:role"] == 2 &&
													<p className="mb-0" style={{height: '20px', lineHeight: '50px'}}>Admin User:</p>
												}
												</Fragment>
											) : (
												<p className="mb-0" style={{height: '20px', lineHeight: '50px'}}>UPI Support:</p>
											)}
											<p className="mb-0" style={{height: '25px', lineHeight: '50px'}}>{this.state.firstName} {this.state.lastName}</p>
										</div>
										<div className="">
										{this.state.currentUser.payload["custom:role"] ? (
											<Fragment>
											{this.state.currentUser.payload["custom:role"] < 2 &&
												<Fragment>
												{this.state.currentUser.payload["custom:market"] == "us" &&
												<img
													style={{ borderRadius: "50%", border: "2px solid #fff" }}
													src={usFlag}
													alt="user"
													width="43"
												/>
												}
												{this.state.currentUser.payload["custom:market"] == "europe" &&
												<img
													style={{ borderRadius: "50%", border: "2px solid #fff" }}
													src={europeFlag}
													alt="user"
													width="43"
												/>
												}
												{this.state.currentUser.payload["custom:market"] == "aus" &&
												<img
													style={{ borderRadius: "50%", border: "2px solid #fff" }}
													src={austFlag}
													alt="user"
													width="43"
												/>
												}
												{this.state.currentUser.payload["custom:market"] == "brazil" &&
												<img
													style={{ borderRadius: "50%", border: "2px solid #fff" }}
													src={brazilFlag}
													alt="user"
													width="43"
												/>
												}
												{this.state.currentUser.payload["custom:market"] == "taiwan" &&
												<img
													style={{ borderRadius: "50%", border: "2px solid #fff" }}
													src={taiwanFlag}
													alt="user"
													width="43"
												/>
												}
												{this.state.currentUser.payload["custom:market"] == "colombia" &&
												<img
													style={{ borderRadius: "50%", border: "2px solid #fff" }}
													src={colombiaFlag}
													alt="user"
													width="43"
												/>
												}
												{this.state.currentUser.payload["custom:market"] == "us-azena" &&
												<img
													style={{ borderRadius: "50%", border: "2px solid #fff" }}
													src={usFlag}
													alt="user"
													width="43"
												/>
												}
												{this.state.currentUser.payload["custom:market"] == "us-upi" &&
												<img
													style={{ borderRadius: "50%", border: "2px solid #fff" }}
													src={usFlag}
													alt="user"
													width="43"
												/>
												}
												</Fragment>
											}
											{this.state.currentUser.payload["custom:role"] == 2 &&
											<img
												style={{ borderRadius: "50%", border: "2px solid #fff" }}
												src={usFlag}
												alt="user"
												width="43"
											/>
											}
											</Fragment>
										) : (
											<img
												style={{ borderRadius: "50%", border: "2px solid #fff" }}
												src={usFlag}
												alt="user"
												width="43"
											/>
										)}
										</div>
									</div>
								</DropdownToggle>
								<DropdownMenu right className="user-dd">
									<span className="with-arrow">
										<span className="bg-primary" />
									</span>
									<div className="d-flex no-block align-items-center p-3 text-black mb-2">
										<div className="">
										{this.state.currentUser.payload["custom:role"] ? (
											<Fragment>
											{this.state.currentUser.payload["custom:role"] < 2 &&
												<Fragment>
												{this.state.currentUser.payload["custom:market"] == "us" &&
												<img
													style={{ borderRadius: "50%", border: "2px solid #fff" }}
													src={usFlag}
													alt="user"
													width="43"
												/>
												}
												{this.state.currentUser.payload["custom:market"] == "europe" &&
												<img
													style={{ borderRadius: "50%", border: "2px solid #fff" }}
													src={europeFlag}
													alt="user"
													width="43"
												/>
												}
												{this.state.currentUser.payload["custom:market"] == "aus" &&
												<img
													style={{ borderRadius: "50%", border: "2px solid #fff" }}
													src={austFlag}
													alt="user"
													width="43"
												/>
												}
												{this.state.currentUser.payload["custom:market"] == "brazil" &&
												<img
													style={{ borderRadius: "50%", border: "2px solid #fff" }}
													src={brazilFlag}
													alt="user"
													width="43"
												/>
												}
												{this.state.currentUser.payload["custom:market"] == "taiwan" &&
												<img
													style={{ borderRadius: "50%", border: "2px solid #fff" }}
													src={taiwanFlag}
													alt="user"
													width="43"
												/>
												}
												{this.state.currentUser.payload["custom:market"] == "colombia" &&
												<img
													style={{ borderRadius: "50%", border: "2px solid #fff" }}
													src={colombiaFlag}
													alt="user"
													width="43"
												/>
												}
												{this.state.currentUser.payload["custom:market"] == "us-azena" &&
												<img
													style={{ borderRadius: "50%", border: "2px solid #fff" }}
													src={usFlag}
													alt="user"
													width="43"
												/>
												}
												{this.state.currentUser.payload["custom:market"] == "us-upi" &&
												<img
													style={{ borderRadius: "50%", border: "2px solid #fff" }}
													src={usFlag}
													alt="user"
													width="43"
												/>
												}
												</Fragment>
											}
											{this.state.currentUser.payload["custom:role"] == 2 &&
											<img
												style={{ borderRadius: "50%", border: "2px solid #fff" }}
												src={usFlag}
												alt="user"
												width="43"
											/>
											}
											</Fragment>
										) : (
											<img
												style={{ borderRadius: "50%", border: "2px solid #fff" }}
												src={usFlag}
												alt="user"
												width="43"
											/>
										)}
										</div>
										<div className="ml-2">
											<h4 className="mb-0">{this.state.firstName} {this.state.lastName}</h4>
											<p className=" mb-0">{this.state.currentUserEmail}</p>
										</div>
									</div>
									<DropdownItem className="text-white" style={{cursor:"pointer"}} tag={NavLink} onClick={this.logout}>
										<i className="fa fa-power-off mr-1 ml-1" /> Logout
                  </DropdownItem>
									{/*// <Button
									// 	color="success"
									// 	className="btn-rounded ml-3 mb-2 mt-2"
									// >
									// 	View Profile
                  // </Button>*/}
								</DropdownMenu>
							</UncontrolledDropdown>
							{/*--------------------------------------------------------------------------------*/}
							{/* End Profile Dropdown                                                           */}
							{/*--------------------------------------------------------------------------------*/}
						</Nav>
					</Collapse>
				</Navbar>
				<div>
				<Modal color="dark" isOpen={this.state.modal} toggle={this.toggleResetModal} className={this.props.className}>
	        <ModalHeader toggle={this.toggleResetModal}>Reset Password</ModalHeader>
	        <ModalBody>
						An email has been sent to {this.state.currentUserEmail}. Please follow the link provided to complete your password reset.
	        </ModalBody>
	        <ModalFooter>
	          <Button color="secondary" onClick={this.toggleResetModal}>OK</Button>
	        </ModalFooter>
	      </Modal>
				</div>
			</header>
		);
	}
}
export default connect(mapStateToProps)(Header);
