import React from "react";
import Moment from 'moment';
import ReactTable from 'react-table-v6'

import {
	Progress,
	Card,
  CardTitle,
	CardBody,
	Row,
	Col,
	Tooltip
} from 'reactstrap';

import Chart from 'react-c3-component';
import 'c3/c3.css';
import { Line, Bar } from 'react-chartjs-2';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';


//import img1 from '../../../assets/images/background/active-bg.png';
//import img2 from '../../../assets/images/dashboard/wavelength_color_guide.png';

class AusInventory extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);
    this.state = {
			flaggedRepairs: [],
      quantityflaggedRepairsList: [],
			noDevices: false,
			noData: false,
			tooltipOpen: false,
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.toggleTooltip = this.toggleTooltip.bind(this);
  }

	async componentDidMount() {
    await this.getMarketQuantityFlaggedData(0);
    await this.getMarketQuantityFlaggedData(1);
    await this.getMarketQuantityFlaggedData(2);
    await this.getMarketQuantityFlaggedData(3);
    await this.getMarketQuantityFlaggedData(4);
    await this.getMarketQuantityFlaggedData(5);
	}

	toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

	componentWillUnmount() {
		//console.log("WavelengthUsage componentWillUnmount");
		clearInterval(this.intervalID);
	}

  getMarketQuantityFlaggedData = async (market) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-inventory-quantity-flagged-items?MarketID="+market;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("getMarketQuantityFlaggedData body response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				if (body.response.length>0) {

          var quantityflaggedRepairsList = JSON.parse(JSON.stringify(this.state.quantityflaggedRepairsList));

          var regionName = "US";

          if (market==1) {
            regionName = "Europe";
          } else if (market==2) {
            regionName = "Australia";
          } else if (market==3) {
            regionName = "Brazil";
          } else if (market==4) {
            regionName = "Taiwan";
          } else if (market==5) {
            regionName = "Colombia";
          }

          var listObject = {
            Region: regionName,
            Number: body.response.length
          }

          quantityflaggedRepairsList.push(listObject);

	        this.setState({ quantityflaggedRepairsList });

				} else {
					this.state.noData = true;
				}
				//this.forceUpdate();
			} else {
				this.state.noData = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	// getData = async () => {
  //
	// 	var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-flagged-repairs";
  //
	// 	try {
  //
	// 		const response = await fetch(url, {
	// 			method: 'GET',
	// 			headers: {
	// 				Authorization: this.state.currentUser.jwtToken
	// 			},
	// 		});
  //
	// 		const body = await response.json();
	// 		console.log("get-flagged-repairs body response: " + JSON.stringify(body));
  //
	// 		if (body.status == 'Success') {
	// 			if (body.response.length>0) {
  //
  //
	//         this.setState({ flaggedRepairs: body.response });
  //
	// 			} else {
	// 				this.state.noData = true;
	// 			}
	// 			//this.forceUpdate();
	// 		} else {
	// 			this.state.noData = true;
	// 		}
  //
	// 	}
	// 	catch (err) {
	// 		console.log(err);
	// 		authenticationService.refresh();
	// 	}
  //
	// };

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	render() {

    const data = this.state.quantityflaggedRepairsList.map((prop, key) => {

      // var start = Moment(prop.ExpectedRepairDate);
      // var finish = Moment();
      //
      // var days = start.diff(finish, 'days')+" days";
      // console.log("days to arrive: " + days);

			return {
				id: key,
        Region: prop.Region,
				Number: prop.Number,
			};
		});

		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-1 [Classic]                                                  */
			/*--------------------------------------------------------------------------------*/
			<Card className="card-hover">
				<CardBody style={{ height: 210 }}>
					<Row>
						<Col xs="6" md="6" lg="6" className="text-left">
							<CardTitle>Low Inventory
							<i style={{color: '#ffffff'}} className="mdi mdi-information-outline ml-1" id="WavelengthUsageTooltip"/>
							<Tooltip placement="top" isOpen={this.state.tooltipOpen} target="WavelengthUsageTooltip" toggle={this.toggleTooltip}>
								Shows most recent quantity flagged inventory.
							</Tooltip>
							</CardTitle>
						</Col>
						<Col xs="6" md="6" lg="6" className="text-right">
						</Col>
					</Row>
          <div style={{height: "145px", overflow: "auto"}}>
          <ReactTable
            style={{autocomplete: 'none'}}
            columns={[
              {
                Header: "Region",
                accessor: "Region",
                style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                minWidth: 15,
                Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
              },
              {
                Header: "Number of Low",
                accessor: "Number",
                style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                minWidth: 15,
                Cell: row => (
                    <div style={{ textAlign: "center", color: "#ff0000" }}>{row.value}</div>
                  )
              }
            ]}
            defaultPageSize={10}
            sortable={false}
            showPaginationBottom={false}
            className="-striped -highlight"
            data={data}
            getTrGroupProps={(state, rowInfo, column, instance) => {
                    if (rowInfo !== undefined) {
                        return {
                            onClick: (e, handleOriginal) => {
                              console.log('It was in this row:', rowInfo)
                              console.log('with ID:', rowInfo.original.repairId);

                              //this.goToRepairLog(rowInfo.original.repairId);

                              this.props.onRadioBtnClick(3);
                            },
                            style: {
                                cursor: 'pointer'
                            }
                        }
                    }}
                }
          />
          </div>
				</CardBody>
			</Card>

		);
	}
}

export default AusInventory;
