import { BehaviorSubject } from 'rxjs';
//import { CometChat } from "@cometchat-pro/chat"
import { Auth } from "aws-amplify";


import { handleResponse } from '../_helpers';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    refresh,
    register,
    getDeviceList,
    forgotPassword,
    updateAccountInfo,
    updateDeviceInfo,
    purchaseWarranty,
    purchaseUpgrade,
    getGlobalConnectionURL,
    getDeviceConnectionURL,
    getDevicesAliveURL,
    getUserUpgradeLevel,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

// function login(email, password) {
//
//     const params = new FormData();
//     params.set('email', email);
//     params.set('password', password);
//
//     const requestOptions = {
//         body: params,
//         method: 'POST',
//         headers: {  },
//     };
//
//     console.log('request: ' + JSON.stringify(requestOptions));
//
//     return fetch(`https://www.geminilaser.com/g2/login.php`, requestOptions)
//         .then(handleResponse)
//         .then(user => {
//             // store user details and jwt token in local storage to keep user logged in between page refreshes
//             let status = user.status;
//             console.log('status: ' + status);
//
//             if (status === 'Success') {
//                 console.log('response info: ' + JSON.stringify(user));
//                 localStorage.setItem('currentUser', JSON.stringify(user));
//                 localStorage.setItem('currentUserEmail', JSON.stringify(email));
//                 localStorage.setItem('firstName', JSON.stringify(user.FirstName));
//                 localStorage.setItem('lastName', JSON.stringify(user.LastName));
//
//                 currentUserSubject.next(user);
//             } else if (status === 'Error') {
//                 console.log('error info: ' + user.response);
//             } else {
//               console.log('status info: ' + status);
//             }
//
//             return user;
//         })
//         .catch(function(error) {
//             console.log('error info: ' + JSON.stringify(error));
//
//             return error;
//         });
// }

function getGlobalConnectionURL() {

  //console.log('getGlobalConnectionURL currentUserSubject: ' + JSON.stringify(currentUserSubject.value.jwtToken));

  const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: currentUserSubject.value.jwtToken
      },
  };

  return fetch(`https://hdlnjb45hb.execute-api.us-west-2.amazonaws.com/v1/request-url?RequestType=1`, requestOptions)
      .then(handleResponse)
      .then(connection => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          let status = connection.status;
          //console.log('status: ' + status);
          //console.log('getGlobalConnectionURL response info: ' + JSON.stringify(connection));

          if (status === 'Success') {
              //console.log('connection response info: ' + JSON.stringify(connection));
          } else if (status === 'Error') {
              console.log('error info: ' + connection.response);
          } else {
            console.log('status info: ' + status);
          }

          return connection;
      })
      .catch(function(error) {
          //console.log('getGlobalConnectionURL info: ' + JSON.stringify(error));

          return error;
      });
}

function getDeviceConnectionURL(serial) {

  //console.log('getGlobalConnectionURL currentUserSubject: ' + JSON.stringify(currentUserSubject.value.jwtToken));

  const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: currentUserSubject.value.jwtToken
      },
  };

  var url = "https://hdlnjb45hb.execute-api.us-west-2.amazonaws.com/v1/request-url?RequestType=0&SerialNumber="+serial;

  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(connection => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          let status = connection.status;
          //console.log('status: ' + status);
          //console.log('getGlobalConnectionURL response info: ' + JSON.stringify(connection));

          if (status === 'Success') {
              //console.log('connection response info: ' + JSON.stringify(connection));
          } else if (status === 'Error') {
              console.log('error info: ' + connection.response);
          } else {
            console.log('status info: ' + status);
          }

          return connection;
      })
      .catch(function(error) {
          //console.log('getGlobalConnectionURL info: ' + JSON.stringify(error));

          return error;
      });
}

function getDevicesAliveURL() {

  //console.log('getGlobalConnectionURL currentUserSubject: ' + JSON.stringify(currentUserSubject.value.jwtToken));

  const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: currentUserSubject.value.jwtToken
      },
  };

  return fetch(`https://hdlnjb45hb.execute-api.us-west-2.amazonaws.com/v1/request-alive-url`, requestOptions)
      .then(handleResponse)
      .then(connection => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          let status = connection.status;
          //console.log('status: ' + status);
          //console.log('getGlobalConnectionURL response info: ' + JSON.stringify(connection));

          if (status === 'Success') {
              //console.log('connection response info: ' + JSON.stringify(connection));
          } else if (status === 'Error') {
              console.log('error info: ' + connection.response);
          } else {
            console.log('status info: ' + status);
          }

          return connection;
      })
      .catch(function(error) {
          //console.log('getGlobalConnectionURL info: ' + JSON.stringify(error));

          return error;
      });
}

function login(email, password) {

  //console.log('email user: '+JSON.stringify(email));
  //console.log('password user: '+JSON.stringify(password));

  return Auth.signIn({
    username: email,
    password,
  })
  .then((user) => {
    //this.setState({email: email});
    //this.setState({user: user});
    console.log('SignIn user: '+JSON.stringify(user));

    if ((user.signInUserSession.idToken.payload["custom:role"] && user.signInUserSession.idToken.payload["custom:role"]>0) || user.signInUserSession.idToken.payload["custom:market"]) {
      localStorage.setItem('currentUser', JSON.stringify(user.signInUserSession.idToken));
      localStorage.setItem('currentUserEmail', JSON.stringify(user.signInUserSession.idToken.payload.email));
      localStorage.setItem('firstName', JSON.stringify(user.signInUserSession.idToken.payload.given_name));
      localStorage.setItem('lastName', JSON.stringify(user.signInUserSession.idToken.payload.family_name));
      localStorage.setItem('phoneNumber', JSON.stringify(user.signInUserSession.idToken.payload["custom:phone"]));

      currentUserSubject.next(user.signInUserSession.idToken);
      return user.signInUserSession.idToken;
    } else {
      return { message: "Not Authorized" };
    }

  })
  .catch((error) => {
    console.log('SignIn error: '+JSON.stringify(error));
    return error;
  });

}

function register(firstname, lastname, email, password, role) {

    const params = new FormData();
    params.set('FirstName', firstname);
    params.set('LastName', lastname);
    params.set('email', email);
    params.set('password', password);
    if (role) {
      params.set('role', role);
    } else {
      params.set('role', 1);
    }

    const requestOptions = {
        body: params,
        method: 'POST',
        headers: {  },
    };

    return fetch(`https://www.geminilaser.com/g2/register.php`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            let status = user.status;
            console.log('status: ' + status);

            if (status === 'Success') {
                console.log('response info: ' + JSON.stringify(user));
                localStorage.clear();
                localStorage.setItem('currentUser', JSON.stringify(user));
                localStorage.setItem('currentUserEmail', JSON.stringify(email));
                localStorage.setItem('firstName', JSON.stringify(user.FirstName));
                localStorage.setItem('lastName', JSON.stringify(user.LastName));

                currentUserSubject.next(user);
            } else if (status === 'Error') {
                console.log('error info: ' + user.response);
            } else {
              console.log('status info: ' + status);
            }

            return user;
        })
        .catch(function(error) {
            console.log('error info: ' + JSON.stringify(error));

            return error;
        });
}

// function getDeviceList(loginId, token) {
//
//     const params = new FormData();
//     params.set('LoginID', loginId);
//     params.set('token', token);
//
//     const requestOptions = {
//         body: params,
//         method: 'POST',
//         headers: {  },
//     };
//
//     return fetch(`https://www.geminilaser.com/g2/device_list.php`, requestOptions)
//         .then(handleResponse)
//         .then(deviceList => {
//             // store user details and jwt token in local storage to keep user logged in between page refreshes
//             let status = deviceList.status;
//             console.log('status: ' + status);
//
//             if (status === 'Success') {
//                 console.log('deviceList response info: ' + JSON.stringify(deviceList));
//                 localStorage.setItem('deviceList', JSON.stringify(deviceList));
//             } else if (status === 'Error') {
//                 console.log('error info: ' + deviceList.response);
//             } else {
//               console.log('status info: ' + status);
//             }
//
//             return deviceList;
//         })
//         .catch(function(error) {
//             console.log('error info: ' + JSON.stringify(error));
//
//             return error;
//         });
// }

function getDeviceList(token) {

    const params = new FormData();
    params.set('token', token);

    const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: token
        },
    };

    return fetch(`https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/device-list`, requestOptions)
        .then(handleResponse)
        .then(deviceList => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            let status = deviceList.status;
            console.log('status: ' + status);

            if (status === 'Success') {
                console.log('deviceList response info: ' + JSON.stringify(deviceList));
                localStorage.setItem('deviceList', JSON.stringify(deviceList.response));
            } else if (status === 'Error') {
                console.log('error info: ' + deviceList.response);
            } else {
              console.log('status info: ' + status);
            }

            return deviceList;
        })
        .catch(function(error) {
            console.log('error info: ' + JSON.stringify(error));

            return error;
        });
}

function getUserUpgradeLevel(token) {

    const params = new FormData();
    params.set('token', token);

    const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: token
        },
    };

    return fetch(`https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/get-user-upgrade-level`, requestOptions)
        .then(handleResponse)
        .then(upgradeLevel => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            let status = upgradeLevel.status;
            console.log('status: ' + status);

            if (status === 'Success') {
                console.log('upgrade level response info: ' + JSON.stringify(upgradeLevel));
                localStorage.setItem('upgradeLevel', JSON.stringify(upgradeLevel.response[0]));
            } else if (status === 'Error') {
                console.log('error info: ' + upgradeLevel.response);
            } else {
              console.log('status info: ' + status);
            }

            return upgradeLevel;
        })
        .catch(function(error) {
            console.log('error info: ' + JSON.stringify(error));

            return error;
        });
}

function purchaseWarranty (nonce, warrantyAmount, warrantyLength, uID, serialNumber) {

  const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: currentUserSubject.value.jwtToken
      },
  };

  var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/process-card?SerialNumber="+serialNumber+"&UID="+uID+"&WarrantyAmount="+warrantyAmount+"&WarrantyLength="+warrantyLength+"&paymentMethodNonce="+nonce;

  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(warrantyResponse => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          let status = warrantyResponse.status;
          console.log('status: ' + status);

          if (status === 'Success') {
              console.log('warranty response info: ' + JSON.stringify(warrantyResponse));
              //localStorage.setItem('deviceList', JSON.stringify(deviceList));
          } else if (status === 'Error') {
              console.log('error info: ' + warrantyResponse.response);
          } else {
            console.log('status info: ' + status);
          }

          return warrantyResponse;
      })
      .catch(function(error) {
          console.log('error info: ' + JSON.stringify(error));

          return error;
      });

}

function purchaseUpgrade (nonce, purchaseAmount) {

  const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: currentUserSubject.value.jwtToken
      },
  };

  var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/process-card-for-upgrade?PaymentAmount="+purchaseAmount+"&PaymentMethodNonce="+nonce;

  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(upgradeResponse => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          let status = upgradeResponse.status;
          console.log('status: ' + status);

          if (status === 'Success') {
              console.log('ASJS upgrade response info: ' + JSON.stringify(upgradeResponse));
              //localStorage.setItem('deviceList', JSON.stringify(deviceList));
          } else if (status === 'Error') {
              console.log('error info: ' + upgradeResponse.response);
          } else {
            console.log('status info: ' + status);
          }

          return upgradeResponse;
      })
      .catch(function(error) {
          console.log('error info: ' + JSON.stringify(error));

          return error;
      });

}

function forgotPassword(email) {

    const params = new FormData();
    params.set('email', email);

    const requestOptions = {
        body: params,
        method: 'POST',
        headers: {  },
    };

    return fetch(`https://www.geminilaser.com/g2/forgot.php`, requestOptions)
        .then(handleResponse)
        .then(response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            let status = response.status;
            console.log('status: ' + status);

            if (status === 'Success') {
                console.log('Forgot Password response: ' + JSON.stringify(response));
            } else if (status === 'Error') {
                console.log('error info: ' + response.response);
            } else {
              console.log('status info: ' + status);
            }

            return response;
        })
        .catch(function(error) {
            console.log('error info: ' + JSON.stringify(error));

            return error;
        });
}

function updateAccountInfo(firstname, lastname) {

    const params = new FormData();
    params.set('LoginID', currentUserSubject.value.OBJID);
    params.set('token', currentUserSubject.value.token);
    params.set('FirstName', firstname);
    params.set('LastName', lastname);

    const requestOptions = {
        body: params,
        method: 'POST',
        headers: {  },
    };

    const newUserInfo = {
        status: currentUserSubject.value.status,
        OBJID: currentUserSubject.value.OBJID,
        token: currentUserSubject.value.token,
        FirstName: firstname,
        LastName: lastname
    };

    return fetch(`https://www.geminilaser.com/g2/edit_account.php`, requestOptions)
        .then(handleResponse)
        .then(response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            let status = response.status;
            console.log('status: ' + status);

            if (status === 'Success') {
                console.log('updateAccountInfo response: ' + JSON.stringify(response));

                localStorage.setItem('currentUser', JSON.stringify(newUserInfo));
                localStorage.setItem('firstName', JSON.stringify(firstname));
                localStorage.setItem('lastName', JSON.stringify(lastname));
            } else if (status === 'Error') {
                console.log('error info: ' + response.response);
            } else {
              console.log('status info: ' + status);
            }

            return response;
        })
        .catch(function(error) {
            console.log('error info: ' + JSON.stringify(error));

            return error;
        });
}

function updateDeviceInfo(UID, serialNumber, nickname) {

    const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: currentUserSubject.value.jwtToken
        },
    };

    var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/edit-device?SerialNumber="+serialNumber+"&UID="+UID+"&Nickname="+nickname;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            let status = response.status;
            console.log('status: ' + status);

            if (status === 'Success') {
                console.log('updateDeviceInfo response: ' + JSON.stringify(response));

                var deviceList = JSON.parse(localStorage.getItem('deviceList'));

                for(var i=0; i<deviceList.length; i++) {
                  if (deviceList[i].SerialNumber == serialNumber) {
                    deviceList[i].Nickname = nickname;
                    console.log('updated deviceList: ' + JSON.stringify(deviceList));
                    localStorage.setItem('deviceList', JSON.stringify(deviceList));
                  }
                }

                //localStorage.setItem('lastName', JSON.stringify(lastname));
            } else if (status === 'Error') {
                console.log('error info: ' + response.response);
            } else {
              console.log('status info: ' + status);
            }

            return response;
        })
        .catch(function(error) {
            console.log('error info: ' + JSON.stringify(error));

            return error;
        });
}

async function logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    currentUserSubject.next(null);
    // CometChat.logout().then(user=>{
    //
    // });

    try {
        await Auth.signOut({ global: true });
    } catch (error) {
        console.log('error signing out: ', error);
    }
    window.location.reload(true);
}

async function refresh() {
  Auth.currentSession()
  .then(data => {
    console.log('Auth refresh data: ', JSON.stringify(data.idToken));
    localStorage.setItem('currentUser', JSON.stringify(data.idToken));
    localStorage.setItem('currentUserEmail', JSON.stringify(data.idToken.payload.email));
    localStorage.setItem('firstName', JSON.stringify(data.idToken.payload.given_name));
    localStorage.setItem('lastName', JSON.stringify(data.idToken.payload.family_name));
    localStorage.setItem('phoneNumber', JSON.stringify(data.idToken.payload["custom:phone"]));

    currentUserSubject.next(data.idToken);
    window.location.reload(true);
  })
  .catch(err => {
    console.log('Auth refresh data: ', err);
  });

  // try {
  //   const cognitoUser = await Auth.currentAuthenticatedUser();
  //   const currentSession = await Auth.currentSession();
  //   cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
  //     console.log('session', err, session);
  //     const { idToken, refreshToken, accessToken } = session;
  //     // do whatever you want to do now :)
  //   });
  // } catch (e) {
  //   console.log('Unable to refresh Token', e);
  // }
}
