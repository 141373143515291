import React from "react";
import {
	Progress,
	Card,
  CardTitle,
	CardBody,
	Row,
	Col,
	Tooltip
} from 'reactstrap';

import Chart from 'react-c3-component';
import 'c3/c3.css';
import { Line, Bar } from 'react-chartjs-2';
import { authenticationService } from '../../../jwt/_services';


//import img1 from '../../../assets/images/background/active-bg.png';
//import img2 from '../../../assets/images/dashboard/wavelength_color_guide.png';

let lineOptions2 = {
	maintainAspectRatio: false,
	legend: {
		display: false,
		labels: { fontFamily: "Nunito Sans" }
	},
	scales: {
		yAxes: [{
			gridLines: {
				display: false
			},
			ticks: {
				fontFamily: "Nunito Sans",
				fontColor: 'rgb(178, 185, 191)'
			}
		}],
		xAxes: [{
			gridLines: {
				display: false
			},
			ticks: {
				fontFamily: "Nunito Sans",
				fontColor: 'rgb(178, 185, 191)'
			}
		}]
	}
}

class RepairsByRegion extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);
    this.state = {
			totalRepairs: '',
			repairsByRegion: [],
			repairsByRegionChart: [],
      data: [],
			noDevices: false,
			noData: false,
			tooltipOpen: false,
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.toggleTooltip = this.toggleTooltip.bind(this);
  }

	async componentDidMount() {
	 	this.getData();
		//this.intervalID = setInterval(this.getData, 5000);
	}

	toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

	componentWillUnmount() {
		//console.log("WavelengthUsage componentWillUnmount");
		clearInterval(this.intervalID);
	}

	getData = async () => {

    var url2 = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-total-repairs";

		try {

			const response2 = await fetch(url2, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body2 = await response2.json();
			console.log("get-total-repairs body response: " + JSON.stringify(body2));

	    this.setState({ totalRepairs: body2.response[0].total });

			var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-repairs-by-region";

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("RepairsByRegion body response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				if (body.response.length>0) {

					var chartData = {
							labels: body.response.map(k => k.State),
							datasets: [{
								label: 'Total Repairs',
								backgroundColor: '#e9c736',
								data: body.response.map(k => k.total)
							}]
						}

		      this.setState({ repairsByRegionChart: chartData });

					this.setState({ repairsByRegion: body.response });

	        var dataArray = [];

	        body.response.map((region, key) => {

	          var smallArray = [region.State, parseFloat(region.total)];

	    			dataArray.push(smallArray);

	    		})

	        this.setState({ data: dataArray });

				} else {
					this.state.noData = true;
				}
				//this.forceUpdate();
			} else {
				this.state.noData = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	render() {

		//let m810 = parseFloat(this.state.wavelengthusage.m810);
		//let m980 = parseFloat(this.state.wavelengthusage.m980);
		//let mDual = parseFloat(this.state.wavelengthusage.mDual);
		//console.log("m810: " + m810)
		let usage = this.state.totalRepairs;
		//console.log("usage: " + usage)
		const options = {
			data: {
				columns: this.state.data,
				type: 'donut',
				tooltip: {
					show: true
				}
			},
			donut: {
				label: {
					show: false
				},
				title: usage,
				width: 35,
			},
			legend: {
				hide: true,
				padding: 10
			},
			color: {
				pattern: ['#ff715b', '#6665dd', '#33d1bf', '#e9c636']
			}
		}
		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-1 [Classic]                                                  */
			/*--------------------------------------------------------------------------------*/
			<Card className="card-hover">
				<CardBody style={{ height: 250 }}>
					<Row>
						<Col xs="6" md="6" lg="6" className="text-left">
							<CardTitle>Repairs By Region
							<i style={{color: '#ffffff'}} className="mdi mdi-information-outline ml-1" id="RepairsByRegionTooltip"/>
							<Tooltip placement="top" isOpen={this.state.tooltipOpen} target="RepairsByRegionTooltip" toggle={this.toggleTooltip}>
								Shows the mix of Repairs by Region
							</Tooltip>
							</CardTitle>
						</Col>
						<Col xs="6" md="6" lg="6" className="text-right">
							<h4 style={{ lineHeight: '1.4'}}>Total Repairs: {usage}</h4>
						</Col>
					</Row>
					{this.state.noDevices || this.state.noData &&
						<div className="mt-5 text-center">
						{!this.state.noData &&
							<h5>User has no devices</h5>
						}
						{this.state.noData &&
							<h5>No data for user's devices</h5>
						}
						</div>
					}
					{!this.state.noDevices && !this.state.noData &&
						<div className="pt-1">
							<Row className="mb-3">
	              <Col md="12">
	                <Bar height={180} data={this.state.repairsByRegionChart} options={lineOptions2} />
	              </Col>
	            </Row>
	          </div>
					}
				</CardBody>
			</Card>

		);
	}
}

export default RepairsByRegion;
