import React from "react";
import Moment from 'moment';
import ReactTable from 'react-table-v6'

import {
	Progress,
	Card,
  CardTitle,
	CardBody,
	Row,
	Col,
	Tooltip
} from 'reactstrap';

import Chart from 'react-c3-component';
import 'c3/c3.css';
import { Line, Bar } from 'react-chartjs-2';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';


//import img1 from '../../../assets/images/background/active-bg.png';
//import img2 from '../../../assets/images/dashboard/wavelength_color_guide.png';

class TaiwanInventory extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);
    this.state = {
			flaggedRepairs: [],
      quantityflaggedRepairs: [],
			noDevices: false,
			noData: false,
			tooltipOpen: false,
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.toggleTooltip = this.toggleTooltip.bind(this);
  }

	async componentDidMount() {
	 	this.getData();
    this.getQuantityFlaggedData();
		//this.intervalID = setInterval(this.getData, 5000);
	}

	toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

	componentWillUnmount() {
		//console.log("WavelengthUsage componentWillUnmount");
		clearInterval(this.intervalID);
	}

  getQuantityFlaggedData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-inventory-quantity-flagged-items?MarketID=4";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("getAusData body response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				if (body.response.length>0) {

	        // var testArray = [{"PartNumber":"0000012","Description":"Testing","Quantity":12,"CurrentQuantity":20,"Timestamp":"2023-02-06T00:26:48.000Z"},
	        //                  {"PartNumber":"000002","Description":"Testing 2","Quantity":9,"CurrentQuantity":null,"Timestamp":null},
	        //                {"PartNumber":"000002","Description":"Testing 2","Quantity":9,"CurrentQuantity":null,"Timestamp":null},
	        //              {"PartNumber":"000002","Description":"Testing 2","Quantity":9,"CurrentQuantity":null,"Timestamp":null},
	        //            {"PartNumber":"000002","Description":"Testing 2","Quantity":9,"CurrentQuantity":null,"Timestamp":null},
	        //          {"PartNumber":"000002","Description":"Testing 2","Quantity":9,"CurrentQuantity":null,"Timestamp":null},
	        //        {"PartNumber":"000002","Description":"Testing 2","Quantity":9,"CurrentQuantity":null,"Timestamp":null}];

	        this.setState({ quantityflaggedRepairs: body.response });
	        //this.setState({ quantityflaggedRepairs: testArray });

				} else {
					this.state.noData = true;
				}
				//this.forceUpdate();
			} else {
				this.state.noData = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-flagged-repairs";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("get-flagged-repairs body response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				if (body.response.length>0) {


	        this.setState({ flaggedRepairs: body.response });

				} else {
					this.state.noData = true;
				}
				//this.forceUpdate();
			} else {
				this.state.noData = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	render() {

    const data = this.state.quantityflaggedRepairs.map((prop, key) => {

      // var start = Moment(prop.ExpectedRepairDate);
      // var finish = Moment();
      //
      // var days = start.diff(finish, 'days')+" days";
      // console.log("days to arrive: " + days);

      var usage = "N/A"

      if (prop.Timestamp) {
        usage = prop.CurrentQuantity + " Since " + Moment(prop.Timestamp).format('MM/DD/YYYY');
      }

			return {
				id: key,
        PartNumber: prop.PartNumber,
				Description: prop.Description,
        Quantity: prop.Quantity,
        Usage: usage
			};
		});

		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-1 [Classic]                                                  */
			/*--------------------------------------------------------------------------------*/
			<Card className="card-hover">
				<CardBody style={{ height: 210 }}>
					<Row>
						<Col xs="6" md="6" lg="6" className="text-left">
							<CardTitle>Taiwan Inventory
							<i style={{color: '#ffffff'}} className="mdi mdi-information-outline ml-1" id="WavelengthUsageTooltip"/>
							<Tooltip placement="top" isOpen={this.state.tooltipOpen} target="WavelengthUsageTooltip" toggle={this.toggleTooltip}>
								Shows most recent quantity flagged inventory.
							</Tooltip>
							</CardTitle>
						</Col>
						<Col xs="6" md="6" lg="6" className="text-right">
						</Col>
					</Row>
          <div style={{height: "145px", overflow: "auto"}}>
          <ReactTable
            style={{autocomplete: 'none'}}
            columns={[
              {
                Header: "Part Number",
                accessor: "PartNumber",
                style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                minWidth: 15,
                Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
              },
              {
                Header: "Description",
                accessor: "Description",
                style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                minWidth: 25,
                Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
              },
              {
                Header: "Quantity",
                accessor: "Quantity",
                style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                minWidth: 15,
                Cell: row => (
                    <div style={{ textAlign: "center", color: "#ff0000" }}>{row.value}</div>
                  )
              },
              {
                Header: "Usage",
                accessor: "Usage",
                style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                minWidth: 25,
                Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
              }
            ]}
            defaultPageSize={10}
            sortable={false}
            showPaginationBottom={false}
            className="-striped -highlight"
            data={data}
            getTrGroupProps={(state, rowInfo, column, instance) => {
                    if (rowInfo !== undefined) {
                        return {
                            onClick: (e, handleOriginal) => {
                              console.log('It was in this row:', rowInfo)
                              console.log('with ID:', rowInfo.original.repairId);

                              //this.goToRepairLog(rowInfo.original.repairId);
                            },
                            style: {
                                cursor: 'pointer'
                            }
                        }
                    }}
                }
          />
          </div>
				</CardBody>
			</Card>

		);
	}
}

export default TaiwanInventory;
