

export function getEmailText(repairInfo) {
   var emailTextString = `Technician Name: `+repairInfo.TechnicianLog+`
                          Unit Serial Number: `+repairInfo.SerialNum+`
                          Unit Batch Number: `+repairInfo.BatchNum+`
                          RMA Number: `+repairInfo.RMANum+`
                          Agile Number: `+repairInfo.AgileNum+`
                          Unit Type: `+repairInfo.UnitType+`
                          Log Creation Date: `+repairInfo.DateCreated+`
                          TAM Name: `+repairInfo.TamName+`
                          TAM Phone #: `+repairInfo.TamPhone+`
                          UPI Closed?: `+repairInfo.UPIClosed+`
                          Status: `+repairInfo.Status+`
                          Customer Name: `+repairInfo.CustomerName+`
                          Address: `+repairInfo.Address+`
                          City: `+repairInfo.City+`
                          State: `+repairInfo.State+`
                          Zip: `+repairInfo.Zip+`
                          Email: `+repairInfo.email+`
                          Action: `+repairInfo.Action+`
                          Tracking # to Repair Center: `+repairInfo.TrackingNum+`
                          Tracking Label Date: `+repairInfo.TrackingDateAzena+`
                          Expected Repair By: `+repairInfo.ExpectedRepairDate+`
                          Original Ship Date: `+repairInfo.OriginalShipDate+`
                          MDR Required?: `+repairInfo.MDRRequired+`
                          Incident Involved: `+repairInfo.IncidentInvolved+`
                          MDR Justification: `+repairInfo.MDRJustification+`
                          Detailed Issue Info: `+repairInfo.DetailedIssue+``;

    return emailTextString;
}

export function getEmailHTML(repairInfo) {

  var emailHTMLString = `<!DOCTYPE html>
<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
   <head>
      <title></title>
      <meta charset="utf-8"/>
      <meta content="width=device-width, initial-scale=1.0" name="viewport"/>
      <!--[if mso]>
      <xml>
         <o:OfficeDocumentSettings>
            <o:PixelsPerInch>96</o:PixelsPerInch>
            <o:AllowPNG/>
         </o:OfficeDocumentSettings>
      </xml>
      <![endif]-->
      <style>
         * {
         box-sizing: border-box;
         }
         body {
         margin: 0;
         padding: 0;
         background-color: #181717;
         }
         th.column {
         padding: 0
         }
         a[x-apple-data-detectors] {
         color: inherit !important;
         text-decoration: inherit !important;
         }
         #MessageViewBody a {
         color: inherit;
         text-decoration: none;
         }
         p {
         line-height: inherit
         }
         @media (max-width:750px) {
         .icons-inner {
         text-align: center;
         }
         .icons-inner td {
         margin: 0 auto;
         }
         .row-content {
         width: 100% !important;
         }
         .stack .column {
         width: 100%;
         display: block;
         }
         }
      </style>
   </head>
   <body style="background-color: #181717; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
      <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #181717;" width="100%">
      <tbody>
         <tr>
            <td>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="heading_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                          <tr>
                                             <td style="width:100%;text-align:center;">
                                                <h1 style="margin: 0; color: #ffc108; font-size: 23px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; line-height: 120%; text-align: center; direction: ltr; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-bottom: 0;">UPI Technical Information</h1>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-2" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 14px;">Technician Name:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.TechnicianLog+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 14px;">Customer Name:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.CustomerName+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-3" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 14px;">Unit Serial Number:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.SerialNum+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">Address:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.Address+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-4" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">Unit Batch Number:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.BatchNum+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">City:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.City+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-5" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">RMA Number:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.RMANum+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">State:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.State+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-6" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">Agile Number:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.AgileNum+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">Zip:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.Zip+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-7" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">Unit Type:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.UnitType+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">Email:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.email+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-8" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">Log Creation Date:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.DateCreated+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">Action:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.Action+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-9" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #555555; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px; mso-line-height-alt: 14.399999999999999px;"> </p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #555555; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px; mso-line-height-alt: 14.399999999999999px;"> </p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">Tracking # to Repair Center:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.TrackingNum+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-10" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">TAM Name:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.TamName+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">Tracking Label Date:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.TrackingDateAzena+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-11" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">TAM Phone #:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.TamPhone+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">Expected Repair By:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.ExpectedRepairDate+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-12" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">UPI Closed?</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.UPIClosed+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">Original Ship Date:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.OriginalShipDate+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-13" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">Status: </p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.Status+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;"></p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;"></p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-14" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">MDR Required?:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px;">`+repairInfo.MDRRequired+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #555555; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px; mso-line-height-alt: 14.399999999999999px;"> </p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #555555; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px; mso-line-height-alt: 14.399999999999999px;"> </p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-15" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">Incident Involved:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="75%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 14px;">`+repairInfo.IncidentInvolved+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-16" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">MDR Justification:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="75%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 14px;">`+repairInfo.MDRJustification+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-17" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="25%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0;">Detailed Issue Info:</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="75%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td style="padding-top:15px;padding-right:10px;padding-bottom:15px;padding-left:10px;">
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 12px; color: #555555; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 12px; mso-line-height-alt: 14.399999999999999px;"> </p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-18" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
                                       <table border="0" cellpadding="10" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                          <tr>
                                             <td>
                                                <div style="font-family: sans-serif">
                                                   <div style="font-size: 14px; color: #b2b9bf; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                      <p style="margin: 0; font-size: 14px;">`+repairInfo.DetailedIssue+`</p>
                                                   </div>
                                                </div>
                                             </td>
                                          </tr>
                                       </table>
                                    </th>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-19" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                  <tbody>
                     <tr>
                        <td>
                           <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="730">
                              <tbody>
                                 <tr>
                                    <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
                                       <table border="0" cellpadding="0" cellspacing="0" class="icons_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                          <tr>
                                             <td style="color:#9d9d9d;font-family:inherit;font-size:15px;padding-bottom:5px;padding-top:5px;text-align:center;">
                                                <table cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                   <tr>
                                                      <td style="text-align:center;">
                                                         <!--[if vml]>
                                                         <table align="left" cellpadding="0" cellspacing="0" role="presentation" style="display:inline-block;padding-left:0px;padding-right:0px;mso-table-lspace: 0pt;mso-table-rspace: 0pt;">
                                                            <![endif]-->
                                                            <!--[if !vml]><!-->
                                                            </td>
                                                            </tr>
                                                         </table>
                                                      </td>
                                                   </tr>
                                                </table>
                                                </th>
                                          </tr>
                                          </tbody>
                                       </table>
                                       </td>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <!-- End -->
   </body>
</html>`;

	return emailHTMLString;
}

export function getEmailHTMLNewRepair(repairInfo) {

  var emailHTMLString = `<!DOCTYPE html>
<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
   <head>
      <title></title>
   </head>
   <body style="background-color: #ffffff; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
     <table width="100%">
       <tr>
          <td style="padding: 5px; text-align: left;">
            Technician Name: `+repairInfo.TechnicianLog+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Product Name: `+repairInfo.ProductName+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Unit Serial Number: `+repairInfo.SerialNum+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Unit Batch Number: `+repairInfo.BatchNum+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            RMA Number: `+repairInfo.RMANum+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Agile Number: `+repairInfo.AgileNum+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Unit Type: `+repairInfo.UnitType+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Log Creation Date: `+repairInfo.DateCreated+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            TAM Name: `+repairInfo.TamName+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            TAM Phone #: `+repairInfo.TamPhone+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            UPI Closed?: `+repairInfo.UPIClosed+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Status: `+repairInfo.Status+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Customer Name: `+repairInfo.CustomerName+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Address: `+repairInfo.Address+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            City: `+repairInfo.City+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            State: `+repairInfo.State+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Zip: `+repairInfo.Zip+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Email: `+repairInfo.email+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Action: `+repairInfo.Action+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Tracking # to Repair Center: `+repairInfo.TrackingNum+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Tracking Label Date: `+repairInfo.TrackingDateAzena+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Expected Repair By: `+repairInfo.ExpectedRepairDate+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Original Ship Date: `+repairInfo.OriginalShipDate+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            MDR Required?: `+repairInfo.MDRRequired+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Incident Involved: `+repairInfo.IncidentInvolved+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            MDR Justification: `+repairInfo.MDRJustification+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Detailed Issue Info: `+repairInfo.DetailedIssue+`
          </td>
       </tr>
     </table>
   </body>
</html>`;

	return emailHTMLString;
}

export function getEmailHTMLRepairUpdate(repairInfo) {

  var emailHTMLString = `<!DOCTYPE html>
<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
   <head>
      <title></title>
   </head>
   <body style="background-color: #ffffff; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
     <table width="100%">
       <tr>
          <td style="padding: 5px; text-align: left;">
            Technician Name: `+repairInfo.TechnicianLog+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Product Name: `+repairInfo.ProductName+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Unit Serial Number: `+repairInfo.SerialNum+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Unit Batch Number: `+repairInfo.BatchNum+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            RMA Number: `+repairInfo.RMANum+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Agile Number: `+repairInfo.AgileNum+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Unit Type: `+repairInfo.UnitType+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Log Creation Date: `+repairInfo.DateCreated+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            TAM Name: `+repairInfo.TamName+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            TAM Phone #: `+repairInfo.TamPhone+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            UPI Closed?: `+repairInfo.UPIClosed+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Status: `+repairInfo.Status+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Customer Name: `+repairInfo.CustomerName+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Address: `+repairInfo.Address+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            City: `+repairInfo.City+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            State: `+repairInfo.State+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Zip: `+repairInfo.Zip+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Email: `+repairInfo.email+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Action: `+repairInfo.Action+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Tracking # to Repair Center: `+repairInfo.TrackingNum+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Tracking Label Date: `+repairInfo.TrackingDateAzena+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Expected Repair By: `+repairInfo.ExpectedRepairDate+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Original Ship Date: `+repairInfo.OriginalShipDate+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            MDR Required?: `+repairInfo.MDRRequired+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Incident Involved: `+repairInfo.IncidentInvolved+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            MDR Justification: `+repairInfo.MDRJustification+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Detailed Issue Info: `+repairInfo.DetailedIssue+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Detailed Repair Info: `+repairInfo.DetailedRepair+`
          </td>
       </tr>
     </table>
   </body>
</html>`;

	return emailHTMLString;
}

export function getEmailHTMLForTechnician(repairID, techname) {

  var emailHTMLString = `<!DOCTYPE html>
<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
   <head>
      <title></title>
   </head>
   <body style="background-color: #ffffff; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
     <table width="100%">
       <tr>
          <td style="padding: 5px; text-align: left;">
            Hi `+techname+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            A PDF Quote has been generated for Repair: `+repairID+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            <a href="https://repair.geminilaser.com/repair-log/`+repairID+`">Come Take a Look!</a>
          </td>
       </tr>
     </table>
   </body>
</html>`;

	return emailHTMLString;
}

export function getEmailHTMLINTLRepairComplete(repairInfo) {

  var quoteItemsTable = ``;

  for (var i=0 ; i < repairInfo.quoteItems.length ; i++) {

    let quoteItem = repairInfo.quoteItems[i];

    var row = `
    <tr>
       <td style="padding: 5px; text-align: left; border: 1px solid black; border-collapse: collapse;">
         `+quoteItem.SKU+`
       </td>
       <td style="padding: 5px; text-align: left; border: 1px solid black; border-collapse: collapse;">
         `+quoteItem.Cost+`
       </td>
       <td style="padding: 5px; text-align: left; border: 1px solid black; border-collapse: collapse;">
         `+quoteItem.Description+`
       </td>
       <td style="padding: 5px; text-align: left; border: 1px solid black; border-collapse: collapse;">
         `+quoteItem.Quantity+`
       </td>
    </tr>
    `;

    quoteItemsTable = quoteItemsTable+row;

  }

  var emailHTMLString = `<!DOCTYPE html>
<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
   <head>
      <title></title>
   </head>
   <body style="background-color: #ffffff; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
     <table width="100%">
       <tr>
          <td style="padding: 5px; text-align: left;">
            INTL - Out of Warranty Repair
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Repair ID: <a href="https://repair.geminilaser.com/repair-log/`+repairInfo.repairID+`">`+repairInfo.repairID+`</a>
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Market: `+repairInfo.Market+`
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Unit Serial Number: `+repairInfo.SerialNumber+`
          </td>
       </tr>
     </table>
     <table width="100%" style="border: 1px solid black; border-collapse: collapse;">
       <tr>
         <th style="padding: 5px; text-align: left; border: 1px solid black; border-collapse: collapse;">
           SKU
         </th>
         <th style="padding: 5px; text-align: left; border: 1px solid black; border-collapse: collapse;">
           Cost
         </th>
         <th style="padding: 5px; text-align: left; border: 1px solid black; border-collapse: collapse;">
           Description
         </th>
         <th style="padding: 5px; text-align: left; border: 1px solid black; border-collapse: collapse;">
           Quantity
         </th>
       </tr>
       `+quoteItemsTable+`
     </table>
     <table width="100%">
       <tr>
          <td style="padding: 5px; text-align: left;">
            Detailed Repair Info: `+repairInfo.DetailedRepair+`
          </td>
       </tr>
     </table>
   </body>
</html>`;

	return emailHTMLString;
}

export function getINTLEmailText(repairInfo) {
   var emailTextString = `INTL - Out of Warranty Repair
                          Market: `+repairInfo.Market+`
                          Unit Serial Number: `+repairInfo.SerialNumber+`
                          Detailed Repair Info: `+repairInfo.DetailedRepair+``;

   return emailTextString;
}
