import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from '../jwt/_services';
import Moment from 'moment';
import { Auth } from 'aws-amplify';


export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;

        console.log('currentUser info: ' + JSON.stringify(currentUser));
        console.log('currentUser info: ' + JSON.stringify(rest));


        if (currentUser) {
          var idTokenExpire = currentUser.payload.exp;
          console.log("idTokenExpire: "+idTokenExpire);
          var currentTimeSeconds = Math.round(+new Date() / 1000);
          console.log("currentTimeSeconds: "+currentTimeSeconds);
          if (idTokenExpire < currentTimeSeconds) {
            //authenticationService.logout();
            console.log("Token expired");
            authenticationService.refresh();
          } else {
            console.log("Token not expired");
            //authenticationService.refresh();
          }

        }

        if (!currentUser) {
            // not logged in so redirect to login page with the return url

            if (rest.location.pathname != "/authentication/register") {
              return <Redirect to={{ pathname: '/authentication/login', state: { from: props.location } }} />
            }

            //return <Redirect to={{ pathname: '/authentication/login', state: { from: props.location } }} />

        }

        // authorised so return component
        return <Component {...props} />
    }} />
)

export default PrivateRoute;
