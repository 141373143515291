import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import Loader from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Papa from 'papaparse';

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	ButtonDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
	Fade
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

import csvPlaceholder from '../../../assets/images/csv-icon512x512.png';

class DevicesBatchUpload extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			repairCustomerNotes: [],
			productsData: [],
			productItemsForNote: [],
			errorMsg: null,
			obj: {},
			modal: false,
			isOpen: false,
			isUploadingFile: false,
			isSavingSerials: false,
			currentItem: "ALL",
			currentDataSetting: 0,
			loadedSerialBatch: false,
			selectedFileSerialBatch: null,
			parsedCSVData: [],
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.toggle = this.toggle.bind(this);

  }

	async componentDidMount() {

		//this.getProductsData();
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	componentWillUnmount() {

	}

	onClickUploadSerialBatchHandler = () => {

		const file = this.state.selectedFileSerialBatch;

    if (!file) {
			this.setState({ errorMsg: 'Please select a CSV file.' });
      //setErrorMsg('Please select a CSV file.');
      return;
    }

    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (result) => {
        if (result.errors.length > 0) {
          //setErrorMsg('Error parsing the CSV file.');
					this.setState({ errorMsg: 'Error parsing the CSV file.' });
        } else {
          //setCsvData(result.data);
          //setErrorMsg(null);
					console.log("CsvData: " + JSON.stringify(result.data));

					if (result.data[result.data.length-1].SerialNumber && result.data[result.data.length-1].BatchNumber) {

						for (const serial of result.data) {

							console.log("SerialNumber: " + JSON.stringify(serial.SerialNumber));
							console.log("BatchNumber: " + JSON.stringify(serial.BatchNumber));

					  };

						const formattedData = result.data.map((prop, key) => {

							var serialNumber = "EL-0"+prop.SerialNumber;

							return {
								SerialNumber: serialNumber,
								BatchNumber: prop.BatchNumber
							};
						});

						this.setState({ errorMsg: null });
						this.setState({ parsedCSVData: formattedData });

					} else {
						this.setState({ errorMsg: 'CSV Format is incorrect.' });
						this.setState({ parsedCSVData: [] });
					}

        }
      },
    });

	}

	onChangeSerialBatchHandler = (event) => {

		var contentType = event.target.files[0].type;

		const contentTypeArray = contentType.split("/");

		let extension = contentTypeArray[1];

		this.setState({
			selectedFileSerialBatch: event.target.files[0],
			loadedSerialBatch: true,
		})

		console.log("file type: "+event.target.files[0].type);
	}

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	saveSerialsData = async () => {

		this.setState({ isSavingSerials: true });

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"save-batch-serials";

		let requestBody = {
			SerialsList:this.state.parsedCSVData
		}

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				Authorization: this.state.currentUser.jwtToken,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(requestBody)
		});

		const body = await response.json();
		console.log("saveSerialsData response: " + JSON.stringify(body));

		if (body.status == 'Success') {
			//this.setState({ productsData: body.response });
			this.setState({ parsedCSVData: [] });
			document.getElementById("fileSerialBatch").value = null;
			this.setState({ selectedFileSerialBatch: null, loadedSerialBatch: false });
			this.setState({ isSavingSerials: false });
			toast.success("All serials successfully uploaded.", {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
				closeButton: false
			});

		} else {
      //ADD ERROR MESSAGE
			this.setState({ isSavingSerials: false });
			toast.error("Error uploading serials. Please try again.", {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
				closeButton: false
			});


    }

	};

  render() {

		// const data = this.state.parsedCSVData.map((prop, key) => {
		//
		// 	return {
		// 		id: key,
		// 		SerialNumber: prop.SerialNumber,
		// 		InvoiceDate: prop.InvoiceDate,
		// 		actions: (
		// 			// we've added some custom button actions
		// 			<div className="text-center">
		// 				{/* use this button to add a edit kind of action */}
		// 				{/* use this button to remove the data row */}
		// 			</div>
		// 		)
		// 	};
		// });

		const data = this.state.parsedCSVData.map((prop, key) => {

			//var serialNumber = "EL-0"+prop.SerialNumber;

			return {
				id: key,
				SerialNumber: prop.SerialNumber,
				BatchNumber: prop.BatchNumber,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
					</div>
				)
			};
		});

		return (
      <Fragment>
        <Card className="card-hover">
          <CardBody>
            <Row className="mb-3">
              <Col md="6">
                <h3 style={{color:'#737782'}}>Gemini SN Batch Upload</h3>
              </Col>
							<Col md="6" className="text-right">
								<div>
								</div>
							</Col>
            </Row>
						{!this.state.isUploadingFile ? (
							<Fragment>
							<Row className="">
								<Col xs="3" md="3" lg="3">
									<Input className="" type="file" name="fileSerialBatch" id="fileSerialBatch" accept=".csv" onChange={this.onChangeSerialBatchHandler} onClick={e => (e.target.value = null)}/>
								</Col>
								<Col xs="1" md="1" lg="1">
									{this.state.loadedSerialBatch &&
										<Button className="" size="sm" color="yellow" title="Upload" onClick={this.onClickUploadSerialBatchHandler}>
											Upload
										</Button>
									}
								</Col>
								<Col xs="3" md="3" lg="3">
									{this.state.loadedSerialBatch &&
										<img style={{ marginTop: "-3px"}} className="ml-2" src={csvPlaceholder} width="30"/>
									}
								</Col>
								<Col xs="5" md="5" lg="5">
								{!this.state.isSavingSerials ? (
									<Fragment>
										{this.state.parsedCSVData.length > 0 &&
											<Button className="float-right" size="" color="yellow" title="Upload" onClick={this.saveSerialsData}>
												Save Serials
											</Button>
										}
									</Fragment>
								) : (
									<Fragment>
										<Row className="pl-4 float-right">
												<Fade in="true">
													<Row className="justify-content-center">
														<Loader type="ThreeDots" color="#ffc108" height={30} width={200}/>
													</Row>
												</Fade>
										</Row>
									</Fragment>
								)}
								</Col>
							</Row>
							<Row className="pt-3">
								<Col xs="6" md="6" lg="6">
									{this.state.errorMsg && <p className="error">{this.state.errorMsg}</p>}
								</Col>
							</Row>
							</Fragment>
						) : (
							<Row className="pl-4 pl-4">
									<Fade in="true">
										<Row className="justify-content-center">
											<Loader type="ThreeDots" color="#ffc108" height={30} width={200}/>
										</Row>
									</Fade>
							</Row>
						)}
          </CardBody>


        </Card>
				<Card className="card-hover">
          <CardBody>
						<ReactTable
							style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
							columns={[
								{
									Header: "Serial Number",
									accessor: "SerialNumber",
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
								{
									Header: "Batch Number",
									accessor: "BatchNumber",
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
							]}
							defaultPageSize={15}
							sortable={true}
							showPaginationBottom={true}
							className="-striped -highlight"
							data={data}
							filterable
							filterAll={true}
							defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
							getTrGroupProps={(state, rowInfo, column, instance) => {
											if (rowInfo !== undefined) {
													return {
															onClick: (e, handleOriginal) => {
																//console.log('It was in this row:', rowInfo)
																//console.log('with ID:', rowInfo.original.categoryID);

																//this.goToRepairLog(rowInfo.original.repairId);
															}
													}
											}}
									}
						/>
					</CardBody>
        </Card>
				<ToastContainer/>
      </Fragment>
		);
	}
}

export default DevicesBatchUpload;
