import React from "react";
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown,
	Tooltip
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import { authenticationService } from '../../../jwt/_services';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import Moment from 'moment';

let lineOptions2 = {
	maintainAspectRatio: false,
	legend: {
		display: false,
		labels: { fontFamily: "Nunito Sans" }
	},
	scales: {
		yAxes: [{
			gridLines: {
				display: false
			},
			ticks: {
        beginAtZero: true,
				fontFamily: "Nunito Sans",
				fontColor: 'rgb(178, 185, 191)'
			}
		}],
		xAxes: [{
			gridLines: {
				display: false
			},
			ticks: {
				fontFamily: "Nunito Sans",
				fontColor: 'rgb(178, 185, 191)'
			}
		}]
	}
}

let lineOptions = {
	maintainAspectRatio: false,
	tooltips: {
		titleFontSize: 16,
		bodyFontSize: 14
	},
	scales: {
		xAxes: [{
			gridLines: {
				display: false
			},
			ticks: {
			}
		}],
		yAxes: [{
			gridLines: {
				display: false
			},
			ticks: {
			}
		}]
	},
	legend: {
		display: true,
		labels: {
		}
	}
};

class TotalRepairs extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

    const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			rSelected: 3,
			totalRepairs: 0,
			repairsByDateData: {},
			noDevices: false,
			noData: false,
			currentDataSetting: 1,
			startDate: Moment().format('YYYY-MM-DD'),
			endDate: Moment().format('YYYY-MM-DD'),
			currentDate: Moment().format('YYYY-MM-DD'),
			isOpen: false,
			currentItem: "Today",
			tooltipOpen: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.toggleTooltip = this.toggleTooltip.bind(this);
  }

	async componentDidMount() {
		//this.selectDropDownItem(2, "Week");

    this.getData();

		//console.log("Start Date: " + JSON.stringify(this.state.startDate));
		//console.log("End Date: " + JSON.stringify(this.state.endDate));
	}

	toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	handleChange(event) {
		if (event.target.getAttribute('name') == 'startDate') {
			this.setState({startDate: event.target.value});
			this.setState({
	      currentItem: "Custom Range",
				currentDataSetting: 4
	    });
		} else if (event.target.getAttribute('name') == 'endDate') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({endDate: event.target.value});
			this.setState({
				currentItem: "Custom Range",
				currentDataSetting: 4
	    });
		}
  }

	handleSubmit(event) {

		this.getData();
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		if (id==1) {
			this.setState({ startDate: Moment().format('YYYY-MM-DD') });
		} else if (id==2) {
			this.setState({ startDate: Moment().subtract(1, 'week').format('YYYY-MM-DD') });
		} else if (id==3) {
			this.setState({ startDate: Moment().subtract(1, 'month').format('YYYY-MM-DD') });
		} else if (id==4) {
			this.setState({ startDate: Moment().subtract(1, 'month').format('YYYY-MM-DD') });
		}

		this.setState({ endDate: Moment().format('YYYY-MM-DD') });
		//this.getData();
    setTimeout(this.getData, 1000);
  }

	componentWillUnmount() {
		//console.log("totalRepairs componentWillUnmount");
		clearInterval(this.intervalID);
	}

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected });
		this.getData(rSelected);
  }

	stepBack () {
		if (this.state.currentDataSetting==1) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'day').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'day').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==2) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'week').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'week').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==3) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'month').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'month').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		}
		//this.getData();
		setTimeout(this.getData, 1000);
	}

	stepForward () {
		if (this.state.currentDataSetting==1) {
			let revisedStartDate = Moment(this.state.startDate).add(1, 'day').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).add(1, 'day').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==2) {
			let revisedStartDate = Moment(this.state.startDate).add(1, 'week').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).add(1, 'week').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		}
		else if (this.state.currentDataSetting==3) {
		 let revisedStartDate = Moment(this.state.startDate).add(1, 'month').format('YYYY-MM-DD');
		 let revisedEndDate = Moment(this.state.endDate).add(1, 'month').format('YYYY-MM-DD');
		 console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
		 console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
		 this.setState({ startDate: revisedStartDate });
		 this.setState({ endDate: revisedEndDate });
	 }
		//this.getData();
		setTimeout(this.getData, 1000);
	}

	getData = async () => {

		console.log("Start Date: " + JSON.stringify(this.state.startDate));
		console.log("End Date: " + JSON.stringify(this.state.endDate));

		if (this.state.currentDataSetting == 2 || this.state.currentDataSetting == 3) {
			var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-total-repairs-by-day-date-range?StartDate="+this.state.startDate+"&EndDate="+this.state.endDate;
			console.log("totalRepairs date range url: " + url);

			try {

				const response = await fetch(url, {
					method: 'GET',
					headers: {
						Authorization: this.state.currentUser.jwtToken
					},
				});

				const body = await response.json();
				console.log("totalRepairs by day date range body response: " + JSON.stringify(body));

				//Bar Chart
				// var barData = {
				//     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
				//     datasets: [{
				//         label: 'Apple',
				//         backgroundColor: '#2962ff',
				//         borderColor: '#2962ff',
				//         data: d.chartData.barData.data.a
				//     },
				//     {
				//         label: 'Google',
				//         backgroundColor: '#4fc3f7',
				//         borderColor: '#4fc3f7',
				//         data: d.chartData.barData.data.b
				//     }]
				// };

				var chartData = {
					labels: body.response.map(k => Moment.utc(k.date).format('DD')),
					datasets: [{
						label: 'Total Repairs',
						backgroundColor: '#e9c736',
						data: body.response.map(k => k.total)
					}]
				}

				if (this.state.currentDataSetting == 2) {
					chartData = {
						labels: body.response.map(k => Moment.utc(k.date).format('MM/DD')),
						datasets: [{
							label: 'Total Repairs',
							backgroundColor: '#e9c736',
							data: body.response.map(k => k.total)
						}]
					}
				}



				console.log("totalRepairs by day: "+JSON.stringify(chartData));

				if (body.status == 'Success') {
					if (body.response.length>0) {
						this.setState({ repairsByDateData: chartData }, () => console.log("totalRepairs by day: "+this.state.repairsByDateData));
						this.state.noData = false;
					} else {
						this.state.noData = true;
					}
					this.forceUpdate();
				} else {
					this.state.noData = true;
				}

			}
			catch (err) {
				console.log(err);
				authenticationService.refresh();
			}

		} else {
			var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-total-repairs-date-range?StartDate="+this.state.startDate+"&EndDate="+this.state.endDate;
			console.log("totalRepairs date range url: " + url);

			try {

				const response = await fetch(url, {
					method: 'GET',
					headers: {
						Authorization: this.state.currentUser.jwtToken
					},
				});

				const body = await response.json();
				console.log("totalRepairs date range body response: " + JSON.stringify(body));

				if (body.status == 'Success') {
					if (body.response.length>0) {
						this.setState({ totalRepairs: body.response[0].total }, () => console.log(this.state.totalRepairs));
						this.state.noData = false;
					} else {
						this.state.noData = true;
					}
					this.forceUpdate();
				} else {
					this.state.noData = true;
				}

			}
			catch (err) {
				console.log(err);
				authenticationService.refresh();
			}

		}


		// } else {
		// 	var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/total-procedures?TimeInterval="+this.state.currentDataSetting;
		//
		// 	const response = await fetch(url, {
		// 		method: 'GET',
		// 		headers: {
		// 			Authorization: this.state.currentUser.jwtToken
		// 		},
		// 	});
		//
		// 	const body = await response.json();
		// 	console.log("totalRepairs body response: " + JSON.stringify(body));
		//
		// 	if (body.status == 'Success') {
		// 		if (body.results.length>0) {
		// 			this.setState({ totalRepairs: body.results[0].total }, () => console.log(this.state.totalRepairs));
		// 		} else {
		// 			this.state.noData = true;
		// 		}
		// 		this.forceUpdate();
		// 	} else {
		// 		this.state.noData = true;
		// 	}
		// }

	}

	render() {
		return (
      <Card className="card-hover">
				<CardBody style={{ height: 250 }}>
					<Row>
						<Col xs="6" md="6" lg="6" className="text-left">
							<CardTitle>Total Repairs
							<i style={{color: '#ffffff'}} className="mdi mdi-information-outline ml-1" id="TotalRepairstooltip"/>
							<Tooltip placement="top" isOpen={this.state.tooltipOpen} target="TotalRepairstooltip" toggle={this.toggleTooltip}>
								Displays total repairs performed by time period. This includes both gemini and evo repairs.
							</Tooltip>
							</CardTitle>
						</Col>
						<Col xs="6" md="6" lg="6" className="text-right">
							<ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
								<DropdownToggle color="dark" caret>
									{this.state.currentItem}
								</DropdownToggle>
								<DropdownMenu right>
									<DropdownItem onClick={() => this.selectDropDownItem(1, 'Today')}>Today</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(2, 'Week')}>Week</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(3, 'Month')}>Month</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(4, 'Custom Range')}>Custom Range</DropdownItem>
								</DropdownMenu>
							</ButtonDropdown>
						</Col>
					</Row>
					{this.state.noDevices || this.state.noData &&
						<div className="mt-5 text-center">
						{!this.state.noData &&
							<h5>User has no devices</h5>
						}
						{this.state.noData &&
							<h5>No data for user's devices</h5>
						}
						</div>
					}
					{!this.state.noDevices && !this.state.noData &&
						<Fragment>
						{
						// <Row>
						// 	<Col xs="12" md="12" lg="12">
						// 		<div className="mt-1">
						// 			<ButtonGroup style={{ width: '100%' }}>
						// 				<Button
						// 					style={{width: '150px'}}
						// 					color="dark"
						// 					onClick={() => this.onRadioBtnClick(1)}
						// 					active={this.state.rSelected === 1}
						// 				>
						// 					Today
						// 				</Button>
						// 				<Button
						// 					color="dark"
						// 					style={{width: '150px'}}
						// 					onClick={() => this.onRadioBtnClick(2)}
						// 					active={this.state.rSelected === 2}
						// 				>
						// 					This Week
						// 				</Button>
						// 				<Button
						// 					color="dark"
						// 					style={{width: '150px'}}
						// 					onClick={() => this.onRadioBtnClick(3)}
						// 					active={this.state.rSelected === 3}
						// 				>
						// 					This Month
						// 				</Button>
						// 			</ButtonGroup>
						// 		</div>
						// 	</Col>
						// </Row>
						}
						<div className="pt-3 pb-1">
							{this.state.currentDataSetting != 4 ? (
								<Fragment>
								{this.state.currentDataSetting == 2 || this.state.currentDataSetting == 3 ? (
									<Fragment>
									<Row className="align-items-center">
										<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
											<Button size="sm" color="link" onClick={() => this.stepBack()} title="Vitals">
												<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-left mdi-24px"/>
											</Button>
										</div>
										<div style={{ width: '80%', margin: '0 auto', height: '60%' }}>
											<Bar height={130} data={this.state.repairsByDateData} options={lineOptions2} />
										</div>
										<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
											<Button size="sm" color="link" disabled={this.state.currentDate==this.state.endDate} onClick={() => this.stepForward()} title="Vitals">
												<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-right mdi-24px"/>
											</Button>
										</div>
									</Row>
									</Fragment>
								) : (
									<Fragment>
									<Row className="align-items-center">
										<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
											<Button size="sm" color="link" onClick={() => this.stepBack()} title="Vitals">
												<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-left mdi-24px"/>
											</Button>
										</div>
										<div style={{ width: '80%', margin: '0 auto', height: '100%' }}>
											<Row className="mt-5">
												<Col xs="6" md="6" lg="6" className="text-left">
													<span>{this.state.totalRepairs}</span>
												</Col>
												<Col xs="6" md="6" lg="6" className="text-right">
													<span></span>
												</Col>
											</Row>
											<Progress multi className="mt-2">
												<Progress bar color="warning" value={this.state.totalRepairs} />
											</Progress>
										</div>
										<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
											<Button size="sm" color="link" disabled={this.state.currentDate==this.state.endDate} onClick={() => this.stepForward()} title="Vitals">
												<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-right mdi-24px"/>
											</Button>
										</div>
									</Row>
									</Fragment>
								)}
								</Fragment>
							) : (
								<Fragment>
								<Row className="mt-5">
									<Col xs="6" md="6" lg="6" className="text-left">
										<span>{this.state.totalRepairs}</span>
									</Col>
									<Col xs="6" md="6" lg="6" className="text-right">
										<span></span>
									</Col>
								</Row>
								<Progress multi className="mt-2">
									<Progress bar color="warning" value={this.state.totalRepairs} />
								</Progress>
								</Fragment>
							)}
						</div>

						{this.state.currentDataSetting == 2 || this.state.currentDataSetting == 3 ? (
							<Fragment>
							<Row className="ml-1">
								{this.state.currentDataSetting == 1 ? (
									<div className="float-left" style={{ width: '40px' }}>
										<h6 style={{ lineHeight: '1.8' }}>Day:</h6>
									</div>
								) : (
									<div className="float-left" style={{ width: '40px' }}>
										<h6 style={{ lineHeight: '1.8' }}>From:</h6>
									</div>
								)}
								<div className="float-left" style={{ width: '140px' }}>
									<Input name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
								</div>
								{this.state.currentDataSetting != 1 &&
									<Fragment>
									<div className="float-left ml-2" style={{ width: '23px' }}>
										<h6 style={{ lineHeight: '1.8' }}>To:</h6>
									</div>
									<div className="float-left" style={{ width: '140px' }}>
										<Input name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
									</div>
									</Fragment>
								}
								<div className="float-left ml-2" style={{ width: '50px' }}>
									<Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="Vitals" disabled={this.state.currentDataSetting != 4}>
										GO
									</Button>
								</div>
							</Row>
							</Fragment>
						) : (
							<Fragment>
							<Row className="pt-4 mt-4 ml-1">
								{this.state.currentDataSetting == 1 ? (
									<div className="float-left" style={{ width: '40px' }}>
										<h6 style={{ lineHeight: '1.8' }}>Day:</h6>
									</div>
								) : (
									<div className="float-left" style={{ width: '40px' }}>
										<h6 style={{ lineHeight: '1.8' }}>From:</h6>
									</div>
								)}
								<div className="float-left" style={{ width: '140px' }}>
									<Input name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
								</div>
								{this.state.currentDataSetting != 1 &&
									<Fragment>
									<div className="float-left ml-2" style={{ width: '23px' }}>
										<h6 style={{ lineHeight: '1.8' }}>To:</h6>
									</div>
									<div className="float-left" style={{ width: '140px' }}>
										<Input name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
									</div>
									</Fragment>
								}
								<div className="float-left ml-2" style={{ width: '50px' }}>
									<Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="Vitals" disabled={this.state.currentDataSetting != 4}>
										GO
									</Button>
								</div>
							</Row>
							</Fragment>
						)}
						</Fragment>
					}
        </CardBody>
      </Card>
		);
	}
}

export default TotalRepairs;
