import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
  Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown,
	Tooltip
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';
import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';

let lineOptions = {
	maintainAspectRatio: false,
	elements: { point: { show: true } },
	layout: {
			padding: {
				right: 6
			}
	},
	tooltips: {
		titleFontSize: 16,
		bodyFontSize: 14
	},
	scales: {
		xAxes: [{
			gridLines: {
				display: false,
				drawBorder: false,
			},
			ticks: {
				display: false
			}
		}],
		yAxes: [{
			gridLines: {
				display: false,
				drawBorder: false,
			},
			ticks: {
				display: false
			}
		}]
	},
	legend: {
		display: false,
		labels: {
			fontColor: 'rgb(255, 99, 132)'
		}
	}

}

class GeminiVsEvo extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
      usage: [],
			chartData: {},
			noDevices: false,
			noData: false,
			currentDataSetting: 0,
			repairTotals: [],
      isOpen: false,
			currentItem: "Global",
			tooltipOpen: false,
      startDate: Moment().subtract(1, 'week').format('YYYY-MM-DD'),
			endDate: Moment().format('YYYY-MM-DD'),
			currentDate: Moment().format('YYYY-MM-DD'),
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
      geminiTotal: 0,
			evoTotal: 0
	  };

    this.toggleDropDown = this.toggleDropDown.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.toggleTooltip = this.toggleTooltip.bind(this);

  }

	async componentDidMount() {

    this.selectDropDownItem(2, 'By Month')
    //this.getData();
	}

	componentWillUnmount() {

	}

	toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

  toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	handleChange(event) {
		if (event.target.getAttribute('name') == 'startDate') {
			this.setState({startDate: event.target.value});
			this.setState({
	      currentItem: "Custom Range",
				currentDataSetting: 3
	    });
		} else if (event.target.getAttribute('name') == 'endDate') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({endDate: event.target.value});
			this.setState({
				currentItem: "Custom Range",
				currentDataSetting: 3
	    });
		}
  }

	handleSubmit(event) {

		this.getData();
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		if (id==1) {
			this.setState({ startDate: Moment().subtract(1, 'week').format('YYYY-MM-DD') });
		} else if (id==2) {
			this.setState({ startDate: Moment().subtract(1, 'month').format('YYYY-MM-DD') });
		}

		this.setState({ endDate: Moment().format('YYYY-MM-DD') });
		//this.getData();
		setTimeout(this.getData, 1000);
  }

	stepBack () {
		if (this.state.currentDataSetting==1) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'week').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'week').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==2) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'month').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'month').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		}
		//this.getData();
		setTimeout(this.getData, 1000);
	}

	stepForward () {
		if (this.state.currentDataSetting==1) {
			let revisedStartDate = Moment(this.state.startDate).add(1, 'week').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).add(1, 'week').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==2) {
			let revisedStartDate = Moment(this.state.startDate).add(1, 'month').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).add(1, 'month').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		}
		//this.getData();
		setTimeout(this.getData, 1000);
	}

  getData = async () => {

		if (this.state.currentDataSetting==0) {

			try {

				const response = await fetch('https://cqzybp52y3.execute-api.us-west-2.amazonaws.com'+process.env.REACT_APP_ENV+'get-gemini-vs-evo', {
					method: 'GET',
					headers: {
						Authorization: this.state.currentUser.jwtToken
					},
				});

				const body = await response.json();
				console.log("gemini vs evo body response: " + JSON.stringify(body));

				if (body.status == 'Success') {
					if (body.response.length>0) {
						this.createGraph(body.response);

						let gemini = body.response.map(k => k.gemini);
						let evo = body.response.map(k => k.evo);

						this.setState({ geminiTotal: gemini.length});
						this.setState({ evoTotal: evo.length});
						console.log("start Date is " + JSON.stringify(body.response[0].date));
						console.log("end Date is " + JSON.stringify(body.response[body.response.length-1].date));
						this.setState({ startDate: Moment(body.response[0].date).format('YYYY-MM-DD') });
						this.setState({ endDate: Moment(body.response[body.response.length-1].date).format('YYYY-MM-DD') });
						this.state.noData = false;
					} else {
						this.state.noData = true;
					}
				} else {
					this.state.noData = true;
				}

			}
			catch (err) {
				console.log(err);
				authenticationService.refresh();
			}

		} else {

			var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-gemini-vs-evo-date-range?StartDate="+this.state.startDate+"&EndDate="+this.state.endDate+""

			try {

				const response = await fetch(url, {
					method: 'GET',
					headers: {
						Authorization: this.state.currentUser.jwtToken
					},
				});

				const body = await response.json();
				console.log("gemini vs evo date range body response: " + JSON.stringify(body));

				if (body.status == 'Success') {
					if (body.response.length>0) {
						this.createGraph(body.response);
	          let gemini = body.response.map(k => k.gemini);
						let evo = body.response.map(k => k.evo);

	          this.setState({ geminiTotal: gemini.length});
						this.setState({ evoTotal: evo.length});

						this.state.noData = false;
					} else {
						this.state.noData = true;
					}
				} else {
					this.state.noData = true;
				}

			}
			catch (err) {
				console.log(err);
				authenticationService.refresh();
			}

		}

	}

  createGraph = (results) => {

		const { keywordData } = results;
		const chartData = {
			labels: results.map(k => Moment(k.date).format('MMM Do YYYY')),
			datasets: [{
				label: 'Total Gemini Repairs',
				borderWidth: 0,
				backgroundColor: 'rgba(65,71,85,.6)',
				borderColor: 'rgba(65,71,85,.6)',
				pointBorderColor: 'rgba(65,71,85,.6)',
				pointBackgroundColor: 'rgba(255,199,44)',
				data: results.map(k => k.gemini)
			},
			{
				label: 'Total EVO Repairs',
				borderWidth: 0,
				backgroundColor: 'rgba(233,236,239)',
				borderColor: 'rgba(233,236,239)',
				pointBorderColor: 'rgba(233,236,239)',
				pointBackgroundColor: 'rgba(255,199,44)',
				data: results.map(k => k.evo)
			}]
		}

		this.setState({ usage: results });
		this.setState({ chartData });
	}

  render() {

		return (
			<Fragment>
        <Card className="card-hover">
  				<CardBody style={{ height: 250 }}>
  						<Row>
  							<Col xs="6" md="6" lg="6" className="text-left">
  								<CardTitle>Gemini vs Gemini EVO
										<i style={{color: '#ffffff'}} className="mdi mdi-information-outline ml-1" id="GVEtooltip"/>
										<Tooltip placement="top" isOpen={this.state.tooltipOpen} target="GVEtooltip" toggle={this.toggleTooltip}>
							        This is the mix of Gemini vs EVO repairs conducted in the desired time period.
							      </Tooltip>
									</CardTitle>
  							</Col>
  							<Col xs="6" md="6" lg="6" className="text-right">
  								<ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
  									<DropdownToggle color="dark" caret>
  										{this.state.currentItem}
  									</DropdownToggle>
  									<DropdownMenu right>

  										<DropdownItem onClick={() => this.selectDropDownItem(0, 'Global')}>Global</DropdownItem>
  										<DropdownItem onClick={() => this.selectDropDownItem(1, 'By Week')}>By Week</DropdownItem>
  										<DropdownItem onClick={() => this.selectDropDownItem(2, 'By Month')}>By Month</DropdownItem>
  										<DropdownItem onClick={() => this.selectDropDownItem(3, 'Custom Range')}>Custom Range</DropdownItem>
  									</DropdownMenu>
  								</ButtonDropdown>
  							</Col>
  						</Row>
  						{this.state.noDevices ? (
  							<div className="mt-5 text-center">
  								<h5>User has no devices</h5>
  							</div>
  						) : (
  							<Fragment>
  							{this.state.currentDataSetting == 0 || this.state.currentDataSetting == 3 ? (
  								<Fragment>
  								<div className="ml-auto mt-1">
  									<div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 125 }}>
  										{this.state.noDataForDevices ? (
  											<div className="d-flex justify-content-center align-items-center" style={{ height: '100%'}}>
  												<h5>No data for user's devices</h5>
  											</div>
  										) : (
  											<Line data={this.state.chartData} options={lineOptions} />
  										)}
  									</div>
  									<div className="text-center" style={{ width: '100%'}}>
  										<ul className="list-inline font-12 dl mr-3 mb-0">
  											<li className="border-0 p-0 list-inline-item" style={{color:'#e9ecef'}}>
  												<i className="fa fa-circle"></i> Gemini ({this.state.geminiTotal})</li>
  											<li className="border-0 p-0 list-inline-item" style={{color:'#71747c'}}>
  												<i className="fa fa-circle"></i> EVO ({this.state.evoTotal})</li>
  										</ul>
  									</div>
  									{this.state.currentDataSetting != 0 &&
  									<Fragment>
  									<Row className="mt-1 ml-1">
  										<div className="float-left" style={{ width: '40px' }}>
  											<h6 style={{ lineHeight: '1.8' }}>From:</h6>
  										</div>
  										<div className="float-left" style={{ width: '140px' }}>
  											<Input name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
  										</div>
  										<div className="float-left ml-2" style={{ width: '23px' }}>
  											<h6 style={{ lineHeight: '1.8' }}>To:</h6>
  										</div>
  										<div className="float-left" style={{ width: '140px' }}>
  											<Input name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
  										</div>
  										<div className="float-left ml-2" style={{ width: '50px' }}>
  											<Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="Vitals" disabled={this.state.currentDataSetting != 3}>
  												GO
  											</Button>
  										</div>
  									</Row>
  									</Fragment>
  									}
  								</div>
  								</Fragment>
  							) : (
  								<Fragment>
  								<Row className="align-items-center">
  									<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
  										<Button size="sm" color="link" onClick={() => this.stepBack()} title="Vitals">
  											<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-left mdi-24px"/>
  										</Button>
  									</div>
  									<div style={{ width: '80%', margin: '0 auto', height: '100%' }}>
  										<div className="ml-4 mt-1">
  											<div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 125 }}>
  												{this.state.noDataForDevices ? (
  													<div className="d-flex justify-content-center align-items-center" style={{ height: '100%'}}>
  														<h5>No data for user's devices</h5>
  													</div>
  												) : (
  													<Line data={this.state.chartData} options={lineOptions} />
  												)}
  											</div>
  											<div className="text-center" style={{ width: '100%'}}>
  												<ul className="list-inline font-12 dl mr-3 mb-0">
                            <li className="border-0 p-0 list-inline-item" style={{color:'#e9ecef'}}>
                              <i className="fa fa-circle"></i> Gemini ({this.state.geminiTotal})</li>
                            <li className="border-0 p-0 list-inline-item" style={{color:'#71747c'}}>
                              <i className="fa fa-circle"></i> EVO ({this.state.evoTotal})</li>
  												</ul>
  											</div>
  										</div>
  									</div>
  									<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
  										<Button size="sm" color="link" disabled={this.state.currentDate==this.state.endDate} onClick={() => this.stepForward()} title="Vitals">
  											<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-right mdi-24px"/>
  										</Button>
  									</div>
  								</Row>
  								<Row className="mt-1 ml-1">
  									<div className="float-left" style={{ width: '40px' }}>
  										<h6 style={{ lineHeight: '1.8' }}>From:</h6>
  									</div>
  									<div className="float-left" style={{ width: '140px' }}>
  										<Input name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
  									</div>
  									<div className="float-left ml-2" style={{ width: '23px' }}>
  										<h6 style={{ lineHeight: '1.8' }}>To:</h6>
  									</div>
  									<div className="float-left" style={{ width: '140px' }}>
  										<Input name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
  									</div>
  									<div className="float-left ml-2" style={{ width: '50px' }}>
  									<Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="Vitals">
  										GO
  									</Button>
  									</div>
  								</Row>
  								</Fragment>
  							)}
  							</Fragment>
  						)}
          </CardBody>
        </Card>
			</Fragment>
		);
	}
}

export default GeminiVsEvo;
