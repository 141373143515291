import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class GeminiUpdateView extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			bomData: [],
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		//this.toggle4 = this.toggle4.bind(this);

  }


	async componentDidMount() {
		//this.getBOMData();
		this.intervalID = setInterval(this.getBOMData, 5000);
	}

	componentWillUnmount() {
		//console.log("Laser-Usage componentWillUnmount");
		clearInterval(this.intervalID);
	}

	getBOMData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-bill-of-materials";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all BOM data: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ bomData: body.response });
		} else {
      //ADD ERROR MESSAGE
    }

	};

  render() {

		return (
			<Fragment>
				<Row>
					<Col md="12">
						<Card className="card-hover">
							<CardBody>
								<Row className="mb-3">
									<Col md="6">
										<h1 style={{color:'#737782'}}>Gemini Update</h1>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Fragment>
		);
	}
}

export default GeminiUpdateView;
