import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	ButtonDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
	Fade
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class DealerList extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			dealerData: [],
			productsData: [],
			marketsData: [],
			productItemsForNote: [],
			marketItemsForDealerItem: [],
			phoneNumber: "",
			obj: {},
			modal: false,
      modal2: false,
			modal5: false,
			isOpen: false,
			currentItem: "ALL Products",
			currentDataSetting: 0,
			isOpen2: false,
			currentItem2: "ALL Markets",
			currentDataSetting2: 100,
			status: "",
			marketSelected: 100,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);

		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.toggle5 = this.toggle5.bind(this);

		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.toggleDropDown2 = this.toggleDropDown2.bind(this);

  }

	async componentDidMount() {

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="europe") {
			this.setState({ currentItem2: "Europe", marketSelected: 1 });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="aus") {
			this.setState({ currentItem2: "Australia", marketSelected: 2 });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="brazil") {
			this.setState({ currentItem2: "Brazil", marketSelected: 3 });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="taiwan") {
			this.setState({ currentItem2: "Taiwan", marketSelected: 4 });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="colombia") {
			this.setState({ currentItem2: "Colombia", marketSelected: 5 });
		}

    await this.getMarketsData();

    this.getDealerData();
	}

	async onRadioBtnClick(marketSelected) {
		await this.setState({ marketSelected, currentDataSetting: marketSelected });
		setTimeout(this.getDealerData, 300);
	}

	toggle() {
	  this.setState({
			status: "",
	    modal: !this.state.modal
	  });
	}

  toggle2() {

	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle5() {
	  this.setState({
			phoneNumber: "",
			status: "",
	    modal5: !this.state.modal5
	  });
	}

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	toggleDropDown2() {
    this.setState({
      isOpen2: !this.state.isOpen2
    });
  }

	selectDropDownItem2(id, title) {

    this.setState({
      currentItem2: title,
			currentDataSetting2: id
    });

		setTimeout(this.getDealerData, 300);

  }

	componentWillUnmount() {

	}

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	getMarketsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-markets";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all markets data: " + JSON.stringify(body));

			if (body.status == 'Success') {

				let userMarket = this.state.currentUser.payload['custom:market'];
				var marketsData = body.response;

				if (userMarket=='aus' || userMarket=='europe' || userMarket=='brazil' || userMarket=='taiwan' || userMarket=='colombia') {
					marketsData = marketsData.filter(market => market.Abbreviation.includes(userMarket))
				}

				this.setState({ marketsData });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	// getProductsData = async () => {
  //
	// 	var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-products";
  //
	// 	const response = await fetch(url, {
	// 		method: 'GET',
	// 		headers: {
	// 			Authorization: this.state.currentUser.jwtToken
	// 		},
	// 	});
  //
	// 	const body = await response.json();
	// 	console.log("all Products data: " + JSON.stringify(body));
  //
	// 	if (body.status == 'Success') {
	// 		this.setState({ productsData: body.response });
	// 	} else {
  //     //ADD ERROR MESSAGE
  //   }
  //
	// };

	getDealerData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-dealers?MarketID="+this.state.marketSelected;

		// if (this.state.currentDataSetting2 != 100) {
		// 	url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-dealers?MarketID="+this.state.currentDataSetting2;
		// }

		console.log("getDealerData url: " + url);

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all getDealerData: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ dealerData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	createDealerSubmit = (event) => {
		event.preventDefault();

    let name = event.target.Name.value;
    console.log(`name is:`, name);

		let contactName = event.target.ContactName.value;
    console.log(`contactName is:`, contactName);

    //let phone = event.target.Phone.value;
		let phone = this.state.phoneNumber;
    console.log(`phone is:`, phone);

    let email = event.target.Email.value;
    console.log(`email is:`, email);

		let address = event.target.Address.value;
    console.log(`address is:`, address);

		let address2 = event.target.Address2.value;
    console.log(`address2 is:`, address2);

    let city = event.target.City.value;
    console.log(`city is:`, city);

		let county = event.target.County.value;
    console.log(`county is:`, county);

    let state = event.target.State.value;
    console.log(`state is:`, state);

		let province = event.target.Province.value;
    console.log(`province is:`, province);

		let country = event.target.Country.value;
    console.log(`country is:`, country);

		let postalCode = event.target.PostalCode.value;
    console.log(`postalCode is:`, postalCode);

		let markets = Array.from(event.target.Markets.selectedOptions, option => option.value);
    console.log(`markets is:`, markets);

		// if (!phone) {
		// 	this.setState({ status: "Phone Number is Required." })
		// } else {
		// 	this.setState({
		// 		modal5: !this.state.modal5
		// 	})
		// 	this.createDealer(name, contactName, phone, email, address, address2, city, county, state, province, country, postalCode, markets);
		// }

		this.setState({
			modal5: !this.state.modal5
		})
		this.createDealer(name, contactName, phone, email, address, address2, city, county, state, province, country, postalCode, markets);

	}

  createDealer = async (name, contactName, phone, email, address, address2, city, county, state, province, country, postalCode, markets) => {

		let createDealerBody = {
			Name: name,
			ContactName: contactName,
			Phone: phone,
			Email: email,
			Address: address,
			Address2: address2,
			City: city,
			County: county,
			State: state,
			Province: province,
			Country: country,
			PostalCode: postalCode
		}

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-dealer-item";

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: this.state.currentUser.jwtToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(createDealerBody)
			});

			//var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-dealer?Name="+name+"&ContactName="+contactName+"&Phone="+phone+"&Email="+email+"&Address="+address+"&Address2="+address2+"&City="+city+"&County="+county+"&State="+state+"&Province="+province+"&Country="+country+"&PostalCode="+postalCode;
			// const response = await fetch(url, {
			// 	method: 'GET',
			// 	headers: {
			// 		Authorization: this.state.currentUser.jwtToken
			// 	},
			// });

			const body = await response.json();
			console.log("create category response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				var dealerID = body.response.insertId;

				for (var i=0; i<markets.length; i++) {
					var market = markets[i];
					await this.addMarketItemForDealerItem(dealerID, market);
				}

	      this.getDealerData();
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleDealerSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let dealerID = event.target.dealerID.value;
    console.log(`dealerID is:`, dealerID);

    let name = event.target.Name.value;
    console.log(`name is:`, name);

		let contactName = event.target.ContactName.value;
    console.log(`contactName is:`, contactName);

		let phone = this.state.phoneNumber;
    //let phone = event.target.Phone.value;
    console.log(`phone is:`, phone);

    let email = event.target.Email.value;
    console.log(`email is:`, email);

		let address = event.target.Address.value;
    console.log(`address is:`, address);

		let address2 = event.target.Address2.value;
    console.log(`address2 is:`, address2);

    let city = event.target.City.value;
    console.log(`city is:`, city);

		let county = event.target.County.value;
    console.log(`county is:`, county);

    let state = event.target.State.value;
    console.log(`state is:`, state);

		let province = event.target.Province.value;
    console.log(`province is:`, province);

		let country = event.target.Country.value;
    console.log(`country is:`, country);

		let postalCode = event.target.PostalCode.value;
    console.log(`postalCode is:`, postalCode);

		let markets = Array.from(event.target.Markets.selectedOptions, option => option.value);
    console.log(`markets is:`, markets);

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.dealerData));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			Name: name,
			ContactName: contactName,
      Phone: phone,
      Email: email,
			Address: address,
			Address2: address2,
      City: city,
			County: county,
      State: state,
			Province: province,
			Country: country,
			PostalCode: postalCode,
    }

		// if (!phone) {
		// 	this.setState({ status: "Phone Number is Required." })
		// } else {
		// 	this.setState({
		// 		dealerData: newObj,
		// 		modal: !this.state.modal
		// 	})
	  //   console.log("handleDealerSubmit newObj is:", JSON.stringify(newObj[id]));
		//
		// 	phone = phone.replace("+", "%2b");
		//
		// 	//const encodedPhone = encodeURI(phone);
		// 	//console.log("handleDealerSubmit encodedPhone: " + JSON.stringify(encodedPhone));
		// 	this.updateDealer(dealerID, name, contactName, phone, email, address, address2, city, county, state, province, country, postalCode, markets);
		// }

		this.setState({
			dealerData: newObj,
			modal: !this.state.modal
		})
		console.log("handleDealerSubmit newObj is:", JSON.stringify(newObj[id]));

		//phone = phone.replace("+", "%2b");

		//const encodedPhone = encodeURI(phone);
		//console.log("handleDealerSubmit encodedPhone: " + JSON.stringify(encodedPhone));
		this.updateDealer(dealerID, name, contactName, phone, email, address, address2, city, county, state, province, country, postalCode, markets);

	}

  updateDealer = async (dealerID, name, contactName, phone, email, address, address2, city, county, state, province, country, postalCode, markets) => {

		let editDealerBody = {
			DealerID: dealerID,
			Name: name,
			ContactName: contactName,
			Phone: phone,
			Email: email,
			Address: address,
			Address2: address2,
			City: city,
			County: county,
			State: state,
			Province: province,
			Country: country,
			PostalCode: postalCode
		}

		console.log("editDealerBody: " + JSON.stringify(editDealerBody));

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-dealer-item";

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: this.state.currentUser.jwtToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(editDealerBody)
			});

			// var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-dealer?DealerID="+dealerID+"&Name="+name+"&ContactName="+contactName+"&Phone="+phone+"&Email="+email+"&Address="+address+"&Address2="+address2+"&City="+city+"&County="+county+"&State="+state+"&Province="+province+"&Country="+country+"&PostalCode="+postalCode;
			//
			// console.log("updateDealer url: " + JSON.stringify(url));
			// //console.log("updateDealer encoded: " + JSON.stringify(encoded));
			//
			// const response = await fetch(url, {
			// 	method: 'GET',
			// 	headers: {
			// 		Authorization: this.state.currentUser.jwtToken
			// 	},
			// });

			const body = await response.json();
			console.log("Update category response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				await this.deleteAllMarketItemsForDealerItem(dealerID)

				for (var i=0; i<markets.length; i++) {
					var market = markets[i];
					await this.addMarketItemForDealerItem(dealerID, market);
				}

				// products.map((product, i) => {
				// 	this.addProductItemForBOM(bomID, product);
				// });

				this.getDealerData();

			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	// getProductItemsForNote = async (obj) => {
  //
	// 	var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-product-items-for-note?NoteID="+obj.noteID;
  //
	// 	const response = await fetch(url, {
	// 		method: 'GET',
	// 		headers: {
	// 			Authorization: this.state.currentUser.jwtToken
	// 		},
	// 	});
  //
	// 	const body = await response.json();
	// 	console.log("all ProductItemForNote data: " + JSON.stringify(body));
  //
	// 	if (body.status == 'Success') {
	// 		this.setState({ productItemsForNote: body.response });
	// 	} else {
  //     //ADD ERROR MESSAGE
  //   }
  //
	// };

	// addProductItemForNote = async (noteID, productID) => {
  //
	// 	var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-product-item-for-note?NoteID="+noteID+"&ProductID="+productID;
  //
	// 	const response = await fetch(url, {
	// 		method: 'GET',
	// 		headers: {
	// 			Authorization: this.state.currentUser.jwtToken
	// 		},
	// 	});
  //
	// 	const body = await response.json();
	// 	console.log("add ProductItemForNote response: " + JSON.stringify(body));
  //
	// 	if (body.status == 'Success') {
  //
	// 	} else {
  //     //ADD ERROR MESSAGE
  //   }
  //
	// };

	// deleteAllProductItemsForNote = async (noteID) => {
  //
	// 	var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-product-items-for-note?NoteID="+noteID;
  //
	// 	const response = await fetch(url, {
	// 		method: 'GET',
	// 		headers: {
	// 			Authorization: this.state.currentUser.jwtToken
	// 		},
	// 	});
  //
	// 	const body = await response.json();
	// 	console.log("ProductItemForNote delete response: " + JSON.stringify(body));
  //
	// 	if (body.status == 'Success') {
  //
	// 	} else {
  //     //ADD ERROR MESSAGE
  //   }
  //
	// };

	getMarketItemsForDealerItem = async (obj) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-market-items-for-manage-option?ManageOptionType=2&OptionID="+obj.dealerID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all MarketItemsForDealerItem data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ marketItemsForDealerItem: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	addMarketItemForDealerItem = async (dealerID, marketID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-market-item-for-manage-option?ManageOptionType=2&OptionID="+dealerID+"&MarketID="+marketID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("add MarketItemForDealerItem response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteAllMarketItemsForDealerItem = async (dealerID) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-market-items-for-manage-option?ManageOptionType=2&OptionID="+dealerID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("MarketItemsForDealerItem delete response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteDealer = async () => {

		console.log("Delete Dealer: " + this.state.obj.Name);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-dealer?DealerID="+this.state.obj.dealerID;

    console.log("Delete note url: " + url);

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete dealer response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle2();
	      this.getDealerData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	onChangePhone = (value) => {

		// console.log("costOrPartNumberChange input id: " + event.target.id);
		console.log("onChangePhone input value: " + value);

		this.setState({
			status: "",
			phoneNumber: value,
		});

	}

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));

		if (id == 'datecreated') {
			var dateString = Moment(row[id]).format('MMM Do YYYY');
			return (
					dateString !== undefined ?
							String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}

  render() {

		var isForeignUser = false;

		if (this.state.currentUser.payload['custom:market'] && (this.state.currentUser.payload['custom:market']=="europe" || this.state.currentUser.payload['custom:market']=="aus" || this.state.currentUser.payload['custom:market']=="brazil" || this.state.currentUser.payload['custom:market']=="taiwan" || this.state.currentUser.payload['custom:market']=="colombia")) {
			isForeignUser = true;
		}

		var defaultCountryCode = "US";

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="aus") {
			defaultCountryCode = "AU";
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="europe") {
			defaultCountryCode = "GB";
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="brazil") {
			defaultCountryCode = "BR";
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="taiwan") {
			defaultCountryCode = "TW";
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="colombia") {
			defaultCountryCode = "CO";
		}

		const data = this.state.dealerData.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				dealerID: prop.id,
				MarketName: prop.marketName,
        Name: prop.Name,
				ContactName: prop.ContactName,
        Phone: prop.Phone,
        Email: prop.Email,
				Address: prop.Address,
				Address2: prop.Address2,
        City: prop.City,
				County: prop.County,
        State: prop.State,
				Province: prop.Province,
				Country: prop.Country,
				PostalCode: prop.PostalCode,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									obj: obj,
									phoneNumber: obj.Phone,
									modal: !this.state.modal,
								});
								//this.showVerification(obj);

								this.getMarketItemsForDealerItem(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal2: !this.state.modal2,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal2: !this.state.modal2,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		return (
      <Fragment>
        <Card className="card-hover">
          <CardBody>
            <Row className="mb-3">
							<Col md="2">
								{this.state.marketSelected==100 &&
									<Fade in="true">
									<h3 style={{color:'#737782'}}>ALL Dealers</h3>
									</Fade>
								}
								{this.state.marketSelected==0 &&
									<Fade in="true">
									<h3 style={{color:'#737782'}}>US Dealers</h3>
									</Fade>
								}
								{this.state.marketSelected==1 &&
									<Fade in="true">
									<h3 style={{color:'#737782'}}>Europe Dealers</h3>
									</Fade>
								}
								{this.state.marketSelected==2 &&
									<Fade in="true">
									<h3 style={{color:'#737782'}}>Australia Dealers</h3>
									</Fade>
								}
								{this.state.marketSelected==3 &&
									<Fade in="true">
									<h3 style={{color:'#737782'}}>Brazil Dealers</h3>
									</Fade>
								}
								{this.state.marketSelected==4 &&
									<Fade in="true">
									<h3 style={{color:'#737782'}}>Taiwan Dealers</h3>
									</Fade>
								}
								{this.state.marketSelected==5 &&
									<Fade in="true">
									<h3 style={{color:'#737782'}}>Colombia Dealers</h3>
									</Fade>
								}
							</Col>
              <Col md="8">
							{!isForeignUser &&
								<div className="mt-1 mb-1 pb-4">
									<ButtonGroup style={{ width: '100%' }}>
										<Button
											style={{width: '100%'}}
											color="btngray"
											onClick={() => this.onRadioBtnClick(100)}
											active={this.state.marketSelected === 100}
										>
											ALL Dealers
										</Button>
										<Button
											className="ml-1"
											style={{width: '100%'}}
											color="btngray"
											onClick={() => this.onRadioBtnClick(0)}
											active={this.state.marketSelected === 0}
										>
											US Dealers
										</Button>
										<Button
											className="ml-1"
											color="btngray"
											style={{width: '100%'}}
											onClick={() => this.onRadioBtnClick(1)}
											active={this.state.marketSelected === 1}
										>
											Europe Dealers
										</Button>
										<Button
											className="ml-1"
											color="btngray"
											style={{width: '100%'}}
											onClick={() => this.onRadioBtnClick(2)}
											active={this.state.marketSelected === 2}
										>
											Australia Dealers
										</Button>
										<Button
											className="ml-1"
											color="btngray"
											style={{width: '100%'}}
											onClick={() => this.onRadioBtnClick(3)}
											active={this.state.marketSelected === 3}
										>
											Brazil Dealers
										</Button>
										<Button
											className="ml-1"
											color="btngray"
											style={{width: '100%'}}
											onClick={() => this.onRadioBtnClick(4)}
											active={this.state.marketSelected === 4}
										>
											Taiwan Dealers
										</Button>
										<Button
											className="ml-1"
											color="btngray"
											style={{width: '100%'}}
											onClick={() => this.onRadioBtnClick(5)}
											active={this.state.marketSelected === 5}
										>
											Colombia Dealers
										</Button>
									</ButtonGroup>
								</div>
							}
              </Col>
							<Col md="2" className="text-right">
								<div>
									{
										// <ButtonDropdown isOpen={this.state.isOpen2} toggle={this.toggleDropDown2}>
										// 	<DropdownToggle color="dark" caret>
										// 		{this.state.currentItem2}
										// 	</DropdownToggle>
										// 	<DropdownMenu right>
										// 		<DropdownItem onClick={() => this.selectDropDownItem2(100, 'ALL Markets')}>ALL Markets</DropdownItem>
										// 		{this.state.marketsData.map((market, i) => (
										// 			<DropdownItem onClick={() => this.selectDropDownItem2(market.id, market.Name)}>{market.Name}</DropdownItem>
										// 		))}
										// 	</DropdownMenu>
										// </ButtonDropdown>
									}
									<Button className="float-right ml-3" color="yellow" onClick={this.toggle5}>Create Dealer</Button>
								</div>
							</Col>
            </Row>
            <ReactTable
              style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
              columns={[
								{
									Header: "Location / Market",
									accessor: "MarketName",
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
                  Header: "Dealer Name",
                  accessor: "Name",
                  style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                  Cell: row => (
                      <div style={{ textAlign: "center" }}>{row.value}</div>
                    )
                },
                {
                  Header: "Phone Number",
                  accessor: "Phone",
                  style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                  Cell: row => (
                      <div style={{ textAlign: "center" }}>{formatPhoneNumberIntl(row.value) ? formatPhoneNumberIntl(row.value) : row.value}</div>
                    )
                },
                {
                  Header: "Email",
                  accessor: "Email",
                  style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                  Cell: row => (
                      <div style={{ textAlign: "center" }}>{row.value}</div>
                    )
                },
								{
                  Header: "Address",
                  accessor: "Address",
                  style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                  Cell: row => (
                      <div style={{ textAlign: "center" }}>{row.value}</div>
                    )
                },
                {
                  Header: "City",
                  accessor: "City",
                  style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                  Cell: row => (
                      <div style={{ textAlign: "center" }}>{row.value}</div>
                    )
                },
                {
                  Header: "State | Territory",
                  accessor: "State",
                  style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                  Cell: row => (
                      <div style={{ textAlign: "center" }}>{row.value}</div>
                    )
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  minWidth: 40,
                  filterable: false
                }
              ]}
              defaultPageSize={15}
              sortable={true}
              showPaginationBottom={true}
              className="-striped -highlight"
              data={data}
              filterable
							filterAll={true}
							defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
              getTrGroupProps={(state, rowInfo, column, instance) => {
                      if (rowInfo !== undefined) {
                          return {
                              onClick: (e, handleOriginal) => {
                                console.log('It was in this row:', rowInfo)
                                console.log('with ID:', rowInfo.original.categoryID);

                                //this.goToRepairLog(rowInfo.original.repairId);
                              },
                              style: {
                                  cursor: 'pointer'
                              }
                          }
                      }}
                  }
            />
          </CardBody>
        </Card>
  			<div>
  				<Modal isOpen={this.state.modal} toggle={this.toggle}>
  					<ModalHeader toggle={this.toggle}>Edit Dealer</ModalHeader>
  					<ModalBody>
  						<Form onSubmit={this.handleDealerSubmit}>
  							<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
  							<Input type="hidden" name="dealerID" id="dealerID" defaultValue={this.state.obj.dealerID}/>
                <FormGroup>
                  <Label for="Name">Name</Label>
                  <Input type="text" name="Name" id="Name" defaultValue={this.state.obj.Name}/>
                </FormGroup>
								<FormGroup>
                  <Label for="ContactName">Contact Name</Label>
                  <Input type="text" name="ContactName" id="ContactName" defaultValue={this.state.obj.ContactName}/>
                </FormGroup>
                <FormGroup>
                  <Label for="Phone">Phone</Label>
									<PhoneInput style={{ height: "25px" }} withCountryCallingCode international countryCallingCodeEditable={false} defaultCountry={defaultCountryCode} name="Phone" id="Phone" value={this.state.phoneNumber} onChange={this.onChangePhone}/>
									{
										//<Input type="text" name="Phone" id="Phone" defaultValue={this.state.obj.Phone}/>
									}
                </FormGroup>
                <FormGroup>
                  <Label for="Email">Email</Label>
                  <Input type="text" name="Email" id="Email" defaultValue={this.state.obj.Email}/>
                </FormGroup>
								<FormGroup>
                  <Label for="Address">Street Address</Label>
                  <Input type="text" name="Address" id="Address" defaultValue={this.state.obj.Address}/>
                </FormGroup>
								<FormGroup>
                  <Label for="Address2">Street Address 2</Label>
                  <Input type="text" name="Address2" id="Address2" defaultValue={this.state.obj.Address2}/>
                </FormGroup>
                <FormGroup>
                  <Label for="City">City</Label>
                  <Input type="text" name="City" id="City" defaultValue={this.state.obj.City}/>
                </FormGroup>
								<FormGroup>
                  <Label for="County">County</Label>
                  <Input type="text" name="County" id="County" defaultValue={this.state.obj.County}/>
                </FormGroup>
                <FormGroup>
                  <Label for="State">State</Label>
                  <Input type="text" name="State" id="State" defaultValue={this.state.obj.State}/>
                </FormGroup>
								<FormGroup>
                  <Label for="Province">Province</Label>
                  <Input type="text" name="Province" id="Province" defaultValue={this.state.obj.Province}/>
                </FormGroup>
								<FormGroup>
                  <Label for="Country">Country</Label>
                  <Input type="text" name="Country" id="Country" defaultValue={this.state.obj.Country}/>
                </FormGroup>
								<FormGroup>
                  <Label for="PostalCode">PostalCode</Label>
                  <Input type="text" name="PostalCode" id="PostalCode" defaultValue={this.state.obj.PostalCode}/>
                </FormGroup>
								<Row>
									<Col md="12">
										<FormGroup>
											<Label for="Markets">Select Markets</Label>
											<Input id="Markets" multiple name="Markets" type="select" disabled={isForeignUser}>
											{this.state.marketsData.map((market, i) => {

												var marketItem = this.state.marketItemsForDealerItem.find(m => m.MarketID === market.id);

												console.log('market: ', market.id);
												console.log('marketItem: ', marketItem);

												if (marketItem) {
													return (
														<option value={market.id} selected>{market.Name}</option>
													);
												} else {
													return (
														<option value={market.id}>{market.Name}</option>
													);
												}

											})}
											</Input>
										</FormGroup>
									</Col>
								</Row>
								{this.state.status &&
									<div className={'alert alert-danger'}>{this.state.status}</div>
								}
  							<FormGroup>
  								<Button color="primary" type="submit">Save</Button>
  								<Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
  							</FormGroup>
  						</Form>
  					</ModalBody>
  				</Modal>
  				<Modal isOpen={this.state.modal2} toggle={this.toggle2}>
    				<ModalHeader toggle={this.toggle2}>Delete Dealer</ModalHeader>
    				<ModalBody>
  						<span>Are you sure you want to delete this Dealer?</span>
    				</ModalBody>
  					<ModalFooter>
  						<Button color="primary" onClick={this.deleteDealer}>Yes</Button>
  						<Button color="secondary" className="ml-1" onClick={this.toggle2}>Cancel</Button>
  					</ModalFooter>
  			  </Modal>
  				<Modal isOpen={this.state.modal5} toggle={this.toggle5}>
  					<ModalHeader toggle={this.toggle5}>Create Dealer</ModalHeader>
  					<ModalBody>
  						<Form onSubmit={this.createDealerSubmit}>
                <FormGroup>
                  <Label for="Name">Name</Label>
                  <Input type="text" name="Name" id="Name"/>
                </FormGroup>
								<FormGroup>
                  <Label for="ContactName">Contact Name</Label>
                  <Input type="text" name="ContactName" id="ContactName"/>
                </FormGroup>
                <FormGroup>
                  <Label for="Phone">Phone</Label>
									<PhoneInput style={{ height: "25px" }} withCountryCallingCode international countryCallingCodeEditable={false} defaultCountry={defaultCountryCode} name="Phone" id="Phone" value={this.state.phoneNumber} onChange={this.onChangePhone}/>
									{
										//<Input type="text" name="Phone" id="Phone"/>
									}
                </FormGroup>
                <FormGroup>
                  <Label for="Email">Email</Label>
                  <Input type="text" name="Email" id="Email"/>
                </FormGroup>
								<FormGroup>
                  <Label for="Address">Street Address</Label>
                  <Input type="text" name="Address" id="Address"/>
                </FormGroup>
								<FormGroup>
                  <Label for="Address2">Street Address 2</Label>
                  <Input type="text" name="Address2" id="Address2"/>
                </FormGroup>
                <FormGroup>
                  <Label for="City">City</Label>
                  <Input type="text" name="City" id="City"/>
                </FormGroup>
								<FormGroup>
                  <Label for="County">County</Label>
                  <Input type="text" name="County" id="County"/>
                </FormGroup>
                <FormGroup>
                  <Label for="State">State</Label>
                  <Input type="text" name="State" id="State"/>
                </FormGroup>
								<FormGroup>
                  <Label for="Province">Province</Label>
                  <Input type="text" name="Province" id="Province"/>
                </FormGroup>
								<FormGroup>
                  <Label for="Country">Country</Label>
                  <Input type="text" name="Country" id="Country"/>
                </FormGroup>
								<FormGroup>
                  <Label for="PostalCode">PostalCode</Label>
                  <Input type="text" name="PostalCode" id="PostalCode"/>
                </FormGroup>
								<Row>
									<Col md="12">
										<FormGroup>
											<Label for="Markets">Select Markets</Label>
											<Input id="Markets" multiple name="Markets" type="select" disabled={isForeignUser}>
												{this.state.marketsData.map((market, i) => {

													if (this.state.currentUser.payload['custom:market'] && (this.state.currentUser.payload['custom:market']=="europe" || this.state.currentUser.payload['custom:market']=="aus" || this.state.currentUser.payload['custom:market']=="brazil" || this.state.currentUser.payload['custom:market']=="taiwan" || this.state.currentUser.payload['custom:market']=="colombia")) {
														return (
															<option value={market.id} selected>{market.Name}</option>
														);
													} else {
														return (
															<option value={market.id}>{market.Name}</option>
														);
													}

												})}
											</Input>
										</FormGroup>
									</Col>
								</Row>
								{this.state.status &&
									<div className={'alert alert-danger'}>{this.state.status}</div>
								}
  							<FormGroup>
  								<Button color="primary" type="submit">Save</Button>
  								<Button color="secondary" className="ml-1" onClick={this.toggle5}>Cancel</Button>
  							</FormGroup>
  						</Form>
  					</ModalBody>
  				</Modal>
  			</div>
      </Fragment>
		);
	}
}

export default DealerList;
