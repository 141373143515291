import React from 'react';
import { Component, Fragment } from "react";
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner'

import {
	Nav,
	Collapse,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Label,
	Col,
	Row,
	Progress,
	Input,
	Fade,
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

//Moving router
import Dashboard from '../../../views/dashboard/dashboard';
import ActiveRepairs from '../../../views/active-repairs/active-repairs';
import CompletedRepairs from '../../../views/completed-repairs/completed-repairs';
import RepairLog from '../../../views/repair-log/repair-log';
import RepairOptionManagement from '../../../views/repair-option-management/repair-option-management';
import LiveInventoryList from '../../../views/live-inventory/live-inventory';
import InventoryManagementList from '../../../views/inventory-management/inventory-management';
import Users from '../../../views/users/users';
import ComponentStatistics from '../../../views/component-statistics/component-statistics';
import PendingCustomerApproval from '../../../views/pending-customer-approval/pending-customer-approval';
import RepairInstructions from '../../../views/repair-instructions/repair-instructions';
import AssemblyInstructions from '../../../views/assembly-instructions/assembly-instructions';
import RepairVideos from '../../../views/repair-videos/repair-videos';
import GeminiUpdate from '../../../views/gemini-update/gemini-update';
//import GeminiRemoteSupport from '../../../views/gemini-remote-support/gemini-remote-support';
import EvoRemoteSupport from '../../../views/evo-remote-support/evo-remote-support';
import GeminiDevices from '../../../views/gemini-devices/gemini-devices';

import QuotingOptionsView from '../../../views/quoting-options-view/quoting-options-view';
import RepairOptionsView from '../../../views/repair-options-view/repair-options-view';
import RepairReasonsView from '../../../views/repair-reasons-view/repair-reasons-view';
import TAMNamesView from '../../../views/tam-names-view/tam-names-view';
import CustomerNotesView from '../../../views/customer-notes-view/customer-notes-view';
import DevicesBatchUploadView from '../../../views/devices-batch-upload-view/devices-batch-upload-view';
import ProductBatchView from '../../../views/product-batch-view/product-batch-view';
import ReportsView from '../../../views/reports-view/reports-view';
import Pending from '../../../views/pending/pending';
import DealerListView from '../../../views/dealer-list-view/dealer-list-view';
import BatchShipmentDateUploadView from '../../../views/batch-shipment-date/batch-shipment-date-view';
import Calibration from '../../../views/calibration/calibration';

import { authenticationService } from '../../../jwt/_services';

import redpin from '../../../assets/images/pins/red_pin.png';
import greenpin from '../../../assets/images/pins/green_pin.png';

const mapStateToProps = state => ({
	...state
});

var testCount = 0;

const timer = ms => new Promise(res => setTimeout(res, ms))

class Sidebar extends React.Component {

	intervalID = 0;
	intervalID2 = 1;

	constructor(props) {
		super(props);
		this.expandLogo = this.expandLogo.bind(this);
		this.activeRoute.bind(this);

		const currentUser = authenticationService.currentUserValue;
		console.log("currentUser sidebar: "+JSON.stringify(currentUser));

		this.state = {
			authentication: this.activeRoute("/authentication") !== "" ? true : false,
			uicomponents: this.activeRoute("/ui-components") !== "" ? true : false,
			samplepages: this.activeRoute("/sample-pages") !== "" ? true : false,
			dashboardpages: this.activeRoute("/dashboards") !== "" ? true : false,
			iconsPages: this.activeRoute("/icons") !== "" ? true : false,
			formlayoutPages: this.activeRoute("/form-layouts") !== "" ? true : false,
			formpickerPages: this.activeRoute("/form-pickers") !== "" ? true : false,
			activeSubMenu: [],
			currentUser,
			modal: false,
			modal2: false,
			ThemeRoutes: [],
			currentDeviceToCheck: "",
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			deviceListOnline: [],
			statusSubject: "Failed",
			statusMessage: "Failed to send message.",
			responsePending: false,

		};

		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		console.log("MY sidebar deviceList: "+JSON.stringify(this.state.deviceList));

	}

	componentDidMount() {

		this.generateThemeRoutes();

	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	toggle2() {
	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);
		clearInterval(this.intervalID2);

		if (this.state.client && this.state.client.isConnected()) {
			this.state.client.disconnect();
		}

	}

	generateThemeRoutes() {

		const currentUser = authenticationService.currentUserValue;
		console.log("currentUser router: "+JSON.stringify(currentUser));

		var ThemeRoutes = [
			{
				collapse: false,
				open: false,
				name: "Pending",
				path: "/pending",
				state: "pending",
				icon: "mdi mdi-view-dashboard",
				component: Pending,
				child: [

				]
			},
			{ path: '/', pathTo: '/pending', name: 'Pending', redirect: true }

		];

		if ((currentUser.payload['custom:role'] && currentUser.payload['custom:role']>1)) {
			ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "Dashboard",
					path: "/dashboard",
					state: "dashboard",
					icon: "mdi mdi-view-dashboard",
					component: Dashboard,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Component Statistics",
					path: "/component-statistics",
					state: "component-statistics",
					icon: "mdi mdi-chart-bar",
					component: ComponentStatistics,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/repair-reasons",
							name: 'Repair Reasons',
							state: "repair-reasons",
							icon: 'mdi mdi-wrench',
							component: RepairReasonsView
						},
						{
							path: "/repair-options",
							name: 'Repair Options',
							state: "repair-options",
							icon: 'mdi mdi-chart-pie',
							component: RepairOptionsView
						},
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
						{
							path: "/inventory-management",
							name: 'Inventory Management',
							state: "inventory-management",
							icon: 'mdi mdi-sort-numeric',
							component: InventoryManagementList
						},
						{
							path: "/quoting-options",
							name: 'Quoting Options',
							state: "quoting-options",
							icon: 'mdi mdi-cash-usd',
							component: QuotingOptionsView
						},
						{
							path: "/customer-notes",
							name: 'Customer Notes',
							state: "customer-notes",
							icon: 'mdi mdi-note-text',
							component: CustomerNotesView
						},
						{
							path: "/product-batch-management",
							name: 'Product Batch Management',
							state: "product-batch-management",
							icon: 'mdi mdi-note-text',
							component: ProductBatchView
						},
						{
							path: "/reports",
							name: 'Reports',
							state: "reports",
							icon: 'mdi mdi-chart-pie',
							component: ReportsView
						},
						{
							path: "/dealers",
							name: 'Dealers',
							state: "dealers",
							icon: 'mdi mdi-sitemap',
							component: DealerListView
						},
					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/calibration",
							name: 'Calibration',
							state: "calibration",
							icon: 'mdi mdi-chart-bar',
							component: Calibration
						},
						{
							path: "/repair-instructions",
							name: 'Repair Instructions',
							state: "repair-instructions",
							icon: 'mdi mdi-wrench',
							component: RepairInstructions
						},
						{
							path: "/assembly-instructions",
							name: 'Assembly Instructions',
							state: "assembly-instructions",
							icon: 'mdi mdi-wrench',
							component: AssemblyInstructions
						},
						{
							path: "/repair-videos",
							name: 'Repair Videos',
							state: "repair-videos",
							icon: 'mdi mdi-wrench',
							component: RepairVideos
						},
						{
							path: "/gemini-update",
							name: 'Gemini Update',
							state: "gemini-update",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-serials",
							name: 'Gemini Serials',
							state: "gemini-serials",
							icon: 'mdi mdi-wrench',
							component: GeminiDevices
						},
						{
							path: "/gemini-remote-support",
							name: 'Gemini Remote Support',
							state: "gemini-remote-support",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-batch-upload",
							name: 'Gemini SN Batch Upload',
							state: "gemini-batch-upload",
							icon: 'mdi mdi-wrench',
							component: DevicesBatchUploadView
						},
						{
							path: "/batch-shipment-date-upload",
							name: "G2 Shipment Date Upload",
							state: "batch-shipment-date-upload",
							icon: "mdi mdi-library-books",
							component: BatchShipmentDateUploadView,
						},
					]
				},
				{
					collapse: false,
					open: false,
					path: "/users",
					name: "Users",
					state: "Users",
					icon: "mdi mdi-account-multiple",
					component: Users,
					child: [

					]
				},
				{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

			];

		}

		if ((currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1)) {
			ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Component Statistics",
					path: "/component-statistics",
					state: "component-statistics",
					icon: "mdi mdi-chart-bar",
					component: ComponentStatistics,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
					]
				},
				{
					collapse: false,
					open: false,
					name: "Live Inventory",
					path: "/live-inventory",
					state: "live-inventory",
					icon: "mdi mdi-counter",
					component: LiveInventoryList,
					child: [

					]
				},
				{ path: '/', pathTo: '/active-repairs', name: 'Active Repairs', redirect: true }

			];
		}

		if (currentUser.payload['custom:market'] && currentUser.payload['custom:market']=="us-azena" && currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1) {
			ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "Dashboard",
					path: "/dashboard",
					state: "dashboard",
					icon: "mdi mdi-view-dashboard",
					component: Dashboard,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Component Statistics",
					path: "/component-statistics",
					state: "component-statistics",
					icon: "mdi mdi-chart-bar",
					component: ComponentStatistics,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/repair-reasons",
							name: 'Repair Reasons',
							state: "repair-reasons",
							icon: 'mdi mdi-wrench',
							component: RepairReasonsView
						},
						{
							path: "/repair-options",
							name: 'Repair Options',
							state: "repair-options",
							icon: 'mdi mdi-chart-pie',
							component: RepairOptionsView
						},
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
						{
							path: "/inventory-management",
							name: 'Inventory Management',
							state: "inventory-management",
							icon: 'mdi mdi-sort-numeric',
							component: InventoryManagementList
						},
						{
							path: "/quoting-options",
							name: 'Quoting Options',
							state: "quoting-options",
							icon: 'mdi mdi-cash-usd',
							component: QuotingOptionsView
						},
						{
							path: "/customer-notes",
							name: 'Customer Notes',
							state: "customer-notes",
							icon: 'mdi mdi-note-text',
							component: CustomerNotesView
						},
						{
							path: "/dealers",
							name: 'Dealers',
							state: "dealers",
							icon: 'mdi mdi-sitemap',
							component: DealerListView
						},
					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/calibration",
							name: 'Calibration',
							state: "calibration",
							icon: 'mdi mdi-chart-bar',
							component: Calibration
						},
						{
							path: "/repair-instructions",
							name: 'Repair Instructions',
							state: "repair-instructions",
							icon: 'mdi mdi-wrench',
							component: RepairInstructions
						},
						{
							path: "/assembly-instructions",
							name: 'Assembly Instructions',
							state: "assembly-instructions",
							icon: 'mdi mdi-wrench',
							component: AssemblyInstructions
						},
						{
							path: "/repair-videos",
							name: 'Repair Videos',
							state: "repair-videos",
							icon: 'mdi mdi-wrench',
							component: RepairVideos
						},
						{
							path: "/gemini-update",
							name: 'Gemini Update',
							state: "gemini-update",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-serials",
							name: 'Gemini Serials',
							state: "gemini-serials",
							icon: 'mdi mdi-wrench',
							component: GeminiDevices
						},
						{
							path: "/gemini-remote-support",
							name: 'Gemini Remote Support',
							state: "gemini-remote-support",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-batch-upload",
							name: 'Gemini SN Batch Upload',
							state: "gemini-batch-upload",
							icon: 'mdi mdi-wrench',
							component: DevicesBatchUploadView
						},
						{
							path: "/batch-shipment-date-upload",
							name: "G2 Shipment Date Upload",
							state: "batch-shipment-date-upload",
							icon: "mdi mdi-library-books",
							component: BatchShipmentDateUploadView,
						},
					]
				},
				{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

			];
		}

		if (currentUser.payload['custom:market'] && currentUser.payload['custom:market']=="us" && currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1) {
			ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Pending Customer Approval",
					path: "/pending-customer-approval",
					state: "pending-customer-approval",
					icon: "mdi mdi-timer",
					component: PendingCustomerApproval,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/gemini-update",
							name: 'Gemini Update',
							state: "gemini-update",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-remote-support",
							name: 'Gemini Remote Support',
							state: "gemini-remote-support",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
					]
				},
				{ path: '/', pathTo: '/active-repairs', name: 'Active Repairs', redirect: true }

			];
		}

		if (currentUser.payload['custom:market'] && currentUser.payload['custom:market']=="europe" && currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1) {
			var ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "Dashboard",
					path: "/dashboard",
					state: "dashboard",
					icon: "mdi mdi-view-dashboard",
					component: Dashboard,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
						{
							path: "/quoting-options",
							name: 'Quoting Options',
							state: "quoting-options",
							icon: 'mdi mdi-cash-usd',
							component: QuotingOptionsView
						},
						{
							path: "/customer-notes",
							name: 'Customer Notes',
							state: "customer-notes",
							icon: 'mdi mdi-note-text',
							component: CustomerNotesView
						},
						{
							path: "/dealers",
							name: 'Dealers',
							state: "dealers",
							icon: 'mdi mdi-sitemap',
							component: DealerListView
						},
					]
				},
				{
					collapse: false,
					open: false,
					name: "Live Inventory",
					path: "/live-inventory",
					state: "live-inventory",
					icon: "mdi mdi-counter",
					component: LiveInventoryList,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/repair-instructions",
							name: 'Repair Instructions',
							state: "repair-instructions",
							icon: 'mdi mdi-wrench',
							component: RepairInstructions
						},
						{
							path: "/assembly-instructions",
							name: 'Assembly Instructions',
							state: "assembly-instructions",
							icon: 'mdi mdi-wrench',
							component: AssemblyInstructions
						},
						{
							path: "/repair-videos",
							name: 'Repair Videos',
							state: "repair-videos",
							icon: 'mdi mdi-wrench',
							component: RepairVideos
						},
						{
							path: "/gemini-update",
							name: 'Gemini Update',
							state: "gemini-update",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-serials",
							name: 'Gemini Serials',
							state: "gemini-serials",
							icon: 'mdi mdi-wrench',
							component: GeminiDevices
						},
						{
							path: "/gemini-remote-support",
							name: 'Gemini Remote Support',
							state: "gemini-remote-support",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-batch-upload",
							name: 'Gemini SN Batch Upload',
							state: "gemini-batch-upload",
							icon: 'mdi mdi-wrench',
							component: DevicesBatchUploadView
						},
						{
							path: "/batch-shipment-date-upload",
							name: "G2 Shipment Date Upload",
							state: "batch-shipment-date-upload",
							icon: "mdi mdi-library-books",
							component: BatchShipmentDateUploadView,
						},
					]
				},
				{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

			];
		}

		if (currentUser.payload['custom:market'] && currentUser.payload['custom:market']=="aus" && currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1) {
			var ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "Dashboard",
					path: "/dashboard",
					state: "dashboard",
					icon: "mdi mdi-view-dashboard",
					component: Dashboard,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
						{
							path: "/quoting-options",
							name: 'Quoting Options',
							state: "quoting-options",
							icon: 'mdi mdi-cash-usd',
							component: QuotingOptionsView
						},
						{
							path: "/customer-notes",
							name: 'Customer Notes',
							state: "customer-notes",
							icon: 'mdi mdi-note-text',
							component: CustomerNotesView
						},
						{
							path: "/dealers",
							name: 'Dealers',
							state: "dealers",
							icon: 'mdi mdi-sitemap',
							component: DealerListView
						},
					]
				},
				{
					collapse: false,
					open: false,
					name: "Live Inventory",
					path: "/live-inventory",
					state: "live-inventory",
					icon: "mdi mdi-counter",
					component: LiveInventoryList,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/repair-instructions",
							name: 'Repair Instructions',
							state: "repair-instructions",
							icon: 'mdi mdi-wrench',
							component: RepairInstructions
						},
						{
							path: "/assembly-instructions",
							name: 'Assembly Instructions',
							state: "assembly-instructions",
							icon: 'mdi mdi-wrench',
							component: AssemblyInstructions
						},
						{
							path: "/repair-videos",
							name: 'Repair Videos',
							state: "repair-videos",
							icon: 'mdi mdi-wrench',
							component: RepairVideos
						},
						{
							path: "/gemini-update",
							name: 'Gemini Update',
							state: "gemini-update",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-serials",
							name: 'Gemini Serials',
							state: "gemini-serials",
							icon: 'mdi mdi-wrench',
							component: GeminiDevices
						},
						{
							path: "/gemini-remote-support",
							name: 'Gemini Remote Support',
							state: "gemini-remote-support",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
					]
				},
				{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

			];
		}

		if (currentUser.payload['custom:market'] && currentUser.payload['custom:market']=="taiwan" && currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1) {
			var ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "Dashboard",
					path: "/dashboard",
					state: "dashboard",
					icon: "mdi mdi-view-dashboard",
					component: Dashboard,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
						{
							path: "/quoting-options",
							name: 'Quoting Options',
							state: "quoting-options",
							icon: 'mdi mdi-cash-usd',
							component: QuotingOptionsView
						},
						{
							path: "/customer-notes",
							name: 'Customer Notes',
							state: "customer-notes",
							icon: 'mdi mdi-note-text',
							component: CustomerNotesView
						},
						{
							path: "/dealers",
							name: 'Dealers',
							state: "dealers",
							icon: 'mdi mdi-sitemap',
							component: DealerListView
						},
					]
				},
				{
					collapse: false,
					open: false,
					name: "Live Inventory",
					path: "/live-inventory",
					state: "live-inventory",
					icon: "mdi mdi-counter",
					component: LiveInventoryList,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/repair-instructions",
							name: 'Repair Instructions',
							state: "repair-instructions",
							icon: 'mdi mdi-wrench',
							component: RepairInstructions
						},
						{
							path: "/assembly-instructions",
							name: 'Assembly Instructions',
							state: "assembly-instructions",
							icon: 'mdi mdi-wrench',
							component: AssemblyInstructions
						},
						{
							path: "/repair-videos",
							name: 'Repair Videos',
							state: "repair-videos",
							icon: 'mdi mdi-wrench',
							component: RepairVideos
						},
						{
							path: "/gemini-update",
							name: 'Gemini Update',
							state: "gemini-update",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-serials",
							name: 'Gemini Serials',
							state: "gemini-serials",
							icon: 'mdi mdi-wrench',
							component: GeminiDevices
						},
						{
							path: "/gemini-remote-support",
							name: 'Gemini Remote Support',
							state: "gemini-remote-support",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
					]
				},
				{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

			];
		}

		if (currentUser.payload['custom:market'] && currentUser.payload['custom:market']=="colombia" && currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1) {
			var ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "Dashboard",
					path: "/dashboard",
					state: "dashboard",
					icon: "mdi mdi-view-dashboard",
					component: Dashboard,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
						{
							path: "/quoting-options",
							name: 'Quoting Options',
							state: "quoting-options",
							icon: 'mdi mdi-cash-usd',
							component: QuotingOptionsView
						},
						{
							path: "/customer-notes",
							name: 'Customer Notes',
							state: "customer-notes",
							icon: 'mdi mdi-note-text',
							component: CustomerNotesView
						},
						{
							path: "/dealers",
							name: 'Dealers',
							state: "dealers",
							icon: 'mdi mdi-sitemap',
							component: DealerListView
						},
					]
				},
				{
					collapse: false,
					open: false,
					name: "Live Inventory",
					path: "/live-inventory",
					state: "live-inventory",
					icon: "mdi mdi-counter",
					component: LiveInventoryList,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/repair-instructions",
							name: 'Repair Instructions',
							state: "repair-instructions",
							icon: 'mdi mdi-wrench',
							component: RepairInstructions
						},
						{
							path: "/assembly-instructions",
							name: 'Assembly Instructions',
							state: "assembly-instructions",
							icon: 'mdi mdi-wrench',
							component: AssemblyInstructions
						},
						{
							path: "/repair-videos",
							name: 'Repair Videos',
							state: "repair-videos",
							icon: 'mdi mdi-wrench',
							component: RepairVideos
						},
						{
							path: "/gemini-update",
							name: 'Gemini Update',
							state: "gemini-update",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-serials",
							name: 'Gemini Serials',
							state: "gemini-serials",
							icon: 'mdi mdi-wrench',
							component: GeminiDevices
						},
						{
							path: "/gemini-remote-support",
							name: 'Gemini Remote Support',
							state: "gemini-remote-support",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
					]
				},
				{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

			];
		}

		if (currentUser.payload['custom:market'] && currentUser.payload['custom:market']=="brazil" && currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1) {
			var ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "Dashboard",
					path: "/dashboard",
					state: "dashboard",
					icon: "mdi mdi-view-dashboard",
					component: Dashboard,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "New Repair",
					path: "/new-repair-log/",
					state: "new-repair-log",
					icon: "mdi mdi-note-plus",
					component: RepairLog,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Active Repairs",
					path: "/active-repairs",
					state: "active-repairs",
					icon: "mdi mdi-checkbox-multiple-blank-outline",
					component: ActiveRepairs,
					child: [

					]
				},
				{
					collapse: false,
					open: false,
					name: "Completed Repairs",
					path: "/completed-repairs",
					state: "completed-repairs",
					icon: "mdi mdi-checkbox-multiple-marked",
					component: CompletedRepairs,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Manage Repair Options",
					state: "repair-option-management",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/tam-names",
							name: 'TAM Names',
							state: "tam-names",
							icon: 'mdi mdi-rename-box',
							component: TAMNamesView
						},
						{
							path: "/quoting-options",
							name: 'Quoting Options',
							state: "quoting-options",
							icon: 'mdi mdi-cash-usd',
							component: QuotingOptionsView
						},
						{
							path: "/customer-notes",
							name: 'Customer Notes',
							state: "customer-notes",
							icon: 'mdi mdi-note-text',
							component: CustomerNotesView
						},
						{
							path: "/dealers",
							name: 'Dealers',
							state: "dealers",
							icon: 'mdi mdi-sitemap',
							component: DealerListView
						},
					]
				},
				{
					collapse: false,
					open: false,
					name: "Live Inventory",
					path: "/live-inventory",
					state: "live-inventory",
					icon: "mdi mdi-counter",
					component: LiveInventoryList,
					child: [

					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-settings-box",
					child:  [
						{
							path: "/repair-instructions",
							name: 'Repair Instructions',
							state: "repair-instructions",
							icon: 'mdi mdi-wrench',
							component: RepairInstructions
						},
						{
							path: "/assembly-instructions",
							name: 'Assembly Instructions',
							state: "assembly-instructions",
							icon: 'mdi mdi-wrench',
							component: AssemblyInstructions
						},
						{
							path: "/repair-videos",
							name: 'Repair Videos',
							state: "repair-videos",
							icon: 'mdi mdi-wrench',
							component: RepairVideos
						},
						{
							path: "/gemini-update",
							name: 'Gemini Update',
							state: "gemini-update",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
						{
							path: "/gemini-serials",
							name: 'Gemini Serials',
							state: "gemini-serials",
							icon: 'mdi mdi-wrench',
							component: GeminiDevices
						},
						{
							path: "/gemini-remote-support",
							name: 'Gemini Remote Support',
							state: "gemini-remote-support",
							icon: 'mdi mdi-wrench',
							component: GeminiUpdate
						},
					]
				},
				{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

			];
		}

		this.setState({ ThemeRoutes });
	}


	/*--------------------------------------------------------------------------------*/
	/*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
	/*--------------------------------------------------------------------------------*/
	expandLogo() {
		document.getElementById("logobg").classList.toggle("expand-logo");
	}

	updateExpanded(seconddd, propstate) {

		this.setState(seconddd);

		if (this.state.activeSubMenu.length > 0) {
			for ( var i = 0; i < this.state.activeSubMenu.length; i++ ) {
				if (this.state.activeSubMenu[i].name != propstate) {
					let submenu = {};
					submenu[this.state.activeSubMenu[i].name] = false;
					this.setState(submenu);
				}
			}
		}
	}
	/*--------------------------------------------------------------------------------*/
	/*Verifies if routeName is the one active (in browser input)                      */
	/*--------------------------------------------------------------------------------*/
	activeRoute(routeName) { return this.props.location.pathname.indexOf(routeName) > -1 ? 'selected' : ''; }


	submitRequest = (event) => {
		event.preventDefault();

		this.setState({ responsePending: true });

		console.log("submitRequest");
		let serialNumber = event.target.serialnumber.value;
    console.log(`serialNumber is:`, serialNumber);

		this.getDeviceToken(serialNumber);
	}

	getDeviceToken = async (serialNumber) => {

		var url = "https://ww7drce55l.execute-api.us-west-2.amazonaws.com/v1/get-keys?SerialNumber="+serialNumber;

		console.log("getDeviceToken URL: " + url);
		console.log("getDeviceToken Authorization: " + JSON.stringify(this.state.currentUser.jwtToken));

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("getDeviceToken response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				navigator.clipboard.writeText(JSON.stringify(body));

				this.toggle();
				this.setState({
					statusSubject: "Success!",
					statusMessage: "Successfully Retrieved Key and Copied to Your Clipboard!"
				})

			} else {

				this.toggle();
				this.setState({
					statusSubject: "FAILED!",
					statusMessage: "Failed to get Key."
				})

			}

			this.setState({ responsePending: false });

			this.toggle2();

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	goToSerialPage(){
		//window.location.href="https://geminiupdate.com/serial.php";

		window.open(
		  "https://geminiupdate.com/serial.php",
		  '_blank'
		);
	}

	goToUpdatePage(){
		//window.location.href="https://geminiupdate.com/serial.php";

		window.open(
		  "http://www.geminiupdate.com/",
		  '_blank'
		);
	}

	goToRemoteSupportPage(){
		//window.location.href="https://geminiupdate.com/serial.php";

		window.open(
		  "http://www.geminiupdate.com/remotesupport.html",
		  '_blank'
		);
	}

	render() {
		return (
			<aside className="left-sidebar" id="sidebarbg" data-sidebarbg={this.props.settings.activeSidebarBg} onMouseEnter={this.expandLogo} onMouseLeave={this.expandLogo}>
				<div className="scroll-sidebar">
					<PerfectScrollbar className="sidebar-nav">
						{/*--------------------------------------------------------------------------------*/}
						{/* Sidebar Menus will go here                                                */}
						{/*--------------------------------------------------------------------------------*/}
						<Nav id="sidebarnav">
							{this.state.ThemeRoutes.map((prop, key) => {
								if (prop.redirect) {
									return null;
								}
								else if (prop.navlabel) {
									return (
										<li className="nav-small-cap" key={key}>
											<i className={prop.icon}></i>
											<span className="hide-menu">{prop.name}</span>
										</li>
									);
								}
								else if (prop.collapse) {
									let firstdd = {};
									firstdd[prop["state"]] = !this.state[prop.state];
									//console.log("this.state[prop.state]: "+JSON.stringify(!this.state[prop.state]));
									//console.log("firstdd: "+JSON.stringify(firstdd));
									return (
										/*--------------------------------------------------------------------------------*/
										/* Menus wiil be goes here                                                        */
										/*--------------------------------------------------------------------------------*/
										<li className={this.activeRoute(prop.path) + ' sidebar-item'} key={key}>
											<span data-toggle="collapse" className="sidebar-link has-arrow" aria-expanded={this.state[prop.state]} onClick={() => this.setState(firstdd)} >
												<i className={prop.icon} />
												<span className="hide-menu">{prop.name}</span>
											</span>
											{/*--------------------------------------------------------------------------------*/}
											{/* Sub-Menus wiil be goes here                                                    */}
											{/*--------------------------------------------------------------------------------*/}
											<Collapse isOpen={this.state[prop.state]}>
												<ul className="first-level">
													{prop.child.map((prop, key) => {
														if (prop.redirect) return null;
														if (prop.collapse) {
															let seconddd = {};
															seconddd[prop["state"]] = !this.state[prop.state];
															//console.log("this.state[prop.state]: "+JSON.stringify(!this.state[prop.state]));
															//console.log("seconddd: "+JSON.stringify(seconddd));

															var item = {
																name: prop["state"],
																isOpen: !this.state[prop.state],
															};

															if (this.state.activeSubMenu.length > 0) {
																let exists = false;
																for ( var i = 0; i < this.state.activeSubMenu.length; i++ ) {
																	if (this.state.activeSubMenu[i].item == prop["state"]) {
																		exists = true;
																	}
																}
																if (!exists) {
																	this.state.activeSubMenu.push(item)
																}
															} else {
																this.state.activeSubMenu.push(item)
															}

															//console.log("activeSubMenu: "+JSON.stringify(this.state.activeSubMenu));
															return (
																<li className={this.activeRoute(prop.path) + ' sidebar-item'} key={key} >
																	<span data-toggle="collapse" className="sidebar-link has-arrow" aria-expanded={this.state[prop.state]} onClick={() => this.updateExpanded(seconddd, prop["state"])} >
																		<i className={prop.icon} />
																		{prop.online ? (
																			<Fragment>
																			<img className="mr-2" src={greenpin} width="20" height="20"/>
																			</Fragment>
																		) : (
																			<Fragment>
																			<img className="mr-2" src={redpin} width="20" height="20"/>
																			</Fragment>
																		)}
																		<span className="hide-menu">{prop.name}</span>
																	</span>
																	{/*--------------------------------------------------------------------------------*/}
																	{/* Sub-Menus wiil be goes here                                                    */}
																	{/*--------------------------------------------------------------------------------*/}
																	<Collapse isOpen={this.state[prop.state]}>
																		<ul className="second-level">
																			{prop.subchild.map((prop, key) => {
																				if (prop.redirect) return null;
																				return (
																					<li className={this.activeRoute(prop.path) + ' sidebar-item'} key={key} >
																						<NavLink to={prop.path} activeClassName="active" className="sidebar-link">
																							<i className={prop.icon} />
																							<span className="hide-menu">{prop.name}</span>
																						</NavLink>
																					</li>
																				);
																			})}
																		</ul>
																	</Collapse>
																</li>
															);
														}

														// if (prop.path=="/gemini-serial") {
														// 	return (
														// 		/*--------------------------------------------------------------------------------*/
														// 		/* Adding Sidebar Item                                                            */
														// 		/*--------------------------------------------------------------------------------*/
														// 		<li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
														// 			<NavLink to={prop.path} className="sidebar-link" activeClassName="active" onClick={() => this.goToSerialPage()}>
														// 				<i className={prop.icon} />
														// 				<span className="hide-menu">{prop.name}</span>
														// 			</NavLink>
														// 		</li>
														// 	);
														// } else

														if (prop.path=="/gemini-update") {
															return (
																/*--------------------------------------------------------------------------------*/
																/* Adding Sidebar Item                                                            */
																/*--------------------------------------------------------------------------------*/
																<li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
																	<NavLink to={prop.path} className="sidebar-link" activeClassName="active" onClick={() => this.goToUpdatePage()}>
																		<i className={prop.icon} />
																		<span className="hide-menu">{prop.name}</span>
																	</NavLink>
																</li>
															);
														} else if (prop.path=="/gemini-remote-support") {
															return (
																/*--------------------------------------------------------------------------------*/
																/* Adding Sidebar Item                                                            */
																/*--------------------------------------------------------------------------------*/
																<li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
																	<NavLink to={prop.path} className="sidebar-link" activeClassName="active" onClick={() => this.goToRemoteSupportPage()}>
																		<i className={prop.icon} />
																		<span className="hide-menu">{prop.name}</span>
																	</NavLink>
																</li>
															);
														} else {
															return (
																/*--------------------------------------------------------------------------------*/
																/* Adding Sidebar Item                                                            */
																/*--------------------------------------------------------------------------------*/
																<li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
																	<NavLink to={prop.path} className="sidebar-link" activeClassName="active">
																		<i className={prop.icon} />
																		<span className="hide-menu">{prop.name}</span>
																	</NavLink>
																</li>
															);
														}
													})}
												</ul>
											</Collapse>
										</li>
									);
								}
								else {

									return (
										/*--------------------------------------------------------------------------------*/
										/* Adding Sidebar Item                                                            */
										/*--------------------------------------------------------------------------------*/
										<li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
											<NavLink to={prop.path} className="sidebar-link" activeClassName="active">
												<i className={prop.icon} />
												<span className="hide-menu">{prop.name}</span>
											</NavLink>
										</li>
									);

								}
							})}
							{this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role'] > 0 &&
								<li className="sidebar-item" key="1000000">
									<NavLink to="#" className="sidebar-link" activeClassName="active" onClick={() => {this.toggle()}}>
										<i className="mdi mdi-memory" />
										<span className="hide-menu">Retrieve Device Token</span>
									</NavLink>
								</li>
							}
						</Nav>
					</PerfectScrollbar>
				</div>
				<div>
					<Modal isOpen={this.state.modal} toggle={this.toggle}>
						<ModalHeader toggle={this.toggle}>Encryption Key</ModalHeader>
						<Form onSubmit={this.submitRequest}>
						<ModalBody>
							{this.state.responsePending ? (
								<Fragment>
									<Fade in="true">
									<Row className="justify-content-center">
										<Loader type="ThreeDots" color="#48b203" height={100} width={100}/>
									</Row>
									</Fade>
								</Fragment>
							) : (
								<Fragment>
									<Fade in="true">
									<Row>
										<Col xs="12" md="12" lg="12" className="text-left pb-3">
											<h4>Retrieve Token Key For a Device.</h4>
										</Col>
									</Row>
									<FormGroup>
										<Label for="subject">Serial Number:</Label>
										<Input type="text" name="serialnumber" id="serialnumber"/>
									</FormGroup>
									</Fade>
								</Fragment>
							)}
						</ModalBody>
						<ModalFooter>
							<Button color="primary" className="ml-1" onClick={this.toggle} disabled={this.state.responsePending}>Cancel</Button>
							<Button color="primary" type="submit" disabled={this.state.responsePending}>Retrieve</Button>
						</ModalFooter>
						</Form>
					</Modal>
				</div>
				<div>
					<Modal isOpen={this.state.modal2} toggle={this.toggle2}>
						<ModalHeader toggle={this.toggle2}>{this.state.statusSubject}</ModalHeader>
						<ModalBody>
							<span>{this.state.statusMessage}</span>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" className="ml-1" onClick={this.toggle2}>Done</Button>
						</ModalFooter>
					</Modal>
				</div>
			</aside>
		);
	}
}
export default connect(mapStateToProps)(Sidebar);
