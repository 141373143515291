import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class RepairCategories extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			repairCategories: [],
			obj: {},
			modal: false,
      modal2: false,
			modal5: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.toggle5 = this.toggle5.bind(this);

  }

	async componentDidMount() {

    this.getCategoryData();
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

  toggle2() {

	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle5() {
	  this.setState({
	    modal5: !this.state.modal5
	  });
	}

	componentWillUnmount() {

	}

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	getCategoryData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-repair-categories";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all repairCategories: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ repairCategories: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	createCategorySubmit = (event) => {
		event.preventDefault();

    let name = event.target.name.value;
    console.log(`name is:`, name);

		this.setState({
			modal5: !this.state.modal5
		})

    this.createCategory(name);
	}

  createCategory = async (name) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-repair-category?Name="+name;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("create category response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;

				this.getCategoryData();
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	handleCategorySubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let categoryID = event.target.categoryID.value;
    console.log(`categoryID is:`, categoryID);
    let name = event.target.name.value;
    console.log(`name is:`, name);

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.repairCategories));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			name: name
    }
		this.setState({
			repairCategories: newObj,
			modal: !this.state.modal
		})
    console.log("saved repairCategories is:", this.state.repairCategories[id].name);

    this.updateCategory(categoryID, name);
	}

  updateCategory = async (categoryID, name) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-repair-category?CategoryID="+categoryID+"&Name="+name;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Update category response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				//this.state.noDataForDevices = false;
			} else {
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	deleteCategory = async () => {

		console.log("Delete Category: " + this.state.obj.name);

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-repair-category?CategoryID="+this.state.obj.categoryID;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Delete category response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				this.toggle2();
				this.getCategoryData();

			} else {


			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};


  render() {

		const data = this.state.repairCategories.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				categoryID: prop.id,
        name: prop.name,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									obj: obj,
									modal: !this.state.modal,
								});
								//this.showVerification(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal2: !this.state.modal2,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal2: !this.state.modal2,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		return (
      <Fragment>
        <Card className="card-hover">
          <CardBody>
            <Row className="mb-3">
              <Col md="6">
                <h1 style={{color:'#737782'}}>Repair Reasons</h1>
              </Col>
              <Col md="6">
                <Button className="float-right" color="yellow" onClick={this.toggle5}>Create Repair Reason</Button>
              </Col>
            </Row>
            <ReactTable
              style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
              columns={[
                {
                  Header: "Name",
                  accessor: "name",
                  style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                  Cell: row => (
                      <div style={{ textAlign: "center" }}>{row.value}</div>
                    )
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  minWidth: 20,
                  filterable: false
                }
              ]}
              defaultPageSize={6}
              sortable={true}
              showPaginationBottom={true}
              className="-striped -highlight"
              data={data}
              filterable
              getTrGroupProps={(state, rowInfo, column, instance) => {
                      if (rowInfo !== undefined) {
                          return {
                              onClick: (e, handleOriginal) => {
                                console.log('It was in this row:', rowInfo)
                                console.log('with ID:', rowInfo.original.categoryID);

                                //this.goToRepairLog(rowInfo.original.repairId);
                              },
                              style: {
                                  cursor: 'pointer'
                              }
                          }
                      }}
                  }
            />
          </CardBody>
        </Card>
  			<div>
  				<Modal isOpen={this.state.modal} toggle={this.toggle}>
  					<ModalHeader toggle={this.toggle}>Edit Repair Reason</ModalHeader>
  					<ModalBody>
  						<Form onSubmit={this.handleCategorySubmit}>
  							<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
  							<Input type="hidden" name="categoryID" id="categoryID" defaultValue={this.state.obj.categoryID}/>
  							<FormGroup>
  								<Label for="firstname">Name</Label>
  								<Input type="text" name="name" id="name" defaultValue={this.state.obj.name}/>
  							</FormGroup>
  							<FormGroup>
  								<Button color="primary" type="submit">Save</Button>
  								<Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
  							</FormGroup>
  						</Form>
  					</ModalBody>
  				</Modal>
  				<Modal isOpen={this.state.modal2} toggle={this.toggle2}>
    				<ModalHeader toggle={this.toggle2}>Delete Repair Reason</ModalHeader>
    				<ModalBody>
  						<span>Are you sure you want to delete this Reason?</span>
    				</ModalBody>
  					<ModalFooter>
  						<Button color="primary" onClick={this.deleteCategory}>Yes</Button>
  						<Button color="secondary" className="ml-1" onClick={this.toggle2}>Cancel</Button>
  					</ModalFooter>
  			  </Modal>
  				<Modal isOpen={this.state.modal5} toggle={this.toggle5}>
  					<ModalHeader toggle={this.toggle5}>Create Repair Reason</ModalHeader>
  					<ModalBody>
  						<Form onSubmit={this.createCategorySubmit}>
  							<FormGroup>
  								<Label for="firstname">Name</Label>
  								<Input type="text" name="name" id="name" defaultValue={this.state.obj.name}/>
  							</FormGroup>
  							<FormGroup>
  								<Button color="primary" type="submit">Save</Button>
  								<Button color="secondary" className="ml-1" onClick={this.toggle5}>Cancel</Button>
  							</FormGroup>
  						</Form>
  					</ModalBody>
  				</Modal>
  			</div>
      </Fragment>
		);
	}
}

export default RepairCategories;
