import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import { pdf, Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	ButtonDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';
import logo from '../../../assets/images/logo.png';
import '../../../assets/calculator/css/calculator.css';
var moment = require('moment-timezone');

const pdfReport1Style = StyleSheet.create({
  page: { backgroundColor: '#FFFFFF' },
  section: { color: 'white', textAlign: 'center', margin: 30 },
  title: {
    fontSize: 25,
    marginVertical: 2,
    textAlign: 'center',
    color: '#f3c12b',
		height: 25,
		paddingTop: 5,
		marginBottom: 10,
  },
  TableCellHeaderOneStyle : {
    padding: 5,
		maxWidth: "75px",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 900,
    backgroundColor: '#ffffff',
		verticalAlign: 'middle'
  },
	TableCellHeaderTwoStyle : {
    padding: 5,
		width: "170px",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 900,
    backgroundColor: '#ffffff',
		"vertical-align": 'middle'
  },
  TableCellBodyOneStyle : {
    padding: 5,
		maxWidth: "75px",
    textAlign: "center",
    fontSize: 11,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
		"vertical-align": 'middle'
  },
	TableCellBodyTwoStyle : {
    padding: 5,
		width: "170px",
    textAlign: "center",
    fontSize: 11,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
		"vertical-align": 'middle'
  },
  TableStyle : {
    padding: 5,
  },
  Table: {
    marginVertical: 20,
    marginHorizontal: 5,
		padding: 10,
  },
  image: {
		textAlign: 'right',
		width: '20%',
		paddingRight: 5
  },
	logo: {
		width: 70,
		height: 64,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '20px',
		padding: 5
  },
	dateContainer: {
		width: '80%',
    marginTop: 15,
		marginBottom: 5,
		padding: 10
  },
	tableContainer: {
		width: '100%',
    marginTop: 0,
		marginBottom: 0,
		padding: 10
  },
	billingContainer: {
		width: '50%',
    marginTop: 0,
		marginBottom: 10,
		padding: 10
  },
	totalContainer: {
		width: '50%',
		marginTop: 0,
		marginBottom: 10,
		padding: 10,
		textAlign: "right"
  },
	addresslabel: {
		color: '#000000',
  },
	row: {
		width: "97%",
		flexDirection: 'row',
		borderBottomColor: '#000000',
		borderBottomWidth: 1,
		alignItems: 'center',
		height: 30,
		fontSize: 20,
		fontStyle: 'bold',
		marginLeft: 10
  },
  description: {
		width: '80%',
		textAlign: 'right',
		borderRightColor: '#000000',
		borderRightWidth: 1,
		color: '#000000',
		paddingRight: 8
  },
  total: {
		width: '20%',
		textAlign: 'center',
		color: '#000000',
  },
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
});

const pdfReport2Style = StyleSheet.create({
  page: { backgroundColor: '#FFFFFF' },
  section: { color: 'white', textAlign: 'center', margin: 30 },
  title: {
    fontSize: 25,
    marginVertical: 2,
    textAlign: 'center',
    color: '#f3c12b',
		height: 25,
		paddingTop: 5,
		marginBottom: 10,
  },
  TableCellHeaderOneStyle : {
    padding: 2,
		maxWidth: "70px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
		vdisplay: 'table-cell',
    verticalAlign: 'middle'
  },
	TableCellBodyOneStyle : {
    padding: 2,
		maxWidth: "70px",
    textAlign: "center",
    fontSize: 8,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
		display: 'table-cell',
    verticalAlign: 'middle'
  },
	TableCellHeaderStyle1 : {
    padding: 2,
		maxWidth: "40px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
		vdisplay: 'table-cell',
    verticalAlign: 'middle'
  },
	TableCellBodyStyle1 : {
    padding: 2,
		maxWidth: "40px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
		display: 'table-cell',
    verticalAlign: 'middle'
  },
	TableCellHeaderStyle2 : {
    padding: 2,
		maxWidth: "35px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff'
  },
	TableCellBodyStyle2 : {
    padding: 2,
		maxWidth: "35px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b"
  },
	TableCellHeaderStyle3 : {
    padding: 2,
		maxWidth: "100px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle3 : {
    padding: 2,
		maxWidth: "100px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle4 : {
    padding: 2,
		maxWidth: "25px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle4 : {
    padding: 2,
		maxWidth: "25px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle5 : {
    padding: 2,
		maxWidth: "45px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle5 : {
    padding: 2,
		maxWidth: "45px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle6 : {
    padding: 2,
		maxWidth: "40px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle6 : {
    padding: 2,
		maxWidth: "40px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle7 : {
    padding: 2,
		maxWidth: "30px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle7 : {
    padding: 2,
		maxWidth: "30px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle8 : {
    padding: 2,
		maxWidth: "25px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle8 : {
    padding: 2,
		maxWidth: "25px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle9 : {
    padding: 2,
		maxWidth: "25px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle9 : {
    padding: 2,
		maxWidth: "25px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle10 : {
    padding: 2,
		maxWidth: "35px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle10 : {
    padding: 2,
		maxWidth: "35px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle11 : {
    padding: 2,
		maxWidth: "45px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle11 : {
    padding: 2,
		maxWidth: "45px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle12 : {
    padding: 2,
		maxWidth: "100px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle12 : {
    padding: 2,
		maxWidth: "100px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle13 : {
    padding: 2,
		maxWidth: "200px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle13 : {
    padding: 2,
		maxWidth: "200px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
  TableStyle : {
    padding: 5,
  },
  Table: {
    marginVertical: 20,
    marginHorizontal: 5,
		padding: 10,
  },
  image: {
		textAlign: 'right',
		width: '20%',
		paddingRight: 5
  },
	logo: {
		width: 70,
		height: 64,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '20px',
		padding: 5
  },
	dateContainer: {
		width: '80%',
    marginTop: 15,
		marginBottom: 5,
		padding: 10
  },
	tableContainer: {
		width: '100%',
    marginTop: 0,
		marginBottom: 0,
		padding: 10
  },
	billingContainer: {
		width: '50%',
    marginTop: 0,
		marginBottom: 10,
		padding: 10
  },
	totalContainer: {
		width: '50%',
		marginTop: 0,
		marginBottom: 10,
		padding: 10,
		textAlign: "right"
  },
	addresslabel: {
		color: '#000000',
  },
	row: {
		width: "97%",
		flexDirection: 'row',
		borderBottomColor: '#000000',
		borderBottomWidth: 1,
		alignItems: 'center',
		height: 30,
		fontSize: 20,
		fontStyle: 'bold',
		marginLeft: 10
  },
  description: {
		width: '80%',
		textAlign: 'right',
		borderRightColor: '#000000',
		borderRightWidth: 1,
		color: '#000000',
		paddingRight: 8
  },
  total: {
		width: '20%',
		textAlign: 'center',
		color: '#000000',
  },
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
});

const pdfReport3Style = StyleSheet.create({
  page: { backgroundColor: '#FFFFFF' },
  section: { color: 'white', textAlign: 'center', margin: 30 },
  title: {
    fontSize: 25,
    marginVertical: 2,
    textAlign: 'center',
    color: '#f3c12b',
		height: 25,
		paddingTop: 5,
		marginBottom: 10,
  },
  TableCellHeaderOneStyle : {
    padding: 2,
		maxWidth: "70px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
		vdisplay: 'table-cell',
    verticalAlign: 'middle'
  },
	TableCellBodyOneStyle : {
    padding: 2,
		maxWidth: "70px",
    textAlign: "center",
    fontSize: 8,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
		display: 'table-cell',
    verticalAlign: 'middle'
  },
	TableCellHeaderStyle1 : {
    padding: 2,
		maxWidth: "40px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
		vdisplay: 'table-cell',
    verticalAlign: 'middle'
  },
	TableCellBodyStyle1 : {
    padding: 2,
		maxWidth: "40px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
		display: 'table-cell',
    verticalAlign: 'middle'
  },
	TableCellHeaderStyle2 : {
    padding: 2,
		maxWidth: "35px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff'
  },
	TableCellBodyStyle2 : {
    padding: 2,
		maxWidth: "35px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b"
  },
	TableCellHeaderStyle3 : {
    padding: 2,
		maxWidth: "100px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle3 : {
    padding: 2,
		maxWidth: "100px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle4 : {
    padding: 2,
		maxWidth: "25px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle4 : {
    padding: 2,
		maxWidth: "25px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle5 : {
    padding: 2,
		maxWidth: "45px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle5 : {
    padding: 2,
		maxWidth: "45px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle6 : {
    padding: 2,
		maxWidth: "40px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle6 : {
    padding: 2,
		maxWidth: "40px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle7 : {
    padding: 2,
		maxWidth: "30px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle7 : {
    padding: 2,
		maxWidth: "30px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle8 : {
    padding: 2,
		maxWidth: "25px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle8 : {
    padding: 2,
		maxWidth: "25px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle9 : {
    padding: 2,
		maxWidth: "25px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle9 : {
    padding: 2,
		maxWidth: "25px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle10 : {
    padding: 2,
		maxWidth: "35px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle10 : {
    padding: 2,
		maxWidth: "35px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle11 : {
    padding: 2,
		maxWidth: "45px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle11 : {
    padding: 2,
		maxWidth: "45px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle12 : {
    padding: 2,
		maxWidth: "100px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle12 : {
    padding: 2,
		maxWidth: "100px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle13 : {
    padding: 2,
		maxWidth: "200px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle13 : {
    padding: 2,
		maxWidth: "200px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
  TableStyle : {
    padding: 5,
  },
  Table: {
    marginVertical: 20,
    marginHorizontal: 5,
		padding: 10,
  },
  image: {
		textAlign: 'right',
		width: '20%',
		paddingRight: 5
  },
	logo: {
		width: 70,
		height: 64,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '20px',
		padding: 5
  },
	dateContainer: {
		width: '80%',
    marginTop: 15,
		marginBottom: 5,
		padding: 10
  },
	tableContainer: {
		width: '100%',
    marginTop: 0,
		marginBottom: 0,
		padding: 10
  },
	billingContainer: {
		width: '50%',
    marginTop: 0,
		marginBottom: 10,
		padding: 10
  },
	totalContainer: {
		width: '50%',
		marginTop: 0,
		marginBottom: 10,
		padding: 10,
		textAlign: "right"
  },
	totalsContainer: {
		width: '100%',
    marginTop: 0,
		marginBottom: 10,
		padding: 10
  },
	addresslabel: {
		color: '#000000',
  },
	row: {
		width: "97%",
		flexDirection: 'row',
		borderBottomColor: '#000000',
		borderBottomWidth: 1,
		alignItems: 'center',
		height: 30,
		fontSize: 20,
		fontStyle: 'bold',
		marginLeft: 10
  },
  description: {
		width: '80%',
		textAlign: 'right',
		borderRightColor: '#000000',
		borderRightWidth: 1,
		color: '#000000',
		paddingRight: 8
  },
  total: {
		width: '20%',
		textAlign: 'center',
		color: '#000000',
  },
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
});

const pdfReport4Style = StyleSheet.create({
  page: { backgroundColor: '#FFFFFF' },
  section: { color: 'white', textAlign: 'center', margin: 30 },
  title: {
    fontSize: 25,
    marginVertical: 2,
    textAlign: 'center',
    color: '#f3c12b',
		height: 25,
		paddingTop: 5,
		marginBottom: 10,
  },
  TableCellHeaderOneStyle : {
    padding: 2,
		maxWidth: "70px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
		vdisplay: 'table-cell',
    verticalAlign: 'middle'
  },
	TableCellBodyOneStyle : {
    padding: 2,
		maxWidth: "70px",
    textAlign: "center",
    fontSize: 8,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
		display: 'table-cell',
    verticalAlign: 'middle'
  },
	TableCellHeaderStyle1 : {
    padding: 2,
		maxWidth: "40px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
		vdisplay: 'table-cell',
    verticalAlign: 'middle'
  },
	TableCellBodyStyle1 : {
    padding: 2,
		maxWidth: "40px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
		display: 'table-cell',
    verticalAlign: 'middle'
  },
	TableCellHeaderStyle2 : {
    padding: 2,
		maxWidth: "35px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff'
  },
	TableCellBodyStyle2 : {
    padding: 2,
		maxWidth: "35px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b"
  },
	TableCellHeaderStyle3 : {
    padding: 2,
		maxWidth: "100px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle3 : {
    padding: 2,
		maxWidth: "100px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle4 : {
    padding: 2,
		maxWidth: "25px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle4 : {
    padding: 2,
		maxWidth: "25px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle5 : {
    padding: 2,
		maxWidth: "40px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle5 : {
    padding: 2,
		maxWidth: "40px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle6 : {
    padding: 2,
		maxWidth: "30px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle6 : {
    padding: 2,
		maxWidth: "30px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle7 : {
    padding: 2,
		maxWidth: "40px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle7 : {
    padding: 2,
		maxWidth: "40px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle8 : {
    padding: 2,
		maxWidth: "40px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle8 : {
    padding: 2,
		maxWidth: "40px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle9 : {
    padding: 2,
		maxWidth: "25px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle9 : {
    padding: 2,
		maxWidth: "25px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle10 : {
    padding: 2,
		maxWidth: "35px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle10 : {
    padding: 2,
		maxWidth: "35px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle11 : {
    padding: 2,
		maxWidth: "45px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle11 : {
    padding: 2,
		maxWidth: "45px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle12 : {
    padding: 2,
		maxWidth: "30px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle12 : {
    padding: 2,
		maxWidth: "30px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
	TableCellHeaderStyle13 : {
    padding: 2,
		maxWidth: "45px",
    textAlign: "center",
    fontSize: 8,
    fontWeight: 900,
    backgroundColor: '#ffffff',
  },
	TableCellBodyStyle13 : {
    padding: 2,
		maxWidth: "45px",
    textAlign: "center",
    fontSize: 7,
		zebra: "true",
		evenRowColor: "#ffffff",
		oddRowColor: "f3c12b",
  },
  TableStyle : {
    padding: 5,
  },
  Table: {
    marginVertical: 20,
    marginHorizontal: 5,
		padding: 10,
  },
  image: {
		textAlign: 'right',
		width: '20%',
		paddingRight: 5
  },
	logo: {
		width: 70,
		height: 64,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '20px',
		padding: 5
  },
	dateContainer: {
		width: '80%',
    marginTop: 15,
		marginBottom: 5,
		padding: 10
  },
	tableContainer: {
		width: '100%',
    marginTop: 0,
		marginBottom: 0,
		padding: 10
  },
	totalsLabelContainer: {
		width: '55%',
    marginTop: 0,
  },
	totalsValueContainer: {
		width: '45%',
		marginTop: 0,
		textAlign: "left"
  },
	billingContainer: {
		width: '50%',
    marginTop: 0,
		marginBottom: 10,
		padding: 10
  },
	totalContainer: {
		width: '50%',
		marginTop: 0,
		marginBottom: 10,
		padding: 10,
		textAlign: "right"
  },
	totalsContainer: {
		width: '100%',
    marginTop: 0,
		marginBottom: 10,
		padding: 10
  },
	addresslabel: {
		color: '#000000',
		fontSize: '12'
  },
	addresslabel2: {
		color: '#000000',
		marginTop: 10,
		fontSize: '12'
  },
	addresslabel3: {
		color: '#000000',
		marginBottom: 10,
		fontSize: '12'
  },
	row: {
		width: "97%",
		flexDirection: 'row',
		borderBottomColor: '#000000',
		borderBottomWidth: 1,
		alignItems: 'center',
		height: 30,
		fontSize: 16,
		fontStyle: 'bold',
		marginLeft: 10
  },
  description: {
		width: '80%',
		textAlign: 'right',
		borderRightColor: '#000000',
		borderRightWidth: 1,
		color: '#000000',
		paddingRight: 8
  },
  total: {
		width: '20%',
		textAlign: 'center',
		color: '#000000',
  },
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
});

class PDFReport1 extends React.Component {

  async componentDidMount() {

    //console.log("MyDocument procedure data: " + JSON.stringify(this.props.data));
	}



	render() {

				 let pageValue = 23;

				 let pages = this.props.data.length/pageValue;
				 var dataByPages = [];

				 for (var i=0; i<pages; i++) {

					 let currentPage = i+1;

					 let topLimit = currentPage*pageValue;
					 let bottomLimit = (currentPage*pageValue)-pageValue;

					 //console.log('topLimit: ' + topLimit);
					 //console.log('bottomLimit: ' + bottomLimit);

					 const page = this.props.data.filter((prop, key) => {

						 //console.log('prop: ' + prop.PartNumber);

						 if ((key>=bottomLimit) && (key<topLimit)) {
							 return prop;
						 }

					 });

					 //console.log('page: ' + currentPage + " Length: "+page.length);

					 dataByPages.push(page);
				 }

				 return (
					 <Document>
					 	 {dataByPages.map((data, i) => (
							 <Fragment>
							 {i==0 ? (
								 <Page size="A4" style={pdfReport1Style.page}>
									 <View style={pdfReport1Style.headerContainer}>
										 <View style={pdfReport1Style.dateContainer}>
										 		<Text style={pdfReport1Style.addresslabel}>Azena Medical, LLC</Text>
										 		<Text style={pdfReport1Style.addresslabel}>Inventory Valuation Report</Text>
									   </View>
										 <Image style={pdfReport1Style.logo} src={logo} />
								   </View>
									 <View style={pdfReport1Style.headerContainer}>
										 <View style={pdfReport1Style.billingContainer}>
									      <Text style={pdfReport1Style.addresslabel}>Date: {Moment().format('MM/DD/YYYY')}</Text>
												<Text style={pdfReport1Style.addresslabel}>Page: {i+1}/{dataByPages.length}</Text>
									   </View>
										 <View style={pdfReport1Style.totalContainer}>
										 		<Text style={pdfReport1Style.addresslabel}> </Text>
									      <Text style={pdfReport1Style.addresslabel}>Total: {parseFloat(this.props.total).toFixed(4)}</Text>
									   </View>
									 </View>
									 <View style={pdfReport1Style.tableContainer}>
										 <Table style={pdfReport1Style.Table} data={data} zebra={true} evenRowColor="#f3c12b" oddRowColor="#ffffff">
			                 <TableHeader style={pdfReport1Style.Table} textAlign={"center"}>
			                     <TableCell style={pdfReport1Style.TableCellHeaderOneStyle}>
			                         {"Part"+"\n"+"Number"}
			                     </TableCell>
													 <TableCell style={pdfReport1Style.TableCellHeaderOneStyle}>
			                         Product
			                     </TableCell>
													 <TableCell style={pdfReport1Style.TableCellHeaderOneStyle}>
			                         Cost
			                     </TableCell>
													 <TableCell style={pdfReport1Style.TableCellHeaderOneStyle}>
			                         Quantity
			                     </TableCell>
			                     <TableCell style={pdfReport1Style.TableCellHeaderTwoStyle}>
			                         Description
			                     </TableCell>
			                     <TableCell style={pdfReport1Style.TableCellHeaderOneStyle}>
			                         {"Extended"+"\n"+"Value"}
			                     </TableCell>
			                 </TableHeader>
			                 <TableBody textAlign={"center"} >
											 		<DataTableCell style={pdfReport1Style.TableCellBodyOneStyle} getContent={(p) => p.PartNumber}/>
													<DataTableCell style={pdfReport1Style.TableCellBodyOneStyle} getContent={(p) => p.ProductName}/>
			                    <DataTableCell style={pdfReport1Style.TableCellBodyOneStyle} getContent={(p) => p.Cost}/>
			                    <DataTableCell style={pdfReport1Style.TableCellBodyOneStyle} getContent={(p) => p.Quantity}/>
			                    <DataTableCell style={pdfReport1Style.TableCellBodyTwoStyle} getContent={(p) => p.Description}/>
			                    <DataTableCell style={pdfReport1Style.TableCellBodyOneStyle} getContent={(p) => p.ExtValue}/>
			                 </TableBody>
			               </Table>
									 </View>
								 </Page>
							 ) : (
								 <Page size="A4" style={pdfReport1Style.page}>
									 <View style={pdfReport1Style.dateContainer}>
									 		<Text style={pdfReport1Style.addresslabel}>Page: {i+1}/{dataByPages.length}</Text>
									 </View>
									 <View style={pdfReport1Style.tableContainer}>
										 <Table style={pdfReport1Style.Table} data={data} zebra={true} evenRowColor="#f3c12b" oddRowColor="#ffffff">
			                 <TableHeader style={pdfReport1Style.Table} textAlign={"center"}>
			                     <TableCell style={pdfReport1Style.TableCellHeaderOneStyle}>
			                         {"Part"+"\n"+"Number"}
			                     </TableCell>
													 <TableCell style={pdfReport1Style.TableCellHeaderOneStyle}>
			                         Product
			                     </TableCell>
													 <TableCell style={pdfReport1Style.TableCellHeaderOneStyle}>
			                         Cost
			                     </TableCell>
													 <TableCell style={pdfReport1Style.TableCellHeaderOneStyle}>
			                         Quantity
			                     </TableCell>
			                     <TableCell style={pdfReport1Style.TableCellHeaderTwoStyle}>
			                         Description
			                     </TableCell>
			                     <TableCell style={pdfReport1Style.TableCellHeaderOneStyle}>
			                         {"Extended"+"\n"+"Value"}
			                     </TableCell>
			                 </TableHeader>
			                 <TableBody textAlign={"center"} >
											 		<DataTableCell style={pdfReport1Style.TableCellBodyOneStyle} getContent={(p) => p.PartNumber}/>
													<DataTableCell style={pdfReport1Style.TableCellBodyOneStyle} getContent={(p) => p.ProductName}/>
			                    <DataTableCell style={pdfReport1Style.TableCellBodyOneStyle} getContent={(p) => p.Cost}/>
			                    <DataTableCell style={pdfReport1Style.TableCellBodyOneStyle} getContent={(p) => p.Quantity}/>
			                    <DataTableCell style={pdfReport1Style.TableCellBodyTwoStyle} getContent={(p) => p.Description}/>
			                    <DataTableCell style={pdfReport1Style.TableCellBodyOneStyle} getContent={(p) => p.ExtValue}/>
			                 </TableBody>
			               </Table>
									 </View>
									 {i==(dataByPages.length-1) &&
										 <View style={pdfReport1Style.row}>
						           <Text style={pdfReport1Style.description}>TOTAL ON HAND</Text>
						           <Text style={pdfReport1Style.total}>{parseFloat(this.props.total).toFixed(4)}</Text>
						         </View>
									 }
								 </Page>
							 )}
							 </Fragment>
						 ))}
					 </Document>
     )}
}

class PDFReport2 extends React.Component {

  async componentDidMount() {

    //console.log("MyDocument procedure data: " + JSON.stringify(this.props.data));
	}

	loadRowContent(rowArray) {

		//console.log("rowArray " + JSON.stringify(rowArray));

		var text = "";

		rowArray.map((item, i) => {
			text = text+item+"\n";
		});

		return (
			text
		);
	}

	loadSortedRowContent(id, rowArray) {

		//console.log("rowArray " + JSON.stringify(rowArray));

		var text = "";

		rowArray.map((item, i) => {

			if (id==0) {
				text = text+Moment(item.time).format('MM-DD-YYYY')+"\n";
			} else if (id==1) {
				text = text+item.purchaseQty+"\n";
			} else if (id==2) {
				text = text+item.issueQty+"\n";
			} else if (id==3) {
				text = text+item.otherQty+"\n";
			} else if (id==4) {
				text = text+item.reason+"\n";
			} else if (id==5) {
				text = text+item.name+"\n";
			}

		});

		return (
			text
		);
	}

	render() {

				 let maxRows = 35;
				 let currentRow = 1;
				 var dataByPages = [];
				 var pageData = [];
				 let rows = this.props.data.length;
				 console.log('total rows: ' + rows);

				 for (var i=0; i<rows; i++) {

					 pageData.push(this.props.data[i]);

					 console.log('currentRow: ' + currentRow);

					 if (currentRow>=maxRows) {
						 console.log('maxRows reached');
						 dataByPages.push(pageData);
						 pageData = [];
						 currentRow = 1;
					 }

					 if (this.props.data[i].allItemsSorted.length>1) {
						 currentRow = currentRow+this.props.data[i].allItemsSorted.length;
					 } else {
						 currentRow = currentRow+1;
					 }

					 if (i==(rows-1)) {
						 dataByPages.push(pageData);
					 }

				 }

				 return (
					 <Document>
					 	 {dataByPages.map((data, i) => (
							 <Fragment>
							 {i==0 ? (
								 <Page size="A4" style={pdfReport2Style.page}>
									 <View style={pdfReport2Style.headerContainer}>
										 <View style={pdfReport2Style.dateContainer}>
										 		<Text style={pdfReport2Style.addresslabel}>Azena Medical, LLC</Text>
										 		<Text style={pdfReport2Style.addresslabel}>Monthly Purchases Report</Text>
									   </View>
										 <Image style={pdfReport2Style.logo} src={logo} />
								   </View>
									 <View style={pdfReport2Style.headerContainer}>
										 <View style={pdfReport2Style.billingContainer}>
									      <Text style={pdfReport2Style.addresslabel}>Date: {Moment().format('MM/DD/YYYY')}</Text>
												<Text style={pdfReport2Style.addresslabel}>Page: {i+1}/{dataByPages.length}</Text>
									   </View>
										 <View style={pdfReport2Style.totalContainer}>
										 		<Text style={pdfReport2Style.addresslabel}> </Text>
									      <Text style={pdfReport2Style.addresslabel}>Total Purchases: {parseFloat(this.props.total).toFixed(4)}</Text>
									   </View>
									 </View>
									 <View style={pdfReport2Style.tableContainer}>
										 <Table style={pdfReport2Style.Table} data={data} zebra={true} evenRowColor="#f3c12b" oddRowColor="#ffffff">
			                 <TableHeader style={pdfReport2Style.Table} textAlign={"center"}>
			                     <TableCell style={pdfReport2Style.TableCellHeaderStyle1}>
			                         {"Part"+"\n"+"Number"}
			                     </TableCell>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle2}>
			                         Product
			                     </TableCell>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle3}>
			                         Description
			                     </TableCell>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle5}>
													     Date
													 </TableCell>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle6}>
													     {"Qty"+"\n"+"Purchase"}
													 </TableCell>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle10}>
													     Cost
													 </TableCell>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle11}>
													     {"Ext"+"\n"+"Value"}
													 </TableCell>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle12}>
													     Reason
													 </TableCell>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle13}>
													     Authorized By
													 </TableCell>
			                 </TableHeader>
			                 <TableBody textAlign={"center"} >
											 		<DataTableCell style={pdfReport2Style.TableCellBodyStyle1} getContent={(row) => row.PartNumber}/>
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle2} getContent={(row) => this.loadRowContent(row.productsArray) }/>
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle3} getContent={(row) => row.Description }/>
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle5} getContent={(row) => this.loadSortedRowContent(0, row.allItemsSorted) }/>
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle6} getContent={(row) => this.loadSortedRowContent(1, row.allItemsSorted) }/>
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle10} getContent={(row) => row.Cost }/>
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle11} getContent={(row) => row.ExtValue }/>
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle12} getContent={(row) => this.loadSortedRowContent(4, row.allItemsSorted) }/>
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle13} getContent={(row) => this.loadSortedRowContent(5, row.allItemsSorted) }/>
			                 </TableBody>
			               </Table>
									 </View>
								 </Page>
							 ) : (
								 <Page size="A4" style={pdfReport2Style.page}>
									 <View style={pdfReport2Style.dateContainer}>
									 		<Text style={pdfReport2Style.addresslabel}>Page: {i+1}/{dataByPages.length}</Text>
									 </View>
									 <View style={pdfReport2Style.tableContainer}>
										 <Table style={pdfReport2Style.Table} data={data} zebra={true} evenRowColor="#f3c12b" oddRowColor="#ffffff">
											 <TableHeader style={pdfReport2Style.Table} textAlign={"center"}>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle1}>
															 {"Part"+"\n"+"Number"}
													 </TableCell>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle2}>
															 Product
													 </TableCell>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle3}>
															 Description
													 </TableCell>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle5}>
															 Date
													 </TableCell>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle6}>
															 {"Qty"+"\n"+"Purchase"}
													 </TableCell>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle10}>
															 Cost
													 </TableCell>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle11}>
															 {"Ext"+"\n"+"Value"}
													 </TableCell>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle12}>
															 Reason
													 </TableCell>
													 <TableCell style={pdfReport2Style.TableCellHeaderStyle13}>
															 Authorized By
													 </TableCell>
											 </TableHeader>
											 <TableBody textAlign={"center"} >
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle1} getContent={(row) => row.PartNumber}/>
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle2} getContent={(row) => this.loadRowContent(row.productsArray) }/>
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle3} getContent={(row) => row.Description }/>
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle5} getContent={(row) => this.loadSortedRowContent(0, row.allItemsSorted) }/>
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle6} getContent={(row) => this.loadSortedRowContent(1, row.allItemsSorted) }/>
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle10} getContent={(row) => row.Cost }/>
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle11} getContent={(row) => row.ExtValue }/>
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle12} getContent={(row) => this.loadSortedRowContent(4, row.allItemsSorted) }/>
													<DataTableCell style={pdfReport2Style.TableCellBodyStyle13} getContent={(row) => this.loadSortedRowContent(5, row.allItemsSorted) }/>
											 </TableBody>
			               </Table>
									 </View>
									 {i==(dataByPages.length-1) &&
										 <View style={pdfReport2Style.row}>
						           <Text style={pdfReport2Style.description}>TOTAL PURCHASES</Text>
						           <Text style={pdfReport2Style.total}>{parseFloat(this.props.total).toFixed(4)}</Text>
						         </View>
									 }
								 </Page>
							 )}
							 </Fragment>
						 ))}
					 </Document>
     )}
}

class PDFReport3 extends React.Component {

  async componentDidMount() {

    //console.log("MyDocument procedure data: " + JSON.stringify(this.props.data));
	}

	loadRowContent(rowArray) {

		//console.log("rowArray " + JSON.stringify(rowArray));

		var text = "";

		rowArray.map((item, i) => {
			text = text+item+"\n";
		});

		return (
			text
		);
	}

	loadSortedRowContent(id, rowArray) {

		//console.log("rowArray " + JSON.stringify(rowArray));

		var text = "";

		rowArray.map((item, i) => {

			if (id==0) {
				text = text+Moment(item.time).format('MM-DD-YYYY')+"\n";
			} else if (id==1) {
				text = text+item.purchaseQty+"\n";
			} else if (id==2) {
				text = text+item.issueQty+"\n";
			} else if (id==3) {
				text = text+item.otherQty+"\n";
			} else if (id==4) {
				text = text+item.reason+"\n";
			} else if (id==5) {
				text = text+item.name+"\n";
			}

		});

		return (
			text
		);
	}

	render() {

				 let firstPageMaxRows = 20;
				 let maxRows = 35;
				 let currentRow = 1;
				 var dataByPages = [];
				 var pageData = [];
				 let rows = this.props.data.length;
				 console.log('total rows: ' + rows);

				 for (var i=0; i<rows; i++) {

					 pageData.push(this.props.data[i]);

					 console.log('currentRow: ' + currentRow);

					 if (dataByPages.length==0) {
						 if (currentRow>=firstPageMaxRows) {
							 console.log('maxRows reached');
							 dataByPages.push(pageData);
							 pageData = [];
							 currentRow = 1;
						 }
					 } else {
						 if (currentRow>=maxRows) {
							 console.log('maxRows reached');
							 dataByPages.push(pageData);
							 pageData = [];
							 currentRow = 1;
						 }
					 }

					 if (this.props.data[i].allItemsSorted.length>1) {
						 currentRow = currentRow+this.props.data[i].allItemsSorted.length;
					 } else {
						 currentRow = currentRow+1;
					 }

					 if (i==(rows-1)) {
						 dataByPages.push(pageData);
					 }

				 }

				 return (
					 <Document>
					 	 {dataByPages.map((data, i) => (
							 <Fragment>
							 {i==0 ? (
								 <Page size="A4" style={pdfReport3Style.page}>
									 <View style={pdfReport3Style.headerContainer}>
										 <View style={pdfReport3Style.dateContainer}>
										 		<Text style={pdfReport3Style.addresslabel}>Azena Medical, LLC</Text>
										 		<Text style={pdfReport3Style.addresslabel}>Monthly Repair Inventory Usage Report</Text>
									   </View>
										 <Image style={pdfReport3Style.logo} src={logo} />
								   </View>
									 <View style={pdfReport3Style.headerContainer}>
										 <View style={pdfReport3Style.billingContainer}>
									      <Text style={pdfReport3Style.addresslabel}>Date: {Moment().format('MM/DD/YYYY')}</Text>
												<Text style={pdfReport3Style.addresslabel}>Page: {i+1}/{dataByPages.length}</Text>
									   </View>
										 <View style={pdfReport3Style.totalContainer}>
										 		<Text style={pdfReport3Style.addresslabel}> </Text>
									   </View>
									 </View>
									 <View style={pdfReport4Style.headerContainer}>
										 <View style={pdfReport4Style.totalsContainer}>
										 		<Text style={pdfReport4Style.addresslabel}> </Text>
												<Text style={pdfReport4Style.addresslabel}>Total Repair: {parseFloat(this.props.reasonTotals.repair).toFixed(4)}</Text>
									      <Text style={pdfReport4Style.addresslabel}>Total Associated Project: {parseFloat(this.props.reasonTotals.assoProject).toFixed(4)}</Text>
												<Text style={pdfReport4Style.addresslabel}>Total R&D: {parseFloat(this.props.reasonTotals.rnd).toFixed(4)}</Text>
												<Text style={pdfReport4Style.addresslabel}>Total Purchases: {parseFloat(this.props.reasonTotals.purchase).toFixed(4)}</Text>
												<Text style={pdfReport4Style.addresslabel}>Total Shortage Adjustment: {parseFloat(this.props.reasonTotals.iAShortage).toFixed(4)}</Text>
												<Text style={pdfReport4Style.addresslabel}>Total Overage Adjustment: {parseFloat(this.props.reasonTotals.iAOverage).toFixed(4)}</Text>
												<Text style={pdfReport4Style.addresslabel}>Total Repair Kit: {parseFloat(this.props.reasonTotals.repairKit).toFixed(4)}</Text>
												<Text style={pdfReport4Style.addresslabel}>Total Other: {parseFloat(this.props.reasonTotals.other).toFixed(4)}</Text>
									   </View>
									 </View>
									 <View style={pdfReport3Style.tableContainer}>
										 <Table style={pdfReport3Style.Table} data={data} zebra={true} evenRowColor="#f3c12b" oddRowColor="#ffffff">
			                 <TableHeader style={pdfReport3Style.Table} textAlign={"center"}>
			                     <TableCell style={pdfReport3Style.TableCellHeaderStyle1}>
			                         {"Part"+"\n"+"Number"}
			                     </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle2}>
			                         Product
			                     </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle3}>
			                         Description
			                     </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle4}>
													 		 {"BOM"+"\n"+"Qty"}
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle5}>
													     Date
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle6}>
													     {"Qty"+"\n"+"Purchase"}
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle7}>
													     {"Qty"+"\n"+"Issues"}
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle8}>
													     {"Qty"+"\n"+"Other"}
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle9}>
													     {"EOM"+"\n"+"Qty"}
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle10}>
													     Cost
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle11}>
													     {"Ext"+"\n"+"Value"}
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle12}>
													     Reason
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle13}>
													     Authorized By
													 </TableCell>
			                 </TableHeader>
			                 <TableBody textAlign={"center"} >
											 		<DataTableCell style={pdfReport3Style.TableCellBodyStyle1} getContent={(row) => row.PartNumber}/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle2} getContent={(row) => this.loadRowContent(row.productsArray) }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle3} getContent={(row) => row.Description }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle4} getContent={(row) => row.PreviousQuantity }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle5} getContent={(row) => this.loadSortedRowContent(0, row.allItemsSorted) }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle6} getContent={(row) => this.loadSortedRowContent(1, row.allItemsSorted) }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle7} getContent={(row) => this.loadSortedRowContent(2, row.allItemsSorted) }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle8} getContent={(row) => this.loadSortedRowContent(3, row.allItemsSorted) }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle9} getContent={(row) => row.EOMValue }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle10} getContent={(row) => row.Cost }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle11} getContent={(row) => row.ExtValue }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle12} getContent={(row) => this.loadSortedRowContent(4, row.allItemsSorted) }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle13} getContent={(row) => this.loadSortedRowContent(5, row.allItemsSorted) }/>
			                 </TableBody>
			               </Table>
									 </View>
								 </Page>
							 ) : (
								 <Page size="A4" style={pdfReport3Style.page}>
									 <View style={pdfReport3Style.dateContainer}>
									 		<Text style={pdfReport3Style.addresslabel}>Page: {i+1}/{dataByPages.length}</Text>
									 </View>
									 <View style={pdfReport3Style.tableContainer}>
										 <Table style={pdfReport3Style.Table} data={data} zebra={true} evenRowColor="#f3c12b" oddRowColor="#ffffff">
											 <TableHeader style={pdfReport3Style.Table} textAlign={"center"}>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle1}>
															 {"Part"+"\n"+"Number"}
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle2}>
															 Product
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle3}>
															 Description
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle4}>
															 {"BOM"+"\n"+"Qty"}
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle5}>
															 Date
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle6}>
															 {"Qty"+"\n"+"Purchase"}
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle7}>
															 {"Qty"+"\n"+"Issues"}
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle8}>
															 {"Qty"+"\n"+"Other"}
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle9}>
															 {"EOM"+"\n"+"Qty"}
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle10}>
															 Cost
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle11}>
															 {"Ext"+"\n"+"Value"}
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle12}>
															 Reason
													 </TableCell>
													 <TableCell style={pdfReport3Style.TableCellHeaderStyle13}>
															 Authorized By
													 </TableCell>
											 </TableHeader>
											 <TableBody textAlign={"center"} >
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle1} getContent={(row) => row.PartNumber}/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle2} getContent={(row) => this.loadRowContent(row.productsArray) }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle3} getContent={(row) => row.Description }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle4} getContent={(row) => row.PreviousQuantity }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle5} getContent={(row) => this.loadSortedRowContent(0, row.allItemsSorted) }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle6} getContent={(row) => this.loadSortedRowContent(1, row.allItemsSorted) }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle7} getContent={(row) => this.loadSortedRowContent(2, row.allItemsSorted) }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle8} getContent={(row) => this.loadSortedRowContent(3, row.allItemsSorted) }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle9} getContent={(row) => row.EOMValue }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle10} getContent={(row) => row.Cost }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle11} getContent={(row) => row.ExtValue }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle12} getContent={(row) => this.loadSortedRowContent(4, row.allItemsSorted) }/>
													<DataTableCell style={pdfReport3Style.TableCellBodyStyle13} getContent={(row) => this.loadSortedRowContent(5, row.allItemsSorted) }/>
											 </TableBody>
			               </Table>
									 </View>
								 </Page>
							 )}
							 </Fragment>
						 ))}
					 </Document>
     )}
}

class PDFReport4 extends React.Component {

  async componentDidMount() {

	}

	loadRowContent(rowArray) {

		//console.log("rowArray " + JSON.stringify(rowArray));

		var text = "";

		rowArray.map((item, i) => {
			text = text+item+"\n";
		});

		return (
			text
		);
	}

	loadSortedRowContent(id, rowArray) {

		//console.log("rowArray " + JSON.stringify(rowArray));

		var text = "";

		rowArray.map((item, i) => {

			if (id==0) {
				text = text+Moment(item.time).format('MM-DD-YYYY')+"\n";
			} else if (id==1) {
				text = text+item.purchaseQty+"\n";
			} else if (id==2) {
				text = text+item.issueQty+"\n";
			} else if (id==3) {
				text = text+item.otherQty+"\n";
			} else if (id==4) {
				text = text+item.reason+"\n";
			} else if (id==5) {
				text = text+item.name+"\n";
			}

		});

		return (
			text
		);
	}

	render() {

				var beginningOfRangeTotalTest = 0;

				for (var i=0; i<this.props.bomData.length; i++) {

					console.log('BOM'+ i + ': PartNumber:' + JSON.stringify(this.props.bomData[i].PartNumber) + ': Quantity:' + JSON.stringify(this.props.bomData[i].Quantity) + ': Cost:' + JSON.stringify(this.props.bomData[i].Cost));

					var costNumber = this.props.bomData[i].Cost.replace("$", "");

					var bomTotal = parseFloat(this.props.bomData[i].Quantity) * parseFloat(costNumber);

					if (isNaN(bomTotal)) {
						bomTotal = 0;
					}

					beginningOfRangeTotalTest = beginningOfRangeTotalTest + bomTotal;

				}

				console.log('PDFReport4 beginningOfRangeTotalTest: ' + beginningOfRangeTotalTest);

				var endOfRangeTotalTest = 0;

				for (var i=0; i<this.props.eomData.length; i++) {

					console.log('EOM'+ i + ': PartNumber:' + JSON.stringify(this.props.eomData[i].PartNumber) + ': Quantity:' + JSON.stringify(this.props.eomData[i].Quantity) + ': Cost:' + JSON.stringify(this.props.eomData[i].Cost));

					var costNumber = this.props.eomData[i].Cost.replace("$", "");

					var eomTotal = parseFloat(this.props.eomData[i].Quantity) * parseFloat(costNumber);

					if (isNaN(eomTotal)) {
						eomTotal = 0;
					}

					endOfRangeTotalTest = endOfRangeTotalTest + eomTotal;

				}

				console.log('PDFReport4 endOfRangeTotalTest: ' + endOfRangeTotalTest);

				 var beginingOfRangeTotal = 0;
				 var outgoingTotal = 0;
				 var incomingTotal = 0;
				 var calcEndOfRangeTotal = 0;
				 var differencePositive = 0;
				 var differenceNegative = 0;
				 var actualEndOfRangeTotal = 0;

				 let maxRows = 32;
				 let currentRow = 1;
				 var dataByPages = [];
				 var pageData = [];
				 let rows = this.props.data.length;
				 console.log('total rows: ' + rows);

				 for (var i=0; i<rows; i++) {

					 console.log('PDFReport4 data eomQuantity: ' + JSON.stringify(this.props.data[i].eomQuantity));

					 var costNumber = this.props.data[i].Cost.replace("$", "");
 					 var costNumberFloat = parseFloat(costNumber);

 					 if (isNaN(costNumberFloat)) {
 						 costNumberFloat = 0;
 					 }

					 beginingOfRangeTotal = beginingOfRangeTotal + (parseFloat(this.props.data[i].bomQuantity) * parseFloat(costNumberFloat));
					 outgoingTotal = outgoingTotal + ((parseFloat(this.props.data[i].repairQty) + parseFloat(this.props.data[i].otherQtyNeg)) * parseFloat(costNumberFloat));
					 incomingTotal = incomingTotal + ((parseFloat(this.props.data[i].purchaseQty) + parseFloat(this.props.data[i].otherQtyPos)) * parseFloat(costNumberFloat));
					 calcEndOfRangeTotal = calcEndOfRangeTotal + (parseFloat(this.props.data[i].calcEOMQty) * parseFloat(costNumberFloat));
					 actualEndOfRangeTotal = actualEndOfRangeTotal + (parseFloat(this.props.data[i].eomQuantity) * parseFloat(costNumberFloat));

					 if (this.props.data[i].difference.discrepancyValue != 0) {
						 if (this.props.data[i].difference.discrepancyIsPositive) {
							 differencePositive = differencePositive + (parseFloat(this.props.data[i].difference.discrepancyValue) * parseFloat(costNumberFloat));
						 } else {
							 differenceNegative = differenceNegative + (parseFloat(this.props.data[i].difference.discrepancyValue) * parseFloat(costNumberFloat));
						 }
					 }

					 pageData.push(this.props.data[i]);

					 //console.log('currentRow: ' + currentRow);

					 if (currentRow>=maxRows) {
						 //console.log('maxRows reached');
						 dataByPages.push(pageData);
						 pageData = [];
						 currentRow = 1;
					 }

					 if (this.props.data[i].productsArray.length>1) {
						 currentRow = currentRow+this.props.data[i].productsArray.length;
					 } else {
						 currentRow = currentRow+1;
					 }

					 if (i==(rows-1)) {
						 dataByPages.push(pageData);
					 }

				 }

				 return (
					 <Document>
					 	 {dataByPages.map((data, i) => (
							 <Fragment>
							 {i==0 ? (
								 <Page size="A4" style={pdfReport4Style.page}>
									 <View style={pdfReport4Style.headerContainer}>
										 <View style={pdfReport4Style.dateContainer}>
										 		<Text style={pdfReport4Style.addresslabel}>Azena Medical, LLC</Text>
										 		<Text style={pdfReport4Style.addresslabel}>Inventory Rollforward Report</Text>
									   </View>
										 <Image style={pdfReport4Style.logo} src={logo} />
								   </View>
									 <View style={pdfReport4Style.headerContainer}>
										 <View style={pdfReport4Style.billingContainer}>
									      <Text style={pdfReport4Style.addresslabel3}>Date Range: {Moment(this.props.startDate).format('MM/DD/YYYY')} - {Moment(this.props.endDate).format('MM/DD/YYYY')}</Text>
												{
													// <View style={pdfReport4Style.headerContainer}>
			 										//  <View style={pdfReport4Style.totalsLabelContainer}>
													//  	<Text style={pdfReport4Style.addresslabel}>Beginning of Range:</Text>
													//  </View>
													//  <View style={pdfReport4Style.totalsValueContainer}>
													//  	<Text style={pdfReport4Style.addresslabel}>${parseFloat(parseFloat(beginingOfRangeTotal) + parseFloat(this.props.nonAdjTotal)).toFixed(4)}</Text>
													//  </View>
													// </View>
												}
												<View style={pdfReport4Style.headerContainer}>
		 										 <View style={pdfReport4Style.totalsLabelContainer}>
												 	<Text style={pdfReport4Style.addresslabel}>Beginning of Range:</Text>
												 </View>
												 <View style={pdfReport4Style.totalsValueContainer}>
												 	<Text style={pdfReport4Style.addresslabel}>${parseFloat(beginningOfRangeTotalTest).toFixed(4)}</Text>
												 </View>
												</View>
												<View style={pdfReport4Style.headerContainer}>
		 										 <View style={pdfReport4Style.totalsLabelContainer}>
												 	<Text style={pdfReport4Style.addresslabel}>Total Outgoing:</Text>
												 </View>
												 <View style={pdfReport4Style.totalsValueContainer}>
												 	<Text style={pdfReport4Style.addresslabel}>${parseFloat(outgoingTotal).toFixed(4)}</Text>
												 </View>
												</View>
												<View style={pdfReport4Style.headerContainer}>
		 										 <View style={pdfReport4Style.totalsLabelContainer}>
												 	<Text style={pdfReport4Style.addresslabel}>Total Incoming:</Text>
												 </View>
												 <View style={pdfReport4Style.totalsValueContainer}>
												 	<Text style={pdfReport4Style.addresslabel}>${parseFloat(incomingTotal).toFixed(4)}</Text>
												 </View>
												</View>
												{
													// <View style={pdfReport4Style.headerContainer}>
			 										//  <View style={pdfReport4Style.totalsLabelContainer}>
													//  	<Text style={pdfReport4Style.addresslabel}>Calculated End of Range:</Text>
													//  </View>
													//  <View style={pdfReport4Style.totalsValueContainer}>
													//  	<Text style={pdfReport4Style.addresslabel}>${parseFloat(parseFloat(calcEndOfRangeTotal) + parseFloat(this.props.nonAdjTotal)).toFixed(4)}</Text>
													//  </View>
													// </View>
													// <View style={pdfReport4Style.headerContainer}>
			 										//  <View style={pdfReport4Style.totalsLabelContainer}>
													//  	<Text style={pdfReport4Style.addresslabel}>Actual End of Range:</Text>
													//  </View>
													//  <View style={pdfReport4Style.totalsValueContainer}>
													//  	<Text style={pdfReport4Style.addresslabel}>${parseFloat(parseFloat(actualEndOfRangeTotal) + parseFloat(this.props.nonAdjTotal)).toFixed(4)}</Text>
													//  </View>
													// </View>
												}
												<View style={pdfReport4Style.headerContainer}>
		 										 <View style={pdfReport4Style.totalsLabelContainer}>
												 	<Text style={pdfReport4Style.addresslabel}>Calculated End of Range:</Text>
												 </View>
												 <View style={pdfReport4Style.totalsValueContainer}>
												 	<Text style={pdfReport4Style.addresslabel}>${parseFloat(parseFloat(beginningOfRangeTotalTest) + parseFloat(incomingTotal) - parseFloat(outgoingTotal)).toFixed(4)}</Text>
												 </View>
												</View>
												<View style={pdfReport4Style.headerContainer}>
		 										 <View style={pdfReport4Style.totalsLabelContainer}>
												 	<Text style={pdfReport4Style.addresslabel}>Actual End of Range:</Text>
												 </View>
												 <View style={pdfReport4Style.totalsValueContainer}>
												 	<Text style={pdfReport4Style.addresslabel}>${parseFloat(endOfRangeTotalTest).toFixed(4)}</Text>
												 </View>
												</View>
												<View style={pdfReport4Style.headerContainer}>
		 										 <View style={pdfReport4Style.totalsLabelContainer}>
												 	<Text style={pdfReport4Style.addresslabel}>Difference Positive:</Text>
												 </View>
												 <View style={pdfReport4Style.totalsValueContainer}>
												 	<Text style={pdfReport4Style.addresslabel}>${parseFloat(differencePositive).toFixed(4)}</Text>
												 </View>
												</View>
												<View style={pdfReport4Style.headerContainer}>
		 										 <View style={pdfReport4Style.totalsLabelContainer}>
												 	<Text style={pdfReport4Style.addresslabel}>Difference Negative:</Text>
												 </View>
												 <View style={pdfReport4Style.totalsValueContainer}>
												 	<Text style={pdfReport4Style.addresslabel}>${parseFloat(differenceNegative).toFixed(4)}</Text>
												 </View>
												</View>
												<Text style={pdfReport4Style.addresslabel2}>Page: {i+1}/{dataByPages.length}</Text>
									   </View>
										 <View style={pdfReport4Style.totalContainer}>
										 		<Text style={pdfReport4Style.addresslabel}> </Text>
									      <Text style={pdfReport4Style.addresslabel}>Total Extended Value: {parseFloat(endOfRangeTotalTest).toFixed(4)}</Text>
									   </View>
									 </View>
									 <View style={pdfReport4Style.tableContainer}>
										 <Table style={pdfReport4Style.Table} data={data} zebra={true} evenRowColor="#f3c12b" oddRowColor="#ffffff">
			                 <TableHeader style={pdfReport4Style.Table} textAlign={"center"}>
			                     <TableCell style={pdfReport4Style.TableCellHeaderStyle1}>
			                         {"Part"+"\n"+"Number"}
			                     </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle2}>
			                         Product
			                     </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle3}>
			                         Description
			                     </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle4}>
													 		 {"BOM"+"\n"+"Qty"}
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle5}>
													     {"Qty"+"\n"+"Purchase"}
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle6}>
													     {"Qty"+"\n"+"Repair"}
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle7}>
													     {"Qty"+"\n"+"Other (-)"}
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle8}>
													     {"Qty"+"\n"+"Other (+)"}
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle9}>
													     {"Calc EOM"+"\n"+"Qty"}
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle10}>
													     {"Act EOM"+"\n"+"Qty"}
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle11}>
													     Difference
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle12}>
													     Cost
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle13}>
													     {"Ext"+"\n"+"Value"}
													 </TableCell>
			                 </TableHeader>
			                 <TableBody textAlign={"center"} >
											 		<DataTableCell style={pdfReport4Style.TableCellBodyStyle1} getContent={(row) => row.PartNumber}/>
													<DataTableCell style={pdfReport4Style.TableCellBodyStyle2} getContent={(row) => this.loadRowContent(row.productsArray) }/>
													<DataTableCell style={pdfReport4Style.TableCellBodyStyle3} getContent={(row) => row.Description }/>
													<DataTableCell style={pdfReport4Style.TableCellBodyStyle4} getContent={(row) => row.bomQuantity }/>
													<DataTableCell style={pdfReport4Style.TableCellBodyStyle5} getContent={(row) => row.purchaseQty }/>
													<DataTableCell style={pdfReport4Style.TableCellBodyStyle6} getContent={(row) => row.repairQty }/>
													<DataTableCell style={pdfReport4Style.TableCellBodyStyle7} getContent={(row) => row.otherQtyNeg }/>
													<DataTableCell style={pdfReport4Style.TableCellBodyStyle8} getContent={(row) => row.otherQtyPos }/>
													<DataTableCell style={pdfReport4Style.TableCellBodyStyle9} getContent={(row) => row.calcEOMQty }/>
													<DataTableCell style={pdfReport4Style.TableCellBodyStyle10} getContent={(row) => row.eomQuantity }/>
													<DataTableCell style={pdfReport4Style.TableCellBodyStyle11} getContent={(row) => {

														var value = "";

														if (row.difference.discrepancyValue==0) {
															value = row.difference.discrepancyValue;
														} else {
															if (row.difference.discrepancyIsPositive) {
																value = "+"+row.difference.discrepancyValue;
															} else {
																value = "-"+row.difference.discrepancyValue;
															}
														}

														return (
															value
														);
													}}/>
													<DataTableCell style={pdfReport4Style.TableCellBodyStyle12} getContent={(row) => row.Cost }/>
													<DataTableCell style={pdfReport4Style.TableCellBodyStyle13} getContent={(row) => row.ExtValue }/>
			                 </TableBody>
			               </Table>
									 </View>
								 </Page>
							 ) : (
								 <Page size="A4" style={pdfReport4Style.page}>
									 <View style={pdfReport4Style.dateContainer}>
									 		<Text style={pdfReport4Style.addresslabel}>Page: {i+1}/{dataByPages.length}</Text>
									 </View>
									 <View style={pdfReport4Style.tableContainer}>
										 <Table style={pdfReport4Style.Table} data={data} zebra={true} evenRowColor="#f3c12b" oddRowColor="#ffffff">
											 <TableHeader style={pdfReport4Style.Table} textAlign={"center"}>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle1}>
															 {"Part"+"\n"+"Number"}
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle2}>
															 Product
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle3}>
															 Description
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle4}>
															 {"BOM"+"\n"+"Qty"}
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle5}>
															 {"Qty"+"\n"+"Purchase"}
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle6}>
															 {"Qty"+"\n"+"Repair"}
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle7}>
															 {"Qty"+"\n"+"Other (-)"}
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle8}>
															 {"Qty"+"\n"+"Other (+)"}
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle9}>
															 {"Calc EOM"+"\n"+"Qty"}
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle10}>
															 {"Act EOM"+"\n"+"Qty"}
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle11}>
															 Difference
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle12}>
															 Cost
													 </TableCell>
													 <TableCell style={pdfReport4Style.TableCellHeaderStyle13}>
															 {"Ext"+"\n"+"Value"}
													 </TableCell>
											 </TableHeader>
											 <TableBody textAlign={"center"} >
													 <DataTableCell style={pdfReport4Style.TableCellBodyStyle1} getContent={(row) => row.PartNumber}/>
													 <DataTableCell style={pdfReport4Style.TableCellBodyStyle2} getContent={(row) => this.loadRowContent(row.productsArray) }/>
													 <DataTableCell style={pdfReport4Style.TableCellBodyStyle3} getContent={(row) => row.Description }/>
													 <DataTableCell style={pdfReport4Style.TableCellBodyStyle4} getContent={(row) => row.bomQuantity }/>
													 <DataTableCell style={pdfReport4Style.TableCellBodyStyle5} getContent={(row) => row.purchaseQty }/>
													 <DataTableCell style={pdfReport4Style.TableCellBodyStyle6} getContent={(row) => row.repairQty }/>
													 <DataTableCell style={pdfReport4Style.TableCellBodyStyle7} getContent={(row) => row.otherQtyNeg }/>
													 <DataTableCell style={pdfReport4Style.TableCellBodyStyle8} getContent={(row) => row.otherQtyPos }/>
													 <DataTableCell style={pdfReport4Style.TableCellBodyStyle9} getContent={(row) => row.calcEOMQty }/>
													 <DataTableCell style={pdfReport4Style.TableCellBodyStyle10} getContent={(row) => row.eomQuantity }/>
													 <DataTableCell style={pdfReport4Style.TableCellBodyStyle11} getContent={(row) => {

														  var value = "";

															if (row.difference.discrepancyValue==0) {
																value = row.difference.discrepancyValue;
															} else {
																if (row.difference.discrepancyIsPositive) {
																	value = "+"+row.difference.discrepancyValue;
																} else {
																	value = "-"+row.difference.discrepancyValue;
																}
															}

															return (
																value
															);
	 													}}/>
													 <DataTableCell style={pdfReport4Style.TableCellBodyStyle12} getContent={(row) => row.Cost }/>
													 <DataTableCell style={pdfReport4Style.TableCellBodyStyle13} getContent={(row) => row.ExtValue }/>
											 </TableBody>
			               </Table>
									 </View>
									 {i==(dataByPages.length-1) &&
										 <View style={pdfReport4Style.row}>
						           <Text style={pdfReport4Style.description}>TOTAL REPAIR INVENTORY ON HAND - EXTENDED VALUE</Text>
						           <Text style={pdfReport4Style.total}>{parseFloat(endOfRangeTotalTest).toFixed(4)}</Text>
						         </View>
									 }
								 </Page>
							 )}
							 </Fragment>
						 ))}
					 </Document>
     )}
}

class Reports extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

		// reportListData: [
		// 	{
		// 		id: 0,
		// 		name: "Inventory Valuation Report"
		// 	},
		// 	{
		// 		id: 1,
		// 		name: "Monthly Purchase Report"
		// 	},
		// 	{
		// 		id: 2,
		// 		name: "Monthly Usage Report"
		// 	},
		// 	{
		// 		id: 3,
		// 		name: "Inventory Rollforward Report"
		// 	},
		// ]

    this.state = {
			bomData: [],
			productsData: [],
			productItemsForBOM: [],
			inventoryReportData: [],
			inventoryLogForBOM: [],
			inventoryLogForEOM: [],
			componentItems: [],
			startDate: Moment().subtract(1, 'month').format('YYYY-MM-DD'),
			endDate: Moment().format('YYYY-MM-DD'),
			reportListData: [
				{
					id: 3,
					name: "Inventory Rollforward Report"
				},
			],
			adjustmentReasons: [{
				name: "Associated Project"
			},{
				name: "R & D"
			},{
				name: "Purchase (For Positive)"
			},{
				name: "Inventory Adjustment (Shortage)"
			},{
				name: "Inventory Adjustment (Overage)"
			},{
				name: "Repair Kit"
			}],
			isOpen: false,
			currentItem: "Inventory Rollforward Report",
			currentDataSetting: 3,
			isOpen2: false,
			currentItem2: "ALL",
			currentDataSetting2: 0,
			modal: false,
			productSelect: 0,
			batchSelect: 0,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.toggle = this.toggle.bind(this);
		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.toggleDropDown2 = this.toggleDropDown2.bind(this);

		this.onChangeProduct = this.onChangeProduct.bind(this);
		this.handleChange = this.handleChange.bind(this);

  }


	async componentDidMount() {

		// await this.getBOMData();
		// this.getInventoryLogForDay(2, this.state.endDate);
		// this.getInventoryLogForDay(1, this.state.startDate);
		//
		// this.getComponentItemsData();
		// this.getProductsData();
		//this.intervalID = setInterval(this.getBOMData, 5000);

		setTimeout(this.getBOMData, 500);
		setTimeout(() => {this.getInventoryLogForDay(1, this.state.startDate)}, 200);
		setTimeout(() => {this.getInventoryLogForDay(2, this.state.endDate)}, 200);
		setTimeout(this.getComponentItemsData, 200);
		setTimeout(this.getInventoryReportData, 500);

		this.getProductsData();
	}

	componentWillUnmount() {
		//console.log("Laser-Usage componentWillUnmount");
		clearInterval(this.intervalID);
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	toggleDropDown2() {
    this.setState({
      isOpen2: !this.state.isOpen2
    });
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		//setTimeout(this.getCategoryData, 300);

		if (id==0) {
			setTimeout(this.getBOMData, 300);
			setTimeout(() => {this.getInventoryLogForDay(2, this.state.endDate)}, 200);
		} else if (id==1) {
			setTimeout(this.getBOMData, 200);
			setTimeout(this.getComponentItemsData, 200);
			setTimeout(this.getInventoryReportData, 500);
		} else if (id==2) {
			setTimeout(this.getBOMData, 200);
			setTimeout(() => {this.getInventoryLogForDay(1, this.state.startDate)}, 200);
			setTimeout(() => {this.getInventoryLogForDay(2, this.state.endDate)}, 200);
			setTimeout(this.getComponentItemsData, 200);
			setTimeout(this.getInventoryReportData, 500);
		} else if (id==3) {
			setTimeout(this.getBOMData, 200);
			setTimeout(() => {this.getInventoryLogForDay(1, this.state.startDate)}, 200);
			setTimeout(() => {this.getInventoryLogForDay(2, this.state.endDate)}, 200);
			setTimeout(this.getComponentItemsData, 200);
			setTimeout(this.getInventoryReportData, 500);
		}

  }

	selectDropDownItem2(id, title) {

    this.setState({
      currentItem2: title,
			currentDataSetting2: id
    });

  }

	onChangeProduct = (event) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);
		console.log("checked value: " + event.target.checked);

		let id = event.target.id;
		let value = event.target.value;

		//console.log("repairLog: " + JSON.stringify(repairLog));

		this.setState({ productSelect: value });

		setTimeout(this.getBOMData, 300);

		if (this.state.currentDataSetting==0) {
			setTimeout(this.getBOMData, 300);
			setTimeout(() => {this.getInventoryLogForDay(2, this.state.endDate)}, 200);
		} else if (this.state.currentDataSetting==1) {
			setTimeout(this.getBOMData, 200);
			setTimeout(this.getComponentItemsData, 200);
			setTimeout(this.getInventoryReportData, 500);
		} else if (this.state.currentDataSetting==2) {
			setTimeout(this.getBOMData, 200);
			setTimeout(() => {this.getInventoryLogForDay(1, this.state.startDate)}, 200);
			setTimeout(() => {this.getInventoryLogForDay(2, this.state.endDate)}, 200);
			setTimeout(this.getComponentItemsData, 200);
			setTimeout(this.getInventoryReportData, 500);
		} else if (this.state.currentDataSetting==3) {
			setTimeout(this.getBOMData, 200);
			setTimeout(() => {this.getInventoryLogForDay(1, this.state.startDate)}, 200);
			setTimeout(() => {this.getInventoryLogForDay(2, this.state.endDate)}, 200);
			setTimeout(this.getComponentItemsData, 200);
			setTimeout(this.getInventoryReportData, 500);
		}

	}

	getProductsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-products";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all Products data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ productsData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			//authenticationService.refresh();
		}

	};

	handleChange = async (event) => {
		if (event.target.getAttribute('name') == 'startDate') {
			this.setState({startDate: event.target.value});
		} else if (event.target.getAttribute('name') == 'endDate') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({endDate: event.target.value});
		}

		if (this.state.currentDataSetting==0) {
			setTimeout(this.getBOMData, 300);
			setTimeout(() => {this.getInventoryLogForDay(2, this.state.endDate)}, 200);
		} else if (this.state.currentDataSetting==1) {
			setTimeout(this.getBOMData, 300);
			setTimeout(this.getComponentItemsData, 200);
			setTimeout(this.getInventoryReportData, 500);
		} else if (this.state.currentDataSetting==2) {
			setTimeout(this.getBOMData, 300);
			setTimeout(() => {this.getInventoryLogForDay(1, this.state.startDate)}, 200);
			setTimeout(() => {this.getInventoryLogForDay(2, this.state.endDate)}, 200);
			setTimeout(this.getComponentItemsData, 200);
			setTimeout(this.getInventoryReportData, 500);
		} else if (this.state.currentDataSetting==3) {
			setTimeout(this.getBOMData, 300);
			setTimeout(() => {this.getInventoryLogForDay(1, this.state.startDate)}, 200);
			setTimeout(() => {this.getInventoryLogForDay(2, this.state.endDate)}, 200);
			setTimeout(this.getComponentItemsData, 200);
			setTimeout(this.getInventoryReportData, 500);
		}
  }

	getComponentItemsData = async () => {

		var timezone = Moment.tz.guess();

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-component-items-date-range?StartDate="+this.state.startDate+"&EndDate="+this.state.endDate+"&Timezone="+timezone;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all getComponentItemsData data: " + JSON.stringify(body));

			if (body.status == 'Success') {

				if (body.response.length>0) {

					var componentItems = body.response;

					this.setState({ componentItems: componentItems });
				} else {

					this.setState({ componentItems: [] });

				}

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			//authenticationService.refresh();
		}

	};

	getBOMData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-bill-of-materials?MarketID=0&ProductID="+this.state.productSelect;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all BOM data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ bomData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			//authenticationService.refresh();
		}

	};

	getInventoryReportData = async () => {

		var timezone = Moment.tz.guess();

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-inventory-report-logs?LogType="+this.state.currentDataSetting+"&StartDate="+this.state.startDate+"&EndDate="+this.state.endDate+"&Timezone="+timezone;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all inventoryReportData data: " + JSON.stringify(body));

			//let newObj = JSON.parse(JSON.stringify(this.state.bomData));

			if (body.status == 'Success') {

				if (this.state.productSelect!=0) {
					var inventoryReportDataTemp = body.response;
					var newinventoryReportData = [];

					const currentProductItem = this.state.productsData.find(product => product.id == this.state.productSelect);

					inventoryReportDataTemp.map((prop, key) => {

						const filteredBOM = this.state.bomData.filter(bom => bom.PartNumber == prop.PartNumber);


						filteredBOM.map((prop1, key) => {

							if (currentProductItem.Name == prop1.productName) {
								newinventoryReportData.push(prop);
							}

						});

					});

					console.log("all newinventoryReportData data: " + JSON.stringify(body));

					this.setState({ inventoryReportData: newinventoryReportData });
				} else {
					this.setState({ inventoryReportData: body.response });
				}

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			//authenticationService.refresh();
		}

	};

	getInventoryLogForDay = async (type, date) => {

		var timezone = Moment.tz.guess();

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-inventory-log-for-day?Type="+type+"&Date="+date+"&Timezone="+timezone;

		console.log("getInventoryLogForDay url: " + JSON.stringify(url));

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();

			if (body.status == 'Success') {

				if (type==1) {
					console.log("inventoryLogForBOM data: " + JSON.stringify(body));
					this.setState({ inventoryLogForBOM: body.response });
				} else if (type==2) {
					console.log("inventoryLogForEOM data: " + JSON.stringify(body));

					var logs = body.response;

					if (logs.length>0) {
						this.setState({ inventoryLogForEOM: logs });
					} else {
						this.setState({ inventoryLogForEOM: this.state.bomData });
					}
				}

			}

		}
		catch (err) {
			console.log(err);
			//authenticationService.refresh();
		}

	};

  render() {

		var data1Total = 0;

		const data1 = this.state.bomData.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			var costNumber = prop.Cost.replace("$", "");
			var extValue = (parseFloat(costNumber) * parseFloat(prop.Quantity)).toFixed(4);

			if (isNaN(extValue)) {
				extValue = 0;
			}

			const filteredBOM = this.state.inventoryLogForEOM.find(a => a.PartNumber == prop.PartNumber);

			console.log('filteredBOM: ' + JSON.stringify(filteredBOM));

			if (filteredBOM) {
				costNumber = filteredBOM.Cost.replace("$", "");
				extValue = (parseFloat(costNumber) * parseFloat(filteredBOM.Quantity)).toFixed(4);

				if (isNaN(extValue)) {
					extValue = 0;
				}
			}

			data1Total = (parseFloat(data1Total)+parseFloat(extValue)).toFixed(4);

			return {
				id: key,
				bomID: prop.id,
				PartNumber: prop.PartNumber,
        Cost: filteredBOM ? filteredBOM.Cost : "N/A",
				Description: prop.Description,
				Quantity: filteredBOM ? filteredBOM.Quantity : "N/A",
				ExtValue: extValue,
				ProductName: prop.productName,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		console.log('data1Total ' + data1Total);

		// var data12Total = 0;
		//
		// const data12 = this.state.inventoryLogForEOM.map((prop, key) => {
		//
		// 	//const buf = Buffer.from(prop.detailedissue, 'utf8');
		//
		// 	//buf.toString(); // 'Hello, World'
		//
		// 	const filteredBOM = this.state.bomData.find(bom => bom.PartNumber == prop.PartNumber);
		//
		// 	var costNumber = prop.Cost.replace("$", "");
		// 	var extValue = (parseFloat(costNumber) * parseFloat(prop.Quantity)).toFixed(4);
		//
		// 	if (isNaN(extValue)) {
		// 		extValue = 0;
		// 	}
		//
		// 	data12Total = (parseFloat(data12Total)+parseFloat(extValue)).toFixed(4);
		//
		// 	return {
		// 		id: key,
		// 		bomID: prop.id,
		// 		inventoryID: prop.inventoryID,
		// 		PartNumber: prop.PartNumber,
    //     Cost: prop.Cost,
		// 		Description: filteredBOM ? filteredBOM.Description : "",
		// 		Quantity: prop.Quantity,
		// 		ExtValue: extValue,
		// 		ProductName: filteredBOM ? filteredBOM.productName : "",
		// 		actions: (
		// 			// we've added some custom button actions
		// 			<div className="text-center">
		// 				{/* use this button to add a edit kind of action */}
		// 				{/* use this button to remove the data row */}
		// 				{/* <Button
		// 					onClick={() => {
		// 						let obj = data2.find(o => o.id === key);
    //             console.log('button obj: ' + obj);
		// 						this.setState({
		// 							modal4: !this.state.modal4,
		// 							obj: obj
		// 						});
		// 					}}
		// 					color="danger"
		// 					size="sm"
		// 					round="true"
		// 					icon="true"
		// 					className="ml-2"
		// 				>
		// 					<i className="fa fa-trash-alt" />
		// 				</Button> */}
		// 			</div>
		// 		)
		// 	};
		// });
		//
		// console.log('data12Total ' + data12Total);

		var data2Total = 0;

		const data2 = this.state.inventoryReportData.map((prop, key) => {

			const pQuantityArray = prop.pQuantity.split(',');
			const cQuantityArray = prop.cQuantity.split(',');
			const aReasonArray = prop.aReason.split(',');
			const aNameArray = prop.aName.split(',');
			const aTimeArray = prop.aTime.split(',');

			//data1Total = (parseFloat(data1Total)+parseFloat(extValue)).toFixed(4);
			const filteredBOM = this.state.bomData.filter(bom => bom.PartNumber == prop.PartNumber);

			var productsArray = [];

			var eomValue = 0;

			filteredBOM.map((prop, key) => {
				//console.log('filteredBOM ' + prop.id);
				productsArray.push(prop.productName);

			});

			var costNumber = prop.Cost.replace("$", "");
			var extValue = 0;

			var qtyPurchaseArray = [];
			var qtyIssueArray = [];
			var qtyOtherArray = [];

			pQuantityArray.map((pQuantity, key) => {

				var itemPreviousQuantity = parseFloat(pQuantity);
				var itemCurrentQuantity = parseFloat(cQuantityArray[key]);

				var qtyPurchaseValue = "-";
				var qtyIssueValue = "-";
				var qtyOtherValue = "-";

				if (itemPreviousQuantity>itemCurrentQuantity) {
					qtyIssueValue = parseFloat(itemPreviousQuantity-itemCurrentQuantity);
				} else {
					qtyPurchaseValue = parseFloat(itemCurrentQuantity-itemPreviousQuantity);
				}

				if (String(aReasonArray[key].toLowerCase()).includes("other")) {
					qtyPurchaseValue = "-";
					qtyIssueValue = "-";
					qtyOtherValue = parseFloat(itemPreviousQuantity-itemCurrentQuantity);
				}

				qtyPurchaseArray.push(qtyPurchaseValue);
				qtyIssueArray.push(qtyIssueValue);
				qtyOtherArray.push(qtyOtherValue);

				console.log('costNumber ' + costNumber);
				console.log('pQuantity ' + pQuantity);

				extValue = (parseFloat(extValue) + (parseFloat(costNumber) * parseFloat(qtyPurchaseValue))).toFixed(4);

				console.log('extValue ' + extValue);

			});

			if (isNaN(extValue)) {
				extValue = 0;
			}

			var allItems = [];

			aTimeArray.map((aTime, key) => {

				var tempTime = aTime;
				var tempPurchase = qtyPurchaseArray[key];
				var tempIssue = qtyIssueArray[key];
				var tempOther = qtyOtherArray[key];
				var tempReason = aReasonArray[key];
				var tempName = aNameArray[key];

				var anItem = {
					time: tempTime,
					purchaseQty: tempPurchase,
					issueQty: tempIssue,
					otherQty: tempOther,
					reason: tempReason,
					name: tempName
				}

				allItems.push(anItem);

			});

			var allItemsSorted = allItems.sort((a, b) => {
				const timeA = a.time.toUpperCase(); // ignore upper and lowercase
			  const timeB = b.time.toUpperCase(); // ignore upper and lowercase
			  if (timeA < timeB) {
			    return -1;
			  }
			  if (timeA > timeB) {
			    return 1;
			  }

			  // names must be equal
			  return 0;
			});

			data2Total = (parseFloat(data2Total)+parseFloat(extValue)).toFixed(4);

			return {
				id: key,
				logID: prop.id,
				PartNumber: prop.PartNumber,
        Cost: prop.Cost,
				PreviousQuantity: pQuantityArray[0],
				CurrentQuantity: prop.cQuantity,
				Description: prop.Description,
				AdjustmentReason: prop.aReason,
				EOMValue: eomValue,
				allItemsSorted: allItemsSorted,
				ExtValue: extValue,
				productsArray: productsArray,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		console.log('data2Total ' + data2Total);

		var data3Total = 0;
		var data3ReasonTotals = {
			assoProject: 0,
			rnd: 0,
			purchase: 0,
			iAShortage: 0,
			iAOverage: 0,
			repairKit: 0,
			other: 0,
			repair: 0
		};

		const data3 = this.state.inventoryReportData.map((prop, key) => {

			//console.log('inventoryLogForEOM: ' + JSON.stringify(this.state.inventoryLogForEOM));

			var bomRecord = this.state.inventoryLogForBOM.find(log => log.PartNumber == prop.PartNumber);
			var eomRecord = this.state.inventoryLogForEOM.find(log => log.PartNumber == prop.PartNumber);

			console.log('bomRecord: ' + JSON.stringify(bomRecord));
			console.log('eomRecord: ' + JSON.stringify(eomRecord));

			const pQuantityArray = prop.pQuantity.split(',');
			const cQuantityArray = prop.cQuantity.split(',');
			const aReasonArray = prop.aReason.split(',');
			const aNameArray = prop.aName.split(',');
			const aTimeArray = prop.aTime.split(',');

			//data1Total = (parseFloat(data1Total)+parseFloat(extValue)).toFixed(4);
			const filteredBOM = this.state.bomData.filter(bom => bom.PartNumber == prop.PartNumber);

			console.log('filteredBOM: ' + JSON.stringify(filteredBOM));

			var productsArray = [];

			var eomValue = 0;

			var allComponentItems = [];

			filteredBOM.map((prop, key) => {
				//console.log('filteredBOM ' + prop.id);
				productsArray.push(prop.productName);
				eomValue = prop.Quantity;

				const filteredComponentItems = this.state.componentItems.filter(component => component.ComponentItemID == prop.id);
				console.log('filteredComponentItems: ' + JSON.stringify(filteredComponentItems));
				if (filteredComponentItems.length>0) {
					filteredComponentItems.map((componentItem, key) => {
							console.log('ComponentItemID: ' + componentItem.ComponentItemID);
							console.log('RepairID: ' + componentItem.RepairID);
							console.log('componentItem: ' + JSON.stringify(componentItem));
							//console.log('componentItem ComponentItemID: ' + componentItem.ComponentItemID);
							//console.log('componentItem Timestamp: ' + componentItem.Timestamp);

							if (componentItem.ProductName==prop.productName) {
								allComponentItems.push(componentItem);
							}
					});
				}

			});

			//console.log('allComponentItems: ' + allComponentItems);

			var costNumber = prop.Cost.replace("$", "");
			var extValue = 0;

			if (eomRecord) {
				extValue = (parseFloat(costNumber) * parseFloat(eomRecord.Quantity)).toFixed(4);
			}

			if (isNaN(extValue)) {
				extValue = 0;
			}

			var qtyPurchaseArray = [];
			var qtyIssueArray = [];
			var qtyOtherArray = [];

			pQuantityArray.map((pQuantity, key) => {

				var itemPreviousQuantity = parseFloat(pQuantity);
				var itemCurrentQuantity = parseFloat(cQuantityArray[key]);

				var qtyPurchaseValue = "-";
				var qtyIssueValue = "-";
				var qtyOtherValue = "-";

				if (itemPreviousQuantity>itemCurrentQuantity) {
					qtyIssueValue = parseFloat(itemPreviousQuantity-itemCurrentQuantity);
				} else {
					qtyPurchaseValue = parseFloat(itemCurrentQuantity-itemPreviousQuantity);
				}

				if (String(aReasonArray[key].toLowerCase()).includes("other")) {
					qtyPurchaseValue = "-";
					qtyIssueValue = "-";
					qtyOtherValue = parseFloat(itemPreviousQuantity-itemCurrentQuantity);

					var otherTotalCost = (parseFloat(qtyOtherValue)*parseFloat(costNumber));

					//console.log("other reason: "+String(aReasonArray[key].toLowerCase()));
					//console.log("otherTotalCost: "+otherTotalCost);

					if (isNaN(otherTotalCost)) {
						otherTotalCost = 0;
					}

					data3ReasonTotals.other = parseFloat(parseFloat(data3ReasonTotals.other)+otherTotalCost).toFixed(4);
				} else {

					if (String(aReasonArray[key].toLowerCase()).includes("associated project")) {

						var assoProjectTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							assoProjectTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
						} else {
							assoProjectTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
						}

						if (isNaN(assoProjectTotalCost)) {
							assoProjectTotalCost = 0;
						}

						data3ReasonTotals.assoProject = parseFloat(parseFloat(data3ReasonTotals.assoProject)+assoProjectTotalCost).toFixed(4);

					} else if (String(aReasonArray[key].toLowerCase()).includes("r & d")) {
						var rndTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							rndTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
						} else {
							rndTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
						}

						if (isNaN(rndTotalCost)) {
							rndTotalCost = 0;
						}

						data3ReasonTotals.rnd = parseFloat(parseFloat(data3ReasonTotals.rnd)+rndTotalCost).toFixed(4);
					} else if (String(aReasonArray[key].toLowerCase()).includes("purchase (for positive)")) {
						var purchaseTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							purchaseTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
						} else {
							purchaseTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
						}

						if (isNaN(purchaseTotalCost)) {
							purchaseTotalCost = 0;
						}

						data3ReasonTotals.purchase = parseFloat(parseFloat(data3ReasonTotals.purchase)+purchaseTotalCost).toFixed(4);
					} else if (String(aReasonArray[key].toLowerCase()).includes("inventory adjustment (shortage)")) {
						var iAShortageTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							iAShortageTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
						} else {
							iAShortageTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
						}

						if (isNaN(iAShortageTotalCost)) {
							iAShortageTotalCost = 0;
						}

						data3ReasonTotals.iAShortage = parseFloat(parseFloat(data3ReasonTotals.iAShortage)+iAShortageTotalCost).toFixed(4);
					} else if (String(aReasonArray[key].toLowerCase()).includes("inventory adjustment (overage)")) {
						var iAOverageTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							iAOverageTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
						} else {
							iAOverageTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
						}

						if (isNaN(iAOverageTotalCost)) {
							iAOverageTotalCost = 0;
						}

						data3ReasonTotals.iAOverage = parseFloat(parseFloat(data3ReasonTotals.iAOverage)+iAOverageTotalCost).toFixed(4);
					} else if (String(aReasonArray[key].toLowerCase()).includes("repair kit")) {
						var repairKitTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							repairKitTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
						} else {
							repairKitTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
						}

						if (isNaN(repairKitTotalCost)) {
							repairKitTotalCost = 0;
						}

						data3ReasonTotals.repairKit = parseFloat(parseFloat(data3ReasonTotals.repairKit)+repairKitTotalCost).toFixed(4);
					}

				}

				qtyPurchaseArray.push(qtyPurchaseValue);
				qtyIssueArray.push(qtyIssueValue);
				qtyOtherArray.push(qtyOtherValue);

			});

			var allItems = [];

			allComponentItems.map((componentItem, key) => {
				//console.log('componentItem Quantity: ' + componentItem.Quantity);
				//console.log('componentItem ComponentItemID: ' + componentItem.ComponentItemID);
				//console.log('componentItem Timestamp: ' + componentItem.Timestamp);

				var tempQtyVl = "-";
				qtyPurchaseArray.push(tempQtyVl);
				var tempIssueVl = "-";
				qtyIssueArray.push(tempQtyVl);

				var tempOtherVl = parseFloat(componentItem.Quantity);
				qtyOtherArray.push(tempOtherVl);

				var tempName = "Repair Technician";
				aNameArray.push(tempName);

				var tempReason = "Repair";
				aReasonArray.push(tempReason);

				//var tempTime = "Other - Repair"
				aTimeArray.push(componentItem.Timestamp);

				data3ReasonTotals.repair = parseFloat(parseFloat(data3ReasonTotals.repair)+(parseFloat(tempOtherVl)*parseFloat(costNumber))).toFixed(4);

			});

			aTimeArray.map((aTime, key) => {

				var tempTime = aTime;
				var tempPurchase = qtyPurchaseArray[key];
				var tempIssue = qtyIssueArray[key];
				var tempOther = qtyOtherArray[key];
				var tempReason = aReasonArray[key];
				var tempName = aNameArray[key];

				var anItem = {
					time: tempTime,
					purchaseQty: tempPurchase,
					issueQty: tempIssue,
					otherQty: tempOther,
					reason: tempReason,
					name: tempName
				}

				allItems.push(anItem);

			});

			var allItemsSorted = allItems.sort((a, b) => {
				const timeA = a.time.toUpperCase(); // ignore upper and lowercase
			  const timeB = b.time.toUpperCase(); // ignore upper and lowercase
			  if (timeA < timeB) {
			    return -1;
			  }
			  if (timeA > timeB) {
			    return 1;
			  }

			  // names must be equal
			  return 0;
			});

			data3Total = (parseFloat(data3Total)+parseFloat(extValue)).toFixed(4);

			return {
				id: key,
				logID: prop.id,
				PartNumber: prop.PartNumber,
        Cost: prop.Cost,
				PreviousQuantity: pQuantityArray[0],
				CurrentQuantity: prop.cQuantity,
				Description: prop.Description,
				AdjustmentReason: prop.aReason,
				EOMValue: eomValue,
				allItemsSorted: allItemsSorted,
				ExtValue: extValue,
				productsArray: productsArray,
				bomQuantity: bomRecord ? bomRecord.Quantity : 0,
				eomQuantity: eomRecord ? eomRecord.Quantity : 0,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		console.log('data3Total ' + data3Total);
		console.log('data3ReasonTotals: ' + JSON.stringify(data3ReasonTotals));

		var data4Total = 0;
		var data4ReasonTotals = {
			assoProject: 0,
			rnd: 0,
			purchase: 0,
			iAShortage: 0,
			iAOverage: 0,
			repairKit: 0,
			other: 0,
			repair: 0
		};

		const data4 = this.state.inventoryReportData.map((prop, key) => {

			var bomRecord = this.state.inventoryLogForBOM.find(log => log.PartNumber == prop.PartNumber);
			var eomRecord = this.state.inventoryLogForEOM.find(log => log.PartNumber == prop.PartNumber);

			console.log('bomRecord: ' + JSON.stringify(bomRecord));
			console.log('eomRecord: ' + JSON.stringify(eomRecord));

			const pQuantityArray = prop.pQuantity.split(',');
			const cQuantityArray = prop.cQuantity.split(',');
			const aReasonArray = prop.aReason.split(',');
			const aNameArray = prop.aName.split(',');
			const aTimeArray = prop.aTime.split(',');

			//data1Total = (parseFloat(data1Total)+parseFloat(extValue)).toFixed(4);
			const filteredBOM = this.state.bomData.filter(bom => bom.PartNumber == prop.PartNumber);

			var productsArray = [];

			var eomValue = 0;

			var allComponentItems = [];

			filteredBOM.map((prop, key) => {
				//console.log('filteredBOM ' + prop.id);
				productsArray.push(prop.productName);
				eomValue = prop.Quantity;

				const filteredComponentItems = this.state.componentItems.filter(component => component.ComponentItemID == prop.id);
				//console.log('filteredComponentItems: ' + filteredComponentItems);
				if (filteredComponentItems.length>0) {
					filteredComponentItems.map((componentItem, key) => {
							//console.log('componentItem Quantity: ' + componentItem.Quantity);
							//console.log('componentItem ComponentItemID: ' + componentItem.ComponentItemID);
							//console.log('componentItem Timestamp: ' + componentItem.Timestamp);
							//allComponentItems.push(componentItem);

							if (componentItem.ProductName==prop.productName) {
								allComponentItems.push(componentItem);
							}
					});
				}

			});

			//console.log('allComponentItems: ' + allComponentItems);

			var costNumber = prop.Cost.replace("$", "");
			var extValue = 0;

			if (eomRecord) {
				var extValue = (parseFloat(costNumber) * parseFloat(eomRecord.Quantity)).toFixed(4);

				if (isNaN(extValue)) {
					extValue = 0;
				}
			}
			// var extValue = (parseFloat(costNumber) * parseFloat(eomRecord.Quantity)).toFixed(4);
			//
			// if (isNaN(extValue)) {
			// 	extValue = 0;
			// }

			var qtyPurchaseArray = [];
			var qtyIssueArray = [];
			var qtyOtherArray = [];

			pQuantityArray.map((pQuantity, key) => {

				var itemPreviousQuantity = parseFloat(pQuantity);
				var itemCurrentQuantity = parseFloat(cQuantityArray[key]);

				var qtyPurchaseValue = "-";
				var qtyIssueValue = "-";
				var qtyOtherValue = "-";

				if (itemPreviousQuantity>itemCurrentQuantity) {
					qtyIssueValue = parseFloat(itemPreviousQuantity-itemCurrentQuantity);
				} else {
					qtyPurchaseValue = parseFloat(itemCurrentQuantity-itemPreviousQuantity);
				}

				if (String(aReasonArray[key].toLowerCase()).includes("other")) {
					qtyPurchaseValue = "-";
					qtyIssueValue = "-";
					qtyOtherValue = parseFloat(itemPreviousQuantity-itemCurrentQuantity);

					var otherTotalCost = (parseFloat(qtyOtherValue)*parseFloat(costNumber));

					if (isNaN(otherTotalCost)) {
						otherTotalCost = 0;
					}

					data4ReasonTotals.other = parseFloat(parseFloat(data4ReasonTotals.other)+otherTotalCost).toFixed(4);
				} else {

					if (String(aReasonArray[key].toLowerCase()).includes("associated project")) {

						var assoProjectTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							assoProjectTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
						} else {
							assoProjectTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
						}

						if (isNaN(assoProjectTotalCost)) {
							assoProjectTotalCost = 0;
						}

						data4ReasonTotals.assoProject = parseFloat(parseFloat(data4ReasonTotals.assoProject)+assoProjectTotalCost).toFixed(4);

					} else if (String(aReasonArray[key].toLowerCase()).includes("r & d")) {
						var rndTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							rndTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
						} else {
							rndTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
						}

						if (isNaN(rndTotalCost)) {
							rndTotalCost = 0;
						}

						data4ReasonTotals.rnd = parseFloat(parseFloat(data4ReasonTotals.rnd)+rndTotalCost).toFixed(4);
					} else if (String(aReasonArray[key].toLowerCase()).includes("purchase (for positive)")) {
						var purchaseTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							purchaseTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
						} else {
							purchaseTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
						}

						if (isNaN(purchaseTotalCost)) {
							purchaseTotalCost = 0;
						}

						data4ReasonTotals.purchase = parseFloat(parseFloat(data4ReasonTotals.purchase)+purchaseTotalCost).toFixed(4);
					} else if (String(aReasonArray[key].toLowerCase()).includes("inventory adjustment (shortage)")) {
						var iAShortageTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							iAShortageTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
						} else {
							iAShortageTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
						}

						if (isNaN(iAShortageTotalCost)) {
							iAShortageTotalCost = 0;
						}

						data4ReasonTotals.iAShortage = parseFloat(parseFloat(data4ReasonTotals.iAShortage)+iAShortageTotalCost).toFixed(4);
					} else if (String(aReasonArray[key].toLowerCase()).includes("inventory adjustment (overage)")) {
						var iAOverageTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							iAOverageTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
						} else {
							iAOverageTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
						}

						if (isNaN(iAOverageTotalCost)) {
							iAOverageTotalCost = 0;
						}

						data4ReasonTotals.iAOverage = parseFloat(parseFloat(data4ReasonTotals.iAOverage)+iAOverageTotalCost).toFixed(4);
					} else if (String(aReasonArray[key].toLowerCase()).includes("repair kit")) {
						var repairKitTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							repairKitTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
						} else {
							repairKitTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
						}

						if (isNaN(repairKitTotalCost)) {
							repairKitTotalCost = 0;
						}

						data4ReasonTotals.repairKit = parseFloat(parseFloat(data4ReasonTotals.repairKit)+repairKitTotalCost).toFixed(4);
					}

				}

				qtyPurchaseArray.push(qtyPurchaseValue);
				qtyIssueArray.push(qtyIssueValue);
				qtyOtherArray.push(qtyOtherValue);

			});

			var allItems = [];

			allComponentItems.map((componentItem, key) => {
				//console.log('componentItem Quantity: ' + componentItem.Quantity);
				//console.log('componentItem ComponentItemID: ' + componentItem.ComponentItemID);
				//console.log('componentItem Timestamp: ' + componentItem.Timestamp);

				var tempQtyVl = "-";
				qtyPurchaseArray.push(tempQtyVl);
				var tempIssueVl = "-";
				qtyIssueArray.push(tempQtyVl);

				var tempOtherVl = parseFloat(componentItem.Quantity);
				qtyOtherArray.push(tempOtherVl);

				var tempName = "Repair Technician";
				aNameArray.push(tempName);

				var tempReason = "Repair";
				aReasonArray.push(tempReason);

				//var tempTime = "Other - Repair"
				aTimeArray.push(componentItem.Timestamp);

				data4ReasonTotals.repair = parseFloat(parseFloat(data4ReasonTotals.repair)+(parseFloat(tempOtherVl)*parseFloat(costNumber))).toFixed(4);

			});

			aTimeArray.map((aTime, key) => {

				var tempTime = aTime;
				var tempPurchase = qtyPurchaseArray[key];
				var tempIssue = qtyIssueArray[key];
				var tempOther = qtyOtherArray[key];
				var tempReason = aReasonArray[key];
				var tempName = aNameArray[key];

				var anItem = {
					time: tempTime,
					purchaseQty: tempPurchase,
					issueQty: tempIssue,
					otherQty: tempOther,
					reason: tempReason,
					name: tempName
				}

				allItems.push(anItem);

			});

			var allItemsSorted = allItems.sort((a, b) => {
				const timeA = a.time.toUpperCase(); // ignore upper and lowercase
			  const timeB = b.time.toUpperCase(); // ignore upper and lowercase
			  if (timeA < timeB) {
			    return -1;
			  }
			  if (timeA > timeB) {
			    return 1;
			  }

			  // names must be equal
			  return 0;
			});

			data4Total = (parseFloat(data4Total)+parseFloat(extValue)).toFixed(4);

			return {
				id: key,
				logID: prop.id,
				PartNumber: prop.PartNumber,
        Cost: prop.Cost,
				PreviousQuantity: pQuantityArray[0],
				CurrentQuantity: prop.cQuantity,
				Description: prop.Description,
				AdjustmentReason: prop.aReason,
				EOMValue: eomValue,
				allItemsSorted: allItemsSorted,
				ExtValue: extValue,
				productsArray: productsArray,
				bomQuantity: bomRecord ? bomRecord.Quantity : 0,
				eomQuantity: eomRecord ? eomRecord.Quantity : 0,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		console.log('data4Total ' + data4Total);
		console.log('data4ReasonTotals: ' + JSON.stringify(data4ReasonTotals));

		var data4TotalRevised = 0;
		var data4ReasonTotalsRevised = {
			assoProject: 0,
			rnd: 0,
			purchase: 0,
			iAShortage: 0,
			iAOverage: 0,
			repairKit: 0,
			other: 0,
			repair: 0
		};

		const data4Revised = this.state.inventoryLogForEOM.map((prop, key) => {

			var bomRecord = data4.find(log => log.PartNumber == prop.PartNumber);
			//console.log('bomRecord found: ' + JSON.stringify(data4));
			if (bomRecord) {
				//console.log('bomRecord found: ' + JSON.stringify(bomRecord));

				return bomRecord;
			} else {
				var costNumber = prop.Cost.replace("$", "");
				var extValue = (parseFloat(costNumber) * parseFloat(prop.Quantity)).toFixed(4);

				if (isNaN(extValue)) {
					extValue = 0;
				}

				data4Total = (parseFloat(data4Total)+parseFloat(extValue)).toFixed(4);

				var productsArray = [];

				const filteredBOM = this.state.bomData.filter(bom => bom.PartNumber == prop.PartNumber);

				filteredBOM.map((prop, key) => {
					productsArray.push(prop.productName);
				});

				var anItem = {
					time: prop.Timestamp,
					purchaseQty: "-",
					issueQty: "-",
					otherQty: "-",
					reason: "Current",
					name: ""
				}

				return {
					id: key,
					logID: prop.id,
					PartNumber: prop.PartNumber,
	        Cost: prop.Cost,
					PreviousQuantity: prop.Quantity,
					CurrentQuantity: prop.Quantity,
					Description: filteredBOM[0] ? filteredBOM[0].Description : "",
					AdjustmentReason: "",
					EOMValue: prop.Quantity,
					allItemsSorted: [anItem],
					ExtValue: extValue,
					productsArray: productsArray,
					bomQuantity: prop.Quantity,
					eomQuantity: prop.Quantity,
					actions: (
						// we've added some custom button actions
						<div className="text-center">
							{/* use this button to add a edit kind of action */}
							{/* use this button to remove the data row */}
							{/* <Button
								onClick={() => {
									let obj = data2.find(o => o.id === key);
	                console.log('button obj: ' + obj);
									this.setState({
										modal4: !this.state.modal4,
										obj: obj
									});
								}}
								color="danger"
								size="sm"
								round="true"
								icon="true"
								className="ml-2"
							>
								<i className="fa fa-trash-alt" />
							</Button> */}
						</div>
					)
				};
			}

		});

		var data5Total = 0;
		var data5ReasonTotals = {
			assoProject: 0,
			rnd: 0,
			purchase: 0,
			iAShortage: 0,
			iAOverage: 0,
			repairKit: 0,
			other: 0,
			repair: 0
		};

		const data5 = this.state.inventoryReportData.map((prop, key) => {

			var bomRecord = this.state.inventoryLogForBOM.find(log => log.PartNumber == prop.PartNumber);
			var eomRecord = this.state.inventoryLogForEOM.find(log => log.PartNumber == prop.PartNumber);

			console.log('bomRecord: ' + JSON.stringify(bomRecord));
			console.log('eomRecord: ' + JSON.stringify(eomRecord));

			const pQuantityArray = prop.pQuantity.split(',');
			const cQuantityArray = prop.cQuantity.split(',');
			const aReasonArray = prop.aReason.split(',');
			const aNameArray = prop.aName.split(',');
			const aTimeArray = prop.aTime.split(',');

			//data1Total = (parseFloat(data1Total)+parseFloat(extValue)).toFixed(4);
			const filteredBOM = this.state.bomData.filter(bom => bom.PartNumber == prop.PartNumber);

			var productsArray = [];

			var eomValue = 0;

			var allComponentItems = [];

			filteredBOM.map((prop, key) => {
				//console.log('filteredBOM ' + prop.id);
				productsArray.push(prop.productName);
				eomValue = prop.Quantity;

				const filteredComponentItems = this.state.componentItems.filter(component => component.ComponentItemID == prop.id);
				//console.log('filteredComponentItems: ' + filteredComponentItems);
				if (filteredComponentItems.length>0) {
					filteredComponentItems.map((componentItem, key) => {
							//console.log('componentItem Quantity: ' + componentItem.Quantity);
							//console.log('componentItem ComponentItemID: ' + componentItem.ComponentItemID);
							//console.log('componentItem Timestamp: ' + componentItem.Timestamp);
							//allComponentItems.push(componentItem);

							if (componentItem.ProductName==prop.productName) {
								allComponentItems.push(componentItem);
							}
					});
				}

			});

			console.log('allComponentItems: ' + JSON.stringify(allComponentItems));

			var costNumber = prop.Cost.replace("$", "");
			var extValue = 0;

			if (eomRecord) {
				var extValue = (parseFloat(costNumber) * parseFloat(eomRecord.Quantity)).toFixed(4);

				if (isNaN(extValue)) {
					extValue = 0;
				}
			}

			var qtyPurchaseArray = [];
			var qtyIssueArray = [];
			var qtyOtherArray = [];

			var data5Totals = {
				purchaseQty: 0,
				repairQty: 0,
				otherQtyNeg: 0,
				otherQtyPos: 0,
			};

			pQuantityArray.map((pQuantity, key) => {

				var itemPreviousQuantity = parseFloat(pQuantity);
				var itemCurrentQuantity = parseFloat(cQuantityArray[key]);

				var qtyPurchaseValue = "-";
				var qtyIssueValue = "-";
				var qtyOtherValue = "-";

				if (itemPreviousQuantity>itemCurrentQuantity) {
					qtyIssueValue = parseFloat(itemPreviousQuantity-itemCurrentQuantity);
				} else {
					qtyPurchaseValue = parseFloat(itemCurrentQuantity-itemPreviousQuantity);
				}

				if (String(aReasonArray[key].toLowerCase()).includes("other")) {
					qtyPurchaseValue = "-";
					qtyIssueValue = "-";
					qtyOtherValue = parseFloat(itemPreviousQuantity-itemCurrentQuantity);

					var otherTotalCost = (parseFloat(qtyOtherValue)*parseFloat(costNumber));

					if (isNaN(otherTotalCost)) {
						otherTotalCost = 0;
					}

					data5Totals.otherQtyNeg = parseFloat(parseFloat(data5Totals.otherQtyNeg)+qtyOtherValue).toFixed(0);
					data5ReasonTotals.other = parseFloat(parseFloat(data5ReasonTotals.other)+otherTotalCost).toFixed(4);
				} else {

					if (String(aReasonArray[key].toLowerCase()).includes("associated project")) {

						var assoProjectTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							assoProjectTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
							data5Totals.purchaseQty = parseFloat(parseFloat(data5Totals.purchaseQty)+qtyPurchaseValue).toFixed(0);
						} else {
							assoProjectTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
							data5Totals.purchaseQty = parseFloat(parseFloat(data5Totals.purchaseQty)+qtyPurchaseValue).toFixed(0);
						}

						if (isNaN(assoProjectTotalCost)) {
							assoProjectTotalCost = 0;
						}

						data5ReasonTotals.assoProject = parseFloat(parseFloat(data5ReasonTotals.assoProject)+assoProjectTotalCost).toFixed(4);

					} else if (String(aReasonArray[key].toLowerCase()).includes("r & d")) {
						var rndTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							rndTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
							data5Totals.otherQtyNeg = parseFloat(parseFloat(data5Totals.otherQtyNeg)+qtyIssueValue).toFixed(0);
						} else {
							rndTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
							data5Totals.otherQtyPos = parseFloat(parseFloat(data5Totals.otherQtyPos)+qtyPurchaseValue).toFixed(0);
						}

						if (isNaN(rndTotalCost)) {
							rndTotalCost = 0;
						}

						data5ReasonTotals.rnd = parseFloat(parseFloat(data5ReasonTotals.rnd)+rndTotalCost).toFixed(4);
					} else if (String(aReasonArray[key].toLowerCase()).includes("purchase (for positive)")) {
						var purchaseTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							purchaseTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
							data5Totals.purchaseQty = parseFloat(parseFloat(data5Totals.purchaseQty)+qtyIssueValue).toFixed(0);
						} else {
							purchaseTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
							data5Totals.purchaseQty = parseFloat(parseFloat(data5Totals.purchaseQty)+qtyPurchaseValue).toFixed(0);
						}

						if (isNaN(purchaseTotalCost)) {
							purchaseTotalCost = 0;
						}

						data5ReasonTotals.purchase = parseFloat(parseFloat(data5ReasonTotals.purchase)+purchaseTotalCost).toFixed(4);
					} else if (String(aReasonArray[key].toLowerCase()).includes("inventory adjustment (shortage)")) {
						var iAShortageTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							iAShortageTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
							data5Totals.otherQtyNeg = parseFloat(parseFloat(data5Totals.otherQtyNeg)+qtyIssueValue).toFixed(0);
						} else {
							iAShortageTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
							data5Totals.otherQtyPos = parseFloat(parseFloat(data5Totals.otherQtyPos)+qtyPurchaseValue).toFixed(0);
						}

						if (isNaN(iAShortageTotalCost)) {
							iAShortageTotalCost = 0;
						}

						data5ReasonTotals.iAShortage = parseFloat(parseFloat(data5ReasonTotals.iAShortage)+iAShortageTotalCost).toFixed(4);
					} else if (String(aReasonArray[key].toLowerCase()).includes("inventory adjustment (overage)")) {
						var iAOverageTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							iAOverageTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
							data5Totals.otherQtyNeg = parseFloat(parseFloat(data5Totals.otherQtyNeg)+qtyIssueValue).toFixed(0);
						} else {
							iAOverageTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
							data5Totals.otherQtyPos = parseFloat(parseFloat(data5Totals.otherQtyPos)+qtyPurchaseValue).toFixed(0);
						}

						if (isNaN(iAOverageTotalCost)) {
							iAOverageTotalCost = 0;
						}

						data5ReasonTotals.iAOverage = parseFloat(parseFloat(data5ReasonTotals.iAOverage)+iAOverageTotalCost).toFixed(4);
					} else if (String(aReasonArray[key].toLowerCase()).includes("repair kit")) {
						var repairKitTotalCost = 0;

						if (itemPreviousQuantity>itemCurrentQuantity) {
							repairKitTotalCost = (parseFloat(qtyIssueValue)*parseFloat(costNumber));
							data5Totals.otherQtyNeg = parseFloat(parseFloat(data5Totals.otherQtyNeg)+qtyIssueValue).toFixed(0);
						} else {
							repairKitTotalCost = (parseFloat(qtyPurchaseValue)*parseFloat(costNumber));
							data5Totals.otherQtyPos = parseFloat(parseFloat(data5Totals.otherQtyPos)+qtyPurchaseValue).toFixed(0);
						}

						if (isNaN(repairKitTotalCost)) {
							repairKitTotalCost = 0;
						}

						data5ReasonTotals.repairKit = parseFloat(parseFloat(data5ReasonTotals.repairKit)+repairKitTotalCost).toFixed(4);
					}

				}

				qtyPurchaseArray.push(qtyPurchaseValue);
				qtyIssueArray.push(qtyIssueValue);
				qtyOtherArray.push(qtyOtherValue);

			});

			var allItems = [];

			allComponentItems.map((componentItem, key) => {
				//console.log('componentItem Quantity: ' + componentItem.Quantity);
				//console.log('componentItem ComponentItemID: ' + componentItem.ComponentItemID);
				//console.log('componentItem Timestamp: ' + componentItem.Timestamp);

				var tempQtyVl = "-";
				qtyPurchaseArray.push(tempQtyVl);
				var tempIssueVl = "-";
				qtyIssueArray.push(tempQtyVl);

				var tempOtherVl = parseFloat(componentItem.Quantity);
				qtyOtherArray.push(tempOtherVl);

				var tempName = "Repair Technician";
				aNameArray.push(tempName);

				var tempReason = "Repair";
				aReasonArray.push(tempReason);

				//var tempTime = "Other - Repair"
				aTimeArray.push(componentItem.Timestamp);

				data5Totals.repairQty = parseFloat(parseFloat(data5Totals.repairQty)+tempOtherVl).toFixed(0);
				data4ReasonTotals.repair = parseFloat(parseFloat(data4ReasonTotals.repair)+(parseFloat(tempOtherVl)*parseFloat(costNumber))).toFixed(4);

			});

			aTimeArray.map((aTime, key) => {

				var tempTime = aTime;
				var tempPurchase = qtyPurchaseArray[key];
				var tempIssue = qtyIssueArray[key];
				var tempOther = qtyOtherArray[key];
				var tempReason = aReasonArray[key];
				var tempName = aNameArray[key];

				var anItem = {
					time: tempTime,
					purchaseQty: tempPurchase,
					issueQty: tempIssue,
					otherQty: tempOther,
					reason: tempReason,
					name: tempName
				}

				allItems.push(anItem);

			});

			var allItemsSorted = allItems.sort((a, b) => {
				const timeA = a.time.toUpperCase(); // ignore upper and lowercase
			  const timeB = b.time.toUpperCase(); // ignore upper and lowercase
			  if (timeA < timeB) {
			    return -1;
			  }
			  if (timeA > timeB) {
			    return 1;
			  }

			  // names must be equal
			  return 0;
			});

			data5Total = (parseFloat(data5Total)+parseFloat(extValue)).toFixed(4);

			var bomQuantity = 0;

			if (bomRecord) {
				bomQuantity = bomRecord.Quantity
			}

			var qtyTotal = parseFloat(bomQuantity) + parseFloat(data5Totals.purchaseQty) - parseFloat(data5Totals.repairQty) - parseFloat(data5Totals.otherQtyNeg) + parseFloat(data5Totals.otherQtyPos);
			var discrepancyValue = 0;
			var discrepancyIsPositive = true;

			if (eomRecord) {
				if (qtyTotal > eomRecord.Quantity) {
					discrepancyValue = parseFloat(qtyTotal) - parseFloat(eomRecord.Quantity);
					discrepancyIsPositive = true;
				} else {
					discrepancyValue = parseFloat(eomRecord.Quantity) - parseFloat(qtyTotal);
					discrepancyIsPositive = false;
				}
			}

			return {
				id: key,
				logID: prop.id,
				PartNumber: prop.PartNumber,
        Cost: prop.Cost,
				PreviousQuantity: pQuantityArray[0],
				CurrentQuantity: prop.cQuantity,
				Description: prop.Description,
				AdjustmentReason: prop.aReason,
				EOMValue: eomValue,
				allItemsSorted: allItemsSorted,
				ExtValue: extValue,
				productsArray: productsArray,
				purchaseQty: data5Totals.purchaseQty,
				repairQty: data5Totals.repairQty,
				otherQtyNeg: data5Totals.otherQtyNeg,
				otherQtyPos: data5Totals.otherQtyPos,
				bomQuantity: bomRecord ? bomRecord.Quantity : 0,
				eomQuantity: eomRecord ? eomRecord.Quantity : 0,
				difference: { discrepancyValue, discrepancyIsPositive},
				calcEOMQty: qtyTotal,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		console.log('data5Total ' + data5Total);
		console.log('data5ReasonTotals: ' + JSON.stringify(data5ReasonTotals));

		var nonAdjustedInventoryTotal = 0;

		if (data5.length>0) {
			this.state.bomData.map((prop, key) => {

				const bomArray = data5.filter(item => item.PartNumber == prop.PartNumber);

				if (bomArray.length==0) {
					//console.log('part not in data5: '+prop.PartNumber);

					var costNumber = prop.Cost.replace("$", "");
					var extValue = (parseFloat(costNumber) * parseFloat(prop.Quantity)).toFixed(4);

					if (isNaN(extValue)) {
						extValue = 0;
					}

					nonAdjustedInventoryTotal = (parseFloat(nonAdjustedInventoryTotal)+parseFloat(extValue)).toFixed(4);
					data5Total = (parseFloat(data5Total)+parseFloat(extValue)).toFixed(4);
				} else {
					//console.log('part is in data5: '+bomArray[0].PartNumber);
				}

			});

			console.log('Revised data5Total: ' + data5Total);
		}

		var componentArrayFiltered = [];

		this.state.componentItems.map((component, key) => {

			const filteredData5 = this.state.inventoryReportData.filter(data => data.PartNumber == component.PartNumber);

			console.log('filteredData5: ' + filteredData5.length);

			if (filteredData5.length==0) {
				componentArrayFiltered.push(component);
			}

		});

		console.log('componentArrayFiltered length: ' + JSON.stringify(componentArrayFiltered));

		var componentArrayFilteredGrouped = [];
		var componentArrayIndex = 0;

		componentArrayFiltered.map((component, key) => {

			var obj = {
				index: componentArrayIndex,
				PartNumber: component.PartNumber,
				Quantity: component.Quantity,
				Cost: component.Cost,
				ProductName: component.ProductName,
				Description: component.Description
			};

			if (componentArrayFilteredGrouped.length>0) {
				const existingComponent = componentArrayFilteredGrouped.find(data => data.PartNumber == component.PartNumber);

				console.log('existingComponent: ' + JSON.stringify(existingComponent));
				console.log('componentArrayFilteredGrouped: ' + JSON.stringify(componentArrayFilteredGrouped));

				if (existingComponent) {
					var i = parseInt(existingComponent.index);
					componentArrayFilteredGrouped[i].Quantity = componentArrayFilteredGrouped[i].Quantity + component.Quantity;
				} else {
					componentArrayFilteredGrouped.push(obj);
					componentArrayIndex++;
				}
			} else {
				componentArrayFilteredGrouped.push(obj);
				componentArrayIndex++;
			}

		});

		console.log('componentArrayFilteredGrouped: ' + JSON.stringify(componentArrayFilteredGrouped));

		var indexAdd = data5.length+1;

		componentArrayFilteredGrouped.map((component, key) => {

			var bomRecord = this.state.inventoryLogForBOM.find(log => log.PartNumber == component.PartNumber);
			var eomRecord = this.state.inventoryLogForEOM.find(log => log.PartNumber == component.PartNumber);

			console.log('componentArrayFilteredGrouped component: ' + JSON.stringify(component));
			console.log('componentArrayFilteredGrouped bomRecord: ' + JSON.stringify(bomRecord));
			console.log('componentArrayFilteredGrouped eomRecord: ' + JSON.stringify(eomRecord));

			var costNumber = component.Cost.replace("$", "");
			var extValue = 0;

			if (eomRecord) {
				var extValue = (parseFloat(costNumber) * parseFloat(eomRecord.Quantity)).toFixed(4);

				if (isNaN(extValue)) {
					extValue = 0;
				}
			}

			const filteredBOM = this.state.bomData.filter(bom => bom.PartNumber == component.PartNumber);

			var productsArray = [];

			filteredBOM.map((prop, key) => {
				//console.log('filteredBOM ' + prop.id);
				productsArray.push(prop.productName);

			});

			var bomQuantity = 0;

			if (bomRecord) {
				bomQuantity = bomRecord.Quantity
			}

			var qtyTotal = parseFloat(bomQuantity) - parseFloat(component.Quantity);
			var discrepancyValue = 0;
			var discrepancyIsPositive = true;

			if (eomRecord) {
				if (qtyTotal > eomRecord.Quantity) {
					discrepancyValue = parseFloat(qtyTotal) - parseFloat(eomRecord.Quantity);
					discrepancyIsPositive = true;
				} else {
					discrepancyValue = parseFloat(eomRecord.Quantity) - parseFloat(qtyTotal);
					discrepancyIsPositive = false;
				}
			}

			var additonalItem = {
				id: indexAdd,
				logID: component.index,
				PartNumber: component.PartNumber,
				Cost: component.Cost,
				PreviousQuantity: bomRecord ? bomRecord.Quantity : 0,
				CurrentQuantity: eomRecord ? eomRecord.Quantity : 0,
				Description: component.Description,
				AdjustmentReason: "Repair",
				EOMValue: eomRecord ? eomRecord.Quantity : 0,
				allItemsSorted: [],
				ExtValue: extValue,
				productsArray: productsArray,
				purchaseQty: 0,
				repairQty: component.Quantity,
				otherQtyNeg: 0,
				otherQtyPos: 0,
				bomQuantity: bomRecord ? bomRecord.Quantity : 0,
				eomQuantity: eomRecord ? eomRecord.Quantity : 0,
				difference: { discrepancyValue, discrepancyIsPositive},
				calcEOMQty: qtyTotal,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
								console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			}

			data5.push(additonalItem);

		});

		return (
			<Fragment>
				<Row>
					<Col md="12">
						<Card className="card-hover">
							<CardBody>
								<Row className="mb-3">
									<Col md="4">
										<h1 style={{color:'#737782'}}>Inventory Reports</h1>
									</Col>
									<Col md="1" className="">
									</Col>
									<Col md="2" className="">
									{this.state.currentDataSetting!=0 ? (
										<Fragment>
											<Row className="ml-2">
												<Col xs="12" md="12" lg="12" className="">
													<div className="float-left text-right" style={{ width: '50px' }}>
														<h6 style={{ lineHeight: '1.9' }}>From:</h6>
													</div>
													<div className="float-left ml-2" style={{ width: '140px' }}>
														<Input style={{width:'100%', height: '30px'}} name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
													</div>
												</Col>
											</Row>
											<Row className="ml-2">
												<Col xs="12" md="12" lg="12" className="">
													<div className="float-left text-right" style={{ width: '50px' }}>
														<h6 style={{ lineHeight: '1.9' }}>To:</h6>
													</div>
													<div className="float-left ml-2" style={{ width: '140px' }}>
														<Input style={{width:'100%', height: '30px'}} name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
													</div>
												</Col>
											</Row>
										</Fragment>
									) : (
										<Fragment>
											<Row className="ml-2">
												<Col xs="12" md="12" lg="12" className="">
													<div className="float-left text-right" style={{ width: '50px' }}>
														<h6 style={{ lineHeight: '1.9' }}>Date:</h6>
													</div>
													<div className="float-left ml-2" style={{ width: '140px' }}>
														<Input style={{width:'100%', height: '30px'}} name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
													</div>
												</Col>
											</Row>
										</Fragment>
									)}
									</Col>
									<Col md="2" className="">
										<Row className="ml-2">
											<Col xs="12" md="12" lg="12" className="">
												<div className="float-left text-right" style={{ width: '60px' }}>
													<h6 style={{ lineHeight: '1.9' }}>Product:</h6>
												</div>
												<div className="float-left ml-2" style={{ width: '140px' }}>
													<Input style={{height: '30px', padding: '3px'}} type="select" name="productSelect" id="productSelect" value={this.state.productSelect} onChange={this.onChangeProduct}>
														<option value="0">ALL</option>
														{this.state.productsData.map((product, i) => (
															<option value={product.id}>{product.Name}</option>
														))}
													</Input>
												</div>
											</Col>
										</Row>
									</Col>
									<Col md="3" className="text-right">
										<div>
											{
												// <ButtonDropdown className="ml-3" isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
												// 	<DropdownToggle color="dark" caret>
												// 		{this.state.currentItem}
												// 	</DropdownToggle>
												// 	<DropdownMenu right>
												// 		{this.state.reportListData.map((report, i) => (
												// 			<DropdownItem onClick={() => this.selectDropDownItem(report.id, report.name)}>{report.name}</DropdownItem>
												// 		))}
												// 	</DropdownMenu>
												// </ButtonDropdown>
											}
											{
												<Button className="float-right ml-3" color="yellow" onClick={this.toggle}>Create Report</Button>
											}
										</div>
									</Col>
								</Row>
								<Row className="mt-5 mb-1">
									<Col md="12">
										<h3 style={{color:'#737782'}}>{this.state.currentItem}</h3>
									</Col>
								</Row>
								{this.state.currentDataSetting==0 &&
									<ReactTable
										style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
										columns={[
											{
												Header: "Part Number",
												accessor: "PartNumber",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Product",
												accessor: "ProductName",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Cost",
												accessor: "Cost",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Quantity",
												accessor: "Quantity",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Extended Value",
												accessor: "ExtValue",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Description",
												accessor: "Description",
												minWidth: 30,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											}
										]}
										defaultPageSize={15}
										sortable={false}
										showPaginationBottom={true}
										className="-striped -highlight"
										data={data1}
										filterable
										getTrGroupProps={(state, rowInfo, column, instance) => {
														if (rowInfo !== undefined) {
																return {
																		onClick: (e, handleOriginal) => {
																			console.log('It was in this row:', rowInfo)
																			console.log('with ID:', rowInfo.original.categoryID);

																			//this.goToRepairLog(rowInfo.original.repairId);
																		},
																		style: {
																				cursor: 'pointer'
																		}
																}
														}}
												}
									>
									{(state, makeTable, instance) => {
				            let recordsInfoText = "";

				            const { filtered, pageRows, pageSize, sortedData, page } = state;

				            if (sortedData && sortedData.length > 0) {
				              let isFiltered = filtered.length > 0;

				              let totalRecords = sortedData.length;

				              let recordsCountFrom = page * pageSize + 1;

				              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

				              if (isFiltered)
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
				              else
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
				            } else recordsInfoText = "No records";

				            return (
				              <div className="main-grid mt-2">
												{makeTable()}
												<Row className="mt-2 text-center">
													<Col md="12">
														<Label style={{fontSize:'15px'}} for=""><b>{recordsInfoText}</b></Label>
													</Col>
												</Row>
				              </div>
				            );
				          }}
				        </ReactTable>
								}
								{this.state.currentDataSetting==1 &&
									<ReactTable
										style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
										columns={[
											{
												Header: "Part Number",
												accessor: "PartNumber",
												minWidth: 20,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Product",
												accessor: "productsArray",
												minWidth: 12,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>
															{row.value.map((product, i) => (
																<Fragment>
																<Col xs="12">
																	<span>{product}</span>
																</Col>
																</Fragment>
																))
															}
														</div>
													)
											},
											{
												Header: "Description",
												accessor: "Description",
												minWidth: 30,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Date",
												accessor: "allItemsSorted",
												minWidth: 14,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>
															{row.value.map((item, i) => (
																<Fragment>
																	<span>{Moment(item.time).format('MM-DD-YYYY')}</span><br/>
																</Fragment>
																))
															}
														</div>
													)
											},
											{
												Header: "Qty Purchases",
												accessor: "allItemsSorted",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>
															{row.value.map((item, i) => (
																<Fragment>
																<Col xs="12">
																	<span>{item.purchaseQty}</span>
																</Col>
																</Fragment>
																))
															}
														</div>
													)
											},
											{
												Header: "Cost",
												accessor: "Cost",
												minWidth: 10,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Ext Value",
												accessor: "ExtValue",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Reason",
												accessor: "allItemsSorted",
												minWidth: 30,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>
															{row.value.map((item, i) => (
																<Fragment>
																<Col xs="12">
																	<span>{item.reason}</span>
																</Col>
																</Fragment>
																))
															}
														</div>
													)
											},
											{
												Header: "Authorized By",
												accessor: "allItemsSorted",
												minWidth: 25,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
													<div style={{ textAlign: "center" }}>
														{row.value.map((item, i) => (
															<Fragment>
															<Col xs="12">
																<span>{item.name}</span>
															</Col>
															</Fragment>
															))
														}
													</div>
													)
											}
										]}
										defaultPageSize={15}
										sortable={false}
										showPaginationBottom={true}
										className="-striped -highlight"
										data={data2}
										filterable
										getTrGroupProps={(state, rowInfo, column, instance) => {
														if (rowInfo !== undefined) {
																return {
																		onClick: (e, handleOriginal) => {
																			console.log('It was in this row:', rowInfo)
																			console.log('with ID:', rowInfo.original.categoryID);

																			//this.goToRepairLog(rowInfo.original.repairId);
																		},
																		style: {
																				cursor: 'pointer'
																		}
																}
														}}
												}
									>
									{(state, makeTable, instance) => {
				            let recordsInfoText = "";

				            const { filtered, pageRows, pageSize, sortedData, page } = state;

				            if (sortedData && sortedData.length > 0) {
				              let isFiltered = filtered.length > 0;

				              let totalRecords = sortedData.length;

				              let recordsCountFrom = page * pageSize + 1;

				              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

				              if (isFiltered)
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
				              else
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
				            } else recordsInfoText = "No records";

				            return (
				              <div className="main-grid mt-2">
												{makeTable()}
												<Row className="mt-2 text-center">
													<Col md="12">
														<Label style={{fontSize:'15px'}} for=""><b>{recordsInfoText}</b></Label>
													</Col>
												</Row>
				              </div>
				            );
				          }}
				        </ReactTable>
								}
								{this.state.currentDataSetting==2 &&
									<ReactTable
										style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
										columns={[
											{
												Header: "Part Number",
												accessor: "PartNumber",
												minWidth: 20,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Product",
												accessor: "productsArray",
												minWidth: 12,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>
															{row.value.map((product, i) => (
																<Fragment>
																<Col xs="12">
																	<span>{product}</span>
																</Col>
																</Fragment>
																))
															}
														</div>
													)
											},
											{
												Header: "Description",
												accessor: "Description",
												minWidth: 30,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "BOM Qty",
												accessor: "bomQuantity",
												minWidth: 10,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Date",
												accessor: "allItemsSorted",
												minWidth: 14,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>
															{row.value.map((item, i) => (
																<Fragment>
																	<span>{Moment(item.time).format('MM-DD-YYYY')}</span><br/>
																</Fragment>
																))
															}
														</div>
													)
											},
											{
												Header: "Qty Purchases",
												accessor: "allItemsSorted",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>
															{row.value.map((item, i) => (
																<Fragment>
																<Col xs="12">
																	<span>{item.purchaseQty}</span>
																</Col>
																</Fragment>
																))
															}
														</div>
													)
											},
											{
												Header: "Qty Issues",
												accessor: "allItemsSorted",
												minWidth: 12,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>
															{row.value.map((item, i) => (
																<Fragment>
																<Col xs="12">
																	<span>{item.issueQty}</span>
																</Col>
																</Fragment>
																))
															}
														</div>
													)
											},
											{
												Header: "Qty Other",
												accessor: "allItemsSorted",
												minWidth: 12,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>
															{row.value.map((item, i) => (
																<Fragment>
																<Col xs="12">
																	<span>{item.otherQty}</span>
																</Col>
																</Fragment>
																))
															}
														</div>
													)
											},
											{
												Header: "EOM Qty",
												accessor: "eomQuantity",
												minWidth: 10,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Cost",
												accessor: "Cost",
												minWidth: 10,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Ext Value",
												accessor: "ExtValue",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Reason",
												accessor: "allItemsSorted",
												minWidth: 30,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>
															{row.value.map((item, i) => (
																<Fragment>
																<Col xs="12">
																	<span>{item.reason}</span>
																</Col>
																</Fragment>
																))
															}
														</div>
													)
											},
											{
												Header: "Authorized By",
												accessor: "allItemsSorted",
												minWidth: 25,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
													<div style={{ textAlign: "center" }}>
														{row.value.map((item, i) => (
															<Fragment>
															<Col xs="12">
																<span>{item.name}</span>
															</Col>
															</Fragment>
															))
														}
													</div>
													)
											}
										]}
										defaultPageSize={15}
										sortable={false}
										showPaginationBottom={true}
										className="-striped -highlight"
										data={data3}
										filterable
										getTrGroupProps={(state, rowInfo, column, instance) => {
														if (rowInfo !== undefined) {
																return {
																		onClick: (e, handleOriginal) => {
																			console.log('It was in this row:', rowInfo)
																			console.log('with ID:', rowInfo.original.categoryID);

																			//this.goToRepairLog(rowInfo.original.repairId);
																		},
																		style: {
																				cursor: 'pointer'
																		}
																}
														}}
												}
									>
									{(state, makeTable, instance) => {
				            let recordsInfoText = "";

				            const { filtered, pageRows, pageSize, sortedData, page } = state;

				            if (sortedData && sortedData.length > 0) {
				              let isFiltered = filtered.length > 0;

				              let totalRecords = sortedData.length;

				              let recordsCountFrom = page * pageSize + 1;

				              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

				              if (isFiltered)
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
				              else
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
				            } else recordsInfoText = "No records";

				            return (
				              <div className="main-grid mt-2">
												{makeTable()}
												<Row className="mt-2 text-center">
													<Col md="12">
														<Label style={{fontSize:'15px'}} for=""><b>{recordsInfoText}</b></Label>
													</Col>
												</Row>
				              </div>
				            );
				          }}
				        </ReactTable>
								}
								{this.state.currentDataSetting==3 &&
									<ReactTable
										style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
										columns={[
											{
												Header: "Part Number",
												accessor: "PartNumber",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Product",
												accessor: "productsArray",
												minWidth: 10,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>
															{row.value.map((product, i) => (
																<Fragment>
																<Col xs="12">
																	<span>{product}</span>
																</Col>
																</Fragment>
																))
															}
														</div>
													)
											},
											{
												Header: "Description",
												accessor: "Description",
												minWidth: 30,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "BOM Qty",
												accessor: "bomQuantity",
												minWidth: 8,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Qty Purchases",
												accessor: "purchaseQty",
												minWidth: 12,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Qty Repair",
												accessor: "repairQty",
												minWidth: 10,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Qty Other (-)",
												accessor: "otherQtyNeg",
												minWidth: 12,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Qty Other (+)",
												accessor: "otherQtyPos",
												minWidth: 12,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Calc EOM Qty",
												accessor: "calcEOMQty",
												minWidth: 12,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Act EOM Qty",
												accessor: "eomQuantity",
												minWidth: 12,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Difference",
												accessor: "difference",
												minWidth: 10,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>
															{row.value.discrepancyValue==0 ? (
																<Fragment>
																	<div style={{ textAlign: "center" }}>{row.value.discrepancyValue}</div>
																</Fragment>
															) : (
																<Fragment>
																	{row.value.discrepancyIsPositive ? (
																		<Fragment>
																			<div style={{ textAlign: "center", color: "#33d134" }}>{row.value.discrepancyValue}</div>
																		</Fragment>
																	) : (
																		<Fragment>
																			<div style={{ textAlign: "center", color: "#e31738" }}>{row.value.discrepancyValue}</div>
																		</Fragment>
																	)}
																</Fragment>
															)}
														</div>
													)
											},
											{
												Header: "Cost",
												accessor: "Cost",
												minWidth: 10,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
											{
												Header: "Ext Value",
												accessor: "ExtValue",
												minWidth: 15,
												style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
												Cell: row => (
														<div style={{ textAlign: "center" }}>{row.value}</div>
													)
											},
										]}
										defaultPageSize={15}
										sortable={false}
										showPaginationBottom={true}
										className="-striped -highlight"
										data={data5}
										filterable
										getTrGroupProps={(state, rowInfo, column, instance) => {
														if (rowInfo !== undefined) {
																return {
																		onClick: (e, handleOriginal) => {
																			console.log('It was in this row:', rowInfo)
																			console.log('with ID:', rowInfo.original.categoryID);

																			//this.goToRepairLog(rowInfo.original.repairId);
																		},
																		style: {
																				cursor: 'pointer'
																		}
																}
														}}
												}
									>
									{(state, makeTable, instance) => {
				            let recordsInfoText = "";

				            const { filtered, pageRows, pageSize, sortedData, page } = state;

				            if (sortedData && sortedData.length > 0) {
				              let isFiltered = filtered.length > 0;

				              let totalRecords = sortedData.length;

				              let recordsCountFrom = page * pageSize + 1;

				              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

				              if (isFiltered)
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
				              else
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
				            } else recordsInfoText = "No records";

				            return (
				              <div className="main-grid mt-2">
												{makeTable()}
												<Row className="mt-2 text-center">
													<Col md="12">
														<Label style={{fontSize:'15px'}} for=""><b>{recordsInfoText}</b></Label>
													</Col>
												</Row>
				              </div>
				            );
				          }}
				        </ReactTable>
								}
							</CardBody>
						</Card>
					</Col>
				</Row>
				<div>
					<Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
						<ModalHeader toggle={this.toggle}>Repair List</ModalHeader>
						<ModalBody>
							<PDFViewer style={{ height: "650px", width: "100%"}}>
								{
									//<GenFactoryWarranty serialNumber={this.state.currentDevice} warrantyLength={this.state.device.WarrantyLength} />
								}
								{this.state.currentDataSetting==0 &&
									<PDFReport1 data={data1} total={data1Total} />
								}
								{this.state.currentDataSetting==1 &&
									<PDFReport2 data={data2} total={data2Total} />
								}
								{this.state.currentDataSetting==2 &&
									<PDFReport3 data={data3} total={data3Total} reasonTotals={data3ReasonTotals} />
								}
								{this.state.currentDataSetting==3 &&
									<PDFReport4 data={data5} total={data5Total} nonAdjTotal={nonAdjustedInventoryTotal} startDate={this.state.startDate} endDate={this.state.endDate} bomData={this.state.inventoryLogForBOM} eomData={this.state.inventoryLogForEOM}/>
								}
							</PDFViewer>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" className="ml-1" onClick={this.toggle}>DONE</Button>
						</ModalFooter>
					</Modal>
				</div>
			</Fragment>
		);
	}
}

export default Reports;
