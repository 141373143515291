import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class GeminiRemoteSupportView extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			serialsData: [],
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		//this.toggle4 = this.toggle4.bind(this);

  }


	async componentDidMount() {
		this.getSerialNumbersData();
		//this.intervalID = setInterval(this.getBOMData, 5000);
	}

	componentWillUnmount() {
		//console.log("Laser-Usage componentWillUnmount");
		clearInterval(this.intervalID);
	}

	getSerialNumbersData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-serial-numbers";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all serial Numbers: " + JSON.stringify(body));

			if (body.status == 'Success') {

				var serials = body.response;

				// const revisedSerials = [];
				// serials.map((serial, i) => {
				//
				// 	if (serial.SerialNumber.includes('EL-')) {
				// 		var revisedSerial = serial.SerialNumber.substring(serial.SerialNumber.length - 5)
				// 		revisedSerials.push(String(revisedSerial));
				// 	} else {
				// 		revisedSerials.push(String(serial.SerialNumber));
				// 	}
				//
				// });
				//
				// console.log("revised serial Numbers: " + JSON.stringify(revisedSerials));

				this.setState({ serialsData: serials });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));

		if (id == 'FirmwareDate') {
			var dateString = Moment(row[id]).format('MM/DD/YYYY');
			return (
					dateString !== undefined ?
							String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}

  render() {

		const data = this.state.serialsData.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				SerialNumber: prop.SerialNumber,
				BatchNumber: prop.BatchNumber,
        FirstName: prop.FirstName,
				LastName: prop.LastName,
				FirmwareVersion: prop.firmware_version,
				FirmwareDate: prop.firmware_date,
				Email: prop.email,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									obj: obj,
									modal: !this.state.modal,
								});
								//this.showVerification(obj);
							}}
							color="yellow"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal2: !this.state.modal2,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal2: !this.state.modal2,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		return (
			<Fragment>
				<Row>
					<Col md="12">
						<Card className="card-hover">
							<CardBody>
								<Row className="mb-3">
									<Col md="6">
										<h1 style={{color:'#737782'}}>Gemini Serials</h1>
									</Col>
								</Row>
								<ReactTable
		              style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
		              columns={[
		                {
		                  Header: "Serial",
		                  accessor: "SerialNumber",
		                  style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
		                  Cell: row => (
		                      <div style={{ textAlign: "center" }}>{row.value}</div>
		                    )
		                },
										{
		                  Header: "Batch",
		                  accessor: "BatchNumber",
		                  style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
		                  Cell: row => (
		                      <div style={{ textAlign: "center" }}>{row.value}</div>
		                    )
		                },
										{
		                  Header: "First Name",
		                  accessor: "FirstName",
		                  style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
		                  Cell: row => (
		                      <div style={{ textAlign: "center" }}>{row.value}</div>
		                    )
		                },
										{
		                  Header: "Last Name",
		                  accessor: "LastName",
		                  style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
		                  Cell: row => (
		                      <div style={{ textAlign: "center" }}>{row.value}</div>
		                    )
		                },
										{
		                  Header: "Firmware Version",
		                  accessor: "FirmwareVersion",
		                  style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
		                  Cell: row => (
		                      <div style={{ textAlign: "center" }}>{row.value}</div>
		                    )
		                },
										{
		                  Header: "Firmware Date",
		                  accessor: "FirmwareDate",
		                  style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
		                  Cell: row => (
		                      <div style={{ textAlign: "center" }}>{Moment(row.value).format('MM/DD/YYYY')}</div>
		                    )
		                },
										{
		                  Header: "Email",
		                  accessor: "Email",
		                  style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
		                  Cell: row => (
		                      <div style={{ textAlign: "center" }}>{row.value}</div>
		                    )
		                },
		              ]}
		              defaultPageSize={15}
		              sortable={true}
		              showPaginationBottom={true}
		              className="-striped -highlight"
		              data={data}
		              filterable
									filterAll={true}
									defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
		              getTrGroupProps={(state, rowInfo, column, instance) => {
		                      if (rowInfo !== undefined) {
		                          return {
		                              onClick: (e, handleOriginal) => {
		                                console.log('It was in this row:', rowInfo)
		                                console.log('with ID:', rowInfo.original.categoryID);

		                                //this.goToRepairLog(rowInfo.original.repairId);
		                              },
		                              style: {

		                              }
		                          }
		                      }}
		                  }
		            >
								{(state, makeTable, instance) => {
				            let recordsInfoText = "";

				            const { filtered, pageRows, pageSize, sortedData, page } = state;

				            if (sortedData && sortedData.length > 0) {
				              let isFiltered = filtered.length > 0;

				              let totalRecords = sortedData.length;

				              let recordsCountFrom = page * pageSize + 1;

				              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

				              if (isFiltered)
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
				              else
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
				            } else recordsInfoText = "No records";

				            return (
				              <div className="main-grid mt-2">
												{makeTable()}
												<Row className="mt-2 text-center">
													<Col md="12">
														<Label style={{fontSize:'15px'}} for=""><b>{recordsInfoText}</b></Label>
													</Col>
												</Row>
				              </div>
				            );
				          }}
				        </ReactTable>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Fragment>
		);
	}
}

export default GeminiRemoteSupportView;
