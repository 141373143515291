import React, { useState } from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import { pdf, Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';
import html2canvas from "html2canvas";
import { getEmailHTML, getEmailText } from "./get-email-html";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	Collapse
} from 'reactstrap';

import update from 'react-addons-update';
import ReactPlayer from 'react-player';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';
import { Lightbox } from 'react-lightbox-pack'; // <--- Importing LightBox Pack
import "react-lightbox-pack/dist/index.css";
import '../../../assets/calculator/css/calculator.css';
//import { Editor } from 'react-draft-wysiwyg';
//import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
//import { Editor } from "@tinymce/tinymce-react";
import videoPlaceholder from '../../../assets/images/video.png';
import pdfPlaceholder from '../../../assets/images/pdf-icon-2.png';
import logo from '../../../assets/images/logo.png';
import signature from '../../../assets/images/signature.png';

import Autocomplete from "react-google-autocomplete";

import TextField from '@material-ui/core/TextField';
import AutoCompleteInput from '@material-ui/lab/Autocomplete';
import { createTheme, ThemeProvider, styled } from '@material-ui/core/styles';
import yellow from '@material-ui/core/colors/yellow';
import grey from '@material-ui/core/colors/grey';

//Google: AIzaSyDgx8tS6g3gcG_7GVgQRmN5FziAW-EMRSM
const YOUR_GOOGLE_MAPS_API_KEY = 'AIzaSyAURs2M8E46FUeKemjvViueBAODjIY9-qU';

const theme = createTheme({
	palette: {
    primary: yellow,
		type: "dark",
  },
});

const autocompleteStyles = (theme) => ({
	listbox: {
		padding: 0,
	},
	option: {
		borderBottom: `1px solid ${theme.palette.system[5]}`,
		// Hover
		'&[data-focus="true"]': {
			backgroundColor: theme.palette.secondSurface.light,
			borderColor: 'transparent',
			color: '#fff',
		},
		// Selected
		'&[aria-selected="true"]': {
			backgroundColor: theme.palette.secondSurface.main,
			borderColor: 'transparent',
			color: '#fff',
		},
	},
	root: {
		'& .MuiAutocomplete-input': {
			color: '#fff',
		},
	}
});

class RepairLogModalView extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));
		console.log('props info: ' + JSON.stringify(this.props.repairID));
		//this.props.match.params.id

    this.state = {
			repairLog: {},
			isOneOpen: true,
			sectionOneTitle: "Close",
			isTwoOpen: true,
			sectionTwoTitle: "Close",
			isThreeOpen: true,
			sectionThreeTitle: "Close",
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			detailedIssue: "",
			detailedRepair: "",
			customerNotes: "",
			repairCategories: [],
			repairOptions: [],
			serialNumbers: [],
			serialNumbersAll: [],
			bomData: [],
			tamData: [],
			repairCustomerNotes: [],
			isNewRepairLog: true,
			selectedFileOne: null,
			loadedOne: false,
			filePreviewOne: null,
			selectedFileTwo: null,
			loadedTwo: false,
			filePreviewTwo: null,
			selectedFileThree: null,
			loadedThree: false,
			filePreviewThree: null,
			selectedFilePurchaseOrder: null,
			loadedPurchaseOrder: false,
			filePreviewPurchaseOrder: null,
			sectionOneFiles: [],
			sectionTwoFiles: [],
			sectionThreeFiles: [],
			purchaseOrderFiles: [],
			lightBoxToggle: false,
			lightBoxIndex: 0,
			lightBoxDataIndex: 1,
			modal: false,
			modal2: false,
			modal3: false,
			modal4: false,
			modal5: false,
			modal6: false,
			modal7: false,
			mediaHash: '',
			file: {},
			invoiceArray: [],
			invoiceTotal: "",
			invoiceNumber: "",
			invoiceDate: Moment().format('MM-DD-YYYY'),
			invoicePDFName: "",
			customerReportPDFName: "",
			invoiceSerialNumber: "",
			invoiceRepairID: "",
			statusSubject: "Failed",
			statusMessage: "Failed to send email.",
			testImageFile: null,
			isUPI: false,
			customerTracking: "",
			customerEmail: "",
			rmaNumber: "",
			customerDaysInService: 0
	  };

		this.toggleOne = this.toggleOne.bind(this);
		this.toggleTwo = this.toggleTwo.bind(this);
		this.toggleThree = this.toggleThree.bind(this);
		this.onChangeHandlerOne = this.onChangeHandlerOne.bind(this);
		this.onChangeHandlerTwo = this.onChangeHandlerTwo.bind(this);
		this.onChangeHandlerThree = this.onChangeHandlerThree.bind(this);
		this.onClickHandlerOne = this.onClickHandlerOne.bind(this);
		this.onClickHandlerTwo = this.onClickHandlerTwo.bind(this);
		this.onClickHandlerThree = this.onClickHandlerThree.bind(this);

		this.onChange = this.onChange.bind(this);
		this.onChangeSerialNumber = this.onChangeSerialNumber.bind(this);
		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.toggle3 = this.toggle3.bind(this);
		this.toggle4 = this.toggle4.bind(this);
		this.toggle5 = this.toggle5.bind(this);
		this.toggle6 = this.toggle6.bind(this);
		this.toggle7 = this.toggle7.bind(this);

		this.createSnapShot = this.createSnapShot.bind(this);

  }

	async componentDidMount() {

		if (this.state.currentUser.payload['custom:market'] && (!this.state.currentUser.payload['custom:role'] || this.state.currentUser.payload['custom:role']==0)) {
			this.setState({ isUPI: true });
		}

		var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		console.log('random mediaHash: ' + hash);
		//console.log('email html: ' + getEmailHTML("Ryan Stickel Ryan Stickel Ryan Stickel Ryan Stickel"));
		//this.sendEmailToAzena("00001");

		this.setState({ mediaHash: hash });

		if (this.props.repairID) {
			this.getData();
			this.setState({isNewRepairLog: false});
		} else {
			this.setState({isTwoOpen: false, isThreeOpen: false});
		}

		this.getSerialNumbersData();
		this.getCustomerNotesData();
		this.getCategoryData();
		this.getOptionsData();
		this.getBOMData();
		this.getTAMData();

		// // a string
		// const str = "Hey. this is a string!";
		//
		// // convert string to Buffer
		// const buff = Buffer.from(str, "utf8");
		//
		// console.log(buff);
		//
		// var string = Buffer.from(buff, 'utf8');
		//
		// console.log("string: "+string);

		// var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		// console.log('random hash: ' + hash);

		//Testing
		setTimeout(this.toggle7, 5000);

	}

	lightBoxHandler(dataIndex, imageIndex) {
		this.toggle();
		this.setState({ lightBoxDataIndex: dataIndex, lightBoxIndex: imageIndex });
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	toggle2() {
	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle3() {
	  this.setState({
	    modal3: !this.state.modal3
	  });
	}

	toggle4() {
	  this.setState({
	    modal4: !this.state.modal4
	  });
	}

	toggle5() {
	  this.setState({
	    modal5: !this.state.modal5
	  });
	}

	toggle6() {
	  this.setState({
	    modal6: !this.state.modal6
	  });
	}

	toggle7() {
	  this.setState({
	    modal7: !this.state.modal7
	  });
	}

	componentWillUnmount() {

	}

	getData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-repair-log?RepairLogID="+this.props.repairID;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("repair Log: " + JSON.stringify(body));

		this.setState({loading: false});

		if (body.status == 'Success') {
			this.setState({ repairLog: body.response[0] });

			var buf1;
			var buf2;
			var buf3;

			if (body.response[0].DetailedIssue) {
				buf1 = Buffer.from(body.response[0].DetailedIssue, 'utf8');
			}

			if (body.response[0].DetailedRepair) {
				buf2 = Buffer.from(body.response[0].DetailedRepair, 'utf8');
			}

			if (body.response[0].CustomerNotes) {
				buf3 = Buffer.from(body.response[0].CustomerNotes, 'utf8');
			}

			if (buf1) {
				this.setState({ detailedIssue: buf1.toString() });
			}

			if (buf2) {
				this.setState({ detailedRepair: buf2.toString() });
			}

			if (buf3) {
				this.setState({ customerNotes: buf3.toString() });
			}

			if (body.response[0].MediaHash) {
				this.setState({ mediaHash: body.response[0].MediaHash });
			}

			this.getFilesForRepair(0);
			this.getFilesForRepair(1);
			this.getFilesForRepair(2);
			this.getFilesForRepair(3);

		} else {
      //ADD ERROR MESSAGE
    }

	};

	getCustomerNotesData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-customer-notes";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all repairCategories: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ repairCustomerNotes: body.response });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	getTAMData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-tam-names";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all TAM data: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ tamData: body.response });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	getBOMData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-bill-of-materials";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all BOM data: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ bomData: body.response });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	getCategoryData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-repair-categories";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all repairCategories: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ repairCategories: body.response });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	getOptionsData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-repair-options";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all repairOptions: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ repairOptions: body.response });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	getSerialNumbersData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-serial-numbers";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all serial Numbers: " + JSON.stringify(body));

		if (body.status == 'Success') {

			var serials = body.response;

			const revisedSerials = [];
			serials.map((serial, i) => {

				if (serial.SerialNumber.includes('EL-')) {
					var revisedSerial = serial.SerialNumber.substring(serial.SerialNumber.length - 5)
					revisedSerials.push(String(revisedSerial));
				} else {
					revisedSerials.push(String(serial.SerialNumb));
				}

			});

			console.log("revised serial Numbers: " + JSON.stringify(revisedSerials));

			this.setState({ serialNumbers: revisedSerials, serialNumbersAll: serials });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	generateInvoice = async () => {

		var issueBOM1 = document.getElementById("IssueBOM1").value;
		console.log("issueBOM1: " + issueBOM1);
		var issueBOM2 = document.getElementById("IssueBOM2").value;
		console.log("issueBOM2: " + issueBOM2);
		var issueBOM3 = document.getElementById("IssueBOM3").value;
		console.log("issueBOM3: " + issueBOM3);
		var issueBOM4 = document.getElementById("IssueBOM4").value;
		console.log("issueBOM4: " + issueBOM4);
		var issueBOM5 = document.getElementById("IssueBOM5").value;
		console.log("issueBOM5: " + issueBOM5);
		var issueBOM6 = document.getElementById("IssueBOM6").value;
		console.log("issueBOM6: " + issueBOM6);
		var issueBOM1Quantity = document.getElementById("IssueBOM1Quantity").value;
		console.log("issueBOM1Quantity: " + issueBOM1Quantity);
		var issueBOM2Quantity = document.getElementById("IssueBOM2Quantity").value;
		console.log("issueBOM2Quantity: " + issueBOM2Quantity);
		var issueBOM3Quantity = document.getElementById("IssueBOM3Quantity").value;
		console.log("issueBOM3Quantity: " + issueBOM3Quantity);
		var issueBOM4Quantity = document.getElementById("IssueBOM4Quantity").value;
		console.log("issueBOM4Quantity: " + issueBOM4Quantity);
		var issueBOM5Quantity = document.getElementById("IssueBOM5Quantity").value;
		console.log("issueBOM5Quantity: " + issueBOM5Quantity);
		var issueBOM6Quantity = document.getElementById("IssueBOM6Quantity").value;
		console.log("issueBOM6Quantity: " + issueBOM6Quantity);

		var invoiceArray = [];
		var invoiceTotal = 0;

		if (issueBOM1Quantity>0) {
			const result = this.state.bomData.filter(bom => bom.id==issueBOM1);

			var total = parseInt(result[0].Cost)*parseInt(issueBOM1Quantity);

			var invoiceRow = {
				PartNumber: result[0].PartNumber,
				Description: result[0].Description,
				Cost: "$"+result[0].Cost,
				Quantity: issueBOM1Quantity,
				Total: "$"+total
			}

			invoiceTotal = invoiceTotal+total;

			console.log("invoiceRow: " + JSON.stringify(invoiceRow));
			invoiceArray.push(invoiceRow);
		}

		if (issueBOM2Quantity>0) {
			const result = this.state.bomData.filter(bom => bom.id==issueBOM2);

			var total = parseInt(result[0].Cost)*parseInt(issueBOM2Quantity);

			var invoiceRow = {
				PartNumber: result[0].PartNumber,
				Description: result[0].Description,
				Cost: "$"+result[0].Cost,
				Quantity: issueBOM2Quantity,
				Total: "$"+total
			}

			invoiceTotal = invoiceTotal+total;

			console.log("invoiceRow: " + JSON.stringify(invoiceRow));
			invoiceArray.push(invoiceRow);
		}

		if (issueBOM3Quantity>0) {
			const result = this.state.bomData.filter(bom => bom.id==issueBOM3);

			var total = parseInt(result[0].Cost)*parseInt(issueBOM3Quantity);

			var invoiceRow = {
				PartNumber: result[0].PartNumber,
				Description: result[0].Description,
				Cost: "$"+result[0].Cost,
				Quantity: issueBOM3Quantity,
				Total: "$"+total
			}

			invoiceTotal = invoiceTotal+total;

			console.log("invoiceRow: " + JSON.stringify(invoiceRow));
			invoiceArray.push(invoiceRow);
		}

		if (issueBOM4Quantity>0) {
			const result = this.state.bomData.filter(bom => bom.id==issueBOM4);

			var total = parseInt(result[0].Cost)*parseInt(issueBOM4Quantity);

			var invoiceRow = {
				PartNumber: result[0].PartNumber,
				Description: result[0].Description,
				Cost: "$"+result[0].Cost,
				Quantity: issueBOM4Quantity,
				Total: "$"+total
			}

			invoiceTotal = invoiceTotal+total;

			console.log("invoiceRow: " + JSON.stringify(invoiceRow));
			invoiceArray.push(invoiceRow);
		}

		if (issueBOM5Quantity>0) {
			const result = this.state.bomData.filter(bom => bom.id==issueBOM5);

			var total = parseInt(result[0].Cost)*parseInt(issueBOM5Quantity);

			var invoiceRow = {
				PartNumber: result[0].PartNumber,
				Description: result[0].Description,
				Cost: "$"+result[0].Cost,
				Quantity: issueBOM5Quantity,
				Total: "$"+total
			}

			invoiceTotal = invoiceTotal+total;

			console.log("invoiceRow: " + JSON.stringify(invoiceRow));
			invoiceArray.push(invoiceRow);
		}

		if (issueBOM6Quantity>0) {
			const result = this.state.bomData.filter(bom => bom.id==issueBOM6);

			var total = parseInt(result[0].Cost)*parseInt(issueBOM6Quantity);

			var invoiceRow = {
				PartNumber: result[0].PartNumber,
				Description: result[0].Description,
				Cost: "$"+result[0].Cost,
				Quantity: issueBOM6Quantity,
				Total: "$"+total
			}

			invoiceTotal = invoiceTotal+total;

			console.log("invoiceRow: " + JSON.stringify(invoiceRow));
			invoiceArray.push(invoiceRow);
		}

		console.log("invoiceArray: " + JSON.stringify(invoiceArray));

		var code = Math.floor(100000 + Math.random() * 900000);
		console.log("random code: "+code);

		console.log("invoiceTotal: "+invoiceTotal);

		var invoiceFormattedTotal = "$"+invoiceTotal;

		//var customerNotes = document.getElementById("customerNotes").value;
		var serialNumber = document.getElementById("SerialNum").value;
		//var customerNotes = document.getElementById("customerNotes").value;

		this.setState({ invoiceArray: invoiceArray, invoiceNumber: code, invoiceTotal: invoiceFormattedTotal, invoiceSerialNumber: serialNumber });

		this.toggle3();

		setTimeout(this.uploadInvoiceToS3, 1000);

	}

	generateCustomerReport = async () => {

		//var customerNotes = document.getElementById("customerNotes").value;
		var serialNumber = document.getElementById("SerialNum").value;
		//var customerNotes = document.getElementById("customerNotes").value;

		var customerTracking = document.getElementById("TrackingCustomer").value;
		var rmaNumber = document.getElementById("RMANum").value;

		var receivedDate = Moment(this.state.repairLog.ReceivedDate);
		var backToCustomerDate = Moment(this.state.repairLog.ReturnedDate);
		var customerDaysInService = Moment.duration(backToCustomerDate.diff(receivedDate)).asDays();
		customerDaysInService = customerDaysInService.toFixed(0);

		var customerEmail = document.getElementById("email").value;

		this.setState({ rmaNumber: rmaNumber, invoiceSerialNumber: serialNumber, customerTracking: customerTracking, customerDaysInService: customerDaysInService, customerEmail: customerEmail });

		this.toggle6();

		setTimeout(this.uploadCustomerReportToS3, 1000);

	}

	sendEmailToAzena = async (repairID) => {

		var upiValue = document.getElementById("UPIClosed").value;

		if (upiValue==1) {
			upiValue = "Yes";
		} else {
			upiValue = "No";
		}

		var mdrValue = document.getElementById("MDRRequired").value;

		if (upiValue==1) {
			mdrValue = "Yes";
		} else {
			mdrValue = "No";
		}

		var incidentValue = document.getElementById("IncidentInvolved").value;

		if (incidentValue==0) {
			incidentValue = "No injury";
		} else if (incidentValue==1) {
			incidentValue = "Minor injury";
		} else if (incidentValue==2) {
			incidentValue = "Major injury";
		} else if (incidentValue==3) {
			incidentValue = "Serious malfunction";
		}

		var mdrJustValue = document.getElementById("IncidentInvolved").value;

		if (mdrJustValue==0) {
			mdrJustValue = "No device related death, serious injury or malfunction that could result in death or serious injury";
		} else if (mdrJustValue==1) {
			mdrJustValue = "Death or serious injury to operator";
		} else if (mdrJustValue==2) {
			mdrJustValue = "Death or serious injury to patient";
		} else if (mdrJustValue==3) {
			mdrJustValue = "Malfunction that could result in death or serious injury";
		}

		var trackDate = document.getElementById("TrackingDateAzena").value;
		trackDate = Moment(trackDate).format('MM-DD-YYYY');

		var expectDate = document.getElementById("ExpectedRepairDate").value;
		expectDate = Moment(expectDate).format('MM-DD-YYYY');

		var originDate = document.getElementById("OriginalShipDate").value;
		originDate = Moment(originDate).format('MM-DD-YYYY');

		let repairInfo = {
			TechnicianLog:document.getElementById("TechnicianLog").innerHTML,
			SerialNum:document.getElementById("SerialNum").value,
			BatchNum:document.getElementById("BatchNum").innerHTML,
			RMANum:document.getElementById("RMANum").value,
			AgileNum:document.getElementById("AgileNum").value,
			UnitType:document.getElementById("UnitType").value,
			DateCreated: Moment().format('MM-DD-YYYY'),
			TamName:document.getElementById("TamName").value,
			TamPhone:document.getElementById("TamPhone").value,
			UPIClosed:upiValue,
			CustomerName:document.getElementById("CustomerName").value,
			Address:document.getElementById("Address").value,
			City:document.getElementById("City").value,
			State:document.getElementById("State").value,
			Zip:document.getElementById("Zip").value,
			email:document.getElementById("email").value,
			Action:document.getElementById("Action").value,
			TrackingNum:document.getElementById("TrackingNum").value,
			TrackingDateAzena:trackDate,
			ExpectedRepairDate:expectDate,
			OriginalShipDate:originDate,
			MDRRequired:mdrValue,
			IncidentInvolved:incidentValue,
			MDRJustification:mdrJustValue,
			DetailedIssue: document.getElementById("detailedIssue").value
		}

		var htmlBody = getEmailHTML(repairInfo);
		var textBody = getEmailText(repairInfo);

		console.log('email html: ' + htmlBody);
		console.log('email text: ' + textBody);

		let requestBody = {
			emailRecipient:"ryan.a.stickel@gmail.com",
			emailSubject:"New Repair Created: "+repairID,
			emailHTMLMessage:htmlBody,
			emailTextMessage:textBody
		}

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-email-to-user";

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				Authorization: this.state.currentUser.jwtToken,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(requestBody)
		});

		const body = await response.json();
		console.log("save-repair-log response: " + JSON.stringify(body));

		if (body.status == 'Success') {
			//this.setState({ repairCategories: body.response });
			console.log("insertId: " + repairID);
			//this.goToRepairLog(repairID);
		} else {
			//ADD ERROR MESSAGE
			//this.goToRepairLog(repairID);
		}

		this.goToRepairLog(repairID);

	}

	updateRepairLog = async () => {

		var old = Moment(this.state.repairLog.DateCreated).isBefore('2021-07-31');;

		console.log("is old: " + old);

		console.log('updateRepairLog mediaHash: ' + this.state.mediaHash);

		// var replacedSD = 0;
		// var replacedFiber = 0;
		// var replacedModule = 0;
		// var replacedMain = 0;
		// var replacedHans = 0;
		// var replacedDisplay = 0;
		// var replacedSpeaker = 0;
		// var replacedBattery = 0;
		// var replacedPedal = 0;
		// var replacedKeypad = 0;
		// var replacedDisplayDriver = 0;
		// var fixedBoardSolder = 0;
		// var replacedFlex16 = 0;
		// var replacedFlex21 = 0;
		// var replacedBox = 0;
		// var replacedFoam = 0;
		//
		// var issueOption1 = null;
		// var issueOption2 = null;
		// var issueOption3 = null;
		// var issueOption4 = null;
		// var issueOption5 = null;
		// var issueOption6 = null;
		// var issueOption7 = null;
		// var issueOption8 = null;
		// var issueOption9 = null;
		// var issueOption10 = null;
		// var issueOption11 = null;
		// var issueOption12 = null;
		//
		// if (old) {
		//
		// 	replacedSD = document.getElementById("ReplacedSD").checked ? 1 : 0;
		// 	replacedFiber = document.getElementById("ReplacedFiber").checked ? 1 : 0;
		// 	replacedModule = document.getElementById("ReplacedModule").checked ? 1 : 0;
		//  	replacedMain = document.getElementById("ReplacedMain").checked ? 1 : 0;
		// 	replacedHans = document.getElementById("ReplacedHans").checked ? 1 : 0;
		// 	replacedDisplay = document.getElementById("ReplacedDisplay").checked ? 1 : 0;
		// 	replacedSpeaker = document.getElementById("ReplacedSpeaker").checked ? 1 : 0;
		// 	replacedBattery = document.getElementById("ReplacedBattery").checked ? 1 : 0;
		// 	replacedPedal = document.getElementById("ReplacedPedal").checked ? 1 : 0;
		// 	replacedKeypad = document.getElementById("ReplacedKeypad").checked ? 1 : 0;
		// 	replacedDisplayDriver = document.getElementById("ReplacedDisplayDriver").checked ? 1 : 0;
		// 	fixedBoardSolder = document.getElementById("FixedBoardSolder").checked ? 1 : 0;
		// 	replacedFlex16 = document.getElementById("ReplacedFlex16").checked ? 1 : 0;
		// 	replacedFlex21 = document.getElementById("ReplacedFlex21").checked ? 1 : 0;
		// 	replacedBox = document.getElementById("ReplacedBox").checked ? 1 : 0;
		// 	replacedFoam = document.getElementById("ReplacedFoam").checked ? 1 : 0;
		//
		// } else {
		//
		// 	issueOption1 = document.getElementById("IssueOption1").value;
		// 	issueOption2 = document.getElementById("issueOption2").value;
		// 	issueOption3 = document.getElementById("IssueOption3").value;
		// 	issueOption4 = document.getElementById("IssueOption4").value;
		// 	issueOption5 = document.getElementById("IssueOption5").value;
		// 	issueOption6 = document.getElementById("IssueOption6").value;
		// 	issueOption7 = document.getElementById("IssueOption7").value;
		// 	issueOption8 = document.getElementById("IssueOption8").value;
		// 	issueOption9 = document.getElementById("IssueOption9").value;
		// 	issueOption10 = document.getElementById("IssueOption11").value;
		// 	issueOption11 = document.getElementById("IssueOption11").value;
		// 	issueOption12 = document.getElementById("IssueOption12").value;
		//
		// }

		if (this.props.repairID) {

			console.log("detailedIssue: " + document.getElementById("detailedIssue").value);
			//document.getElementById("market").value

			//let detailedIssue = document.getElementById("detailedIssue").value;
			//const detailedIssueBuff = Buffer.from(detailedIssue, "utf8");

			let requestBody = {
				ID:this.props.repairID,
				market:this.state.repairLog.market,
				TechnicianLog:document.getElementById("TechnicianLog").innerHTML,
				SerialNum:document.getElementById("SerialNum").value,
				BatchNum:document.getElementById("BatchNum").innerHTML,
				RMANum:document.getElementById("RMANum").value,
				AgileNum:document.getElementById("AgileNum").value,
				UnitType:document.getElementById("UnitType").value,
				DateCreated:this.state.repairLog.DateCreated,
				TamName:document.getElementById("TamName").value,
				TamPhone:document.getElementById("TamPhone").value,
				CustomerName:document.getElementById("CustomerName").value,
				Address:document.getElementById("Address").value,
				City:document.getElementById("City").value,
				State:document.getElementById("State").value,
				Zip:document.getElementById("Zip").value,
				email:document.getElementById("email").value,
				Action:document.getElementById("Action").value,
				TrackingNum:document.getElementById("TrackingNum").value,
				TrackingDateAzena:document.getElementById("TrackingDateAzena").value,
				ExpectedRepairDate:document.getElementById("ExpectedRepairDate").value,
				OriginalShipDate:document.getElementById("OriginalShipDate").value,
				DetailedIssue: document.getElementById("detailedIssue").value,
				TechnicianRepair:document.getElementById("OriginalShipDate").innerHTML,
				ReceivedDate:document.getElementById("ReceivedDate") ? document.getElementById("ReceivedDate").value : null,
				Status:document.getElementById("Status") ? document.getElementById("Status").value : null,
				DetailedRepair: document.getElementById("detailedRepair") ? document.getElementById("detailedRepair").value : null,
				Firmware:document.getElementById("Firmware") ? document.getElementById("Firmware").value : null,
				NewCalibration:document.getElementById("NewCalibration") ? document.getElementById("NewCalibration").value : null,
				ReplacedSD:document.getElementById("ReplacedSD") && document.getElementById("ReplacedSD").checked ? 1 : 0,
				ReplacedFiber:document.getElementById("ReplacedFiber") && document.getElementById("ReplacedFiber").checked ? 1 : 0,
				ReplacedModule:document.getElementById("ReplacedModule") && document.getElementById("ReplacedModule").checked ? 1 : 0,
				ReplacedMain:document.getElementById("ReplacedMain") && document.getElementById("ReplacedMain").checked ? 1 : 0,
				ReplacedHans:document.getElementById("ReplacedHans") && document.getElementById("ReplacedHans").checked ? 1 : 0,
				ReplacedDisplay:document.getElementById("ReplacedDisplay") && document.getElementById("ReplacedDisplay").checked ? 1 : 0,
				ReplacedSpeaker:document.getElementById("ReplacedSpeaker") && document.getElementById("ReplacedSpeaker").checked ? 1 : 0,
				ReplacedBattery:document.getElementById("ReplacedBattery") && document.getElementById("ReplacedBattery").checked ? 1 : 0,
				ReplacedPedal:document.getElementById("ReplacedPedal") && document.getElementById("ReplacedPedal").checked ? 1 : 0,
				ReplacedKeypad:document.getElementById("ReplacedKeypad") && document.getElementById("ReplacedKeypad").checked ? 1 : 0,
				ReplacedDisplayDriver:document.getElementById("ReplacedDisplayDriver") && document.getElementById("ReplacedDisplayDriver").checked ? 1 : 0,
				FixedBoardSolder:document.getElementById("FixedBoardSolder") && document.getElementById("FixedBoardSolder").checked ? 1 : 0,
				ReplacedFlex16:document.getElementById("ReplacedFlex16") && document.getElementById("ReplacedFlex16").checked ? 1 : 0,
				ReplacedFlex21:document.getElementById("ReplacedFlex21") && document.getElementById("ReplacedFlex21").checked ? 1 : 0,
				ReplacedBox:document.getElementById("ReplacedBox") && document.getElementById("ReplacedBox").checked ? 1 : 0,
				ReplacedFoam:document.getElementById("ReplacedFoam") && document.getElementById("ReplacedFoam").checked ? 1 : 0,
				ReturnedDate:document.getElementById("ReturnedDate") ? document.getElementById("ReturnedDate").value : null,
				TrackingCustomer:document.getElementById("TrackingCustomer") ? document.getElementById("TrackingCustomer").value : null,
				ReturnedStatus:document.getElementById("ReturnedStatus") ? document.getElementById("ReturnedStatus").value : null,
				ShippingDestination:document.getElementById("ShippingDestination") ? document.getElementById("ShippingDestination").value : null,
				CustomerNotes: document.getElementById("customerNotes") ? document.getElementById("customerNotes").value : null,
				IssueCategory1:document.getElementById("IssueCategory1") ? document.getElementById("IssueCategory1").value : null,
				IssueCategory2:document.getElementById("IssueCategory2") ? document.getElementById("IssueCategory2").value : null,
				IssueCategory3:document.getElementById("IssueCategory3") ? document.getElementById("IssueCategory3").value : null,
				IssueCategory4:document.getElementById("IssueCategory4") ? document.getElementById("IssueCategory4").value : null,
				IssueBOM1: document.getElementById("IssueBOM1") ? document.getElementById("IssueBOM1").value : null,
				IssueBOM2: document.getElementById("IssueBOM2") ? document.getElementById("IssueBOM2").value : null,
				IssueBOM3: document.getElementById("IssueBOM3") ? document.getElementById("IssueBOM3").value : null,
				IssueBOM4: document.getElementById("IssueBOM4") ? document.getElementById("IssueBOM4").value : null,
				IssueBOM5: document.getElementById("IssueBOM5") ? document.getElementById("IssueBOM5").value : null,
				IssueBOM6: document.getElementById("IssueBOM6") ? document.getElementById("IssueBOM6").value : null,
				IssueBOM1Quantity: document.getElementById("IssueBOM1Quantity") ? document.getElementById("IssueBOM1Quantity").value : null,
				IssueBOM2Quantity: document.getElementById("IssueBOM2Quantity") ? document.getElementById("IssueBOM2Quantity").value : null,
				IssueBOM3Quantity: document.getElementById("IssueBOM3Quantity") ? document.getElementById("IssueBOM3Quantity").value : null,
				IssueBOM4Quantity: document.getElementById("IssueBOM4Quantity") ? document.getElementById("IssueBOM4Quantity").value : null,
				IssueBOM5Quantity: document.getElementById("IssueBOM5Quantity") ? document.getElementById("IssueBOM5Quantity").value : null,
				IssueBOM6Quantity: document.getElementById("IssueBOM6Quantity") ? document.getElementById("IssueBOM6Quantity").value : null,
				MDRRequired:document.getElementById("MDRRequired") ? document.getElementById("MDRRequired").value : null,
				MDRJustification:document.getElementById("MDRJustification") ? document.getElementById("MDRJustification").value : null,
				IncidentInvolved:document.getElementById("IncidentInvolved") ? document.getElementById("IncidentInvolved").value : null,
				RecallRequired:document.getElementById("RecallRequired") ? document.getElementById("RecallRequired").value : null,
				SendCustomerEmail:document.getElementById("SendCustomerEmail") && document.getElementById("SendCustomerEmail").checked ? 1 : 0,
				UPIClosed:document.getElementById("UPIClosed") ? document.getElementById("UPIClosed").value : null,
				MediaHash:this.state.mediaHash,
				IssueOption1:document.getElementById("IssueOption1") ? document.getElementById("IssueOption1").value : null,
				IssueOption2:document.getElementById("IssueOption2") ? document.getElementById("IssueOption2").value : null,
				IssueOption3:document.getElementById("IssueOption3") ? document.getElementById("IssueOption3").value : null,
				IssueOption4:document.getElementById("IssueOption4") ? document.getElementById("IssueOption4").value : null,
				IssueOption5:document.getElementById("IssueOption5") ? document.getElementById("IssueOption5").value : null,
				IssueOption6:document.getElementById("IssueOption6") ? document.getElementById("IssueOption6").value : null,
				IssueOption7:document.getElementById("IssueOption7") ? document.getElementById("IssueOption7").value : null,
				IssueOption8:document.getElementById("IssueOption8") ? document.getElementById("IssueOption8").value : null,
				IssueOption9:document.getElementById("IssueOption9") ? document.getElementById("IssueOption9").value : null,
				IssueOption10:document.getElementById("IssueOption10") ? document.getElementById("IssueOption10").value : null,
				IssueOption11:document.getElementById("IssueOption11") ? document.getElementById("IssueOption11").value : null,
				IssueOption12:document.getElementById("IssueOption12") ? document.getElementById("IssueOption12").value : null}

				console.log("update-repair-log requestBody: " + JSON.stringify(requestBody));

				var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"update-repair-log";

				const response = await fetch(url, {
					method: 'POST',
					headers: {
						Authorization: this.state.currentUser.jwtToken,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(requestBody)
				});

				const body = await response.json();
				console.log("updateRepairLog response: " + JSON.stringify(body));

				if (body.status == 'Success') {
					//this.setState({ repairCategories: body.response });
					//this.sendEmailToAzena(this.props.repairID)
				} else {
					//ADD ERROR MESSAGE
				}

		} else {

			//console.log("detailedIssue: " + document.getElementById("detailedIssue").value);
			//document.getElementById("market").value

			//let detailedIssue = document.getElementById("detailedIssue").value;
			//const detailedIssueBuff = Buffer.from(detailedIssue, "utf8");

			var market = "us"

			if (this.state.isUPI) {
				market = this.state.currentUser.payload['custom:market'];
			}

			let requestBody = {
				market:market,
				TechnicianLog:document.getElementById("TechnicianLog").innerHTML,
				SerialNum:document.getElementById("SerialNum").value,
				BatchNum:document.getElementById("BatchNum").innerHTML,
				RMANum:document.getElementById("RMANum").value,
				AgileNum:document.getElementById("AgileNum").value,
				UnitType:document.getElementById("UnitType").value,
				TamName:document.getElementById("TamName").value,
				TamPhone:document.getElementById("TamPhone").value,
				CustomerName:document.getElementById("CustomerName").value,
				Address:document.getElementById("Address").value,
				City:document.getElementById("City").value,
				State:document.getElementById("State").value,
				Zip:document.getElementById("Zip").value,
				email:document.getElementById("email").value,
				Action:document.getElementById("Action").value,
				TrackingNum:document.getElementById("TrackingNum").value,
				TrackingDateAzena:document.getElementById("TrackingDateAzena").value,
				ExpectedRepairDate:document.getElementById("ExpectedRepairDate").value,
				OriginalShipDate:document.getElementById("OriginalShipDate").value,
				DetailedIssue: document.getElementById("detailedIssue").value,
				TechnicianRepair:document.getElementById("OriginalShipDate").innerHTML,
				ReceivedDate:document.getElementById("ReceivedDate") ? document.getElementById("ReceivedDate").value : null,
				Status:document.getElementById("Status") ? document.getElementById("Status").value : null,
				DetailedRepair: document.getElementById("detailedRepair") ? document.getElementById("detailedRepair").value : null,
				Firmware:document.getElementById("Firmware") ? document.getElementById("Firmware").value : null,
				NewCalibration:document.getElementById("NewCalibration") ? document.getElementById("NewCalibration").value : null,
				ReplacedSD:document.getElementById("ReplacedSD") && document.getElementById("ReplacedSD").checked ? 1 : 0,
				ReplacedFiber:document.getElementById("ReplacedFiber") && document.getElementById("ReplacedFiber").checked ? 1 : 0,
				ReplacedModule:document.getElementById("ReplacedModule") && document.getElementById("ReplacedModule").checked ? 1 : 0,
				ReplacedMain:document.getElementById("ReplacedMain") && document.getElementById("ReplacedMain").checked ? 1 : 0,
				ReplacedHans:document.getElementById("ReplacedHans") && document.getElementById("ReplacedHans").checked ? 1 : 0,
				ReplacedDisplay:document.getElementById("ReplacedDisplay") && document.getElementById("ReplacedDisplay").checked ? 1 : 0,
				ReplacedSpeaker:document.getElementById("ReplacedSpeaker") && document.getElementById("ReplacedSpeaker").checked ? 1 : 0,
				ReplacedBattery:document.getElementById("ReplacedBattery") && document.getElementById("ReplacedBattery").checked ? 1 : 0,
				ReplacedPedal:document.getElementById("ReplacedPedal") && document.getElementById("ReplacedPedal").checked ? 1 : 0,
				ReplacedKeypad:document.getElementById("ReplacedKeypad") && document.getElementById("ReplacedKeypad").checked ? 1 : 0,
				ReplacedDisplayDriver:document.getElementById("ReplacedDisplayDriver") && document.getElementById("ReplacedDisplayDriver").checked ? 1 : 0,
				FixedBoardSolder:document.getElementById("FixedBoardSolder") && document.getElementById("FixedBoardSolder").checked ? 1 : 0,
				ReplacedFlex16:document.getElementById("ReplacedFlex16") && document.getElementById("ReplacedFlex16").checked ? 1 : 0,
				ReplacedFlex21:document.getElementById("ReplacedFlex21") && document.getElementById("ReplacedFlex21").checked ? 1 : 0,
				ReplacedBox:document.getElementById("ReplacedBox") && document.getElementById("ReplacedBox").checked ? 1 : 0,
				ReplacedFoam:document.getElementById("ReplacedFoam") && document.getElementById("ReplacedFoam").checked ? 1 : 0,
				ReturnedDate:document.getElementById("ReturnedDate") ? document.getElementById("ReturnedDate").value : null,
				TrackingCustomer:document.getElementById("TrackingCustomer") ? document.getElementById("TrackingCustomer").value : null,
				ReturnedStatus:document.getElementById("ReturnedStatus") ? document.getElementById("ReturnedStatus").value : null,
				ShippingDestination:document.getElementById("ShippingDestination") ? document.getElementById("ShippingDestination").value : null,
				CustomerNotes: document.getElementById("customerNotes") ? document.getElementById("customerNotes").value : null,
				IssueCategory1:document.getElementById("IssueCategory1") ? document.getElementById("IssueCategory1").value : null,
				IssueCategory2:document.getElementById("IssueCategory2") ? document.getElementById("IssueCategory2").value : null,
				IssueCategory3:document.getElementById("IssueCategory3") ? document.getElementById("IssueCategory3").value : null,
				IssueCategory4:document.getElementById("IssueCategory4") ? document.getElementById("IssueCategory4").value : null,
				IssueBOM1: document.getElementById("IssueBOM1") ? document.getElementById("IssueBOM1").value : null,
				IssueBOM2: document.getElementById("IssueBOM2") ? document.getElementById("IssueBOM2").value : null,
				IssueBOM3: document.getElementById("IssueBOM3") ? document.getElementById("IssueBOM3").value : null,
				IssueBOM4: document.getElementById("IssueBOM4") ? document.getElementById("IssueBOM4").value : null,
				IssueBOM5: document.getElementById("IssueBOM5") ? document.getElementById("IssueBOM5").value : null,
				IssueBOM6: document.getElementById("IssueBOM6") ? document.getElementById("IssueBOM6").value : null,
				IssueBOM1Quantity: document.getElementById("IssueBOM1Quantity") ? document.getElementById("IssueBOM1Quantity").value : null,
				IssueBOM2Quantity: document.getElementById("IssueBOM2Quantity") ? document.getElementById("IssueBOM2Quantity").value : null,
				IssueBOM3Quantity: document.getElementById("IssueBOM3Quantity") ? document.getElementById("IssueBOM3Quantity").value : null,
				IssueBOM4Quantity: document.getElementById("IssueBOM4Quantity") ? document.getElementById("IssueBOM4Quantity").value : null,
				IssueBOM5Quantity: document.getElementById("IssueBOM5Quantity") ? document.getElementById("IssueBOM5Quantity").value : null,
				IssueBOM6Quantity: document.getElementById("IssueBOM6Quantity") ? document.getElementById("IssueBOM6Quantity").value : null,
				MDRRequired:document.getElementById("MDRRequired") ? document.getElementById("MDRRequired").value : null,
				MDRJustification:document.getElementById("MDRJustification") ? document.getElementById("MDRJustification").value : null,
				IncidentInvolved:document.getElementById("IncidentInvolved") ? document.getElementById("IncidentInvolved").value : null,
				RecallRequired:document.getElementById("RecallRequired") ? document.getElementById("RecallRequired").value : null,
				SendCustomerEmail:document.getElementById("SendCustomerEmail") && document.getElementById("SendCustomerEmail").checked ? 1 : 0,
				UPIClosed:document.getElementById("UPIClosed") ? document.getElementById("UPIClosed").value : null,
				MediaHash:this.state.mediaHash,
				IssueOption1:document.getElementById("IssueOption1") ? document.getElementById("IssueOption1").value : null,
				IssueOption2:document.getElementById("IssueOption2") ? document.getElementById("IssueOption2").value : null,
				IssueOption3:document.getElementById("IssueOption3") ? document.getElementById("IssueOption3").value : null,
				IssueOption4:document.getElementById("IssueOption4") ? document.getElementById("IssueOption4").value : null,
				IssueOption5:document.getElementById("IssueOption5") ? document.getElementById("IssueOption5").value : null,
				IssueOption6:document.getElementById("IssueOption6") ? document.getElementById("IssueOption6").value : null,
				IssueOption7:document.getElementById("IssueOption7") ? document.getElementById("IssueOption7").value : null,
				IssueOption8:document.getElementById("IssueOption8") ? document.getElementById("IssueOption8").value : null,
				IssueOption9:document.getElementById("IssueOption9") ? document.getElementById("IssueOption9").value : null,
				IssueOption10:document.getElementById("IssueOption10") ? document.getElementById("IssueOption10").value : null,
				IssueOption11:document.getElementById("IssueOption11") ? document.getElementById("IssueOption11").value : null,
				IssueOption12:document.getElementById("IssueOption12") ? document.getElementById("IssueOption12").value : null}

				console.log("save-repair-log requestBody: " + JSON.stringify(requestBody));

				var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"save-repair-log";

				const response = await fetch(url, {
					method: 'POST',
					headers: {
						Authorization: this.state.currentUser.jwtToken,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(requestBody)
				});

				const body = await response.json();
				console.log("save-repair-log response: " + JSON.stringify(body));

				if (body.status == 'Success') {
					//this.setState({ repairCategories: body.response });
					console.log("insertId: " + JSON.stringify(body.response.insertId));

					this.sendEmailToAzena(body.response.insertId)
				} else {
					//ADD ERROR MESSAGE
				}

		}

	};

	onChangePurchaseOrderHandler = (event) => {

		var contentType = event.target.files[0].type;

		const contentTypeArray = contentType.split("/");

		let extension = contentTypeArray[1];

		this.setState({
			selectedFilePurchaseOrder: event.target.files[0],
			filePreviewPurchaseOrder: pdfPlaceholder,
			loadedPurchaseOrder: true,
		})

		console.log("file type: "+event.target.files[0].type);
	}

	onChangeHandlerOne = (event) => {

		var contentType = event.target.files[0].type;

		const contentTypeArray = contentType.split("/");

		let extension = contentTypeArray[1];

		if (this.is_video_url_extension(extension)) {
			this.setState({
	      selectedFileOne: event.target.files[0],
				filePreviewOne: videoPlaceholder,
	      loadedOne: true,
	    })
		} else {
			this.setState({
	      selectedFileOne: event.target.files[0],
				filePreviewOne: URL.createObjectURL(event.target.files[0]),
	      loadedOne: true,
	    })
		}

		console.log("file type: "+event.target.files[0].type);
	}

	onChangeHandlerTwo = (event) => {

		var contentType = event.target.files[0].type;

		const contentTypeArray = contentType.split("/");

		let extension = contentTypeArray[1];

		if (this.is_video_url_extension(extension)) {
			this.setState({
	      selectedFileTwo: event.target.files[0],
				filePreviewTwo: videoPlaceholder,
	      loadedTwo: true,
	    })
		} else {
			this.setState({
	      selectedFileTwo: event.target.files[0],
				filePreviewTwo: URL.createObjectURL(event.target.files[0]),
	      loadedTwo: true,
	    })
		}

		console.log("file type: "+event.target.files[0].type);
	}

	onChangeHandlerThree = (event) => {

		var contentType = event.target.files[0].type;

		const contentTypeArray = contentType.split("/");

		let extension = contentTypeArray[1];

		if (this.is_video_url_extension(extension)) {
			this.setState({
	      selectedFileThree: event.target.files[0],
				filePreviewThree: videoPlaceholder,
	      loadedThree: true,
	    })
		} else {
			this.setState({
	      selectedFileThree: event.target.files[0],
				filePreviewThree: URL.createObjectURL(event.target.files[0]),
	      loadedThree: true,
	    })
		}

		console.log("file type: "+event.target.files[0].type);
	}

	onClickPurchaseOrderHandler = () => {

		this.uploadToS3(3);
	}

	onClickHandlerOne = () => {

		this.uploadToS3(0);
	}

	onClickHandlerTwo = () => {

		this.uploadToS3(1);
	}

	onClickHandlerThree = () => {

		this.uploadToS3(2);
	}

 	// getBase64(file) {
	//   return new Promise((resolve, reject) => {
	//     const reader = new FileReader();
	//     reader.readAsBinaryString(file);
	//     reader.onload = () => resolve(reader.result);
	//     reader.onerror = error => reject(error);
	//   });
	// }

	getBase64(file) {
	  return new Promise((resolve, reject) => {
	    const reader = new FileReader();
	    reader.readAsDataURL(file);
	    reader.onload = () => resolve(reader.result);
	    reader.onerror = error => reject(error);
	  });
	}

 	getBinaryFromFile(file) {
		return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener("load", () => resolve(reader.result));
        reader.addEventListener("error", err => reject(err));

        reader.readAsBinaryString(file);
    });
	}

	uploadToS3 = async (section) => {

		var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		console.log('random hash: ' + hash);

		var contentType = null;
		var fileData = null;

		if (section==0) {
			console.log("file type: "+this.state.selectedFileOne.type);
			contentType = this.state.selectedFileOne.type;
			fileData = this.state.selectedFileOne;
		} else if (section==1) {
			console.log("file type: "+this.state.selectedFileTwo.type);
			contentType = this.state.selectedFileTwo.type;
			fileData = this.state.selectedFileTwo;
		} else if (section==2) {
			console.log("file type: "+this.state.selectedFileThree.type);
			contentType = this.state.selectedFileThree.type;
			fileData = this.state.selectedFileThree;
		} else if (section==3) {
			console.log("file type: "+this.state.selectedFilePurchaseOrder.type);
			contentType = this.state.selectedFilePurchaseOrder.type;
			fileData = this.state.selectedFilePurchaseOrder;
		}

		const contentTypeArray = contentType.split("/");

		//OLD WAY
		//let fileName = this.props.repairID+"-"+hash+"."+contentTypeArray[1];
		let fileName = this.state.mediaHash+"-"+hash+"."+contentTypeArray[1];
		console.log('fileName: ' + fileName);

		if (section==3) {
			fileName = this.state.mediaHash+"-PurchaseOrder.pdf";
		}

		var url = "https://vzc88uk8pa.execute-api.us-west-2.amazonaws.com/v1/azena-repair-files/"+fileName;

		const options = {
			method: 'PUT',
			headers: {
				Authorization: this.state.currentUser.jwtToken,
				'Content-Type': contentType
			},
			body: fileData
		};

		const response = await fetch(url, options);

		//const body = await response.json();
		console.log("upload response: " + JSON.stringify(response.status));

		if (response.status == '200') {
			console.log("Upload Success!");

			if (section==3) {
				if (this.state.purchaseOrderFiles.length==0) {
					this.addFileToRepair(fileName, section);
				} else {
					document.getElementById("filePurchaseOrder").value = null;
					this.setState({ filePreviewPurchaseOrder: null, loadedPurchaseOrder: false });
				}
			} else {
				this.addFileToRepair(fileName, section);
			}
		} else {
			//ADD ERROR MESSAGE
		}
		//https://azena-repair-files.s3.us-west-2.amazonaws.com/2038-s55ktm2m1akvbz21n33ur.png
	}

	sendInvoice = (event) => {
		event.preventDefault();

		console.log("Send message");
		let email = event.target.email.value;
    console.log(`email is:`, email);

		this.sendEmailMessage(email, 0);
	}

	sendReport = (event) => {
		event.preventDefault();

		console.log("Send message");
		let email = event.target.email.value;
    console.log(`email is:`, email);

		this.sendEmailMessage(email, 1);
	}

	sendEmailMessage = async (emailAddress, type) => {

		var repairLogID = this.props.repairID;
		var serialNumber = document.getElementById("SerialNum").value;

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"email-invoice?EmailRecipient="+emailAddress+"&FileName="+this.state.invoicePDFName+"&SerialNumber="+serialNumber+"&RepairLogID="+repairLogID;

		if (type==1) {
			url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"email-customer-report?EmailRecipient="+emailAddress+"&FileName="+this.state.customerReportPDFName+"&SerialNumber="+serialNumber+"&RepairLogID="+repairLogID;
		}

		console.log("Send Email URL: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("Send Email response: " + JSON.stringify(body));

		if (body.status == 'Success') {

			if (type==1) {
				this.toggle6();
			} else {
				this.toggle3();
			}

			this.setState({
				statusSubject: "SUCCESS!",
				statusMessage: "Email sent Successfully!"
			})

		} else {

			if (type==1) {
				this.toggle6();
			} else {
				this.toggle3();
			}

			this.setState({
				statusSubject: "FAILED!",
				statusMessage: "Failed to send Email to "+emailAddress+"."
			})

		}

		this.toggle5();

	};

	addFileToRepair = async (fileName, section) => {

		let fileURL = "https://azena-repair-files.s3.us-west-2.amazonaws.com/"+fileName;

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"add-file-to-repair?RepairLogID="+this.state.mediaHash+"&URL="+fileURL+"&Section="+section;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();

		console.log("upload response: " + JSON.stringify(body));

		if (body.status == 'Success') {
			console.log("Added File Successfully!");
			this.getFilesForRepair(section);
			if (section == 0) {
				document.getElementById("fileSectionOne").value = null;
				this.setState({ filePreviewOne: null, loadedOne: false });
			} else if (section == 1) {
				document.getElementById("fileSectionTwo").value = null;
				this.setState({ filePreviewTwo: null, loadedTwo: false });
			} else if (section == 2) {
				document.getElementById("fileSectionThree").value = null;
				this.setState({ filePreviewThree: null, loadedThree: false });
			} else if (section == 3) {
				document.getElementById("filePurchaseOrder").value = null;
				this.setState({ filePreviewPurchaseOrder: null, loadedPurchaseOrder: false });
			}
		} else {
			//ADD ERROR MESSAGE
		}
		//https://azena-repair-files.s3.us-west-2.amazonaws.com/2038-s55ktm2m1akvbz21n33ur.png
	}

	deleteFileForRepair = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-file-for-repair?RepairFileID="+this.state.file.id;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();

		console.log("upload response: " + JSON.stringify(body));

		if (body.status == 'Success') {
			console.log("Added Deleted Successfully!");
			this.toggle2();
			this.getFilesForRepair(this.state.file.section);
		} else {
			//ADD ERROR MESSAGE
		}
		//https://azena-repair-files.s3.us-west-2.amazonaws.com/2038-s55ktm2m1akvbz21n33ur.png
	}

	getFilesForRepair = async (section) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-files-for-repair?RepairLogID="+this.props.repairID+"&Section="+section+"&MediaHash="+this.state.mediaHash;
		console.log("getFilesForRepair url: " + url);
		console.log("getFilesForRepair mediaHash: " + this.state.mediaHash);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();

		console.log("getFilesForRepair response: " + JSON.stringify(body));

		if (body.status == 'Success') {

			if (section==0) {
				this.setState({ sectionOneFiles: body.response });
			} else if (section==1) {
				this.setState({ sectionTwoFiles: body.response });
			} else if (section==2) {
				this.setState({ sectionThreeFiles: body.response });
			} else if (section==3) {
				this.setState({ purchaseOrderFiles: body.response });
			}

		} else {
			//ADD ERROR MESSAGE
		}
		//https://azena-repair-files.s3.us-west-2.amazonaws.com/2038-s55ktm2m1akvbz21n33ur.png
	}

	toggleOne () {

		if (this.state.sectionOneTitle=="Open"){
			this.setState({ sectionOneTitle: "Close" });
		} else {
			this.setState({ sectionOneTitle: "Open" });
		}

		this.setState({ isOneOpen: !this.state.isOneOpen });
	}

	toggleTwo () {

		if (this.state.sectionTwoTitle=="Open"){
			this.setState({ sectionTwoTitle: "Close" });
		} else {
			this.setState({ sectionTwoTitle: "Open" });
		}

		this.setState({ isTwoOpen: !this.state.isTwoOpen });
	}

	toggleThree () {

		if (this.state.sectionThreeTitle=="Open"){
			this.setState({ sectionThreeTitle: "Close" });
		} else {
			this.setState({ sectionThreeTitle: "Open" });
		}

		this.setState({ isThreeOpen: !this.state.isThreeOpen });
	}

	saveRepairLog() {



	}

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	onChange = (event) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);
		console.log("checked value: " + event.target.checked);

		let id = event.target.id;
		let value = event.target.value;
		let checked = event.target.checked;

		var repairLog = JSON.parse(JSON.stringify(this.state.repairLog));

		repairLog[id] = value;

		if (checked != undefined) {

			if (event.target.type == 'checkbox') {
				repairLog[id] = checked;
				console.log("set checked as value");
			} else if (event.target.type == 'date') {
				repairLog[id] = value;
				console.log("set checked as value");
			}

		}

		console.log("repairLog: " + JSON.stringify(repairLog));

		this.setState({repairLog});

	}

	onChangeBOM = (event, bomValue) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);
		console.log("checked value: " + event.target.checked);

		let id = event.target.id;
		let value = event.target.value;
		//let checked = event.target.checked;

		var repairLog = JSON.parse(JSON.stringify(this.state.repairLog));

		repairLog[id] = value;

		if (id=="IssueBOM1") {
			if (bomValue != 0) {
				var startValue = document.getElementById("IssueBOM1Quantity").value;
				var startBOMValue = document.getElementById("IssueBOM1").value;
				const result = this.state.bomData.filter(bom => bom.id==bomValue);
				console.log("Quantity: " + result[0].Quantity);
				var revisedQuantity = parseInt(result[0].Quantity) + parseInt(startValue);
				console.log("revisedQuantity: " + revisedQuantity);
				this.updateBOM(result[0], revisedQuantity);
			}
			repairLog.IssueBOM1Quantity = 0;
		} else if (id=="IssueBOM2") {
			if (bomValue != 0) {
				var startValue = document.getElementById("IssueBOM2Quantity").value;
				var startBOMValue = document.getElementById("IssueBOM2").value;
				const result = this.state.bomData.filter(bom => bom.id==bomValue);
				console.log("Quantity: " + result[0].Quantity);
				var revisedQuantity = parseInt(result[0].Quantity) + parseInt(startValue);
				console.log("revisedQuantity: " + revisedQuantity);
				this.updateBOM(result[0], revisedQuantity);
			}

			repairLog.IssueBOM2Quantity = 0;
		} else if (id=="IssueBOM3") {
			if (bomValue != 0) {
				var startValue = document.getElementById("IssueBOM3Quantity").value;
				var startBOMValue = document.getElementById("IssueBOM3").value;
				const result = this.state.bomData.filter(bom => bom.id==bomValue);
				console.log("Quantity: " + result[0].Quantity);
				var revisedQuantity = parseInt(result[0].Quantity) + parseInt(startValue);
				console.log("revisedQuantity: " + revisedQuantity);
				this.updateBOM(result[0], revisedQuantity);
			}

			repairLog.IssueBOM3Quantity = 0;
		} else if (id=="IssueBOM4") {
			if (bomValue != 0) {
				var startValue = document.getElementById("IssueBOM4Quantity").value;
				var startBOMValue = document.getElementById("IssueBOM4").value;
				const result = this.state.bomData.filter(bom => bom.id==bomValue);
				console.log("Quantity: " + result[0].Quantity);
				var revisedQuantity = parseInt(result[0].Quantity) + parseInt(startValue);
				console.log("revisedQuantity: " + revisedQuantity);
				this.updateBOM(result[0], revisedQuantity);
			}

			repairLog.IssueBOM4Quantity = 0;
		} else if (id=="IssueBOM5") {
			if (bomValue != 0) {
				var startValue = document.getElementById("IssueBOM5Quantity").value;
				var startBOMValue = document.getElementById("IssueBOM5").value;
				const result = this.state.bomData.filter(bom => bom.id==bomValue);
				console.log("Quantity: " + result[0].Quantity);
				var revisedQuantity = parseInt(result[0].Quantity) + parseInt(startValue);
				console.log("revisedQuantity: " + revisedQuantity);
				this.updateBOM(result[0], revisedQuantity);
			}

			repairLog.IssueBOM5Quantity = 0;
		} else if (id=="IssueBOM6") {
			if (bomValue != 0) {
				var startValue = document.getElementById("IssueBOM6Quantity").value;
				var startBOMValue = document.getElementById("IssueBOM6").value;
				const result = this.state.bomData.filter(bom => bom.id==bomValue);
				console.log("Quantity: " + result[0].Quantity);
				var revisedQuantity = parseInt(result[0].Quantity) + parseInt(startValue);
				console.log("revisedQuantity: " + revisedQuantity);
				this.updateBOM(result[0], revisedQuantity);
			}

			repairLog.IssueBOM6Quantity = 0;
		}

		this.setState({repairLog});

	}

	onChangeQuantity = (event, issueBom) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);
		console.log("checked value: " + event.target.checked);

		let id = event.target.id;
		let value = event.target.value;
		//let checked = event.target.checked;

		var repairLog = JSON.parse(JSON.stringify(this.state.repairLog));

		console.log("id value: " + issueBom);

		const result = this.state.bomData.filter(bom => bom.id==issueBom);

		console.log("Quantity: " + result[0].Quantity);

		var startValue = repairLog[id];

		console.log("Start Value: " + startValue);

		console.log("New Value: " + value);

		var revisedQuantity = parseInt(result[0].Quantity) + parseInt(startValue) - parseInt(value);
		console.log("revisedQuantity: " + revisedQuantity);

		this.updateBOM(result[0], revisedQuantity);

		repairLog[id] = value;

		this.setState({repairLog});

	}

	updateBOM = async (bom, quantity) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-bill-of-materials?BomID="+bom.id+"&PartNumber="+bom.PartNumber+"&Cost="+bom.Cost+"&Description="+bom.Description+"&Quantity="+quantity;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("Update bomData response: " + JSON.stringify(body));

		if (body.status == 'Success') {
			//this.setState({deviceInfo: body.response});
			//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
			//this.state.noDataForDevices = false;
			this.getBOMData();
		} else {
			//this.state.noDataForDevices = true;
		}

	};

	onChangeSerialNumber = (event, value) => {

		console.log("onChangeSerialNumber input id: " + event.target.id);

		console.log("onChangeSerialNumber value: " + value);

		if (value != null) {
			let id = event.target.id;

			var repairLog = JSON.parse(JSON.stringify(this.state.repairLog));

			repairLog.SerialNum = value;

			const result = this.state.serialNumbersAll.filter(serial => serial.SerialNumber.includes(value));

			console.log("onChangeSerialNumber result: " + JSON.stringify(result));

			repairLog['BatchNum'] = result[0].BatchNumber;

			console.log("onChangeSerialNumber repairLog: " + JSON.stringify(repairLog));

			this.setState({repairLog});
		} else {
			var repairLog = JSON.parse(JSON.stringify(this.state.repairLog));

			repairLog.SerialNum = "";
			repairLog['BatchNum'] = "";
			this.setState({repairLog});
		}

	}

	onChangeCustomerNotesSelect = (event) => {

		console.log("onChangeCustomerNotesSelect input id: " + event.target.id);
		console.log("onChangeCustomerNotesSelect input value: " + event.target.value);
		console.log("onChangeCustomerNotesSelect checked value: " + event.target.checked);

		let id = event.target.id;
		let value = event.target.value;
		let checked = event.target.checked;

		//var repairLog = JSON.parse(JSON.stringify(this.state.repairLog));

		//repairLog[id] = value;

		const result = this.state.repairCustomerNotes.filter(note => note.id==value);

		console.log("onChangeCustomerNotesSelect result: " + JSON.stringify(result));

		//repairLog['TamPhone'] = result[0].phone;

		this.setState({ customerNotes: result[0].Message});
		document.getElementById("customerNotes").value = result[0].Message;

		//console.log("onChangeTAMName repairLog: " + JSON.stringify(repairLog));

		//this.setState({repairLog});

	}

	onChangeTAMName = (event) => {

		console.log("onChangeTAMName input id: " + event.target.id);
		console.log("onChangeTAMName input value: " + event.target.value);
		console.log("onChangeTAMName checked value: " + event.target.checked);

		let id = event.target.id;
		let value = event.target.value;
		let checked = event.target.checked;

		var repairLog = JSON.parse(JSON.stringify(this.state.repairLog));

		repairLog[id] = value;

		const result = this.state.tamData.filter(tam => tam.name.includes(value));

		console.log("onChangeTAMName result: " + JSON.stringify(result));

		repairLog['TamPhone'] = result[0].phone;

		console.log("onChangeTAMName repairLog: " + JSON.stringify(repairLog));

		this.setState({repairLog});

	}

	onChangeRepairNotes = (event) => {

		console.log("onChangeRepairNotes input id: " + event.target.id);
		console.log("onChangeRepairNotes input value: " + event.target.value);

		let id = event.target.id;
		let value = event.target.value;

		this.setState({ detailedRepair: value});

	}

	onChangeCustomerNotes = (event) => {

		console.log("onChangeCustomerNotes input id: " + event.target.id);
		console.log("onChangeCustomerNotes input value: " + event.target.value);

		let id = event.target.id;
		let value = event.target.value;

		this.setState({ customerNotes: value});

	}

	onTextAreaChange = (value) => {
    this.setState({detailedIssue:value});
    // if (this.props.onChange) {
    //   // Send the changes up to the parent component as an HTML string.
    //   // This is here to demonstrate using `.toString()` but in a real app it
    //   // would be better to avoid generating a string on each change.
    //   this.props.onChange(
    //     value.toString('html')
    //   );
    // }
  };

	createSnapShot() {

		console.log('createSnapShot Clicked!');

		var node = document.getElementById('UPICard');

		html2canvas(node).then((canvas)=>{
				var image = canvas.toDataURL('image/png', 1.0);
				//saveAs(image, 'exported-vis.png')
				this.setState({ testImageFile: image });
		})

		// htmlToImage.toPng(node)
		//   .then(function (dataUrl) {
		// 		console.log('Snapshot Success!');
		//     //var img = new Image();
		// 		console.log('Snapshot url:'+dataUrl);
		// 		//this.setState({ testImageFile: dataUrl });
		// 		var image = dataUrl.toDataURL('image/png', 1.0);
		// 		this.setState({ testImageFile: image });
		//
		//     //img.src = dataUrl;
		//     //document.body.appendChild(img);
		//   })
		//   .catch(function (error) {
		//     console.error('oops, something went wrong!', error);
		//   });

  };

	is_video_url_extension (url) {

		var ext = url.split(/[#?]/)[0].split('.').pop().trim();

		var isVideo = false;

		if (ext == "mp4") {
			isVideo = true;
		} else if (ext == "m4a") {
			isVideo = true;
		} else if (ext == "m4p") {
			isVideo = true;
		} else if (ext == "m4b") {
			isVideo = true;
		} else if (ext == "m4r") {
			isVideo = true;
		} else if (ext == "m4v") {
			isVideo = true;
		} else if (ext == "mov") {
			isVideo = true;
		} else if (ext == "m4v") {
			isVideo = true;
		}

	  return isVideo;
	}

	is_pdf_url_extension (url) {

		var ext = url.split(/[#?]/)[0].split('.').pop().trim();

		var isPDF = false;

		if (ext == "pdf") {
			isPDF = true;
		}

	  return isPDF;
	}

	video_url_extension (url) {

		var ext = url.split(/[#?]/)[0].split('.').pop().trim();

	  return ext;
	}

  render() {

		var userName = this.state.currentUser.payload.given_name + " " + this.state.currentUser.payload.family_name;
		console.log("userName: " + userName);

		var sectionOneUrl = {};

		if (this.state.sectionOneFiles.length>0) {
			sectionOneUrl = this.state.sectionOneFiles[this.state.lightBoxIndex];
			console.log("sectionOneUrl: " + JSON.stringify(sectionOneUrl));
		}

		var sectionTwoUrl = {};

		if (this.state.sectionTwoFiles.length>0) {
			sectionTwoUrl = this.state.sectionTwoFiles[this.state.lightBoxIndex];
			console.log("sectionTwoUrl: " + JSON.stringify(sectionTwoUrl));
		}

		var sectionThreeUrl = {};

		if (this.state.sectionThreeFiles.length>0) {
			sectionThreeUrl = this.state.sectionThreeFiles[this.state.lightBoxIndex];
			console.log("sectionThreeUrl: " + JSON.stringify(sectionThreeUrl));
		}

		var purchaseOrderURL = {};

		if (this.state.purchaseOrderFiles.length>0) {
			purchaseOrderURL = this.state.purchaseOrderFiles[0];
			console.log("purchaseOrderURL: " + JSON.stringify(purchaseOrderURL));
		}

		var old = Moment(this.state.repairLog.DateCreated).isBefore('2021-07-31');

		console.log("is old: " + old);


		var d1 = Moment(this.state.repairLog.OriginalShipDate).utc(false).format('YYYY-MM-DD');
		var d2 = new Date();
		var underWarrantyValue = "Unit out of warranty";

		if (Math.floor((d2-d1)/86400000)>730) {
			underWarrantyValue = "Unit under warranty"
		}

		var checkBatteryValue = "Battery Ok";

		if (Math.floor((d2-d1)/86400000)>548) {
			checkBatteryValue = "Check Battery"
		}

		return (
			<Fragment>
				<Row className="pb-5">
					<Col md="6">
						{this.state.isNewRepairLog ? (
							<h1 style={{color:'#737782'}}>New Repair Log</h1>
						) : (
							<h1 style={{color:'#737782'}}>Repair Log - {this.props.repairID}</h1>
						)}
					</Col>
				</Row>
				<Row>
					<Col md="6">
						<h1 style={{color:'#737782'}}>UPI Technical Information</h1>
					</Col>
					<Col md="6">
						{this.state.isOneOpen ? (
							<Fragment>
								<i style={{color:'#ffc108'}} className="mdi mdi-arrow-up-drop-circle-outline mdi-36px float-right" onClick={this.toggleOne}/>
							</Fragment>
						) : (
							<Fragment>
								<i style={{color:'#ffc108'}} className="mdi mdi-arrow-down-drop-circle-outline mdi-36px float-right" onClick={this.toggleOne}/>
							</Fragment>
						)}
					</Col>
				</Row>
				<Collapse isOpen={this.state.isOneOpen}>
					<Row>
						<Col md="12">
							<Card style={{backgroundColor:'#000', color: '#92989d'}} className="modal-card-color">
								<CardBody id="UPICard">
									<Row className="mb-3">
										<Col md="6">
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}}>Technician Name:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													{this.state.isNewRepairLog ? (
														<Label style={{fontSize:'18px'}} id="TechnicianLog" readonly>{userName}</Label>
													) : (
														<Label style={{fontSize:'18px'}} id="TechnicianLog" readonly>{this.state.repairLog.TechnicianLog}</Label>
													)}
												</Col>
											</Row>
											<Row>
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="SerialNum">Unit Serial Number:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
                        {
                          <Label style={{fontSize:'18px'}} for="SerialNum">{this.state.repairLog.SerialNum}</Label>
                        }
												{
													// <ThemeProvider theme={theme}>
													// 	<AutoCompleteInput
													// 	 	value={this.state.repairLog.SerialNum || ""}
													// 		onChange={this.onChangeSerialNumber}
													// 		getItemValue={(item) => item.name}
													// 	 	name="SerialNum"
													// 	 	id="SerialNum"
													// 	  options={this.state.serialNumbers}
													// 	  getOptionLabel={(option) => option}
													// 	  style={{ width: '100%', color: '#b2b9bf' }}
													// 	  renderInput={(params) => <TextField {...params} label="Serial Number" />}
													// 	/>
													// </ThemeProvider>
												}
												{
													// <Input style={{height: '30px', padding: '3px'}} type="select" name="SerialNum" id="SerialNum" value={this.state.repairLog.SerialNum} onChange={this.onChangeSerialNumber}>
													// 	<option value="0">Select Serial Number</option>
													// 	{this.state.serialNumbers.map((serial, i) => (
													// 		<Fragment>
													// 		{serial.SerialNumber.includes('EL-') ? (
													// 			<Fragment>
													// 				<option value={serial.SerialNumber.substring(serial.SerialNumber.length - 5)}>{serial.SerialNumber.substring(serial.SerialNumber.length - 5)}</option>
													// 			</Fragment>
													// 		) : (
													// 			<Fragment>
													// 				<option value={serial.SerialNumber}>{serial.SerialNumber}</option>
													// 			</Fragment>
													// 		)}
													// 		</Fragment>
													// 	))}
													// </Input>
												}
												</Col>
											</Row>
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="BatchNum">Unit Batch Number:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Label style={{fontSize:'18px'}} id="BatchNum">{this.state.repairLog.BatchNum}</Label>
												</Col>
											</Row>
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="RMANum">RMA Number:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{width:'100%', height: '30px'}} type="text" name="RMANum" id="RMANum" defaultValue={this.state.repairLog.RMANum} disabled/>
												</Col>
											</Row>
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="AgileNum">Agile Number:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{width:'100%', height: '30px'}} type="text" name="AgileNum" id="AgileNum" defaultValue={this.state.repairLog.AgileNum} disabled/>
												</Col>
											</Row>
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="UnitType">Unit Type:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{height: '30px', padding: '3px'}} type="select" name="UnitType" id="UnitType" value={this.state.repairLog.UnitType} onChange={this.onChange} disabled>
														<option value="LGP">LGP</option>
														<option value="UNIV">UNIV</option>
														<option value="DOM">DOM</option>
														<option value="GOV">GOV</option>
														<option value="INTL">INTL</option>
													</Input>
												</Col>
											</Row>
											<Row className="pb-3">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="UnitType">Log Creation Date:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{width:'100%', height: '30px'}} type="date" name="DateCreated" id="DateCreated" value={Moment(this.state.repairLog.DateCreated).format('YYYY-MM-DD')} onChange={this.onChange} disabled/>
												</Col>
											</Row>
											<Row className="pt-3">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="TamName">TAM Name:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{height: '30px', padding: '3px'}} type="select" name="TamName" id="TamName" value={this.state.repairLog.TamName} onChange={this.onChangeTAMName} disabled>
														<option value="0">Select TAM Name</option>
														{this.state.tamData.map((tam, i) => (
															<option value={tam.name}>{tam.name}</option>
														))}
													</Input>
												</Col>
											</Row>
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="TamPhone">TAM Phone:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{width:'100%', height: '30px'}} type="text" name="TamPhone" id="TamPhone" defaultValue={this.state.repairLog.TamPhone} disabled/>
												</Col>
											</Row>
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="UPIClosed">UPI Closed?</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{height: '30px', padding: '3px'}} type="select" name="UPIClosed" id="UPIClosed" value={this.state.repairLog.UPIClosed} onChange={this.onChange} disabled>
														<option value="1">Yes</option>
														<option value="0">No</option>
													</Input>
												</Col>
											</Row>
										</Col>
										<Col md="6">
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="CustomerName">Customer Name:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{width:'100%', height: '30px'}} type="text" name="CustomerName" id="CustomerName" defaultValue={this.state.repairLog.CustomerName} disabled/>
												</Col>
											</Row>
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="Address">Address:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
												{
													// <Autocomplete className="form-control" style={{width:'100%', height: '30px', color: '#b2b9bf', backgroundColor: 'rgba(255, 255, 255, 0.0)'}} type="text" name="Address" id="Address" defaultValue={this.state.repairLog.Address}
													// 	options={{
													//     types: ["address"],
													//     componentRestrictions: { country: "us" },
													//   }}
													// 	onPlaceSelected={(place) => {
													// 		console.log(place);
                          //
													// 		var streetNumber = '';
													// 		var streetName = '';
													// 		var city = '';
													// 		var state = '';
													// 		var zip = '';
                          //
													// 		for (var i=0; i<place.address_components.length; i++) {
                          //
													// 			var component = place.address_components[i];
                          //
													// 			//console.log(component);
                          //
													// 			if (component.types[0]=='street_number') {
													// 				streetNumber = component.long_name;
													// 				console.log("streetNumber: " + streetNumber);
													// 			} else if (component.types[0]=='route') {
													// 				streetName = component.short_name;
													// 				console.log("streetName: " + streetName);
													// 			} else if (component.types[0]=='locality') {
													// 				city = component.short_name;
													// 				console.log("city: " + city);
													// 			} else if (component.types[0]=='administrative_area_level_1') {
													// 				state = component.short_name;
													// 				console.log("state: " + state);
													// 			} else if (component.types[0]=='postal_code') {
													// 				zip = component.short_name;
													// 				console.log("zip: " + zip);
													// 			}
													// 		}
                          //
													// 		document.getElementById("Address").value = streetNumber+" "+streetName;
													// 		document.getElementById("City").value = city;
													// 		document.getElementById("State").value = state;
													// 		document.getElementById("Zip").value = zip;
                          //
													// 	}}
													// />
												}
												{
													<Input style={{width:'100%', height: '30px'}} type="text" name="Address" id="Address" defaultValue={this.state.repairLog.Address}  disabled/>
												}
												</Col>
											</Row>
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="City">City:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{width:'100%', height: '30px'}} type="text" name="City" id="City" defaultValue={this.state.repairLog.City} disabled/>
												</Col>
											</Row>
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="State">State:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{width:'100%', height: '30px'}} type="text" name="State" id="State" defaultValue={this.state.repairLog.State} disabled/>
												</Col>
											</Row>
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="Zip">Zip Code:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{width:'100%', height: '30px'}} type="text" name="Zip" id="Zip" defaultValue={this.state.repairLog.Zip} disabled/>
												</Col>
											</Row>
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="email">Email:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{width:'100%', height: '30px'}} type="text" name="email" id="email" defaultValue={this.state.repairLog.email} disabled/>
												</Col>
											</Row>
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="Action">Action:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{height: '30px', padding: '3px'}} type="select" name="Action" id="Action" value={this.state.repairLog.Action} onChange={this.onChange} disabled>
														<option value="Return to Customer">Return to Customer</option>
														<option value="Return to UPI">Return to UPI</option>
													</Input>
												</Col>
											</Row>
											<Row className="pb-1">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'17px'}} for="TrackingNum">Tracking # to Repair Center:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{width:'100%', height: '30px'}} type="text" name="TrackingNum" id="TrackingNum" defaultValue={this.state.repairLog.TrackingNum} disabled/>
												</Col>
											</Row>
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="TrackingDateAzena">Tracking Label Date:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{width:'100%', height: '30px'}} type="date" name="TrackingDateAzena" id="TrackingDateAzena" value={Moment(this.state.repairLog.TrackingDateAzena).utc(false).format('YYYY-MM-DD')} onChange={this.onChange} disabled/>
												</Col>
											</Row>
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="ExpectedRepairDate">Expected Repair By:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{width:'100%', height: '30px'}} type="date" name="ExpectedRepairDate" id="ExpectedRepairDate" value={Moment(this.state.repairLog.ExpectedRepairDate).utc(false).format('YYYY-MM-DD')} onChange={this.onChange} disabled/>
												</Col>
											</Row>
											<Row className="">
												<Col xs="4" md="4" lg="4">
													<Label style={{fontSize:'18px'}} for="OriginalShipDate">Original Ship Date:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{width:'100%', height: '30px'}} type="date" name="OriginalShipDate" id="OriginalShipDate" value={Moment(this.state.repairLog.OriginalShipDate).utc(false).format('YYYY-MM-DD')} onChange={this.onChange} disabled/>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row className="pt-5">
										<Col md="12">
											<Row className="">
												<Col xs="2" md="2" lg="2">
													<Label style={{fontSize:'18px'}} for="MDRRequired">MDR Required?</Label>
												</Col>
												<Col xs="3" md="3" lg="3">
													<Input style={{height: '30px', padding: '3px'}} type="select" name="MDRRequired" id="MDRRequired" value={this.state.repairLog.MDRRequired} onChange={this.onChange} disabled>
														<option value="0">No</option>
														<option value="1">Yes</option>
													</Input>
												</Col>
											</Row>
											<Row className="">
												<Col xs="2" md="2" lg="2">
													<Label style={{fontSize:'18px'}} for="IncidentInvolved">Incident Involved:</Label>
												</Col>
												<Col xs="4" md="4" lg="4">
													<Input style={{height: '30px', padding: '3px'}} type="select" name="IncidentInvolved" id="IncidentInvolved" value={this.state.repairLog.IncidentInvolved} onChange={this.onChange} disabled>
														<option value="0">No injury</option>
														<option value="1">Minor injury</option>
														<option value="2">Major injury</option>
														<option value="3">Serious malfunction</option>
													</Input>
												</Col>
											</Row>
											<Row className="">
												<Col xs="2" md="2" lg="2">
													<Label style={{fontSize:'18px'}} for="MDRJustification">MDR Justification:</Label>
												</Col>
												<Col xs="7" md="7" lg="7">
													<Input style={{height: '30px', padding: '3px'}} type="select" name="MDRJustification" id="MDRJustification" value={this.state.repairLog.MDRJustification} onChange={this.onChange} disabled>
														<option value="0">No device related death, serious injury or malfunction that could result in death or serious injury</option>
														<option value="1">Death or serious injury to operator</option>
														<option value="2">Death or serious injury to patient</option>
														<option value="3">Malfunction that could result in death or serious injury</option>
													</Input>
												</Col>
											</Row>
											<Row className="pt-4">
												<Col xs="12" md="12" lg="12">
													<Label style={{fontSize:'18px'}} for="detailedIssue">Detailed Issue Info:</Label>
													{
														<Input style={{width:'100%', height: '200px'}} type="textarea" name="detailedIssue" id="detailedIssue" defaultValue={this.state.detailedIssue} disabled/>
														//<RichTextEditor style={{width:'100%', height: '300px'}} name="detailedIssue" id="detailedIssue" value={this.state.detailedIssue} onChange={this.onTextAreaChange}/>
													}

												</Col>
											</Row>
											<Row xs={3} className="text-center pt-4" style={{ display: 'flex', alignItems: 'center'}}>
											{this.state.sectionOneFiles.map((file, key) => {
												return (

				 									<Col xs="2" md="2" lg="2">
														<Card style={{ backgroundColor: "#313642" }}>
			                          <CardBody>

																	<div style={{ height: '100px', width: '100%'}}>
																		{this.is_video_url_extension(file.url) ? (
																			<ReactPlayer style={{ cursor:"pointer" }} width="100%" height="100%" url={file.url}/>
																		) : (
																			<Fragment>
																			{this.is_pdf_url_extension(file.url) ? (
																				<Fragment>
																				<img key={file.id} style={{ height: 'inherit', maxWidth: '100%', cursor:"pointer" }} className="mr-2" src={pdfPlaceholder}/>
																				</Fragment>
																			) : (
																				<Fragment>
																				<img key={file.id} style={{ height: 'inherit', maxWidth: '100%', cursor:"pointer" }} className="mr-2" src={file.url}/>
																				</Fragment>
																			)}
																			</Fragment>
																		)}
																	</div>

			                          </CardBody>
			                      </Card>
				 									</Col>

				 									);
				 							 })}
											</Row>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Collapse>
				{!this.state.isUPI &&
					<Fragment>
						<Row>
							<Col md="6">
								<h1 style={{color:'#737782'}}>Repair Information</h1>
							</Col>
							<Col md="6">
								{this.state.isTwoOpen ? (
									<Fragment>
										<i style={{color:'#ffc108'}} className="mdi mdi-arrow-up-drop-circle-outline mdi-36px float-right" onClick={this.toggleTwo}/>
									</Fragment>
								) : (
									<Fragment>
										<i style={{color:'#ffc108'}} className="mdi mdi-arrow-down-drop-circle-outline mdi-36px float-right" onClick={this.toggleTwo}/>
									</Fragment>
								)}
							</Col>
						</Row>
						<Collapse isOpen={this.state.isTwoOpen}>
							<Row>
								<Col md="12">
									<Card style={{backgroundColor:'#000', color: '#92989d'}} className="modal-card-color">
										<CardBody>
											<Row className="mb-3">
												<Col md="6">
													<Row className="">
														<Col xs="4" md="4" lg="4">
															<Label style={{fontSize:'18px'}} for="TechnicianRepair">Technician Name:</Label>
														</Col>
														<Col xs="7" md="7" lg="7">
															{this.state.isNewRepairLog ? (
																<Label style={{fontSize:'18px'}} id="TechnicianRepair">{userName}</Label>
															) : (
																<Label style={{fontSize:'18px'}} id="TechnicianRepair">{this.state.repairLog.TechnicianRepair}</Label>
															)}
														</Col>
													</Row>
													<Row className="">
														<Col xs="4" md="4" lg="4">
															<Label style={{fontSize:'18px'}} for="Status">Status:</Label>
														</Col>
														<Col xs="7" md="7" lg="7">
															<Input style={{height: '30px', padding: '3px'}} type="select" name="Status" id="Status" value={this.state.repairLog.Status} onChange={this.onChange} disabled>
																<option value="Pending product return">Pending product return</option>
																<option value="In Progress">In Progress</option>
																<option value="Completed">Completed</option>
																<option value="Pending customer approval">Pending customer approval</option>
																<option value="Customer approved work">Customer approved work</option>
																<option value="Customer Denied - Return">Customer Denied - Return</option>
															</Input>
														</Col>
													</Row>
													<Row className="">
														<Col xs="4" md="4" lg="4">
															<Label style={{fontSize:'18px'}} for="Status">Unit under warranty</Label>
														</Col>
														<Col xs="7" md="7" lg="7">
															{underWarrantyValue=="Unit out of warranty" ? (
																<Label style={{fontSize:'18px', color: '#e42128'}} for="Status">{underWarrantyValue}</Label>
															) : (
																<Label style={{fontSize:'18px', color: '#48b203'}} for="Status">{underWarrantyValue}</Label>
															)}
														</Col>
													</Row>
													<Row className="">
														<Col xs="4" md="4" lg="4">
															<Label style={{fontSize:'18px'}} for="Status">Check Battery</Label>
														</Col>
														<Col xs="7" md="7" lg="7">
															{checkBatteryValue=="Check Battery" ? (
																<Label style={{fontSize:'18px', color: '#e42128'}} for="Status">{checkBatteryValue}</Label>
															) : (
																<Label style={{fontSize:'18px', color: '#48b203'}} for="Status">{checkBatteryValue}</Label>
															)}
														</Col>
													</Row>
												</Col>
												<Col md="6">
													<Row className="">
														<Col xs="4" md="4" lg="4">
															<Label style={{fontSize:'18px'}} for="ReceivedDate">Date Received:</Label>
														</Col>
														<Col xs="7" md="7" lg="7">
															<Input style={{width:'100%', height: '30px'}} type="date" name="ReceivedDate" id="ReceivedDate" value={Moment(this.state.repairLog.ReceivedDate).utc(false).format('YYYY-MM-DD')} onChange={this.onChange} disabled/>
														</Col>
													</Row>
													<Row className="">
														<Col xs="4" md="4" lg="4">
														{
															//<Label style={{fontSize:'18px'}} for="SendCustomerEmail">Send email to customer:</Label>
														}

														</Col>
														<Col xs="7" md="7" lg="7">
														{
															//<Input type="checkbox" name="SendCustomerEmail" id="SendCustomerEmail" checked={this.state.repairLog.SendCustomerEmail} onChange={this.onChange}/>
														}
														</Col>
													</Row>
												</Col>
											</Row>
											<Row className="pt-4">
												<Col xs="12" md="12" lg="12">
													<Label style={{fontSize:'18px'}} for="detailedRepair">Detailed Repair Info:</Label>
													<Input style={{width:'100%', height: '200px'}} type="textarea" name="detailedRepair" id="detailedRepair" defaultValue={this.state.detailedRepair} onChange={this.onChangeRepairNotes} disabled/>
												</Col>
											</Row>
											<Row className="pt-3">
												<Col md="6">
													<Row className="">
														<Col xs="4" md="4" lg="4">
															<Label style={{fontSize:'18px'}} for="Firmware">Firmware/Software:</Label>
														</Col>
														<Col xs="7" md="7" lg="7">
															<Input style={{width:'100%', height: '30px'}} type="text" name="Firmware" id="Firmware" defaultValue={this.state.repairLog.Firmware} disabled/>
														</Col>
													</Row>
												</Col>
												<Col md="6">
													<Row className="">
														<Col xs="4" md="4" lg="4">
															<Label style={{fontSize:'18px'}} for="NewCalibration">New Calibration:</Label>
														</Col>
														<Col xs="7" md="7" lg="7">
															<Input style={{height: '30px', padding: '3px'}} type="select" name="NewCalibration" id="NewCalibration" value={this.state.repairLog.NewCalibration} onChange={this.onChange} disabled>
																<option value="Yes">Yes</option>
																<option value="No">No</option>
															</Input>
														</Col>
													</Row>
												</Col>
											</Row>
											{old ? (
												<Fragment>
												<Row className="pt-4">
													<Col xs="3" md="3" lg="3">
														<Row className="">
															<Col xs="12" md="12" lg="12">
																<Input style={{marginLeft:'0px'}} type="checkbox" name="ReplacedSD" id="ReplacedSD" checked={this.state.repairLog.ReplacedSD} onChange={this.onChange} disabled/>
																<Label className="ml-4" style={{fontSize:'18px'}} for="ReplacedSD">Replaced SD Card</Label>
															</Col>
														</Row>
														<Row className="">
															<Col xs="12" md="12" lg="12">
																<Input style={{marginLeft:'0px'}} type="checkbox" name="ReplacedFiber" id="ReplacedFiber" checked={this.state.repairLog.ReplacedFiber} onChange={this.onChange} disabled/>
																<Label className="ml-4" style={{fontSize:'18px'}} for="ReplacedFiber">Replaced Fiber</Label>
															</Col>
														</Row>
														<Row className="">
															<Col xs="12" md="12" lg="12">
																<Input style={{marginLeft:'0px'}} type="checkbox" name="ReplacedModule" id="ReplacedModule" checked={this.state.repairLog.ReplacedModule} onChange={this.onChange} disabled/>
																<Label className="ml-4" style={{fontSize:'18px'}} for="ReplacedModule">Replaced Module</Label>
															</Col>
														</Row>
														<Row className="">
															<Col xs="12" md="12" lg="12">
																<Input style={{marginLeft:'0px'}} type="checkbox" name="ReplacedMain" id="ReplacedMain" checked={this.state.repairLog.ReplacedMain} onChange={this.onChange} disabled/>
																<Label className="ml-4" style={{fontSize:'18px'}} for="ReplacedMain">Replaced Main</Label>
															</Col>
														</Row>
													</Col>
													<Col xs="3" md="3" lg="3">
														<Row className="">
															<Col xs="12" md="12" lg="12">
																<Input style={{marginLeft:'0px'}} type="checkbox" name="ReplacedHans" id="ReplacedHans" checked={this.state.repairLog.ReplacedHans} onChange={this.onChange} disabled/>
																<Label className="ml-4" style={{fontSize:'18px'}} for="ReplacedHans">Replaced Hans</Label>
															</Col>
														</Row>
														<Row className="">
															<Col xs="12" md="12" lg="12">
																<Input style={{marginLeft:'0px'}} type="checkbox" name="ReplacedDisplay" id="ReplacedDisplay" checked={this.state.repairLog.ReplacedDisplay} onChange={this.onChange} disabled/>
																<Label className="ml-4" style={{fontSize:'18px'}} for="ReplacedDisplay">Replaced Display</Label>
															</Col>
														</Row>
														<Row className="">
															<Col xs="12" md="12" lg="12">
																<Input style={{marginLeft:'0px'}} type="checkbox" name="ReplacedSpeaker" id="ReplacedSpeaker" checked={this.state.repairLog.ReplacedSpeaker} onChange={this.onChange} disabled/>
																<Label className="ml-4" style={{fontSize:'18px'}} for="ReplacedSpeaker">Replaced Speaker</Label>
															</Col>
														</Row>
														<Row className="">
															<Col xs="12" md="12" lg="12">
																<Input style={{marginLeft:'0px'}} type="checkbox" name="ReplacedBattery" id="ReplacedBattery" checked={this.state.repairLog.ReplacedBattery} onChange={this.onChange} disabled/>
																<Label className="ml-4" style={{fontSize:'18px'}} for="ReplacedBattery">Replaced Battery</Label>
															</Col>
														</Row>
													</Col>
													<Col xs="3" md="3" lg="3">
														<Row className="">
															<Col xs="12" md="12" lg="12">
																<Input style={{marginLeft:'0px'}} type="checkbox" name="ReplacedPedal" id="ReplacedPedal" checked={this.state.repairLog.ReplacedPedal} onChange={this.onChange} disabled/>
																<Label className="ml-4" style={{fontSize:'18px'}} for="ReplacedPedal">Replaced Pedal</Label>
															</Col>
														</Row>
														<Row className="">
															<Col xs="12" md="12" lg="12">
																<Input style={{marginLeft:'0px'}} type="checkbox" name="ReplacedKeypad" id="ReplacedKeypad" checked={this.state.repairLog.ReplacedKeypad} onChange={this.onChange} disabled/>
																<Label className="ml-4" style={{fontSize:'18px'}} for="ReplacedKeypad">Replaced Keypad</Label>
															</Col>
														</Row>
														<Row className="">
															<Col xs="12" md="12" lg="12">
																<Input style={{marginLeft:'0px'}} type="checkbox" name="ReplacedDisplayDriver" id="ReplacedDisplayDriver" checked={this.state.repairLog.ReplacedDisplayDriver} onChange={this.onChange} disabled/>
																<Label className="ml-4" style={{fontSize:'18px'}} for="ReplacedDisplayDriver">Replaced Display Driver</Label>
															</Col>
														</Row>
														<Row className="">
															<Col xs="12" md="12" lg="12">
																<Input style={{marginLeft:'0px'}} type="checkbox" name="FixedBoardSolder" id="FixedBoardSolder" checked={this.state.repairLog.FixedBoardSolder} onChange={this.onChange} disabled/>
																<Label className="ml-4" style={{fontSize:'18px'}} for="FixedBoardSolder">Fixed Board Solder</Label>
															</Col>
														</Row>
													</Col>
													<Col xs="3" md="3" lg="3">
														<Row className="">
															<Col xs="12" md="12" lg="12">
																<Input style={{marginLeft:'0px'}} type="checkbox" name="ReplacedFlex16" id="ReplacedFlex16" checked={this.state.repairLog.ReplacedFlex16} onChange={this.onChange} disabled/>
																<Label className="ml-4" style={{fontSize:'18px'}} for="ReplacedFlex16">Replaced Flex 16</Label>
															</Col>
														</Row>
														<Row className="">
															<Col xs="12" md="12" lg="12">
																<Input style={{marginLeft:'0px'}} type="checkbox" name="ReplacedFlex21" id="ReplacedFlex21" checked={this.state.repairLog.ReplacedFlex21} onChange={this.onChange} disabled/>
																<Label className="ml-4" style={{fontSize:'18px'}} for="ReplacedFlex21">Replaced Flex 21</Label>
															</Col>
														</Row>
														<Row className="">
															<Col xs="12" md="12" lg="12">
																<Input style={{marginLeft:'0px'}} type="checkbox" name="ReplacedBox" id="ReplacedBox" checked={this.state.repairLog.ReplacedBox} onChange={this.onChange} disabled/>
																<Label className="ml-4" style={{fontSize:'18px'}} for="ReplacedBox">Replaced Box</Label>
															</Col>
														</Row>
														<Row className="">
															<Col xs="12" md="12" lg="12">
																<Input style={{marginLeft:'0px'}} type="checkbox" name="ReplacedFoam" id="ReplacedFoam" checked={this.state.repairLog.ReplacedFoam} onChange={this.onChange} disabled/>
																<Label className="ml-4" style={{fontSize:'18px'}} for="ReplacedFoam">Replaced Foam</Label>
															</Col>
														</Row>
													</Col>
												</Row>
												</Fragment>
											) : (
												<Fragment>
												<Row className="pt-4">
													<Col xs="4" md="4" lg="4">
														<Row className="">
															<Col xs="4" md="4" lg="4">
																<Label style={{fontSize:'18px'}} for="IssueOption1">Components:</Label>
															</Col>
															<Col xs="8" md="8" lg="8">
																<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueOption1" id="IssueOption1" value={this.state.repairLog.IssueOption1} onChange={this.onChange} disabled>
																	<option value="0">Select appropriate issue</option>
																	{this.state.repairOptions.map((option, i) => (
																		<option value={option.id}>{option.name}</option>
																	))}
																</Input>
															</Col>
														</Row>
														<Row className="">
															<Col xs="4" md="4" lg="4">

															</Col>
															<Col xs="8" md="8" lg="8">
																<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueOption2" id="IssueOption2" value={this.state.repairLog.IssueOption2} onChange={this.onChange} disabled>
																	<option value="0">Select appropriate issue</option>
																	{this.state.repairOptions.map((option, i) => (
																		<option value={option.id}>{option.name}</option>
																	))}
																</Input>
															</Col>
														</Row>
														<Row className="">
															<Col xs="4" md="4" lg="4">

															</Col>
															<Col xs="8" md="8" lg="8">
																<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueOption3" id="IssueOption3" value={this.state.repairLog.IssueOption3} onChange={this.onChange} disabled>
																	<option value="0">Select appropriate issue</option>
																	{this.state.repairOptions.map((option, i) => (
																		<option value={option.id}>{option.name}</option>
																	))}
																</Input>
															</Col>
														</Row>
														<Row className="">
															<Col xs="4" md="4" lg="4">

															</Col>
															<Col xs="8" md="8" lg="8">
																<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueOption4" id="IssueOption4" value={this.state.repairLog.IssueOption4} onChange={this.onChange} disabled>
																	<option value="0">Select appropriate issue</option>
																	{this.state.repairOptions.map((option, i) => (
																		<option value={option.id}>{option.name}</option>
																	))}
																</Input>
															</Col>
														</Row>
													</Col>
													<Col xs="4" md="4" lg="4">
														<Row className="">
															<Col xs="2" md="2" lg="2">

															</Col>
															<Col xs="8" md="8" lg="8">
																<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueOption5" id="IssueOption5" value={this.state.repairLog.IssueOption5} onChange={this.onChange} disabled>
																	<option value="0">Select appropriate issue</option>
																	{this.state.repairOptions.map((option, i) => (
																		<option value={option.id}>{option.name}</option>
																	))}
																</Input>
															</Col>
														</Row>
														<Row className="">
															<Col xs="2" md="2" lg="2">

															</Col>
															<Col xs="8" md="8" lg="8">
																<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueOption6" id="IssueOption6" value={this.state.repairLog.IssueOption6} onChange={this.onChange} disabled>
																	<option value="0">Select appropriate issue</option>
																	{this.state.repairOptions.map((option, i) => (
																		<option value={option.id}>{option.name}</option>
																	))}
																</Input>
															</Col>
														</Row>
														<Row className="">
															<Col xs="2" md="2" lg="2">

															</Col>
															<Col xs="8" md="8" lg="8">
																<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueOption7" id="IssueOption7" value={this.state.repairLog.IssueOption7} onChange={this.onChange} disabled>
																	<option value="0">Select appropriate issue</option>
																	{this.state.repairOptions.map((option, i) => (
																		<option value={option.id}>{option.name}</option>
																	))}
																</Input>
															</Col>
														</Row>
														<Row className="">
															<Col xs="2" md="2" lg="2">

															</Col>
															<Col xs="8" md="8" lg="8">
																<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueOption8" id="IssueOption8" value={this.state.repairLog.IssueOption8} onChange={this.onChange} disabled>
																	<option value="0">Select appropriate issue</option>
																	{this.state.repairOptions.map((option, i) => (
																		<option value={option.id}>{option.name}</option>
																	))}
																</Input>
															</Col>
														</Row>
													</Col>
													<Col xs="4" md="4" lg="4">
														<Row className="">
															<Col xs="8" md="8" lg="8">
																<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueOption9" id="IssueOption9" value={this.state.repairLog.IssueOption9} onChange={this.onChange} disabled>
																	<option value="0">Select appropriate issue</option>
																	{this.state.repairOptions.map((option, i) => (
																		<option value={option.id}>{option.name}</option>
																	))}
																</Input>
															</Col>
														</Row>
														<Row className="">
															<Col xs="8" md="8" lg="8">
																<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueOption10" id="IssueOption10" value={this.state.repairLog.IssueOption10} onChange={this.onChange} disabled>
																	<option value="0">Select appropriate issue</option>
																	{this.state.repairOptions.map((option, i) => (
																		<option value={option.id}>{option.name}</option>
																	))}
																</Input>
															</Col>
														</Row>
														<Row className="">
															<Col xs="8" md="8" lg="8">
																<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueOption11" id="IssueOption11" value={this.state.repairLog.IssueOption11} onChange={this.onChange} disabled>
																	<option value="0">Select appropriate issue</option>
																	{this.state.repairOptions.map((option, i) => (
																		<option value={option.id}>{option.name}</option>
																	))}
																</Input>
															</Col>
														</Row>
														<Row className="">
															<Col xs="8" md="8" lg="8">
																<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueOption12" id="IssueOption12" value={this.state.repairLog.IssueOption12} onChange={this.onChange} disabled>
																	<option value="0">Select appropriate issue</option>
																	{this.state.repairOptions.map((option, i) => (
																		<option value={option.id}>{option.name}</option>
																	))}
																</Input>
															</Col>
														</Row>
													</Col>
												</Row>
												</Fragment>
											)}
											<hr/>
											<Row className="">
												<div style={{ width: '100%', height: '1px', backgroundColor: "#ffc72c", margin: '25px' }} id="line"></div>
											</Row>
											<Row className="">
												<Col xs="6" md="6" lg="6">
													<Row className="">
														<Col xs="4" md="4" lg="4">
															<Label style={{fontSize:'18px'}} for="IssueCategory1">Nature of Problem:</Label>
														</Col>
														<Col xs="7" md="7" lg="7">
															<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueCategory1" id="IssueCategory1" value={this.state.repairLog.IssueCategory1} onChange={this.onChange} disabled>
																<option value="0">Select appropriate issue</option>
																{this.state.repairCategories.map((category, i) => (
																	<option value={category.id}>{category.name}</option>
																))}
															</Input>
														</Col>
													</Row>
													<Row className="">
														<Col xs="4" md="4" lg="4">
														</Col>
														<Col xs="7" md="7" lg="7">
															<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueCategory2" id="IssueCategory2" value={this.state.repairLog.IssueCategory2} onChange={this.onChange} disabled>
																<option value="0">Select appropriate issue</option>
																{this.state.repairCategories.map((category, i) => (
																	<option value={category.id}>{category.name}</option>
																))}
															</Input>
														</Col>
													</Row>
													<Row className="">
														<Col xs="4" md="4" lg="4">
														</Col>
														<Col xs="7" md="7" lg="7">
															<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueCategory3" id="IssueCategory3" value={this.state.repairLog.IssueCategory3} onChange={this.onChange} disabled>
																<option value="0">Select appropriate issue</option>
																{this.state.repairCategories.map((category, i) => (
																	<option value={category.id}>{category.name}</option>
																))}
															</Input>
														</Col>
													</Row>
													<Row className="">
														<Col xs="4" md="4" lg="4">
														</Col>
														<Col xs="7" md="7" lg="7">
															<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueCategory4" id="IssueCategory4" value={this.state.repairLog.IssueCategory4} onChange={this.onChange} disabled>
																<option value="0">Select appropriate issue</option>
																{this.state.repairCategories.map((category, i) => (
																	<option value={category.id}>{category.name}</option>
																))}
															</Input>
														</Col>
													</Row>
													<Row className="pt-4">
														<Col xs="4" md="4" lg="4">
															<Label style={{fontSize:'18px'}} for="RecallRequired">Recall Required?</Label>
														</Col>
														<Col xs="7" md="7" lg="7">
															<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="RecallRequired" id="RecallRequired" value={this.state.repairLog.RecallRequired} onChange={this.onChange} disabled>
																<option value="0">No</option>
																<option value="1">Yes</option>
															</Input>
														</Col>
													</Row>
												</Col>
												<Col xs="6" md="6" lg="6">
													<Row className="">
														<Col xs="4" md="4" lg="4">
															<Label style={{fontSize:'18px'}} for="IssueCategory4">Component Replacement:</Label>
														</Col>
														<Col xs="6" md="6" lg="6">
															<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueBOM1" id="IssueBOM1" value={this.state.repairLog.IssueBOM1} onChange={(e) => { this.onChangeBOM(e, this.state.repairLog.IssueBOM1); }} disabled>
																<option value="0">Select appropriate issue</option>
																{this.state.bomData.map((bom, i) => (
																	<option value={bom.id}>{bom.Description}</option>
																))}
															</Input>
														</Col>
														<Col xs="2" md="2" lg="2">
															<Input style={{width: '50px', height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueBOM1Quantity" id="IssueBOM1Quantity" value={this.state.repairLog.IssueBOM1Quantity} onChange={(e) => { this.onChangeQuantity(e, this.state.repairLog.IssueBOM1); }} disabled>
																<option value="0">0</option>
																<option value="1">1</option>
																<option value="2">2</option>
																<option value="3">3</option>
																<option value="4">4</option>
																<option value="5">5</option>
															</Input>
														</Col>
													</Row>
													<Row className="">
														<Col xs="4" md="4" lg="4">
														</Col>
														<Col xs="6" md="6" lg="6">
															<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueBOM2" id="IssueBOM2" value={this.state.repairLog.IssueBOM2} onChange={(e) => { this.onChangeBOM(e, this.state.repairLog.IssueBOM2); }} disabled>
																<option value="0">Select appropriate issue</option>
																{this.state.bomData.map((bom, i) => (
																	<option value={bom.id}>{bom.Description}</option>
																))}
															</Input>
														</Col>
														<Col xs="2" md="2" lg="2">
															<Input style={{width: '50px', height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueBOM2Quantity" id="IssueBOM2Quantity" value={this.state.repairLog.IssueBOM2Quantity} onChange={(e) => { this.onChangeQuantity(e, this.state.repairLog.IssueBOM2); }} disabled>
																<option value="0">0</option>
																<option value="1">1</option>
																<option value="2">2</option>
																<option value="3">3</option>
																<option value="4">4</option>
																<option value="5">5</option>
															</Input>
														</Col>
													</Row>
													<Row className="">
														<Col xs="4" md="4" lg="4">
														</Col>
														<Col xs="6" md="6" lg="6">
															<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueBOM3" id="IssueBOM3" value={this.state.repairLog.IssueBOM3} onChange={(e) => { this.onChangeBOM(e, this.state.repairLog.IssueBOM3); }} disabled>
																<option value="0">Select appropriate issue</option>
																{this.state.bomData.map((bom, i) => (
																	<option value={bom.id}>{bom.Description}</option>
																))}
															</Input>
														</Col>
														<Col xs="2" md="2" lg="2">
															<Input style={{width: '50px', height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueBOM3Quantity" id="IssueBOM3Quantity" value={this.state.repairLog.IssueBOM3Quantity} onChange={(e) => { this.onChangeQuantity(e, this.state.repairLog.IssueBOM3); }} disabled>
																<option value="0">0</option>
																<option value="1">1</option>
																<option value="2">2</option>
																<option value="3">3</option>
																<option value="4">4</option>
																<option value="5">5</option>
															</Input>
														</Col>
													</Row>
													<Row className="">
														<Col xs="4" md="4" lg="4">
														</Col>
														<Col xs="6" md="6" lg="6">
															<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueBOM4" id="IssueBOM4" value={this.state.repairLog.IssueBOM4} onChange={(e) => { this.onChangeBOM(e, this.state.repairLog.IssueBOM4); }} disabled>
																<option value="0">Select appropriate issue</option>
																{this.state.bomData.map((bom, i) => (
																	<option value={bom.id}>{bom.Description}</option>
																))}
															</Input>
														</Col>
														<Col xs="2" md="2" lg="2">
															<Input style={{width: '50px', height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueBOM4Quantity" id="IssueBOM4Quantity" value={this.state.repairLog.IssueBOM4Quantity} onChange={(e) => { this.onChangeQuantity(e, this.state.repairLog.IssueBOM4); }} disabled>
																<option value="0">0</option>
																<option value="1">1</option>
																<option value="2">2</option>
																<option value="3">3</option>
																<option value="4">4</option>
																<option value="5">5</option>
															</Input>
														</Col>
													</Row>
													<Row className="">
														<Col xs="4" md="4" lg="4">
														</Col>
														<Col xs="6" md="6" lg="6">
															<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueBOM5" id="IssueBOM5" value={this.state.repairLog.IssueBOM5} onChange={(e) => { this.onChangeBOM(e, this.state.repairLog.IssueBOM5); }} disabled>
																<option value="0">Select appropriate issue</option>
																{this.state.bomData.map((bom, i) => (
																	<option value={bom.id}>{bom.Description}</option>
																))}
															</Input>
														</Col>
														<Col xs="2" md="2" lg="2">
															<Input style={{width: '50px', height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueBOM5Quantity" id="IssueBOM5Quantity" value={this.state.repairLog.IssueBOM5Quantity} onChange={(e) => { this.onChangeQuantity(e, this.state.repairLog.IssueBOM5); }} disabled>
																<option value="0">0</option>
																<option value="1">1</option>
																<option value="2">2</option>
																<option value="3">3</option>
																<option value="4">4</option>
																<option value="5">5</option>
															</Input>
														</Col>
													</Row>
													<Row className="">
														<Col xs="4" md="4" lg="4">
														</Col>
														<Col xs="6" md="6" lg="6">
															<Input style={{height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueBOM6" id="IssueBOM6" value={this.state.repairLog.IssueBOM6} onChange={(e) => { this.onChangeBOM(e, this.state.repairLog.IssueBOM6); }} disabled>
																<option value="0">Select appropriate issue</option>
																{this.state.bomData.map((bom, i) => (
																	<option value={bom.id}>{bom.Description}</option>
																))}
															</Input>
														</Col>
														<Col xs="2" md="2" lg="2">
															<Input style={{width: '50px', height: '30px', margin: '3px', padding: '3px'}} type="select" name="IssueBOM6Quantity" id="IssueBOM6Quantity" value={this.state.repairLog.IssueBOM6Quantity} onChange={(e) => { this.onChangeQuantity(e, this.state.repairLog.IssueBOM6); }} disabled>
																<option value="0">0</option>
																<option value="1">1</option>
																<option value="2">2</option>
																<option value="3">3</option>
																<option value="4">4</option>
																<option value="5">5</option>
															</Input>
														</Col>
													</Row>
												</Col>
											</Row>
											<Row xs={3} className="text-center pt-4" style={{ display: 'flex', alignItems: 'center'}}>
											{this.state.sectionTwoFiles.map((file, key) => {
												return (

													<Col xs="2" md="2" lg="2">
														<Card style={{ backgroundColor: "#313642" }}>
																<CardBody>
																	<div style={{ height: '100px', width: '100%'}}>
																		{this.is_video_url_extension(file.url) ? (
																			<ReactPlayer style={{ cursor:"pointer" }} width="100%" height="100%" url={file.url}/>
																		) : (
																			<Fragment>
																			{this.is_pdf_url_extension(file.url) ? (
																				<Fragment>
																				<img key={file.id} style={{ height: 'inherit', maxWidth: '100%', cursor:"pointer" }} className="mr-2" src={pdfPlaceholder}/>
																				</Fragment>
																			) : (
																				<Fragment>
																				<img key={file.id} style={{ height: 'inherit', maxWidth: '100%', cursor:"pointer" }} className="mr-2" src={file.url}/>
																				</Fragment>
																			)}
																			</Fragment>
																		)}
																	</div>
																</CardBody>
														</Card>
													</Col>

													);
											 })}
											</Row>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Collapse>
						<Row>
							<Col md="6">
								<h1 style={{color:'#737782'}}>Customer Information</h1>
							</Col>
							<Col md="6">
								{this.state.isThreeOpen ? (
									<Fragment>
										<i style={{color:'#ffc108'}} className="mdi mdi-arrow-up-drop-circle-outline mdi-36px float-right" onClick={this.toggleThree}/>
									</Fragment>
								) : (
									<Fragment>
										<i style={{color:'#ffc108'}} className="mdi mdi-arrow-down-drop-circle-outline mdi-36px float-right" onClick={this.toggleThree}/>
									</Fragment>
								)}
							</Col>
						</Row>
						<Collapse isOpen={this.state.isThreeOpen}>
							<Row>
								<Col md="12">
									<Card style={{backgroundColor:'#000', color: '#92989d'}} className="modal-card-color">
										<CardBody>
										<Row className="mb-3">
											<Col md="6">
												<Row className="">
													<Col xs="4" md="4" lg="4">
														<Label style={{fontSize:'18px'}} for="ReturnedDate">Date Back to Customer:</Label>
													</Col>
													<Col xs="7" md="7" lg="7">
														<Input style={{width:'100%', height: '30px'}} type="date" name="ReturnedDate" id="ReturnedDate" value={Moment(this.state.repairLog.ReturnedDate).utc(false).format('YYYY-MM-DD')} onChange={this.onChange} disabled/>
													</Col>
												</Row>
												<Row className="">
													<Col xs="4" md="4" lg="4">
														<Label style={{fontSize:'18px'}} for="TrackingCustomer">Tracking # to Customer:</Label>
													</Col>
													<Col xs="7" md="7" lg="7">
														<Input style={{width:'100%', height: '30px'}} type="text" name="TrackingCustomer" id="TrackingCustomer" defaultValue={this.state.repairLog.TrackingCustomer} disabled/>
													</Col>
												</Row>
											</Col>
											<Col md="6">
												<Row className="">
													<Col xs="4" md="4" lg="4">
														<Label style={{fontSize:'18px'}} for="ReturnedStatus">Returned Unit Status:</Label>
													</Col>
													<Col xs="7" md="7" lg="7">
														<Input style={{height: '30px', padding: '3px'}} type="select" name="ReturnedStatus" id="ReturnedStatus" value={this.state.repairLog.ReturnedStatus} onChange={this.onChange} disabled>
															<option value="As Is">As Is</option>
															<option value="Back to Inventory">Back to Inventory</option>
														</Input>
													</Col>
												</Row>
												<Row className="">
													<Col xs="4" md="4" lg="4">
														<Label style={{fontSize:'18px'}} for="ShippingDestination">Shipping Destination:</Label>
													</Col>
													<Col xs="7" md="7" lg="7">
														<Input style={{height: '30px', padding: '3px'}} type="select" name="ShippingDestination" id="ShippingDestination" value={this.state.repairLog.ShippingDestination} onChange={this.onChange} disabled>
															<option value="Customer">Customer</option>
															<option value="UPI">UPI</option>
														</Input>
													</Col>
												</Row>
											</Col>
										</Row>
										<Row className="pt-3">
											<Col xs="4" md="4" lg="4">
												<Label style={{fontSize:'18px'}} for="CustomerNoteSelect">Select Customer Note Template:</Label>
											</Col>
											<Col xs="7" md="7" lg="7">
												<Input style={{height: '30px', padding: '3px'}} type="select" name="CustomerNoteSelect" id="CustomerNoteSelect" onChange={this.onChangeCustomerNotesSelect} disabled>
													<option value="0">Select Note Template</option>
													{this.state.repairCustomerNotes.map((note, i) => (
														<option value={note.id}>{note.Description}</option>
													))}
												</Input>
											</Col>
										</Row>
										<Row className="pt-4">
											<Col xs="12" md="12" lg="12">
												<Label style={{fontSize:'18px'}} for="customerNotes">Customer Notes:</Label>
												<Input style={{width:'100%', height: '200px'}} type="textarea" name="customerNotes" id="customerNotes" defaultValue={this.state.customerNotes} onChange={this.onChangeCustomerNotes} disabled/>
											</Col>
										</Row>
										<Row xs={3} className="text-center pt-4" style={{ display: 'flex', alignItems: 'center'}}>
										{this.state.sectionThreeFiles.map((file, key) => {
											return (

												<Col xs="2" md="2" lg="2">
													<Card style={{ backgroundColor: "#313642" }}>
															<CardBody>
																<div style={{ height: '100px', width: '100%'}}>
																	{this.is_video_url_extension(file.url) ? (
																		<ReactPlayer style={{ cursor:"pointer" }} width="100%" height="100%" url={file.url}/>
																	) : (
																		<Fragment>
																		{this.is_pdf_url_extension(file.url) ? (
																			<Fragment>
																			<img key={file.id} style={{ height: 'inherit', maxWidth: '100%', cursor:"pointer" }} className="mr-2" src={pdfPlaceholder}/>
																			</Fragment>
																		) : (
																			<Fragment>
																			<img key={file.id} style={{ height: 'inherit', maxWidth: '100%', cursor:"pointer" }} className="mr-2" src={file.url}/>
																			</Fragment>
																		)}
																		</Fragment>
																	)}
																</div>
															</CardBody>
													</Card>
												</Col>

												);
										 })}
										</Row>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Collapse>
					</Fragment>
				}
			</Fragment>
		);
	}
}

export default RepairLogModalView;
