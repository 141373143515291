import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import Loader from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Papa from 'papaparse';

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	ButtonDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
	Fade
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

import csvPlaceholder from '../../../assets/images/csv-icon512x512.png';

class BatchShipmentDateUpload extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			repairCustomerNotes: [],
			productsData: [],
			productItemsForNote: [],
			errorMsg: null,
			obj: {},
			modal: false,
			isOpen: false,
			isUploadingFile: false,
			isSavingDates: false,
			currentItem: "ALL",
			currentDataSetting: 0,
			loadedSerialBatch: false,
			selectedFileSerialBatch: null,
			parsedCSVData: [],
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.toggle = this.toggle.bind(this);

  }

	async componentDidMount() {

		//this.getProductsData();
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	componentWillUnmount() {

	}

	onClickUploadSerialBatchHandler = () => {

		const file = this.state.selectedFileSerialBatch;

    if (!file) {
			this.setState({ errorMsg: 'Please select a CSV file.' });
      //setErrorMsg('Please select a CSV file.');
      return;
    }

    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (result) => {
        if (result.errors.length > 0) {
          //setErrorMsg('Error parsing the CSV file.');
					this.setState({ errorMsg: 'Error parsing the CSV file.' });
        } else {
          //setCsvData(result.data);
          //setErrorMsg(null);
					console.log("CsvData: " + JSON.stringify(result.data));

          if (result.data[result.data.length-1]["Serial Numbers"] && result.data[result.data.length-1]["Invoice Date"]) {

  					var formattedCSVData = [];

  					result.data.map((prop, key) => {

  						const serials = prop["Serial Numbers"];

  						const serialsArray = serials.split(",");

  						if (serialsArray.length > 1) {

  							serialsArray.map((prop2, key) => {

  								const data = {
  									id: key,
  									SerialNumber: prop2,
  									InvoiceDate: prop["Invoice Date"]
  								};

  								formattedCSVData.push(data);

  							});

  						} else {

  							const data = {
  								id: key,
  								SerialNumber: prop["Serial Numbers"],
  								InvoiceDate: prop["Invoice Date"]
  							};

  							formattedCSVData.push(data);

  						}

  						//let extension = contentTypeArray[1];

  						return {
  							id: key,
  							SerialNumber: prop["Serial Numbers"],
  							InvoiceDate: prop["Invoice Date"]
  						};
  					});

  					console.log("CsvData formatted: " + JSON.stringify(formattedCSVData));

  					this.setState({ parsedCSVData: formattedCSVData, errorMsg: null });

          } else {
            this.setState({ errorMsg: 'CSV Format is incorrect.' });
            this.setState({ parsedCSVData: [] });
          }

        }
      },
    });

	}

	// onClickUploadSerialBatchHandler = () => {
  //
	// 	const file = this.state.selectedFileSerialBatch;
  //
  //   if (!file) {
	// 		this.setState({ errorMsg: 'Please select a CSV file.' });
  //     //setErrorMsg('Please select a CSV file.');
  //     return;
  //   }
  //
  //   Papa.parse(file, {
  //     header: true,
  //     dynamicTyping: true,
  //     skipEmptyLines: true,
  //     complete: (result) => {
  //       if (result.errors.length > 0) {
  //         //setErrorMsg('Error parsing the CSV file.');
	// 				this.setState({ errorMsg: 'Error parsing the CSV file.' });
  //       } else {
  //         //setCsvData(result.data);
  //         //setErrorMsg(null);
	// 				console.log("CsvData: " + JSON.stringify(result.data));
  //
	// 				if (result.data[result.data.length-1].SerialNumber && result.data[result.data.length-1].BatchNumber) {
  //
	// 					for (const serial of result.data) {
  //
	// 						console.log("SerialNumber: " + JSON.stringify(serial.SerialNumber));
	// 						console.log("BatchNumber: " + JSON.stringify(serial.BatchNumber));
  //
	// 				  };
  //
	// 					const formattedData = result.data.map((prop, key) => {
  //
	// 						var serialNumber = "EL-0"+prop.SerialNumber;
  //
	// 						return {
	// 							SerialNumber: serialNumber,
	// 							BatchNumber: prop.BatchNumber
	// 						};
	// 					});
  //
	// 					this.setState({ errorMsg: null });
	// 					this.setState({ parsedCSVData: formattedData });
  //
	// 				} else {
	// 					this.setState({ errorMsg: 'CSV Format is incorrect.' });
	// 					this.setState({ parsedCSVData: [] });
	// 				}
  //
  //       }
  //     },
  //   });
  //
	// }

	onChangeSerialBatchHandler = (event) => {

		var contentType = event.target.files[0].type;

		const contentTypeArray = contentType.split("/");

		let extension = contentTypeArray[1];

		this.setState({
			selectedFileSerialBatch: event.target.files[0],
			loadedSerialBatch: true,
		})

		console.log("file type: "+event.target.files[0].type);
	}

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	saveShipmentDatesData = async () => {

		this.setState({ isSavingDates: true });

    const data = this.state.parsedCSVData.map((prop, key) => {

      const serialArray = prop.SerialNumber.split("/");
      const invoiceDateFormatted = Moment(prop.InvoiceDate).format('YYYY-MM-DD')

			return {
				SerialNumber: serialArray[1],
				InvoiceDate: invoiceDateFormatted
			};
		});

    console.log("saveShipmentDatesData response: " + JSON.stringify(data));

    var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"save-batch-shipment-dates";

		let requestBody = {
			ShipmentDatesList:data
		}

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				Authorization: this.state.currentUser.jwtToken,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(requestBody)
		});

		const body = await response.json();
		console.log("saveShipmentDatesData response: " + JSON.stringify(body));

		if (body.status == 'Success') {
			//this.setState({ productsData: body.response });
			this.setState({ parsedCSVData: [] });
			document.getElementById("fileSerialBatch").value = null;
			this.setState({ selectedFileSerialBatch: null, loadedSerialBatch: false });
			this.setState({ isSavingDates: false });
			toast.success("All device shipment dates successfully uploaded.", {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
				closeButton: false
			});

		} else {
      //ADD ERROR MESSAGE
			this.setState({ isSavingDates: false });
			toast.error("Error uploading device shipment dates. Please try again.", {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
				closeButton: false
			});

    }

	};

  render() {

		const data = this.state.parsedCSVData.map((prop, key) => {

			return {
				id: key,
				SerialNumber: prop.SerialNumber,
				InvoiceDate: prop.InvoiceDate,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
					</div>
				)
			};
		});

		// const data = this.state.parsedCSVData.map((prop, key) => {
    //
		// 	//var serialNumber = "EL-0"+prop.SerialNumber;
    //
		// 	return {
		// 		id: key,
		// 		SerialNumber: prop.SerialNumber,
		// 		BatchNumber: prop.BatchNumber,
		// 		actions: (
		// 			// we've added some custom button actions
		// 			<div className="text-center">
		// 				{/* use this button to add a edit kind of action */}
		// 				{/* use this button to remove the data row */}
		// 			</div>
		// 		)
		// 	};
		// });

		return (
      <Fragment>
        <Card className="card-hover">
          <CardBody>
            <Row className="mb-3">
              <Col md="6">
                <h3 style={{color:'#737782'}}>G2 Shipment Date Upload</h3>
              </Col>
							<Col md="6" className="text-right">
								<div>
								</div>
							</Col>
            </Row>
						{!this.state.isUploadingFile ? (
							<Fragment>
							<Row className="">
								<Col xs="3" md="3" lg="3">
									<Input className="" type="file" name="fileSerialBatch" id="fileSerialBatch" accept=".csv" onChange={this.onChangeSerialBatchHandler} onClick={e => (e.target.value = null)}/>
								</Col>
								<Col xs="1" md="1" lg="1">
									{this.state.loadedSerialBatch &&
										<Button className="" size="sm" color="yellow" title="Upload" onClick={this.onClickUploadSerialBatchHandler}>
											Upload
										</Button>
									}
								</Col>
								<Col xs="3" md="3" lg="3">
									{this.state.loadedSerialBatch &&
										<img style={{ marginTop: "-3px"}} className="ml-2" src={csvPlaceholder} width="30"/>
									}
								</Col>
								<Col xs="5" md="5" lg="5">
								{!this.state.isSavingDates ? (
									<Fragment>
										{this.state.parsedCSVData.length > 0 &&
											<Button className="float-right" size="" color="yellow" title="Upload" onClick={this.saveShipmentDatesData}>
												Save Shipment Dates
											</Button>
										}
									</Fragment>
								) : (
									<Fragment>
										<Row className="pl-4 float-right">
												<Fade in="true">
													<Row className="justify-content-center">
														<Loader type="ThreeDots" color="#ffc108" height={30} width={200}/>
													</Row>
												</Fade>
										</Row>
									</Fragment>
								)}
								</Col>
							</Row>
							<Row className="pt-3">
								<Col xs="6" md="6" lg="6">
									{this.state.errorMsg && <p className="error">{this.state.errorMsg}</p>}
								</Col>
							</Row>
							</Fragment>
						) : (
							<Row className="pl-4 pl-4">
									<Fade in="true">
										<Row className="justify-content-center">
											<Loader type="ThreeDots" color="#ffc108" height={30} width={200}/>
										</Row>
									</Fade>
							</Row>
						)}
          </CardBody>


        </Card>
				<Card className="card-hover">
          <CardBody>
						<ReactTable
							style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
							columns={[
								{
									Header: "Serial Number",
									accessor: "SerialNumber",
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
								{
									Header: "Invoice Date",
									accessor: "InvoiceDate",
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{Moment(row.value).format('MM/DD/YYYY')}</div>
										)
								},
							]}
							defaultPageSize={15}
							sortable={true}
							showPaginationBottom={true}
							className="-striped -highlight"
							data={data}
							filterable
							filterAll={true}
							defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
							getTrGroupProps={(state, rowInfo, column, instance) => {
											if (rowInfo !== undefined) {
													return {
															onClick: (e, handleOriginal) => {
																//console.log('It was in this row:', rowInfo)
																//console.log('with ID:', rowInfo.original.categoryID);

																//this.goToRepairLog(rowInfo.original.repairId);
															}
													}
											}}
									}
						/>
					</CardBody>
        </Card>
				<ToastContainer/>
      </Fragment>
		);
	}
}

export default BatchShipmentDateUpload;
