import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class LiveInventoryList extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			bomData: [],
			userMarket: 0,
			isInternationalTech: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		//this.toggle4 = this.toggle4.bind(this);
		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);

  }


	async componentDidMount() {

		var userMarket = 0;

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="europe") {
			userMarket = 1;
			this.setState({ isInternationalTech: true, userMarket });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="aus") {
			userMarket = 2;
			this.setState({ isInternationalTech: true, userMarket });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="brazil") {
			userMarket = 3;
			this.setState({ isInternationalTech: true, userMarket });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="taiwan") {
			userMarket = 4;
			this.setState({ isInternationalTech: true, userMarket });
		}

		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']=="colombia") {
			userMarket = 5;
			this.setState({ isInternationalTech: true, userMarket });
		}

		this.getBOMData(userMarket);
		//this.intervalID = setInterval(this.getBOMData, 5000);
		this.intervalID = setInterval(()=>{ this.getBOMData(this.state.userMarket); }, 5000);
	}

	async onRadioBtnClick(userMarket) {

		await this.setState({ userMarket });

		//setTimeout(() => this.getBOMData(userMarket), 300);
		this.getBOMData(userMarket);

	}

	componentWillUnmount() {
		//console.log("Laser-Usage componentWillUnmount");
		clearInterval(this.intervalID);
	}

	getBOMData = async (userMarket) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-bill-of-materials?MarketID="+userMarket;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all BOM data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ bomData: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));

		if (id == 'datecreated') {
			var dateString = Moment(row[id]).format('MMM Do YYYY');
			return (
					dateString !== undefined ?
							String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}

  render() {

		const data3 = this.state.bomData.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				bomID: prop.id,
				PartNumber: prop.PartNumber,
        Cost: prop.Cost,
				Description: prop.Description,
				Quantity: prop.Quantity,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		return (
			<Fragment>
				<Row>
					<Col md="12">
						<Card className="card-hover">
							<CardBody>
								<Row className="mb-3">
									<Col md="3">
										<h1 style={{color:'#737782'}}>Bill of Materials</h1>
									</Col>
									<Col md="6">
										{!this.state.isInternationalTech && this.state.currentUser.payload['custom:role']>1 &&
										<div className="mt-1 mb-1">
											<ButtonGroup style={{ width: '100%' }}>
												<Button
													style={{width: '100%'}}
													color="btngray"
													onClick={() => this.onRadioBtnClick(0)}
													active={this.state.userMarket === 0}
												>
													US Inventory
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(1)}
													active={this.state.userMarket === 1}
												>
													Europe Inventory
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(2)}
													active={this.state.userMarket === 2}
												>
													Australia Inventory
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(3)}
													active={this.state.userMarket === 3}
												>
													Brazil Inventory
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(4)}
													active={this.state.userMarket === 4}
												>
													Taiwan Inventory
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(5)}
													active={this.state.userMarket === 5}
												>
													Colombia Inventory
												</Button>
											</ButtonGroup>
										</div>
										}
									</Col>
									<Col md="3">
									</Col>
								</Row>
								<ReactTable
									style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
									columns={[
										{
											Header: "Part Number",
											accessor: "PartNumber",
											minWidth: 15,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "Cost",
											accessor: "Cost",
											minWidth: 15,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "Quantity",
											accessor: "Quantity",
											minWidth: 15,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "Description",
											accessor: "Description",
											minWidth: 40,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										}
									]}
									defaultPageSize={15}
									sortable={true}
									showPaginationBottom={true}
									className="-striped -highlight"
									data={data3}
									filterable
									filterAll={true}
									defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
									getTrGroupProps={(state, rowInfo, column, instance) => {
													if (rowInfo !== undefined) {
															return {
																	onClick: (e, handleOriginal) => {
																		console.log('It was in this row:', rowInfo)
																		console.log('with ID:', rowInfo.original.categoryID);

																		//this.goToRepairLog(rowInfo.original.repairId);
																	},
																	style: {
																			cursor: 'pointer'
																	}
															}
													}}
											}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Fragment>
		);
	}
}

export default LiveInventoryList;
