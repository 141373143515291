
import React, { Component, Fragment, useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardTitle,
	Row,
	Col,
	Button,
	Fade,
	ButtonGroup
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { authenticationService } from '../../jwt/_services';

import '../../assets/calculator/css/calculator.css';

import {
	DashboardMain,
	TopCompletedRepairs,
	TopPendingRepairs,
	TotalRepairsByBatch,
	AverageRepairTime,
	TotalRepairsByUnitType,
	AverageTechnicianTime,
	GeminiVsEvo,
	TotalRepairs,
	RepairsByRegion,
	AverageAzenaRepairTime,
	TotalRepairsByTechnician,
	TopComponents,
	TopComponentsByBatch,
	IncomingRepairs,
	TopRecentRepairs,
	FlaggedRepairs,
	AusInventory,
	EuropeInventory,
	UsInventory,
	BrazilInventory,
	TaiwanInventory,
	ColombiaInventory,
	LowInventory
} from '../../components/dashboard/';

class Dashboard extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;

    this.state = {
			currentUser,
			dashboardTitle: "Dashboard - Page One",
			dashboardButtonTitle: "Dashboard - Page Two",
			dashboardCurrentPage: 1,
			showLowInventory: false,
			showUsInventory: false,
			showAusInventory: false,
			showEuropeInventory: false,
			showBrazilInventory: false,
			showTaiwanInventory: false,
			showColombiaInventory: false,
	  };

		this.toggleDashBoardPage = this.toggleDashBoardPage.bind(this);
		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
		//this.testCall = this.testCall.bind(this);

  }

	async componentDidMount() {

		await this.getQuantityFlaggedData(0);
		await this.getQuantityFlaggedData(1);
		await this.getQuantityFlaggedData(2);
		await this.getQuantityFlaggedData(3);
		await this.getQuantityFlaggedData(4);
		await this.getQuantityFlaggedData(5);

		//this.getData();
		if (this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']!="us" && this.state.currentUser.payload['custom:market']!="us-azena") {
			this.setState({ dashboardCurrentPage: 2, dashboardTitle: "Dashboard" });
		}
	}

	componentWillUnmount() {

	}

	async onRadioBtnClick(dashboardCurrentPage) {

		await this.setState({ dashboardCurrentPage });

	}

	toggleDashBoardPage() {

		if (this.state.dashboardCurrentPage==1) {
			this.setState({ dashboardCurrentPage: 2, dashboardTitle: "Dashboard - Page Two", dashboardButtonTitle: "Dashboard - Page One"});
		} else {
			this.setState({ dashboardCurrentPage: 1, dashboardTitle: "Dashboard - Page One", dashboardButtonTitle: "Dashboard - Page Two"});
		}

	}

	getData = async () => {

		try {

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/all-devices";

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all devices: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({ devices: body.results });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

    //this.setState({ devicesLoaded: true });
	};

	getQuantityFlaggedData = async (market) => {

		try {

			var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-inventory-quantity-flagged-items?MarketID="+market;

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("getAusData body response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				if (body.response.length>0) {

					this.setState({ showLowInventory: true });

					if (market==0) {
            this.setState({ showUsInventory: true });
          } else if (market==1) {
            this.setState({ showEuropeInventory: true });
          } else if (market==2) {
            this.setState({ showAusInventory: true });
          } else if (market==3) {
            this.setState({ showBrazilInventory: true });
          } else if (market==4) {
            this.setState({ showTaiwanInventory: true });
          } else if (market==5) {
            this.setState({ showColombiaInventory: true });
          }

				}

			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getQuantityFlaggedDataEurope = async () => {

		try {

			var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-inventory-quantity-flagged-items?MarketID=1";

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("getAusData body response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				if (body.response.length>0) {
					this.setState({ showEuropeInventory: true });
				}

			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	getQuantityFlaggedDataUS = async () => {

		try {

			var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-inventory-quantity-flagged-items?MarketID=0";

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("getAusData body response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				if (body.response.length>0) {
					this.setState({ showUsInventory: true });
				}

			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};


	render() {

		var market = "us";
		var isInternationalUser = false;
		console.log("isInternationalUser: false");

		if (this.state.currentUser.payload['custom:market']) {
			market = this.state.currentUser.payload['custom:market'];
			console.log("dashboard market: " + JSON.stringify(market));

			if (market!="us" && market!="us-azena") {
				isInternationalUser = true;
				console.log("isInternationalUser: true");
			}
		}

		return (
			<div>
				<Row className="pb-2">
					<Col xs="6" md="6" lg="6" className="text-left">
						<h1 style={{color:'#ffc108'}}>{this.state.dashboardTitle}</h1>
					</Col>
					<Col xs="2" md="2" lg="2" className="text-left">
					</Col>
					<Col xs="4" md="4" lg="4" className="text-right">
						{
							// <Button style={{width: '300px'}} color="primary" size='lg' onClick={this.toggleDashBoardPage}>
							// 	{this.state.dashboardButtonTitle}
							// </Button>
						}
						{!isInternationalUser &&
							<div className="">
								<ButtonGroup style={{ width: '100%' }}>
									<Button
										style={{width: '100%'}}
										color="btngray"
										onClick={() => this.onRadioBtnClick(1)}
										active={this.state.dashboardCurrentPage === 1}
									>
										Page 1
									</Button>
									<Button
										className="ml-1"
										style={{width: '100%'}}
										color="btngray"
										onClick={() => this.onRadioBtnClick(2)}
										active={this.state.dashboardCurrentPage === 2}
									>
										Page 2
									</Button>
									<Button
										className="ml-1"
										style={{width: '100%'}}
										color="btngray"
										onClick={() => this.onRadioBtnClick(3)}
										active={this.state.dashboardCurrentPage === 3}
									>
										Page 3
									</Button>
								</ButtonGroup>
							</div>
						}
					</Col>
				</Row>
				{!isInternationalUser &&
					<Fragment>
					{this.state.showLowInventory &&
						<Row>
							<Col md="12">
								<LowInventory onRadioBtnClick={this.onRadioBtnClick} />
							</Col>
						</Row>
					}
					</Fragment>
				}
				{isInternationalUser ? (
					<Fragment>
						<Fade in="true">
							<Row>
								<Col md="12">
									<TopRecentRepairs />
								</Col>
							</Row>
							<Row>
								<Col md="6">
									<TopCompletedRepairs />
								</Col>
								<Col md="6">
									<TopPendingRepairs />
								</Col>
							</Row>
						</Fade>
					</Fragment>
				) : (
					<Fragment>
					{this.state.dashboardCurrentPage==1 &&
						<Fragment>
							<Fade in="true">
								<Row>
									<Col md="4">
										<Row>
											<Col md="12">
												<FlaggedRepairs />
											</Col>
											<Col md="12">
												<AverageRepairTime />
											</Col>
										</Row>
									</Col>
									<Col md="4">
										<Row>
											<Col md="12">
												<TotalRepairs />
											</Col>
											<Col md="12">
												<AverageAzenaRepairTime />
											</Col>
										</Row>
									</Col>
									<Col md="4">
										<Row>
											<Col md="12">
												<IncomingRepairs />
											</Col>
											<Col md="12">
												<RepairsByRegion />
											</Col>
										</Row>
									</Col>
								</Row>
								<Row>
									<Col md="4">
										<TotalRepairsByBatch />
									</Col>
									<Col md="4">
										<Row>
											<Col md="12">
												<TotalRepairsByTechnician />
											</Col>
										</Row>
									</Col>
									<Col md="4">
										<TotalRepairsByUnitType />
									</Col>
								</Row>
							</Fade>
						</Fragment>
					}
					{this.state.dashboardCurrentPage==2 &&
						<Fragment>
							<Fade in="true">
								<Row>
									<Col md="12">
										<TopRecentRepairs />
									</Col>
								</Row>
								<Row>
									<Col md="6">
										<TopCompletedRepairs />
									</Col>
									<Col md="6">
										<TopPendingRepairs />
									</Col>
								</Row>
							</Fade>
						</Fragment>
					}
					{this.state.dashboardCurrentPage==3 &&
						<Fragment>
							<Row>
								{this.state.showUsInventory &&
									<Col md="12">
										<Row>
											<Col md="12">
												<UsInventory />
											</Col>
										</Row>
									</Col>
								}
								{this.state.showEuropeInventory &&
									<Col md="12">
										<Row>
											<Col md="12">
												<EuropeInventory />
											</Col>
										</Row>
									</Col>
								}
								{this.state.showAusInventory &&
									<Col md="12">
										<Row>
											<Col md="12">
												<AusInventory />
											</Col>
										</Row>
									</Col>
								}
								{this.state.showBrazilInventory &&
									<Col md="12">
										<Row>
											<Col md="12">
												<BrazilInventory />
											</Col>
										</Row>
									</Col>
								}
								{this.state.showTaiwanInventory &&
									<Col md="12">
										<Row>
											<Col md="12">
												<TaiwanInventory />
											</Col>
										</Row>
									</Col>
								}
								{this.state.showColombiaInventory &&
									<Col md="12">
										<Row>
											<Col md="12">
												<ColombiaInventory />
											</Col>
										</Row>
									</Col>
								}
							</Row>
						</Fragment>
					}
					</Fragment>
				)}
			</div>
		);
	}
}

export default Dashboard;
