
import React, { Component, Fragment, useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardTitle,
	Row,
	Col,
	Button,
	ButtonGroup
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { authenticationService } from '../../jwt/_services';

import {
	G1Calibration,
  G2Calibration
} from '../../components/calibration/';

class Calibration extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;

    this.state = {
			currentUser,
      calibrationType: 0,
	  };

		//this.handleMessage = this.handleMessage.bind(this);
		//this.testCall = this.testCall.bind(this);
    this.onTabBtnClick = this.onTabBtnClick.bind(this);

  }

	componentDidMount() {

		//this.getData();
	}

  async onTabBtnClick(calibrationTypeSelected) {
		this.setState({ calibrationType: calibrationTypeSelected });
	}

	componentWillUnmount() {

	}

	getData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com/v1/all-devices";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all devices: " + JSON.stringify(body));

		if (body.status == 'Success') {
			//this.setState({ devices: body.results });
		} else {
      //ADD ERROR MESSAGE
    }

    //this.setState({ devicesLoaded: true });
	};


	render() {
		return (
			<div>
        <Row>
					<Col md="4">
					</Col>
          <Col md="4">
	          <div className="mt-1 mb-1 pb-4">
	            <ButtonGroup style={{ width: '100%' }}>
	              <Button
	                style={{width: '100%'}}
	                color="btngray"
	                onClick={() => this.onTabBtnClick(0)}
	                active={this.state.calibrationType === 0}
	              >
	                G1 Calibration
	              </Button>
	              <Button
	                className="ml-1"
	                style={{width: '100%'}}
	                color="btngray"
	                onClick={() => this.onTabBtnClick(1)}
	                active={this.state.calibrationType === 1}
	              >
	                G2 Calibration
	              </Button>
	            </ButtonGroup>
	          </div>
          </Col>
					<Col md="4">
					</Col>
        </Row>
        <Row>
					<Col md="12">
            {this.state.calibrationType==0 ? (
              <G1Calibration />
            ) : (
              <G2Calibration />
            )}
					</Col>
				</Row>
			</div>
		);
	}
}

export default Calibration;
