import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import { pdf, Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';
import logo from '../../../assets/images/logoBW.png';
import repairTravelerImg from '../../../assets/images/RepairTravelerImg.png';

const repairOrderStyle = StyleSheet.create({
  page: { backgroundColor: '#ffffff' },
  section: { color: 'white', textAlign: 'center', margin: 30 },
  headerContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
  dateContainer: {
		width: '30%',
		padding: 5,
    marginTop: 5
  },
  middleContainer: {
		width: '25%',
		padding: 5,
    marginTop: 5
  },
  addressContainer: {
		width: '35%',
		padding: 5,
    marginTop: 5
  },
  addresslabel: {
    fontSize: 10,
    padding: 1,
  },
  addresslabelBold: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold',
    padding: 1,
  },
  logo: {
		width: 66,
		height: 60,
		marginLeft: 'auto',
		marginRight: 'auto',
		padding: 10
  },
  travelerImage: {
		width: '100%',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '5px'
  },
  row: {
		flexDirection: 'row',
		borderBottomColor: '#000',
		borderBottomWidth: 1,
		alignItems: 'center',
		height: 30,
		fontSize: 12,
		fontStyle: 'bold',
  },
  description: {
		width: '80%',
		textAlign: 'right',
		borderRightColor: '#000',
		borderRightWidth: 1,
		paddingRight: 8,
		color: '#000',
  },
  total: {
		width: '20%',
		textAlign: 'center',
		paddingRight: 8,
		color: '#000',
  },
	repairNotes: {
    marginLeft: 12,
    marginRight: 12,
    fontSize: 9,
    textAlign: 'justify',
		color: '#000',
  },
});

class GenRepairOrder extends React.Component {

  async componentDidMount() {

    //console.log("MyDocument procedure data: " + JSON.stringify(this.props.data));
	}

  render() {
         return (
					 <Document>
						 <Page size="A4" style={repairOrderStyle.page}>
							 <View style={repairOrderStyle.headerContainer}>
                 <Image style={repairOrderStyle.logo} src={logo} />
								 <View style={repairOrderStyle.dateContainer}>
								 		<Text style={repairOrderStyle.addresslabel}>Repair Order / Log ID: 3232</Text>
                    <Text style={repairOrderStyle.addresslabel}>Under Warranty: YES</Text>
                    <Text style={repairOrderStyle.addresslabel}>Warranty Date: 07-25-2021</Text>
							   </View>
                 <View style={repairOrderStyle.middleContainer}>
										<Text style={repairOrderStyle.addresslabelBold}>Unit Number: 00013</Text>
                    <Text style={repairOrderStyle.addresslabel}>Number of Repairs: 4</Text>
							   </View>
                 <View style={repairOrderStyle.addressContainer}>
                    <Text style={repairOrderStyle.addresslabelBold}>Arrival Date: _______________</Text>
										<Text style={repairOrderStyle.addresslabel}>Return To: ( UPI / Customer )</Text>
                    <Text style={repairOrderStyle.addresslabel}>State: CA</Text>
							   </View>
						   </View>
							 <Text style={repairOrderStyle.repairNotes}><b>Detailed Issue:</b> The Gemini laser is not cutting because the unit has no laser power output (0 Watts) on all wavelengths. Upon inspection, the laser fiber delivery system has somehow been disconnected from the optical connector. The laser fiber delivery system has been reconnected and secured back in place. The laser power output on all wavelengths has been tested and verified to be stable afterward. The software of the unit (display too) has been updated to the latest version, and the unit has been re-calibrated to be within specifications. Sealant has been applied to the rear of the buttons and the top housing plastic piece to prevent corrosion incidents from happening in the future.</Text>
               <Image style={repairOrderStyle.travelerImage} src={repairTravelerImg} />
						 </Page>
					 </Document>
     )}
}

class Sample extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			repairLogs: [],
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			searchTerm: "",
			startDate: "",
			endDate: "",
			currentDate: Moment().format('YYYY-MM-DD'),
			currentDataSetting: 0,
      modal: false,
	  };

		//this.toggle4 = this.toggle4.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);

  }

	async componentDidMount() {

    //this.getData();
	}

	componentWillUnmount() {

	}

  toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	handleChange(event) {
		if (event.target.getAttribute('name') == 'startDate') {
			this.setState({startDate: event.target.value});
			this.setState({
				currentDataSetting: 1
	    });
		} else if (event.target.getAttribute('name') == 'endDate') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({endDate: event.target.value});
			this.setState({
				currentDataSetting: 1
	    });
		}

		//this.searchData("", 1, this.state.pageSize);

		var searchTerm = document.getElementById("SearchTerm").value;

		if (searchTerm && searchTerm != "") {
			console.log("searchTerm: " + searchTerm);
			//this.searchData(searchTerm);
			setTimeout(() => this.searchData(searchTerm), 1000);
		} else {
			setTimeout(() => this.searchData(""), 1000);
		}
  }

	onChange() {
		var searchTerm = document.getElementById("SearchTerm").value;

		clearInterval(this.intervalID);

		//this.setState({ currentPage: 1 });

		if (searchTerm && searchTerm != "") {
			console.log("searchTerm: " + searchTerm);
			this.searchData(searchTerm);
			//this.intervalID = setInterval(()=>{ this.searchData(searchTerm, 1, this.state.pageSize); }, 5000);
		}
	}

	clearSearch() {

		clearInterval(this.intervalID);

		document.getElementById("SearchTerm").value = "";

		this.setState({ currentDataSetting:0 });
		this.setState({ startDate: "" });
		this.setState({ endDate: "" });
		this.getData();
	}

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	getData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-in-progress-repair-logs";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all repairLogs: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ repairLogs: body.response });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	searchData = async (searchTerm) => {

		if (this.state.currentDataSetting==0) {
			var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"search-active-repair-logs?SearchTerm="+searchTerm;
			console.log("url: " + url);
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all repairLogs: " + JSON.stringify(body));

			this.setState({loading: false});

			if (body.status == 'Success') {
				this.setState({ repairLogs: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }
		} else {
			var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"search-active-repair-logs-date-range?SearchTerm="+searchTerm+"&StartDate="+this.state.startDate+"&EndDate="+this.state.endDate;
			console.log("url: " + url);
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all repairLogs: " + JSON.stringify(body));

			this.setState({loading: false});

			if (body.status == 'Success') {
				this.setState({ repairLogs: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }
		}

	};


  render() {

		return (
      <Fragment>
      <Card className="card-hover">
				<CardBody>
					<Row className="mb-3">
						<Col md="6">
							<h1 style={{color:'#737782'}}>SAMPLE</h1>
						</Col>
					</Row>
          <Row>
            <Col md="12">
              <Button className="ml-3" size="lg" color="yellow" title="Create Repair Order" onClick={() => this.toggle()}>
                Create Repair Order
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Modal isOpen={this.state.modal} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>Repair Traveler Order</ModalHeader>
        <ModalBody>
          <PDFViewer style={{ height: "650px", width: "100%"}}>
            <GenRepairOrder />
          </PDFViewer>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" className="ml-1" onClick={this.toggle}>DONE</Button>
          {
            //<Button color="primary" type="submit">Email Customer Repair Report</Button>
          }
        </ModalFooter>
      </Modal>
      </Fragment>
		);
	}
}

export default Sample;
