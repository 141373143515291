import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class TopPendingRepairs extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			repairLogs: [],
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		//this.toggle4 = this.toggle4.bind(this);

  }

	async componentDidMount() {

    this.getData();
	}

	componentWillUnmount() {

	}

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	getData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-top-pending-repair-logs";

		if (this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']==1 && this.state.currentUser.payload['custom:market'] && this.state.currentUser.payload['custom:market']!="us" && this.state.currentUser.payload['custom:market']!="us-azena") {
			console.log("top pending repairLogs Is International: " + JSON.stringify(this.state.currentUser.payload['custom:market']));
			url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-top-pending-repair-logs?Market="+this.state.currentUser.payload['custom:market'];
		}

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("top pending repairLogs: " + JSON.stringify(body));

			this.setState({loading: false});

			if (body.status == 'Success') {
				this.setState({ repairLogs: body.response });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

  render() {

		const data = this.state.repairLogs.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
				repairId: prop.id,
        serialnum: prop.serialnum,
				batchnum: prop.batchnum,
				datecreated: Moment(prop.datecreated).format('MMM Do YYYY'),
				market: prop.market,
				Status: prop.Status,
				UPIClosed: prop.UPIClosed,
				CustomerName: prop.CustomerName,
				TrackingNum: prop.TrackingNum
			};
		});

		return (
			<Fragment>
        <Card className="card-hover">
          <CardBody style={{ minheight: 675 }}>
            <Row className="mb-3">
              <Col md="12">
                <h3 style={{color:'#737782'}}>Pending Customer Approvals</h3>
              </Col>
            </Row>
						<ReactTable
						  style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
							columns={[
								{
									Header: "ID",
									accessor: "repairId",
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									minWidth: 15,
									Cell: row => (
						          <div style={{ textAlign: "center", height: "27px", marginTop: "7px" }}>{row.value}</div>
						        )
								},
								{
									Header: "Serial",
									accessor: "serialnum",
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									minWidth: 20,
									Cell: row => (
						          <div style={{ textAlign: "center" }}>{row.value}</div>
						        )
								},
								{
									Header: "Batch Number",
									accessor: "batchnum",
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									minWidth: 20,
									Cell: row => (
						          <div style={{ textAlign: "center" }}>{row.value}</div>
						        )
								},
								{
									Header: "Creation Date",
									accessor: "datecreated",
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									minWidth: 25,
									Cell: row => (
						          <div style={{ textAlign: "center" }}>{row.value}</div>
						        )
								},
								{
									Header: "Market",
									accessor: "market",
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									minWidth: 15,
									Cell: row => (
						          <div style={{ textAlign: "center" }}>{row.value}</div>
						        )
								},
								{
									Header: "Customer Name",
									accessor: "CustomerName",
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									minWidth: 50,
									Cell: row => (
						          <div style={{ textAlign: "center" }}>{row.value}</div>
						        )
								}
							]}
							defaultPageSize={10}
						  sortable={true}
							showPaginationBottom={true}
							className="-striped -highlight"
							data={data}
							getTrGroupProps={(state, rowInfo, column, instance) => {
	                    if (rowInfo !== undefined) {
	                        return {
	                            onClick: (e, handleOriginal) => {
	                              console.log('It was in this row:', rowInfo)
																console.log('with ID:', rowInfo.original.repairId);

																this.goToRepairLog(rowInfo.original.repairId);
	                            },
	                            style: {
	                                cursor: 'pointer'
	                            }
	                        }
	                    }}
	                }
						/>
          </CardBody>
        </Card>
			</Fragment>
		);
	}
}

export default TopPendingRepairs;
