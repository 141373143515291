import React from "react";
import Moment from 'moment';
import ReactTable from 'react-table-v6'

import {
	Progress,
	Card,
  CardTitle,
	CardBody,
	Row,
	Col,
	Tooltip
} from 'reactstrap';

import Chart from 'react-c3-component';
import 'c3/c3.css';
import { Line, Bar } from 'react-chartjs-2';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';


//import img1 from '../../../assets/images/background/active-bg.png';
//import img2 from '../../../assets/images/dashboard/wavelength_color_guide.png';

class IncomingRepairs extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);
    this.state = {
			incomingRepairs: [],
			noDevices: false,
			noData: false,
			tooltipOpen: false,
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.toggleTooltip = this.toggleTooltip.bind(this);
  }

	async componentDidMount() {
	 	this.getData();
		//this.intervalID = setInterval(this.getData, 5000);

		/*const moment1 = Moment('2022-03-08');
		const moment2 = Moment('2022-03-10');
		const diff = moment1.diff(moment2 , 'days')
		console.log(diff);*/
	}

	toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

	componentWillUnmount() {
		//console.log("WavelengthUsage componentWillUnmount");
		clearInterval(this.intervalID);
	}

	getData = async () => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-incoming-repairs";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("get-incoming-repairs body response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				if (body.response.length>0) {


	        this.setState({ incomingRepairs: body.response });

				} else {
					this.state.noData = true;
				}
				//this.forceUpdate();
			} else {
				this.state.noData = true;
			}

		}
		catch (err) {
			console.log(err);
			authenticationService.refresh();
		}

	};

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	render() {

		// const onRowClick = (state, rowInfo, column, instance) => {
		//     return {
		//         onClick: e => {
		//             console.log('A Td Element was clicked!')
		//             console.log('it produced this event:', e)
		//             console.log('It was in this column:', column)
		//             console.log('It was in this row:', rowInfo)
		//             console.log('It was in this table instance:', instance)
		//         }
		//     }
		// }

    const data = this.state.incomingRepairs.map((prop, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

      var start = Moment(prop.ExpectedRepairDate);
      var finish = Moment();

      var days = start.diff(finish, 'days')+" days";
      console.log("days to arrive: " + days);

			if (start.diff(finish, 'days')<0) {
				days = finish.diff(start, 'days')+" days ago";
			}

			return {
				id: key,
				repairID: prop.ID,
        SerialNum: prop.SerialNum,
				BatchNum: prop.BatchNum,
        TrackingNum: prop.TrackingNum,
        TrackingURL: "",
				daysToArrive: days
			};
		});

		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-1 [Classic]                                                  */
			/*--------------------------------------------------------------------------------*/
			<Card className="card-hover">
				<CardBody style={{ height: 250 }}>
					<Row>
						<Col xs="6" md="6" lg="6" className="text-left">
							<CardTitle>Incoming Repairs
							<i style={{color: '#ffffff'}} className="mdi mdi-information-outline ml-1" id="WavelengthUsageTooltip"/>
							<Tooltip placement="top" isOpen={this.state.tooltipOpen} target="WavelengthUsageTooltip" toggle={this.toggleTooltip}>
								Shows most recent incoming repairs.
							</Tooltip>
							</CardTitle>
						</Col>
						<Col xs="6" md="6" lg="6" className="text-right">
						</Col>
					</Row>
          <ReactTable
            style={{autocomplete: 'none'}}
            columns={[
							{
								id: 0,
                Header: "ID",
                accessor: "repairID",
                style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                minWidth: 12,
                Cell: row => (
										<div style={{ textAlign: "center" }}><a href={"/repair-log/"+row.value}>{row.value}</a></div>
                  )
              },
              {
								id: 1,
                Header: "Serial",
                accessor: "SerialNum",
                style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                minWidth: 12,
                Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
              },
              {
								id: 2,
                Header: "Batch",
                accessor: "BatchNum",
                style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                minWidth: 12,
                Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
              },
              {
								id: 3,
                Header: "Days to Arrive",
                accessor: "daysToArrive",
                style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                minWidth: 25,
                Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
              },
              {
								id: 4,
                Header: "Tracking",
                accessor: "TrackingNum",
                style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                minWidth: 40,
                Cell: row => (
                    <div style={{ textAlign: "center" }}><a href={"http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums="+row.value} target="_blank">{row.value}</a></div>
                  )
              }
            ]}
            defaultPageSize={4}
            sortable={true}
            showPaginationBottom={false}
            className="-striped -highlight"
            data={data}
            getTrGroupProps={(state, rowInfo, column, instance) => {
                    if (rowInfo !== undefined) {
                        return {
                            onClick: (e, handleOriginal) => {
                              console.log('It was in this row:', rowInfo)
                              console.log('with ID:', rowInfo.original.repairID);
															if (column !== undefined) {
																console.log('It was in this column:', column);
															}
															//console.log('It was in this state:', state);
                              //this.goToRepairLog(rowInfo.original.repairID);
                            },
                            style: {
                                cursor: 'pointer'
                            }
                        }
                    }}
                }
          />
				</CardBody>
			</Card>

		);
	}
}

export default IncomingRepairs;
