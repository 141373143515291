
import React, { Component, Fragment, useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardTitle,
	Row,
	Col,

} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { authenticationService } from '../../jwt/_services';

import {
	CustomerNotes
} from '../../components/repair-option-management/';

class CustomerNotesView extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;

    this.state = {
			currentUser,
	  };

		//this.handleMessage = this.handleMessage.bind(this);
		//this.testCall = this.testCall.bind(this);

  }

	componentDidMount() {

		//this.getData();
	}

	componentWillUnmount() {

	}


	render() {
		return (
			<div>
				<Row>
					<Col md="12">
						<CustomerNotes />
					</Col>
				</Row>
			</div>
		);
	}
}

export default CustomerNotesView;
