import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/style.scss';
//import { CometChat } from "@cometchat-pro/chat";
import Amplify from "aws-amplify";
// setup fake backend
//import { configureFakeBackend } from './jwt/_helpers';
//configureFakeBackend();
const App = require('./app').default;

//TEMP APP ID FOR TESTING
//var appID = "264308845414f92";

// var appID = "24639f1e5e1d24b";
// var region = "us";
// var appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
// CometChat.init(appID, appSetting).then(
//   () => {
//     console.log("Initialization completed successfully");
//     // You can now call login function.
//   },
//   error => {
//     console.log("Initialization failed with error:", error);
//     // Check the reason for error and take appropriate action.
//   }
// );

Amplify.configure({
      Auth: {
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      },
    });

ReactDOM.render(<
	App />, document.getElementById('root')
);
